import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Paper,
  Button,
  Container,
  Grid,
  TextField,
  Autocomplete,
  IconButton,
  CardContent,
  Box,
  Tooltip
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { userService } from "../userService";
import { disableSite } from "../features/actions/siteAction";
import { AlertBar } from "../Alert/AlertBar";
import { getEncryptPayload } from "../Network_Utility";
import {
  accountGroupDropdownUrl,
  accountGroupDropdownUrlV2,
  addTransactionUrl,
  filterAccountUrl,
  getAllState,
  voucherTypeSaveUrl,
  accountGroupDropdownUrlVoucher,
  accountTypesV2Url,
  accountTypesV1Url,
  singleVoucherGetUrl
} from "../config";
import { styles } from "../Theme/Theme";
import { Card, Typography } from "@material-ui/core";
import {
  accountTypeConfigValidation,
  templateViewList,
  validateCustomFields
} from "./util";
import { useSelector } from "react-redux";
import { checkPathExist, checkPermissionExist } from "../utils";
import { themecolor } from "../Navigation";
import { v4 as uuidv4 } from "uuid";
import { FcSettings } from "react-icons/fc";
import DragAndDropComponent from "./DynamicFields";
import TableComponentModal from "./TableComponentModal";
import Editor from "./Editor";
import LoadingComponent from "../Loader";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Header from "../CommonComps/Header";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ConfigModal from "./ConfigModal";
import {
  ChildAccountConfiguration,
  ConfigurationCheckFields,
  DetailsViewConfigurations,
  DueDataConfigurations,
  EInvoiceDocTypes,
  MandatoryCheck,
  TaggedFunction
} from "./CollapsableData";
import { getAllAccountTypes } from "../features/actions/AccountTypeAction";
import { AutoSizer, List } from "react-virtualized";
const customFieldIcon = require("../images/custom.png");

// const templateViewList = [
//   { id: 1, name: "Journal", alias: "JOURNAL" },
//   { id: 2, name: "Purchase", alias: "PURCHASE" },
//   { id: 3, name: "Sale", alias: "SALE" }
// ];

const CreateVoucherTemp = ({ classes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const reduxState = useSelector((state) => state?.rootReducer);
  const siteList = reduxState.sitesReducer?.data;
  const endPoints = reduxState?.permissionReducer?.data;
  const accountTypesState = reduxState.accountTypeReducer;
  const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));
  // ==================States=================//
  const [loading, setLoading] = useState(false);
  const [accGrps, setAccGrps] = useState([]);
  const [accountGroups, setAccountGroups] = useState([]);
  const [accountGroupsv2, setAccountGroupsv2] = useState([]);
  const [debitAccounts, setDebitAccounts] = useState([]);
  const [creditAccounts, setCreditAccounts] = useState([]);
  const [vendorName, setVendorName] = useState(null);
  const [customErrors, setCustomErrors] = useState({});
  const [tableItem, setTableItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [dynamicFields, setDynamicFields] = React.useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [editedIndex, seteditedIndex] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [stateActionList, setStateActionList] = useState([]);
  const [nextState, setNextState] = useState(null);
  const [accountTypes, setAccountTypes] = useState([]);
  const [targetAccountTypes, setTargetAccountTypes] = useState([]);

  // ===========Templates Confuiguration state's========//
  const [selectTemplateView, setSelectTemplateView] = useState(null);
  const [showTemplateView, setTemplateView] = useState(false);

  const [overDueBoolean, setOverDueBoolean] = useState(false);
  const [accountCategory, setAccountCategory] = useState(false);
  const [overDueDays, setOverDueDays] = useState(0);
  const [checkboxError, setCheckboxError] = React.useState(false);
  const [radioError, setRadioError] = React.useState(false);

  const [taggedInvoice, setTaggedInvoice] = useState(false);

  const [invoiceOrNoteNumber, setInvoiceorNotNumber] = useState(false);
  const [accountCode, setAccountCode] = useState(false);
  const [transactionType, setTransactionType] = useState(false);
  const [eInvoiceGeneration, setEInvoiceGeneration] = React.useState(false);
  const [eInvoiceGenerationImmediate, setEInvoiceGenerationImmediate] =
    useState(false);
  const [invDocType, setInvDocType] = useState(null);

  const [accountTypeChildLists, setAccountTypeChildLists] = useState([]);
  const [sourceAccountType, setSourceAccountType] = useState(null);
  const [targetAccountType, setTargetAccountType] = useState(null);
  const [sourceAccountTypeChildLists, setSourceAccountTypeChildLists] =
    useState([]);
  const [sourceAccountTypeChildList, setSourceAccountTypeChildList] =
    useState(null);
  const [targetAccountTypeChildLists, setTargetAccountTypeChildLists] =
    useState([]);
  const [targetAccountTypeChildList, setTargetAccountTypeChildList] =
    useState(null);
  const [isSourceValidation, setIsSourceValidation] = useState(true);
  const [childConfigError, setChildConfigError] = useState({});
  const [sourceAccountChildTableList, setSourceAccountChildTableList] =
    useState([]);
  const [targetAccountChildTableList, setTargetAccountChildTableList] =
    useState([]);

  const [searchDebitAccounts, setSearchDebitAccounts] = useState([]);
  const [searchCreditAccounts, setSearchCreditAccounts] = useState([]);

  // const [mappedStrings, setMappedString] = useState([])
  // ===========Alerts===================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: ""
  });

  // Function to close alert bar
  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });

  // Function to show success alert
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Data fetched succesfully",
      alertType: "success"
    });
    setLoading(false);
    setTimeout(() => {
      navigate("/voucherTemplate");
    }, 1000);
  };

  // Function to show error
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Something went wrong",
      alertType: "error"
    });
    setLoading(false);
  };

  //Function to edit created fields
  const openEditorFuntion = (index) => {
    setOpenEditor(true);
    seteditedIndex(index);
  };

  //Function to close created fields modal
  const closeEditor = () => setOpenEditor(false);

  // Function to open table modal
  const setHandleTableComponent = (item) => {
    setOpenModal(true);
    setTableItem(item);
  };

  // Function to close table modal
  const closeDialogBox = () => setOpenModal(false);

  // const addMappedString = (e) => setMappedString([...mappedStrings, e.target.value])

  function hasDuplicateNames(arr) {
    const nameSet = new Set();
    for (const obj of arr) {
      if (nameSet.has(obj.label)) {
        return true; // Duplicate label found
      }
      nameSet.add(obj.label);
    }
    return false; // No duplicate names found
  }

  //Function to save template
  const handlesaveTemplate = async () => {
    const textFieldErrors = validateCustomFields(
      vendorName,
      creditAccounts,
      debitAccounts,
      selectTemplateView,
      overDueBoolean,
      accountCategory,
      overDueDays,
      taggedInvoice,
      invoiceOrNoteNumber,
      accountCode,
      transactionType
    );

    setCustomErrors(textFieldErrors);

    if (Object.keys(textFieldErrors).length < 1) {
      if (hasDuplicateNames(dynamicFields)) {
        errorAlert("Template contains fields with similar label.");
      } else {
        const payload = {
          customFormType: vendorName,
          companyId: localStorage.getItem("companyId"),
          debitGroupIds: debitAccounts?.length
            ? debitAccounts.map((val) => val.id)
            : null,
          creditGroupIds: creditAccounts?.length
            ? creditAccounts.map((val) => val.id)
            : null,
          siteId: localStorage.getItem("siteId"),
          customFormData: { templateData: dynamicFields },
          permissionStateId: nextState?.stateId || null,
          isAvailable: nextState?.isFinal || false,
          voucherCategoryType:
            overDueBoolean === true
              ? "SALE_PURCHASE_NOTE"
              : taggedInvoice
              ? "PAYMENT"
              : "OTHER",
          voucherTempalteCategoryId: selectTemplateView?.id || null,
          isOverdueForDebit:
            accountCategory === "debit" || taggedInvoice === "debit"
              ? true
              : accountCategory === "credit" || taggedInvoice === "credit"
              ? false
              : null,
          defaultOverdueDays: overDueDays > 0 ? overDueDays : null,
          // taggedInvoice: taggedInvoice || null,
          isInvoiceMandatory: invoiceOrNoteNumber || false,
          isAccountCode: accountCode || null,
          isTransactionType: transactionType || null,
          isSourceChildAccountType:
            sourceAccountChildTableList?.length > 0 ? true : false,
          isTargetChildAccountType:
            targetAccountChildTableList?.length > 0 ? true : false,
          sourceChildAccountTypeDtoList: sourceAccountChildTableList?.map(
            (ele) => {
              return {
                customFormTypeId: null,
                childAccountTypeId: ele?.child?.id,
                isActive: true,
                createdBy: +localStorage.getItem("userId"),
                updatedBy: +localStorage.getItem("userId"),
                isForSource: true,
                accountTypeId: ele?.type?.id
              };
            }
          ),
          targetChildAccountTypeDtoList: targetAccountChildTableList?.map(
            (ele) => {
              return {
                customFormTypeId: null,
                childAccountTypeId: ele?.child?.id,
                isActive: true,
                createdBy: +localStorage.getItem("userId"),
                updatedBy: +localStorage.getItem("userId"),
                isForSource: false,
                accountTypeId: ele?.type?.id
              };
            }
          ),
          isInvoiceGenerationMandatory: eInvoiceGeneration || false,
          isInvoiceGenerationImmediate: eInvoiceGenerationImmediate || false,
          invDocType: invDocType || null
        };

        const encryptedPayload = getEncryptPayload(payload, true);
        try {
          setLoading(true);
          const response = await userService.postData(
            voucherTypeSaveUrl,
            encryptedPayload
          );
          if (response && response.data && response.data.status === 200) {
            successAlert(response?.data?.message);
          } else errorAlert(response?.data?.message);
        } catch (error) {
          errorAlert();
        }
      }
    }
  };

  console.log(targetAccountChildTableList, sourceAccountChildTableList);

  const handleAccountChange = (event, newValue, accountType) => {
    if (!newValue) return;

    const targetAccounts =
      accountType === "debit" ? creditAccounts : debitAccounts;

    if (accountType === "debit") {
      setDebitAccounts([...debitAccounts, newValue]);
      setSearchDebitAccounts([...debitAccounts, newValue]);
    } else {
      setCreditAccounts([...creditAccounts, newValue]);
      setSearchCreditAccounts([...creditAccounts, newValue]);
    }

    // const isPresent = targetAccounts && targetAccounts.some(val => val.id === newValue.id);

    // if (isPresent) {
    //     errorAlert(`Selected account group is already present in ${accountType === 'debit' ? 'credit' : 'debit'} account group.`);
    // } else {
    //     if (accountType === 'debit') setDebitAccounts([...debitAccounts, newValue]);
    //     else setCreditAccounts([...creditAccounts, newValue]);
    // }
  };

  // Debounce function
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  // Debounced event handler
  const debounceData = debounce(async (event, value) => {
    const payload = { siteId: null, searchField: event.target.value };

    if (event.target.value) {
      const valString = event.target.value;
      if (valString.length > 2) {
        setLoading(true);
        try {
          const encryptedPayload = getEncryptPayload(payload, true);

          const res = await userService.postData(
            accountGroupDropdownUrlVoucher,
            encryptedPayload
          );

          if (res?.data?.status === 200) {
            const { data } = res.data;

            const filteredAccgroup = (data || []).filter(
              (val) => val.siteId != null
            );

            const arrayOfGroup = filteredAccgroup
              .map((group) => {
                const siteMatch = siteList.find(
                  (site) => site.id === group.siteId
                );
                if (siteMatch) {
                  return {
                    ...group,
                    siteName: siteMatch.name
                  };
                }
                return null;
              })
              .filter(Boolean);

            setAccountGroupsv2(arrayOfGroup);
            // setAccountGroups(arrayOfGroup);
            // setDebitAccounts([]);
            // setCreditAccounts([]);
            setLoading(false);
          } else {
            setLoading(false);
            setAccountGroupsv2([]);
            // setAccountGroups([]);
            // setDebitAccounts([]);
            // setCreditAccounts([]);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    } else {
      // setAccountGroups([]);
      // setDebitAccounts([]);
      // setCreditAccounts([]);
    }
  }, 300); // Adjust debounce time as needed

  // Function to fetch account groups
  const getAccountGroup = async () => {
    try {
      const payload = { siteId: null };
      const encryptedPayload = getEncryptPayload(payload, true);

      const res = await userService.postData(
        accountGroupDropdownUrlVoucher,
        encryptedPayload
      );

      if (res?.data?.status === 200) {
        const { data } = res.data;

        const filteredAccgroup = (data || []).filter(
          (val) => val.siteId != null
        );

        const arrayOfGroup = filteredAccgroup
          .map((group) => {
            const siteMatch = siteList.find((site) => site.id === group.siteId);
            if (siteMatch) {
              return {
                ...group,
                siteName: siteMatch.name
              };
            }
            return null;
          })
          .filter(Boolean);

        setAccountGroups(arrayOfGroup);
        setDebitAccounts([]);
        setCreditAccounts([]);
      } else {
        setAccountGroups([]);
        setDebitAccounts([]);
        setCreditAccounts([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    dispatch(disableSite(false));
    // getAccountGroup();
    // eslint-disable-next-line
  }, []);

  const filterAccountGroups = (inputValue, type) => {
    if (!inputValue) {
      setAccGrps([]);
      return;
    }

    const filteredGrps = accountGroups.filter((val) =>
      val.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    if (filteredGrps && filteredGrps.length > 0) {
      setAccGrps(filteredGrps);
    }
    // else {
    //   type == "credit"
    //     ? setCustomErrors({
    //         ...customErrors,
    //         creditAccounts: "No accounts found",
    //       })
    //     : setCustomErrors({
    //         ...customErrors,
    //         debitAccounts: "No accounts found",
    //       });
    // }
  };

  const handleKeyPress = (e, type) => {
    filterAccountGroups(e.target.value, type);
  };

  const getPermissionCombinationList = async (url) => {
    const payload = {
      permissionId:
        endPoints && endPoints.find((val) => val.apiEndpoint === `/${url}`)?.id,
      permissionStateId: null,
      isActive: true,
      roleId: null
      // rolePermission && rolePermission.length > 0
      // ? rolePermission.map((ele) => ele?.roleId)
      // : [],
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      const res = await userService.getPermissionCombination(
        getAllState,
        encryptedPayload
      );
      if (res && res.data && res.data.status) {
        let data = res?.data?.data.map((val) => ({
          ...val,
          stateId: val?.stateId?.id,
          name: val?.stateId?.name
        }));
        setStateActionList(data);
        // if (data && data.length > 0) {
        //   setNextState(data[0]);
        // }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPermissionCombinationList(addTransactionUrl);
  }, []);

  // Function to get account  types list
  // Function to get account  types list
  // const getAccountTypes = async () => {
  //   const permissionExist = checkPermissionExist(endPoints, accountTypesV1Url);
  //   // const isPath = checkPathExist(endPoints, window.location.pathname);
  //   if (permissionExist) {
  //     let payload = { isChildAccountCreationNeeded: true };
  //     let encryptedPayload = getEncryptPayload(payload, true);
  //     dispatch(getAllAccountTypes(encryptedPayload));
  //   } else {
  //     errorAlert("Permission Denied for account type");
  //   }
  // };

  const getTemplateDataById = async (id) => {
    const companyId = localStorage.getItem("companyId");
    const apiEndpoint = `${singleVoucherGetUrl}?companyId=${companyId}&templateTypeId=${id}`;

    try {
      setLoading(true);
      const response = await userService.getData(apiEndpoint);

      if (response && response.data && response.data.status === 200) {
        const {
          creditGroup = [],
          debitGroup = [],
          templateData,
          permissionStateId,
          voucherTempalteCategoryId,
          voucherCategoryType,
          isOverdueForDebit,
          defaultOverdueDays,
          taggedInvoice,
          isInvoiceMandatory,
          isAccountCode,
          isTransactionType,
          isInvoiceGenerationMandatory,
          isInvoiceGenerationImmediate,
          invDocType,
          sourceChildAccountTypeDtoList,
          targetChildAccountTypeDtoList
        } = response.data.data;

        // setPermissionStateId(permissionStateId);
        const targetObj = [];
        for (let i = 0; i < targetChildAccountTypeDtoList.length; i++) {
          const ele = targetChildAccountTypeDtoList[i];
          targetObj.push(ele?.accountTypeId);
        }
        // setTargetExistData(targetObj);
        const sourceObj = [];
        for (let i = 0; i < sourceChildAccountTypeDtoList.length; i++) {
          const ele = sourceChildAccountTypeDtoList[i];
          sourceObj.push(ele?.accountTypeId);
        }
        // setSourceExistData(sourceObj);

        const filteredSourceArray = accountTypesState?.data.filter(
          (item) => !sourceObj?.includes(item.id)
        );
        setAccountTypes(filteredSourceArray);
        const filteredTargetArray = accountTypesState?.data.filter(
          (item) => !targetObj?.includes(item.id)
        );
        setTargetAccountTypes(filteredTargetArray);
        // console.log(filteredSourceArray, sourceObj)

        //       setAccountTypes(accountTypesState?.data);

        setTargetAccountChildTableList(
          targetChildAccountTypeDtoList?.map((ele) => {
            return {
              id: ele?.id,
              type: { id: ele?.accountTypeId, name: ele?.accountTypeDto?.name },
              child: {
                id: ele?.childAccountTypeId,
                childAccountNamePrifixSubString:
                  ele?.childAccountTypeDto?.childAccountNamePrifixSubString,
                childAccountNameSufixSubString:
                  ele?.childAccountTypeDto?.childAccountNameSufixSubString
              }
            };
          })
        );

        setSourceAccountChildTableList(
          sourceChildAccountTypeDtoList?.map((ele) => {
            return {
              id: ele?.id,
              type: { id: ele?.accountTypeId, name: ele?.accountTypeDto?.name },
              child: {
                id: ele?.childAccountTypeId,
                childAccountNamePrifixSubString:
                  ele?.childAccountTypeDto?.childAccountNamePrifixSubString,
                childAccountNameSufixSubString:
                  ele?.childAccountTypeDto?.childAccountNameSufixSubString
              }
            };
          })
        );

        const allGroups = [...creditGroup, ...debitGroup];
        const filteredGroups = allGroups.filter(
          (group) => group.siteId != null
        );

        const arrayOfGroup = filteredGroups
          .map((group) => {
            const siteMatch = siteList.find((site) => site.id === group.siteId);
            if (siteMatch) {
              return { ...group, siteName: siteMatch.name };
            }
            return null;
          })
          .filter(Boolean);

        setAccountGroups(arrayOfGroup);

        const addSiteName = (groups) => {
          return groups.map((group) => {
            const matchingAccountGroup = arrayOfGroup.find(
              (val) => val.id === group.id
            );
            return {
              ...group,
              siteName: matchingAccountGroup?.siteName || null
            };
          });
        };

        const arrayOfCreditGroup = addSiteName(creditGroup);
        const arrayOfDebitGroup = addSiteName(debitGroup);
        const voucherTemplate = templateViewList.filter(
          (ele) => ele.id === voucherTempalteCategoryId
        );

        setCreditAccounts(arrayOfCreditGroup);
        setDebitAccounts(arrayOfDebitGroup);
        setSearchDebitAccounts(arrayOfDebitGroup);
        setSearchCreditAccounts(arrayOfCreditGroup);
        setDynamicFields(templateData);
        setSelectTemplateView(voucherTemplate[0]);
        setOverDueBoolean(
          voucherCategoryType === "SALE_PURCHASE_NOTE" ? true : false
        );
        setAccountCategory(
          isOverdueForDebit === true &&
            voucherCategoryType === "SALE_PURCHASE_NOTE"
            ? "debit"
            : isOverdueForDebit === false &&
              voucherCategoryType === "SALE_PURCHASE_NOTE"
            ? "credit"
            : null
        );
        setOverDueDays(defaultOverdueDays || 0);
        setTaggedInvoice(
          isOverdueForDebit === true && voucherCategoryType === "PAYMENT"
            ? "debit"
            : isOverdueForDebit === false && voucherCategoryType === "PAYMENT"
            ? "credit"
            : null
        );
        setInvoiceorNotNumber(isInvoiceMandatory || false);
        setAccountCode(isAccountCode || false);
        setTransactionType(isTransactionType || false);
        setEInvoiceGeneration(isInvoiceGenerationMandatory || false);
        setEInvoiceGenerationImmediate(isInvoiceGenerationImmediate || false);
        setInvDocType(invDocType || null);
        // localStorage.removeItem("stateHistory");
      } else {
        errorAlert(response?.data?.message);
      }
    } catch (error) {
      console.error(error);
      errorAlert(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const localSearchHandle = (e, name) => {
    if (name === "Debit") {
      const tempData = debitAccounts?.map((ele, i) => {
        const jointValue = ele?.name + ele?.siteName;
        return {
          ...ele,
          searchValues: jointValue.toLowerCase()
        };
      });
      const val = e.target.value.toLowerCase();
      const filteredData = tempData?.filter((ele) =>
        ele.searchValues.includes(val)
      );
      setSearchDebitAccounts(filteredData);
    } else {
      const tempData = creditAccounts?.map((ele, i) => {
        const jointValue = ele?.name + ele?.siteName;
        return {
          ...ele,
          searchValues: jointValue.toLowerCase()
        };
      });
      const val = e.target.value.toLowerCase();
      const filteredData = tempData?.filter((ele) =>
        ele.searchValues.includes(val)
      );
      setSearchCreditAccounts(filteredData);
    }
  };

  useEffect(() => {
    if (location && location.state && location.state) {
      const { id, name } = location.state;
      getTemplateDataById(id);
      setVendorName(name);
    }
  }, [location]);

  useEffect(() => {
    setAccountTypes(accountTypesState?.data);
    setTargetAccountTypes(accountTypesState?.data);
  }, [accountTypesState]);

  // useEffect(() => {
  //   getTemplateDataById();
  // }, []);

  // useEffect(() => {
  //   getAccountTypes();
  // }, []);

  const addSourceAccountChildHandle = () => {
    // Validate the input fields
    const isValidate = accountTypeConfigValidation(
      true,
      sourceAccountType,
      sourceAccountTypeChildList
    );
    setChildConfigError(isValidate);

    if (Object.keys(isValidate).length === 0) {
      // Check if an item with the same name or suffixName already exists

      // If no such item exists, add the new item
      const obj = {
        type: sourceAccountType,
        child: sourceAccountTypeChildList,
        id: uuidv4()
      };
      setSourceAccountChildTableList([...sourceAccountChildTableList, obj]);
      const removeFromList =
        accountTypes &&
        accountTypes.length > 0 &&
        accountTypes.filter((ele) => ele.id !== sourceAccountType.id);
      setAccountTypes(removeFromList);
      setSourceAccountType(null);
      setSourceAccountTypeChildList(null);
    }
  };

  const deleteSourceAccountChildHandle = (id) => {
    if (id) {
      const findDeletedData = accountTypesState?.data?.find(
        (ele) => ele.id === id?.type?.id
      );
      setAccountTypes([...accountTypes, findDeletedData]);
      const findDeletedDataList = sourceAccountChildTableList?.filter(
        (ele) => ele.id !== id?.id
      );
      setSourceAccountChildTableList(findDeletedDataList);
    }
  };

  const addTargetAccountChildHandle = () => {
    // debugger
    // Validate the input fields
    const isValidate = accountTypeConfigValidation(
      false,
      targetAccountType,
      targetAccountTypeChildList
    );
    setChildConfigError(isValidate);

    if (Object.keys(isValidate).length === 0) {
      // If no such item exists, add the new item
      const obj = {
        type: targetAccountType,
        child: targetAccountTypeChildList,
        id: uuidv4()
      };
      setTargetAccountChildTableList([...targetAccountChildTableList, obj]);
      const removeFromList =
        targetAccountTypes &&
        targetAccountTypes.length > 0 &&
        targetAccountTypes.filter((ele) => ele.id !== targetAccountType.id);
      setTargetAccountTypes(removeFromList);
      setTargetAccountType(null);
      setTargetAccountTypeChildList(null);
    }
  };
  const deleteTargetAccountChildHandle = (id) => {
    if (id) {
      const findDeletedData = accountTypesState?.data?.find(
        (ele) => ele.id === id?.type?.id
      );
      setTargetAccountTypes([...accountTypes, findDeletedData]);
      const findDeletedDataList = targetAccountChildTableList?.filter(
        (ele) => ele.id !== id?.id
      );
      setTargetAccountChildTableList(findDeletedDataList);
    }
  };

  // need to create two state sourceAccountType and targetAccountType. monday done

  console.log(eInvoiceGeneration, eInvoiceGenerationImmediate, "vagsbsh");

  return (
    <>
      <Paper className={classes.paper} elevation={0} style={{ width: "100%" }}>
        <Container style={{ maxWidth: "100%" }}>
          <Grid container style={{ margin: "0", alignItems: "center" }}>
            <Header
              gridSize="12"
              source={customFieldIcon}
              headerText="Add Voucher Template"
            />
          </Grid>

          {/* ============Voucher Temporary Fields============ */}
          <Grid container marginTop={2} justifyContent="space-between">
            <Grid item xs={4}>
              <TextField
                label="Voucher"
                style={{ width: "300px" }}
                variant="outlined"
                size="small"
                margin="dense"
                value={vendorName || ""}
                onChange={(e) => setVendorName(e.target.value)}
                error={customErrors.vendorName}
                onBlur={() => {
                  setVendorName(vendorName?.replace(/\s+/g, " ").trim());
                  vendorName
                    ? setCustomErrors({ ...customErrors, vendorName: false })
                    : setCustomErrors({ ...customErrors, vendorName: true });
                }}
                onInput={(e) =>
                  (e.target.value = e.target.value.toString().slice(0, 20))
                }
              />
            </Grid>

            <Grid item xs={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap"
                }}
              >
                <Autocomplete
                  // options={(accGrps && accGrps.length > 0 && accGrps.filter(option => (
                  //     !debitAccounts.some(selectedOption => selectedOption.id === option.id)
                  // ))) || []}

                  // options={accountGroups || []}

                  options={
                    (accountGroupsv2 &&
                      accountGroupsv2.length > 0 &&
                      accountGroupsv2.filter(
                        (option) =>
                          !debitAccounts.some(
                            (selectedOption) => selectedOption.id == option.id
                          )
                      )) ||
                    []
                  }
                  value={null}
                  size="small"
                  style={{ width: "300px" }}
                  // getOptionLabel={(option) => `${option?.name}` || ""}
                  getOptionLabel={(option) =>
                    `${option?.name}(${option?.siteName})` || ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.name === value?.name
                  }
                  onChange={(event, newValue) => {
                    handleAccountChange(event, newValue, "debit");
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Debit Group"
                      size="small"
                      variant="outlined"
                      error={customErrors.debitAccounts}
                      onBlur={() => {
                        debitAccounts && debitAccounts.length > 0
                          ? setCustomErrors({
                              ...customErrors,
                              debitAccounts: false
                            })
                          : setCustomErrors({
                              ...customErrors,
                              debitAccounts: true
                            });
                      }}
                      onChange={debounceData}
                      onKeyPress={(e) => handleKeyPress(e, "debit")}
                    />
                  )}
                />
              </div>
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {debitAccounts &&
                  debitAccounts.length > 0 &&
                  debitAccounts.map((val, index) => (
                    <div
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      <Button
                        disabled
                        size="small"
                        className="account_group_btn"
                        sx={{
                          marginLeft: 1,
                          marginY: "5px",
                          background: "green",
                          color: "#fff !important",
                        }}
                      >
                        {val.name}
                        <span>{val?.siteName && `(${val?.siteName})`}</span>
                      </Button>
                      <ClearIcon
                        sx={{ fontSize: 16, color: "red", cursor: "pointer" }}
                        onClick={() => {
                          const accounts = [...debitAccounts];
                          accounts.splice(index, 1);
                          setDebitAccounts(accounts);
                        }}
                      />
                    </div>
                  ))}
              </div> */}
            </Grid>

            <Grid item xs={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap"
                }}
              >
                <Autocomplete
                  // options={(accountGroups && accountGroups.length > 0 && accountGroups.filter(val => val.name !== "All")) || []}
                  // options={(accGrps && accGrps.length > 0 && accGrps.filter(option => (
                  //     !creditAccounts.some(selectedOption => selectedOption.id === option.id)
                  // ))) || []}
                  // options={accountGroups || []}
                  options={
                    (accountGroupsv2 &&
                      accountGroupsv2.length > 0 &&
                      accountGroupsv2.filter(
                        (option) =>
                          !creditAccounts.some(
                            (selectedOption) => selectedOption.id == option.id
                          )
                      )) ||
                    []
                  }
                  value={null}
                  size="small"
                  style={{ width: "300px" }}
                  getOptionLabel={(option) =>
                    `${option?.name}(${option?.siteName})` || ""
                  }
                  // getOptionLabel={(option) => `${option?.name}` || ""}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  // onChange={(event, newValue) => setCreditAccounts(newValue)}
                  onChange={(event, newValue) => {
                    handleAccountChange(event, newValue, "credit");
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Credit Groups"
                      size="small"
                      variant="outlined"
                      error={customErrors.creditAccounts}
                      helperText={customErrors.creditAccounts}
                      onBlur={() => {
                        creditAccounts && creditAccounts.length > 0
                          ? setCustomErrors({
                              ...customErrors,
                              creditAccounts: false
                            })
                          : setCustomErrors({
                              ...customErrors,
                              creditAccounts: true
                            });
                      }}
                      onChange={debounceData}
                      onKeyPress={(e) => handleKeyPress(e, "credit")}
                    />
                  )}
                />
              </div>

              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {creditAccounts &&
                  creditAccounts.length > 0 &&
                  creditAccounts.map((val, index) => (
                    <div
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      <Button
                        disabled
                        size="small"
                        className="account_group_btn"
                        sx={{
                          marginLeft: 1,
                          marginY: "5px",
                          background: "#a11919",
                          color: "#fff !important",
                        }}
                      >
                        {val.name}
                        <span>{val?.siteName && `(${val?.siteName})`}</span>
                      </Button>
                      <ClearIcon
                        sx={{ fontSize: 16, color: "red", cursor: "pointer" }}
                        onClick={() => {
                          const accounts = [...creditAccounts];
                          accounts.splice(index, 1);
                          setCreditAccounts(accounts);
                        }}
                      />
                    </div>
                  ))}
              </div> */}
            </Grid>

            <Grid item xs={4} marginTop={2}>
              <Autocomplete
                options={stateActionList || []}
                value={nextState}
                size="small"
                fullWidth
                getOptionLabel={(option) => `${option?.name}` || ""}
                getOptionSelected={(option, value) =>
                  option?.name === value?.name
                }
                onChange={(event, newValue) => {
                  newValue && setNextState(newValue);
                }}
                style={{ width: "300px" }}
                variant="outlined"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Current Status"
                    size="small"
                    required
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item md={4}>
              <Box sx={{ marginTop: "9px" }}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <div
                      className="field_box calender"
                      style={{ marginTop: "8px" }}
                    >
                      <TextField
                        name="search"
                        type="text"
                        style={{ width: "300px" }}
                        size="small"
                        variant="outlined"
                        placeholder="Search"
                        fullWidth
                        onChange={(e) => localSearchHandle(e, "Debit")}
                        InputProps={{
                          endAdornment: (
                            <SearchIcon
                              sx={{ cursor: "pointer" }}
                              color="action"
                              position="end"
                            />
                          )
                        }}
                      />
                      <Tooltip
                        title={<Typography>Clear All</Typography>}
                        placement="bottom-start"
                      >
                        <IconButton
                          sx={{ color: "red" }}
                          onClick={() => {
                            setDebitAccounts([]);
                            setSearchDebitAccounts([]);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        height: "400px",
                        "& ::-webkit-scrollbar": {
                          width: "4px"
                        },
                        "& ::-webkit-scrollbar-track": {
                          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)"
                        },
                        "& ::-webkit-scrollbar-thumb": {
                          backgroundColor: "darkgrey"
                        }
                      }}
                    >
                      <AutoSizer>
                        {({ height, width }) => {
                          const itemsPerRow = 1;

                          return (
                            searchDebitAccounts &&
                            searchDebitAccounts.length > 0 && (
                              <List
                                width={335}
                                height={height}
                                rowHeight={50}
                                rowCount={Math.ceil(
                                  searchDebitAccounts?.length / itemsPerRow
                                )}
                                overscanRowCount={3}
                                rowRenderer={({ index, key, style }) => {
                                  const startIndex = index * itemsPerRow;
                                  const endIndex = Math.min(
                                    startIndex + itemsPerRow,
                                    searchDebitAccounts?.length
                                  );
                                  const items = searchDebitAccounts
                                    ?.slice(startIndex, endIndex)
                                    .map((val, ind) => (
                                      <div
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center"
                                        }}
                                      >
                                        <Tooltip
                                          title={`${val.name} (${
                                            val?.siteName ? val?.siteName : ""
                                          })`}
                                        >
                                          <Button
                                            // disabled
                                            size="small"
                                            className="account_group_btn"
                                            sx={{
                                              marginLeft: 1,
                                              marginY: "5px",
                                              background: "green",
                                              color: "#fff !important",
                                              pointerEvents: "all !important",
                                              ":hover": {
                                                background: "green"
                                              }
                                            }}
                                          >
                                            <p
                                              style={{
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 2,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                              }}
                                            >
                                              {val.name}{" "}
                                              {val?.siteName &&
                                                `(${val?.siteName})`}
                                            </p>
                                          </Button>
                                        </Tooltip>
                                        <ClearIcon
                                          sx={{
                                            fontSize: 16,
                                            color: "red",
                                            cursor: "pointer"
                                          }}
                                          onClick={() => {
                                            const accounts = [...debitAccounts];
                                            const accountsFilter =
                                              accounts.filter(
                                                (ele, i) => ele.id !== val.id
                                              );
                                            // accounts.splice(ind, 1);
                                            setDebitAccounts(accountsFilter);
                                            setSearchDebitAccounts(
                                              accountsFilter
                                            );
                                          }}
                                        />
                                      </div>
                                    ));

                                  return (
                                    // items.length > 0 && (

                                    <Grid item md={12} key={key} style={style}>
                                      {items}
                                    </Grid>
                                    // )
                                  );
                                }}
                              />
                            )
                          );
                        }}
                      </AutoSizer>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box sx={{ marginTop: "9px" }}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <div
                      className="field_box calender"
                      style={{ marginTop: "8px" }}
                    >
                      <TextField
                        name="search"
                        type="text"
                        style={{ width: "300px" }}
                        size="small"
                        variant="outlined"
                        placeholder="Search"
                        fullWidth
                        onChange={(e) => localSearchHandle(e, "Credit")}
                        InputProps={{
                          endAdornment: (
                            <SearchIcon
                              sx={{ cursor: "pointer" }}
                              color="action"
                              position="end"
                            />
                          )
                        }}
                      />
                      <Tooltip
                        title={<Typography>Clear All</Typography>}
                        placement="bottom-start"
                      >
                        <IconButton
                          sx={{ color: "red" }}
                          onClick={() => {
                            setCreditAccounts([]);
                            setSearchCreditAccounts([]);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        height: "400px",
                        "& ::-webkit-scrollbar": {
                          width: "4px"
                        },
                        "& ::-webkit-scrollbar-track": {
                          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)"
                        },
                        "& ::-webkit-scrollbar-thumb": {
                          backgroundColor: "darkgrey"
                        }
                      }}
                    >
                      <AutoSizer>
                        {({ height, width }) => {
                          const itemsPerRow = 1;

                          return (
                            creditAccounts &&
                            creditAccounts.length > 0 && (
                              <List
                                width={335}
                                height={height}
                                rowHeight={50}
                                rowCount={Math.ceil(
                                  searchCreditAccounts?.length / itemsPerRow
                                )}
                                overscanRowCount={3}
                                rowRenderer={({ index, key, style }) => {
                                  const startIndex = index * itemsPerRow;
                                  const endIndex = Math.min(
                                    startIndex + itemsPerRow,
                                    searchCreditAccounts?.length
                                  );
                                  const items = searchCreditAccounts
                                    ?.slice(startIndex, endIndex)
                                    .map((val, ind) => (
                                      <div
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center"
                                        }}
                                      >
                                        <Tooltip
                                          title={`${val.name} (${
                                            val?.siteName ? val?.siteName : ""
                                          })`}
                                        >
                                          <Button
                                            // disabled
                                            size="small"
                                            className="account_group_btn"
                                            sx={{
                                              marginLeft: 1,
                                              marginY: "5px",
                                              background: "#a11919",
                                              color: "#fff !important",
                                              maxWidth: "295px",
                                              pointerEvents: "all !important",
                                              ":hover": {
                                                background: "#a11919"
                                              }
                                            }}
                                          >
                                            <p
                                              style={{
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 2,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                              }}
                                            >
                                              {val.name}{" "}
                                              {val?.siteName &&
                                                `(${val?.siteName})`}
                                            </p>
                                          </Button>
                                        </Tooltip>
                                        <ClearIcon
                                          sx={{
                                            fontSize: 16,
                                            color: "red",
                                            cursor: "pointer"
                                          }}
                                          onClick={() => {
                                            const accounts = [
                                              ...creditAccounts
                                            ];
                                            const accountsFilter =
                                              accounts.filter(
                                                (ele, i) => ele.id !== val.id
                                              );
                                            // accounts.splice(ind, 1);
                                            setCreditAccounts(accountsFilter);
                                            setSearchCreditAccounts(
                                              accountsFilter
                                            );
                                          }}
                                        />
                                      </div>
                                    ));

                                  return (
                                    // items.length > 0 && (

                                    <Grid item md={12} key={key} style={style}>
                                      {items}
                                    </Grid>
                                    // )
                                  );
                                }}
                              />
                            )
                          );
                        }}
                      </AutoSizer>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          {/* ==============Child Account Configuration Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <ChildAccountConfiguration
              addTargetAccountChildHandle={addTargetAccountChildHandle}
              deleteTargetAccountChildHandle={deleteTargetAccountChildHandle}
              sourceAccountChildTableList={sourceAccountChildTableList}
              deleteSourceAccountChildHandle={deleteSourceAccountChildHandle}
              setSourceAccountChildTableList={setSourceAccountChildTableList}
              addSourceAccountChildHandle={addSourceAccountChildHandle}
              childConfigError={childConfigError}
              setChildConfigError={setChildConfigError}
              accountTypes={accountTypes || []}
              targetAccountTypes={targetAccountTypes || []}
              sourceAccountType={sourceAccountType}
              setSourceAccountType={setSourceAccountType}
              sourceAccountTypeChildList={sourceAccountTypeChildList}
              setSourceAccountTypeChildList={setSourceAccountTypeChildList}
              sourceAccountTypeChildLists={sourceAccountTypeChildLists}
              setSourceAccountTypeChildLists={setSourceAccountTypeChildLists}
              targetAccountType={targetAccountType}
              setTargetAccountType={setTargetAccountType}
              setAccountTypeChildLists={setAccountTypeChildLists}
              accountTypeChildLists={accountTypeChildLists}
              targetAccountTypeChildLists={targetAccountTypeChildLists}
              targetAccountTypeChildList={targetAccountTypeChildList}
              setTargetAccountTypeChildLists={setTargetAccountTypeChildLists}
              setTargetAccountTypeChildList={setTargetAccountTypeChildList}
              targetAccountChildTableList={targetAccountChildTableList}
              setTargetAccountChildTableList={setTargetAccountChildTableList}
            />
          </Grid>

          {/* ==============Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <DetailsViewConfigurations
              templateViewList={templateViewList}
              selectTemplateView={selectTemplateView}
              setSelectTemplateView={setSelectTemplateView}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
            />
          </Grid>
          {/* ==============Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <DueDataConfigurations
              overDueBoolean={overDueBoolean}
              setOverDueBoolean={setOverDueBoolean}
              accountCategory={accountCategory}
              setAccountCategory={setAccountCategory}
              overDueDays={overDueDays}
              setOverDueDays={setOverDueDays}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
              checkboxError={checkboxError}
              setCheckboxError={setCheckboxError}
              radioError={radioError}
              setRadioError={setRadioError}
              taggedInvoice={taggedInvoice}
            />
          </Grid>
          {/* ==============Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <TaggedFunction
              overDueBoolean={overDueBoolean}
              taggedInvoice={taggedInvoice}
              setTaggedInvoice={setTaggedInvoice}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
            />
          </Grid>

          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <MandatoryCheck
              invoiceOrNoteNumber={invoiceOrNoteNumber}
              setInvoiceorNotNumber={setInvoiceorNotNumber}
              accountCode={accountCode}
              setAccountCode={setAccountCode}
              transactionType={transactionType}
              setTransactionType={setTransactionType}
              eInvoiceGeneration={eInvoiceGeneration}
              setEInvoiceGeneration={setEInvoiceGeneration}
              eInvoiceGenerationImmediate={eInvoiceGenerationImmediate}
              setEInvoiceGenerationImmediate={setEInvoiceGenerationImmediate}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
            />
          </Grid>

          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <EInvoiceDocTypes
              invDocType={invDocType}
              setInvDocType={setInvDocType}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
            />
          </Grid>
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <ConfigurationCheckFields
              openModal={openModal}
              setOpenModal={setOpenModal}
              dynamicFields={dynamicFields}
              setDynamicFields={setDynamicFields}
              setHandleTableComponent={setHandleTableComponent}
              openEditorFuntion={openEditorFuntion}
            />
          </Grid>

          {/* ============Voucher Dynamic Fields============ */}
          {/* <Grid
            container
            marginTop={2}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
          >
            <Card style={{ width: "100%" }}>
              <CardContent>
                <Grid item xs={12} marginY={3}>
                  <Typography variant="h6">
                    {"Configure Voucher Fields"}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <DragAndDropComponent
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    dynamicFields={dynamicFields}
                    setDynamicFields={setDynamicFields}
                    setHandleTableComponent={setHandleTableComponent}
                    openEditorFuntion={openEditorFuntion}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid> */}

          {/* ================Buttons============ */}

          <Grid
            container
            justifyContent="flex-end"
            style={{ margin: "20px 0px", padding: "10px 0px" }}
          >
            <Grid item>
              <Button
                onClick={handlesaveTemplate}
                color="primary"
                style={{
                  background: `${themecolor}`,
                  display: checkPermissionExist(endPoints, voucherTypeSaveUrl)
                    ? "inline-flex"
                    : "none"
                }}
                variant="contained"
              >
                Save Template{" "}
              </Button>
            </Grid>

            <Grid item marginLeft={2}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel{" "}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>

      {/* ==============Alerts=============== */}
      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />

      {/* =============Loader============= */}
      {loading && <LoadingComponent />}

      {/* =========Table Component Modal=========== */}
      {openModal && (
        <TableComponentModal
          open={openModal}
          closeDialogBox={closeDialogBox}
          tableItem={tableItem}
          dynamicFields={dynamicFields}
          setDynamicFields={setDynamicFields}
          errorAlert={errorAlert}
        />
      )}

      {/* ======================Editor Modal============ */}
      {openEditor && (
        <Editor
          open={openEditor}
          closeDialogBox={closeEditor}
          dynamicFields={dynamicFields}
          setDynamicFields={setDynamicFields}
          editedIndex={editedIndex}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          errorAlert={errorAlert}
        />
      )}
    </>
  );
};

export default withStyles(styles)(CreateVoucherTemp);
// setNetCreditTotalAmount(netCreditTotalAmount)
// setNetDebitTotalAmount(netDebitTotalAmount)
