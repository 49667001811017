export const GST1Data = [
  {
    invoiceDate: "2024-08-05T00:00:00.000+00:00",
    invoiceNo: "INV002",
    customerName: "XYZ Enterprises",
    customerGSTIN: "29BBBBB1111B1Z6",
    placeOfSupply: "Maharashtra",
    typeOfSupply: "Intrastate",
    taxableValue: 50000,
    cgst: 4500,
    sgst: 4500,
    igst: 0,
    totalInvoiceValue: 59000
  },
  {
    invoiceDate: "2024-08-10T00:00:00.000+00:00",
    invoiceNo: "INV003",
    customerName: "PQR Industries",
    customerGSTIN: "27CCCCC2222C1Z7",
    placeOfSupply: "Maharashtra",
    typeOfSupply: "Intrastate",
    taxableValue: 200000,
    cgst: 18000,
    sgst: 18000,
    igst: 0,
    totalInvoiceValue: 236000
  },
  {
    invoiceDate: "2024-08-15T00:00:00.000+00:00",
    invoiceNo: "INV004",
    customerName: "DEF Co.",
    customerGSTIN: "07DDDDD3333D1Z8",
    placeOfSupply: "Delhi",
    typeOfSupply: "Interstate",
    taxableValue: 150000,
    cgst: 0,
    sgst: 0,
    igst: 27000,
    totalInvoiceValue: 177000
  },
  {
    invoiceDate: "2024-08-20T00:00:00.000+00:00",
    invoiceNo: "INV005",
    customerName: "MNO Ltd.",
    customerGSTIN: "24EEEEE4444E1Z9",
    placeOfSupply: "Gujarat",
    typeOfSupply: "Interstate",
    taxableValue: 75000,
    cgst: 0,
    sgst: 0,
    igst: 13500,
    totalInvoiceValue: 88500
  },
  {
    invoiceDate: "2024-08-25T00:00:00.000+00:00",
    invoiceNo: "INV006",
    customerName: "Gurugram's Company",
    customerGSTIN: "06GGGGG5555G1Z0",
    placeOfSupply: "Haryana",
    typeOfSupply: "Intrastate",
    taxableValue: 125000,
    cgst: 11250,
    sgst: 11250,
    igst: 0,
    totalInvoiceValue: 147500
  },
  {
    invoiceDate: "2024-09-01T00:00:00.000+00:00",
    invoiceNo: "INV007",
    customerName: "STU Corp.",
    customerGSTIN: "08HHHHH6666H1Z1",
    placeOfSupply: "Rajasthan",
    typeOfSupply: "Interstate",
    taxableValue: 50000,
    cgst: 0,
    sgst: 0,
    igst: 9000,
    totalInvoiceValue: 59000
  },
  {
    invoiceDate: "2024-09-05T00:00:00.000+00:00",
    invoiceNo: "INV008",
    customerName: "JKL Pvt. Ltd.",
    customerGSTIN: "21IIIIIIII1I1Z2",
    placeOfSupply: "Odisha",
    typeOfSupply: "Intrastate",
    taxableValue: 175000,
    cgst: 15750,
    sgst: 15750,
    igst: 0,
    totalInvoiceValue: 206500
  },
  {
    invoiceDate: "2024-09-10T00:00:00.000+00:00",
    invoiceNo: "INV009",
    customerName: "LMN Industries",
    customerGSTIN: "19JJJJJ7777J1Z3",
    placeOfSupply: "West Bengal",
    typeOfSupply: "Interstate",
    taxableValue: 60000,
    cgst: 0,
    sgst: 0,
    igst: 10800,
    totalInvoiceValue: 70800
  },
  {
    invoiceDate: "2024-09-15T00:00:00.000+00:00",
    invoiceNo: "INV010",
    customerName: "QRS Enterprises",
    customerGSTIN: "32KKKKK8888K1Z4",
    placeOfSupply: "Kerala",
    typeOfSupply: "Intrastate",
    taxableValue: 90000,
    cgst: 8100,
    sgst: 8100,
    igst: 0,
    totalInvoiceValue: 106200
  },
  {
    invoiceDate: "2024-09-20T00:00:00.000+00:00",
    invoiceNo: "INV011",
    customerName: "TUV Ltd.",
    customerGSTIN: "05LLLLL9999L1Z5",
    placeOfSupply: "Uttarakhand",
    typeOfSupply: "Interstate",
    taxableValue: 130000,
    cgst: 0,
    sgst: 0,
    igst: 23400,
    totalInvoiceValue: 153400
  }
];

export const GST2Report = [
  {
    invoiceDate: "02-08-2024",
    invoiceNo: "PUR001",
    supplierName: "ABC Pvt. Ltd.",
    supplierGSTIN: "22AAAAA0000A1Z5",
    placeOfSupply: "Karnataka",
    typeOfSupply: "Interstate",
    taxableValue: 150000,
    cgst: 0,
    sgst: 0,
    igst: 27000,
    totalInvoiceValue: 177000,
    itcClaimed: 27000
  },
  {
    invoiceDate: "07-08-2024",
    invoiceNo: "PUR002",
    supplierName: "XYZ Enterprises",
    supplierGSTIN: "29BBBBB1111B1Z6",
    placeOfSupply: "Maharashtra",
    typeOfSupply: "Intrastate",
    taxableValue: 75000,
    cgst: 6750,
    sgst: 6750,
    igst: 0,
    totalInvoiceValue: 88500,
    itcClaimed: 13500
  },
  {
    invoiceDate: "12-08-2024",
    invoiceNo: "PUR003",
    supplierName: "PQR Industries",
    supplierGSTIN: "27CCCCC2222C1Z7",
    placeOfSupply: "Maharashtra",
    typeOfSupply: "Intrastate",
    taxableValue: 200000,
    cgst: 18000,
    sgst: 18000,
    igst: 0,
    totalInvoiceValue: 236000,
    itcClaimed: 36000
  },
  {
    invoiceDate: "17-08-2024",
    invoiceNo: "PUR004",
    supplierName: "DEF Co.",
    supplierGSTIN: "07DDDDD3333D1Z8",
    placeOfSupply: "Delhi",
    typeOfSupply: "Interstate",
    taxableValue: 100000,
    cgst: 0,
    sgst: 0,
    igst: 18000,
    totalInvoiceValue: 118000,
    itcClaimed: 18000
  },
  {
    invoiceDate: "23-08-2024",
    invoiceNo: "PUR005",
    supplierName: "MNO Ltd.",
    supplierGSTIN: "24EEEEE4444E1Z9",
    placeOfSupply: "Gujarat",
    typeOfSupply: "Interstate",
    taxableValue: 125000,
    cgst: 0,
    sgst: 0,
    igst: 22500,
    totalInvoiceValue: 147500,
    itcClaimed: 22500
  },
  {
    invoiceDate: "28-08-2024",
    invoiceNo: "PUR006",
    supplierName: "JKL Pvt. Ltd.",
    supplierGSTIN: "08FFFFF5555F1Z0",
    placeOfSupply: "Rajasthan",
    typeOfSupply: "Interstate",
    taxableValue: 175000,
    cgst: 0,
    sgst: 0,
    igst: 31500,
    totalInvoiceValue: 206500,
    itcClaimed: 31500
  },
  {
    invoiceDate: "02-09-2024",
    invoiceNo: "PUR007",
    supplierName: "GHI Corp.",
    supplierGSTIN: "09GGGGG6666G1Z1",
    placeOfSupply: "Uttar Pradesh",
    typeOfSupply: "Intrastate",
    taxableValue: 50000,
    cgst: 4500,
    sgst: 4500,
    igst: 0,
    totalInvoiceValue: 59000,
    itcClaimed: 9000
  },
  {
    invoiceDate: "12-09-2024",
    invoiceNo: "PUR009",
    supplierName: "QRS Ltd.",
    supplierGSTIN: "11IIIII8888I1Z3",
    placeOfSupply: "Assam",
    typeOfSupply: "Intrastate",
    taxableValue: 125000,
    cgst: 11250,
    sgst: 11250,
    igst: 0,
    totalInvoiceValue: 147500,
    itcClaimed: 22500
  },
  {
    invoiceDate: "18-09-2024",
    invoiceNo: "PUR010",
    supplierName: "TUV Enterprises",
    supplierGSTIN: "12JJJJJ9999J1Z4",
    placeOfSupply: "Jharkhand",
    typeOfSupply: "Interstate",
    taxableValue: 100000,
    cgst: 0,
    sgst: 0,
    igst: 18000,
    totalInvoiceValue: 118000,
    itcClaimed: 18000
  },
  {
    invoiceDate: "22-09-2024",
    invoiceNo: "PUR011",
    supplierName: "UVW Pvt. Ltd.",
    supplierGSTIN: "13KKKKK0000K1Z5",
    placeOfSupply: "West Bengal",
    typeOfSupply: "Intrastate",
    taxableValue: 200000,
    cgst: 18000,
    sgst: 18000,
    igst: 0,
    totalInvoiceValue: 236000,
    itcClaimed: 36000
  }
];

export const GST3BReport = [
  {
    taxable_value_inr: 500000,
    cgst_inr: 45000,
    sgst_inr: 45000,
    igst_inr: 0,
    cess_inr: 0,
    description: "Outward taxable supplies",
    childList: [
      {
        description:
          "Outward taxable supplies (other than zero-rated, nil-rated, and exempt)",
        taxable_value_inr: 500000,
        cgst_inr: 45000,
        sgst_inr: 45000,
        igst_inr: 0,
        cess_inr: 0
      },
      {
        description: "Outward taxable supplies (zero-rated)",
        taxable_value_inr: 100000,
        cgst_inr: 0,
        sgst_inr: 0,
        igst_inr: 0,
        cess_inr: 0
      },
      {
        description: "Other outward supplies (nil-rated, exempt)",
        taxable_value_inr: 50000,
        cgst_inr: 0,
        sgst_inr: 0,
        igst_inr: 0,
        cess_inr: 0
      },
      {
        description: "Inward supplies (liable to reverse charge)",
        taxable_value_inr: 20000,
        cgst_inr: 1800,
        sgst_inr: 1800,
        igst_inr: 0,
        cess_inr: 0
      },
      {
        description: "Non-GST outward supplies",
        taxable_value_inr: 30000,
        cgst_inr: 0,
        sgst_inr: 0,
        igst_inr: 0,
        cess_inr: 0
      },
      {
        description: "Outward taxable supplies (exempt)",
        taxable_value_inr: 70000,
        cgst_inr: 0,
        sgst_inr: 0,
        igst_inr: 0,
        cess_inr: 0
      },
      {
        description: "Outward taxable supplies (nil-rated)",
        taxable_value_inr: 25000,
        cgst_inr: 0,
        sgst_inr: 0,
        igst_inr: 0,
        cess_inr: 0
      },
      {
        description: "Inward supplies (not liable to reverse charge)",
        taxable_value_inr: 15000,
        cgst_inr: 1350,
        sgst_inr: 1350,
        igst_inr: 0,
        cess_inr: 0
      }
    ]
  }
];
