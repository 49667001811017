
import { accountTypesV1Url } from "../../config";
import { userService } from "../../userService";

// Action creator to account type list function
export const startAccountTypeRequest = () => {
    return {
        type: 'START_ACCOUNT_TYPE_REQUEST',
    };
};


// Action creator for handling account success
export const accountTypeRequestSuccess = (data, message) => {
 
    return {
        type: 'ACCOUNT_TYPE_REQUEST_SUCCESS',
        payload: {
            data,
            message
        }
    };
};

// Action creator for handling account failure
export const accountTypeRequestFailure = (message) => {
    return {
        type: 'ACCOUNT_TYPE_REQUEST_FAILURE',
        payload: message,
    };
};



export function getAllAccountTypes(payload) {

    return (dispatch) => {
        dispatch(startAccountTypeRequest())
        userService.postData(accountTypesV1Url, payload)
            .then((res) => {

                if (res && res.data && res.data.data && res.data.status === 200) {
                    const { data } = res.data;
                    dispatch(accountTypeRequestSuccess(data, "Success"));

                }
                else {
                    dispatch(accountTypeRequestFailure(res?.data?.message || "something went wrong"))
                }

            })
            .catch(err => {
                dispatch(accountTypeRequestFailure(err.message))
            })
    };
}

