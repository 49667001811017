import { Stack } from '@mui/material'
import React from 'react'
import { renderChip } from './ChipComponent'

const ColorInfo = () => {
    return (
        <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-end" style={{ width: "30%", marginTop: "10px" }}>
            {renderChip('Debit', null, "success")}
            {renderChip('Credit', null, "error")}
        </Stack>
    )
}

export default ColorInfo