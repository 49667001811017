import { getEncryptPayload } from "../../Network_Utility";
import { singleAccountUrl } from "../../config";
import { userService } from "../../userService";

// Action creator to account list function
export const startAccountRequest = () => {
  return {
    type: "START_ACCOUNT_REQUEST",
  };
};

// Action creator for handling account success
export const accountRequestSuccess = (data, message) => {
  return {
    type: "ACCOUNT_REQUEST_SUCCESS",
    payload: {
      data,
      message,
    },
  };
};

// Action creator for handling account failure
export const accountRequestFailure = (message) => {
  return {
    type: "ACCOUNT_REQUEST_FAILURE",
    payload: message,
  };
};

export function getAllAccounts(payload, setTotalPages, pageSize) {
  return (dispatch) => {
    dispatch(startAccountRequest());
    userService
      .postData(singleAccountUrl, payload)
      .then((res) => {
        if (res && res.data && res.data.data && res.data.status === 200) {
          const { data, totalLength } = res.data.data;

          if (data && Array.isArray(data) && data.length > 0) {
            dispatch(accountRequestSuccess(data, "Success"));
            if (pageSize) {
              setTotalPages(Math.ceil(totalLength / pageSize));
            }
          } else {
            // setTotalPages(1);
            if (pageSize) {
              setTotalPages(1);
            }
            dispatch(accountRequestSuccess([], "No records available"));
          }
        } else {
          dispatch(
            accountRequestFailure(res?.data?.message || "something went wrong")
          );
        }
      })
      .catch((err) => {
        dispatch(accountRequestFailure(err.message));
      });
  };
}

export function getAllAccountsOnSearch(
  valString,
  siteList,
  errorAlert,
  setLoading
) {
  setLoading(true);
  const globalSiteFilter = {
    id: localStorage.getItem("siteId"),
    name:
      siteList &&
      siteList.find((val) => val.id == localStorage.getItem("siteId"))?.name,
  };
  let payload = {
    pageNo: 0,
    pageSize: 0,
    searchField: null,
    // siteId: localStorage.getItem("siteId"),
    isEnableForCurrentSite: true,
    isAvailable: true,
    site: globalSiteFilter,
    searchField: valString,
  };
  const encryptedPayload = getEncryptPayload(payload, true);

  return userService
    .postData(singleAccountUrl, encryptedPayload)
    .then((res) => {
      if (res && res.data && res.data.data && res.data.status === 200) {
        setLoading(false);
        const { data, totalLength } = res.data.data;
        if (data && Array.isArray(data) && data.length > 0) {
          return data;
        } else {
          errorAlert("No records available");
        }
      } else {
        setLoading(false);
        errorAlert("Something went wrong!!");
      }
    })
    .catch((err) => {
      setLoading(false);
      errorAlert("Something went wrong!!");
    });
}
