export const validateCustomFields = (
  vendorName,
  creditAccounts,
  debitAccounts,
  selectTemplateView,
  overDueBoolean,
  accountCategory,
  overDueDays,
  taggedInvoice,
  invoiceOrNoteNumber,
  accountCode,
  transactionType
) => {
  let customErrors = {};

  // Checking for required fields
  if (!vendorName) customErrors.vendorName = true;
  if (creditAccounts.length < 1) customErrors.creditAccounts = true;
  if (debitAccounts.length < 1) customErrors.debitAccounts = true;
  if (selectTemplateView === null) customErrors.selectTemplateView = true;
  // if (!overDueBoolean) customErrors.overDueBoolean = true;
  // if (!accountCategory) customErrors.accountCategory = true;
  // if (!overDueBoolean && !accountCategory && overDueDays < 1)
  //   customErrors.overDueDays = true;
  // if (!taggedInvoice) customErrors.taggedInvoice = true;
  // if (!invoiceOrNoteNumber) customErrors.invoiceOrNoteNumber = true;
  // if (!accountCode) customErrors.accountCode = true;
  // if (!transactionType) customErrors.transactionType = true;
  return customErrors;
};

export const templateViewList = [
  { id: 1, name: "Journal", alias: "JOURNAL" },
  { id: 2, name: "Purchase", alias: "PURCHASE" },
  { id: 3, name: "Sale", alias: "SALE" },
];

export const accountTypeConfigValidation = (
  isSourceValidation,
  sourceAccountType,
  sourceAccountTypeChildList,
) => {
  let errors = {};
  if (isSourceValidation) {
    if (!sourceAccountType) errors.sourceAccountType = true;
    if (!sourceAccountTypeChildList) errors.sourceAccountTypeChildList = true;
    return errors;
  }
  if (!isSourceValidation) {
    if (!sourceAccountType) errors.targetAccountType = true;
    if (!sourceAccountTypeChildList) errors.targetAccountTypeChildList = true;
    return errors;
  }
};
