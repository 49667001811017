import * as React from "react";
import { useState } from "react";
import Divider from "@mui/material/Divider";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AmountInfo from "../CommonComps/AmountInfo";

export default function FlexHeaderDivider({
  payableHeader,
  dueDaysArray,
  overDueDaysArray,
  resData,
}) {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedDays, setSelectedDays] = useState({
    Due: dueDaysArray && dueDaysArray[0],
    OverDue: overDueDaysArray && overDueDaysArray[0],
  });

  const handleDaysChange = (name, newValue) => {
    console.log(newValue);
    if (newValue) {
      setSelectedDays((prev) => ({
        ...prev,
        [name]: newValue,
      }));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid rgba(195, 212, 255, 1)",
        borderRadius: "8px",
        backgroundColor: "rgba(240, 246, 255, 1)",
        color: "rgba(0, 0, 0, 0.6)",
        marginTop: "10px",
        padding: matchesSm ? "8px 8px" : "8px 12px",
        flexDirection: matchesSm ? "column" : "row",
        overflowX: "auto",
        gap: theme.spacing(1),
      }}
    >
      {payableHeader?.map((ele, index) => {
        const valueDueDay = selectedDays.Due;
        const valueOverDueDay = selectedDays.OverDue;

        // console.log(resData, valueOverDueDay)

        return (
          <React.Fragment key={index}>
            <Box
              sx={{
                padding: "8px 8px",
                display: "flex",
                alignItems: "center",
                flexDirection: matchesSm ? "column" : "row",
              }}
            >
              <Box
                component="img"
                sx={{
                  marginRight: matchesSm ? "8px" : "12px",
                  width: matchesSm ? "40px" : "48px",
                }}
                src={ele.icon}
                alt="icon"
              />
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: matchesSm ? "column" : "row",
                    gap: matchesSm ? 1 : 2,
                    marginBottom: matchesSm ? 2 : 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: matchesSm ? "16px" : "18px",
                    }}
                  >
                    ₹
                    <AmountInfo
                      fontSize={matchesSm ? "16px" : "18px"}
                      amount={
                        resData !== null && ele?.name === "Due"
                          ? +resData[valueDueDay.key] ?? 0
                          : resData !== null && ele?.name === "Over Due"
                          ? +resData[valueOverDueDay.key] ?? 0
                          : +ele?.amount
                      }
                    />
                    {/* {ele?.amount} */}
                  </Typography>
                  {ele?.name === "Due" && (
                    <Autocomplete
                      disablePortal
                      options={dueDaysArray}
                      getOptionLabel={(option) => option.name}
                      value={selectedDays[ele?.name] || dueDaysArray[0]}
                      onChange={(_, newValue) => {
                        handleDaysChange("Due", newValue);
                      }}
                      size="small"
                      sx={{
                        width: matchesSm ? "100%" : 120,
                        background: "#fff",
                        borderRadius: "8px",
                        marginTop: matchesSm ? 1 : 0,
                        marginRight: matchesSm ? 0 : 2,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            ".MuiOutlinedInput-root": {
                              borderRadius: "8px",
                              paddingRight: "8px !important",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            ".MuiInputBase-input": {
                              height: "16px",
                            },
                            ".MuiAutocomplete-clearIndicator": {
                              display: "none",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                  {ele?.name === "Over Due" && (
                    <Autocomplete
                      disablePortal
                      options={overDueDaysArray}
                      getOptionLabel={(option) => option.name}
                      value={selectedDays["OverDue"] || overDueDaysArray[0]}
                      onChange={(_, newValue) => {
                        handleDaysChange("OverDue", newValue);
                      }}
                      size="small"
                      sx={{
                        width: matchesSm ? "100%" : 120,
                        background: "#fff",
                        borderRadius: "8px",
                        marginTop: matchesSm ? 1 : 0,
                        marginRight: matchesSm ? 0 : 2,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            ".MuiOutlinedInput-root": {
                              borderRadius: "8px",
                              paddingRight: "8px !important",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            ".MuiInputBase-input": {
                              height: "16px",
                            },
                            ".MuiAutocomplete-clearIndicator": {
                              display: "none",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                </Box>
                <Typography
                  sx={{ color: ele?.color, marginTop: matchesSm ? 1 : 0 }}
                >
                  {ele?.name}
                </Typography>
                <Typography sx={{ fontSize: matchesSm ? "11px" : "12px" }}>
                  {ele?.name === "Due" && (
                    <>
                      <Box
                        component="span"
                        sx={{ color: "rgba(52, 122, 226, 1)" }}
                      >
                        {ele?.transaction || "0"}
                      </Box>{" "}
                      Due Transaction
                    </>
                  )}
                  {ele?.name === "Over Due" && (
                    <>
                      <Box
                        component="span"
                        sx={{ color: "rgba(52, 122, 226, 1)" }}
                      >
                        {ele?.transaction || '0'}
                      </Box>{" "}
                      Over Due Transaction
                    </>
                  )}
                  {ele?.name === "Advance" && (
                    <>
                      <Box
                        component="span"
                        sx={{ color: "rgba(52, 122, 226, 1)" }}
                      >
                        {ele?.transaction || "0"}
                      </Box>{" "}
                      Transaction
                    </>
                  )}
                  {ele?.name !== "Due" &&
                    ele?.name !== "Over Due" &&
                    ele?.name !== "Advance" &&
                    ele?.name !== "Non Due" && (
                      <>
                        <Box
                          component="span"
                          sx={{ color: "rgba(52, 122, 226, 1)" }}
                        >
                          {ele?.transaction || "0"}
                        </Box>{" "}
                        Transaction |{" "}
                        <Box
                          component="span"
                          sx={{ color: "rgba(52, 122, 226, 1)" }}
                        >
                          {ele?.client || "0"}
                        </Box>{" "}
                        Client
                      </>
                    )}
                </Typography>
              </Box>
            </Box>
            {index < payableHeader.length - 1 && (
              <Divider
                orientation={matchesSm ? "horizontal" : "vertical"}
                variant="middle"
                flexItem
                sx={{
                  marginLeft: matchesSm ? 0 : "8px",
                  height: matchesSm ? "1px" : "auto",
                  backgroundColor: "rgba(195, 212, 255, 0.5)",
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
}
