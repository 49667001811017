
import { userService } from "../userService";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
// import CryptoJS from "crypto-js";

export function getEncryptPayload(payload, appendTokenData) {
  const tokenData = {
    companyId: localStorage.getItem("companyId"),
    moduleId: null,
    organizationId: null,
    userCode: null,
    userId: localStorage.getItem("userId"),
    userName: null,
    userRoleId: null,
  };

  const isEncrypted = localStorage.getItem("encryptData")

  const financialYear = JSON.parse(localStorage.getItem("financialYear"));
  let financialYearId = financialYear && financialYear?.id;

  if (appendTokenData) payload = { ...payload, tokenData, financialYearId };

  if (isEncrypted && isEncrypted == "true") {


    // var secretKey = 'QWERT$$KEY';
    // const payloadString = JSON.stringify(payload);
    // const encrypteddata = CryptoJS.AES.encrypt(payloadString, secretKey).toString();
    // // Decrypt the data
    // const bytes = CryptoJS.AES.decrypt(encrypteddata, secretKey);
    // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    // // Now, `decryptedData` contains the original data
    // console.log(JSON.parse(decryptedData), "decryptedData");

    // payload = encrypteddata
  }

  return payload;
}

export function getHeaders(key) {
  const headers = {
    Authorization: "Bearer " + sessionStorage.getItem("token"),
    "Access-Control-Allow-Origin": "*",
    "auth-head": "d3v3lop3r",
  };

  if (key) {
    headers["accountKey"] = key;
  }

  return {
    headers: headers,
  };
}

// Function to download template
export const getTemplate = async (
  setLoading,
  key,
  successAlert,
  errorAlert,
  name,
  site
) => {
  setLoading(true);

  let payload = {
    templateKey: key,
    siteId: site ? site.id : localStorage.getItem("siteId"),
  };
  let encryptedPayload = getEncryptPayload(payload, true);

  let apiEndpoint = "/excel-util/v1/get-template";
  const res = await userService.downloadTemplate(
    apiEndpoint,
    encryptedPayload,
    name
  );
  try {
    if (res && res.data && res.data.status === 200) {
      successAlert(res.data.message);
      setLoading(false);
    } else {
      errorAlert(res.data.message || "Something went wrong");
      setLoading(false);
    }
  } catch (error) {
    errorAlert(res.data.message || "Something went wrong");
    setLoading(false);
  }
};

export const exportToPDF = async (
  headers,
  dataForPDF,
  setLoading,
  successAlert,
  errorAlert,
  name
) => {
  try {
    setLoading(true);
    // Create a new PDF document
    const doc = new jsPDF();

    // Define the title
    const title = `${name}`;

    // Set the title text
    doc.setFontSize(16); // Set font size for the title
    doc.text(title, 10, 10); // Adjust the position as needed

    // Set the table headers
    const startY = 30; // Adjust the starting Y position for the table
    doc.autoTable({
      head: [headers],
      body: dataForPDF,
      startY,
    });

    // Save the PDF
    doc.save(`${name}.pdf`);
    // Hide loading indicator and show success message
    setLoading(false);
    successAlert("Data exported successfully");
  } catch (error) {
    // Hide loading indicator and show error message
    setLoading(false);
    errorAlert(error.message || "Something went wrong");
  }
};

export const exportToExcel = async (
  excelHeaders,
  dataForExcel,
  setLoading,
  successAlert,
  errorAlert,
  name,
  companyDetail,


) => {

  try {

    // Show loading indicator
    setLoading(true);

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    excelHeaders.unshift({
      name: "Company Name",
      bgColor: "784F41",
      color: "FFFFFF",
      width: 20,
    });
    excelHeaders.push({
      name: "Excel Generate Date",
      bgColor: "05CDF5",
      color: "FFFFFF",
      width: 20,
    });

    const updateData = dataForExcel.map((prev) => [
      companyDetail?.company?.shortName,
      ...prev,
      moment(new Date()).format("DD-MM-YYYY, hh:mm a"), ,
    ]);
    dataForExcel = [...updateData]
    // Create a header row with custom styles
    const headerRow = worksheet.addRow(
      excelHeaders.map((header) => header.name)
    );

    headerRow.eachCell((cell, colNumber) => {
      // Set font color for the header cell
      cell.font = { color: { argb: excelHeaders[colNumber - 1].color } };

      // Set background color for the header cell
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: excelHeaders[colNumber - 1].bgColor },
      };

      // Set column width for the header cell
      worksheet.getColumn(colNumber).width = excelHeaders[colNumber - 1].width;
    });

    // Add the data rows
    dataForExcel.forEach((data) => {
        // Set background color for the header cell
        data.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb:"red" },
        };
      worksheet.addRow(data);
    });

    // Save the workbook as an Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${name}.xlsx`);

    // Hide loading indicator and show success message
    setLoading(false);
    successAlert("Data exported successfully");
  } catch (error) {
    // Hide loading indicator and show error message
    setLoading(false);
    errorAlert(error.message || "Something went wrong");
  }
};

export const exportToExcelTemplate = async (
  excelHeaders,
  setLoading,
  successAlert,
  errorAlert,
  name,
) => {
  try {
    // Show loading indicator
    setLoading(true);

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

   

    // Create a header row with custom styles
    const headerRow = worksheet.addRow(excelHeaders.map((header) => header.label));

    headerRow.eachCell((cell, colNumber) => {
      // Set font color for the header cell
      cell.font = { color: { argb: excelHeaders[colNumber - 1].color } };

      // Set background color for the header cell
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        // fgColor: { argb: excelHeaders[colNumber - 1].bgColor },
      };
    });

    // Auto-size columns based on content
    worksheet.columns.forEach((column, colNumber) => {
      let maxLength = 0;
      column.eachCell((cell) => {
        const len = cell.value ? cell.value.toString().length : 10; // Minimum width 10
        if (len > maxLength) {
          maxLength = len;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength; // Minimum width 10
    });

  

    // Save the workbook as an Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `${name}.xlsx`);

    // Hide loading indicator and show success message
    setLoading(false);
    successAlert('Data exported successfully');
  } catch (error) {
    // Hide loading indicator and show error message
    setLoading(false);
    errorAlert(error.message || 'Something went wrong');
  }
};


export const exportErrorTemplate = async (
  excelHeaders,
  dataForExcel,
  setLoading,
  successAlert,
  errorAlert,
  name,
 


) => {

  try {
   

    // Show loading indicator
    setLoading(true);

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
   
    // Create a header row with custom styles
    const headerRow = worksheet.addRow(
      excelHeaders.map((header) => header)
    );

    headerRow.eachCell((cell, colNumber) => {
      // Set font color for the header cell
      cell.font = { color: { argb: excelHeaders[colNumber - 1].color } };

      // Set background color for the header cell
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: excelHeaders[colNumber - 1].bgColor },
      };

      // Set column width for the header cell
      worksheet.getColumn(colNumber).width = excelHeaders[colNumber - 1].width;
    });

    // Add the data rows
    dataForExcel.forEach((data) => {
    
      worksheet.addRow(data);
    });

    // Save the workbook as an Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${name}.xlsx`);

    // Hide loading indicator and show success message
    setLoading(false);
    successAlert("Data exported successfully");
  } catch (error) {
    // Hide loading indicator and show error message
    setLoading(false);
    errorAlert(error.message || "Something went wrong");
  }
};