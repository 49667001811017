export const setNewSite = (data) => {
    return {
        type: "SET_SITE",
        data: data,
    };
};

export const disableSite =(data)=>{
    return{
        type:"DISABLE_SITE",
        data:data
    }
}
