import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Grid, TextField, Autocomplete, IconButton } from "@mui/material";
export default function AutoDialogBox({
  open,
  setOpen,
  scroll,
  columns,
  // selectedColumns,
  // setSelectedColumns,
  heading,
  autoCompName,
  handleDelete,
  columnHandle,
}) {
  const [searchKey, setSearchKey] = React.useState(null);
  const [localSearch, setLocalSearch] = React.useState([]);
  const columnMinLength =
  columns && columns?.filter((ele) => ele.isShow === true);

  const handleClose = () => {
    setOpen(false);
  };

  const hrStyle = { color: "rgb(63, 121, 181)" };

  const localSearchHandle = (e, name) => {
    const targetValue = e.target.value.toLowerCase();

    const filteredValues = columnMinLength?.filter((ele) => {
      if (
        ele?.isShow == true &&
        ele?.alias !== "all" &&
        ele?.alias !== "remove" &&
        ele.name !== "S.NO" &&
        ele.name !== " "
      ) {
        if (ele?.name.toLowerCase().includes(targetValue)) {
          return ele;
        }
      }
    });
    if (filteredValues.length > 0) {
      setLocalSearch(filteredValues);
    } else {
      setLocalSearch([]);
      // setSelectedColumns([]);
    }
  };

  const smoothScrollTo = (container, targetOffset, duration) => {
    const start = container.scrollLeft;
    const distance = targetOffset - start;
    const startTime = performance.now();

    const scrollStep = () => {
      const currentTime = performance.now();
      const timeFraction = Math.min(1, (currentTime - startTime) / duration);
      const scrollPosition = start + distance * ease(timeFraction);

      container.scrollLeft = scrollPosition;

      if (timeFraction < 1) {
        requestAnimationFrame(scrollStep);
      }
    };

    const ease = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

    requestAnimationFrame(scrollStep);
  };

  const scrollHandle = (item) => {
    const targetCell = document.getElementById(`scroll_id_${item.id}`);

    if (targetCell) {
      const tableContainer = document.getElementById(
        "cashflow_table_container"
      );
      const targetCellOffset =
        targetCell.offsetLeft - tableContainer.offsetLeft;
      smoothScrollTo(tableContainer, targetCellOffset, 500);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <>
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ flexGrow: 1, color: hrStyle.color }}
        >
          {heading}
        </DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            color: hrStyle.color,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </>
      <hr style={{ overflow: "hidden", display: "none" }}></hr>
      <DialogContent
        dividers={scroll === "paper"}
        sx={{ width: "100%", textAlign: "-webkit-center", overflow: "hidden" }}
      >
        <Autocomplete
          options={
            (columns &&
              columns.length > 0 &&
              columns.filter((option) => option.isShow === false)) ||
            []
          }
          value={{}}
          size="small"
          style={{ width: "100%" }}
          getOptionLabel={(option) => option?.name || ""}
          getOptionSelected={(option, value) => option?.isShow === true}
          onChange={(event, newValue) => {
            columnHandle(newValue);
            // const newValueArray = Array.isArray(newValue)
            //   ? newValue
            //   : [newValue];

            // if (newValueArray.find((val) => val && val.alias === "all")) {
            //   const filteredColumns =
            //     columns &&
            //     columns.length > 0 &&
            //     columns.filter(
            //       (val) => val.alias !== "all" && val.alias !== "remove"
            //     );
            //   setSelectedColumns(filteredColumns);
            //   setLocalSearch(filteredColumns);
            // } else if (
            //   newValueArray.find((val) => val && val.alias === "remove")
            // ) {
            //   setSelectedColumns(() => []);
            //   setLocalSearch(() => []);
            // } else {
            //   setSelectedColumns([...selectedColumns, ...newValueArray]);
            //   setLocalSearch([...selectedColumns, ...newValueArray]);
            // }
          }}
          variant="outlined"
          renderInput={(params) => (
            <TextField
              {...params}
              label={autoCompName}
              size="small"
              variant="outlined"
            />
          )}
        />

        <hr style={{ marginTop: "20px" }}></hr>
        <Grid container sx={{ width: "100%", marginTop: "20px" }}>
          <Grid item xs={12}>
            <TextField
              name="search"
              type="text"
              style={{ width: "100%" }}
              size="small"
              variant="outlined"
              placeholder="Search"
              fullWidth
              onChange={(e) => localSearchHandle(e, autoCompName)}
              InputProps={{
                endAdornment: (
                  <SearchIcon
                    sx={{ cursor: "pointer" }}
                    color="action"
                    position="end"
                  />
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "20px",
              overflow: "auto",
              width: "100%",
              height: "250px",
              "& ::-webkit-scrollbar": {
                width: "3px !important", // for vertical scroll bar
                height: "3px !important", // for horizontal scroll bar
              },
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                display: "block",
                textAlign: "start",
              }}
            >
              {localSearch && localSearch.length > 0
                ? localSearch?.map((items, index) => (
                    <Chip
                      disabled={columnMinLength?.length <= 5}
                      // disabled={items?.isFixed && items?.isFixed }
                      key={index}
                      style={{ marginTop: "3px" }}
                      label={items.name}
                      onDelete={() => handleDelete(items)}
                      onClick={() => scrollHandle(items)}
                    />
                  ))
                : columnMinLength?.map(
                    (items, index) =>
                      items?.isShow == true &&
                      items?.alias !== "all" &&
                      items?.alias !== "remove" && (
                        <Chip
                          disabled={columnMinLength?.length <= 5}
                          // disabled={items?.isFixed && items?.isFixed }
                          key={index}
                          style={{ marginTop: "3px" }}
                          label={items.name}
                          onDelete={() => handleDelete(items)}
                          onClick={() => scrollHandle(items)}
                        />
                      )
                  )}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
