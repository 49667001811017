import React, { useState } from "react";
import { IconButton, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { blockInvalidChar } from "../utils";

const TableCompoentModal = ({ open, closeDialogBox, tableItem, dynamicFields, setDynamicFields, errorAlert }) => {

    const [firstEntry, setFirstEntry] = useState(true);
    const [numberOfFields, setNumberOfFields] = useState();
    const [fieldValues, setFieldValues] = useState([]);

    const handleAdd = () => {
        if (firstEntry) {
            if (!Array.isArray(numberOfFields) || numberOfFields.length === 0)
                errorAlert("Provide the number of columns to proceed.");
            else setFirstEntry(false);

        }
        else {
            if (Array.isArray(numberOfFields) && numberOfFields.length > 0 && fieldValues.every(val => val !== '')) {
                setDynamicFields([...dynamicFields, { ...tableItem, options: fieldValues }]);
                closeDialogBox();
            } else
                errorAlert("No value existed or some field values are empty.");

        }
    };

    const handleFieldChange = (index, value) => {
        const updatedFieldValues = [...fieldValues];
        updatedFieldValues[index] = value;
        setFieldValues(updatedFieldValues);
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            style={{ width: firstEntry ? "600px" : "500px", margin: "auto" }}
        >
            <DialogTitle id="alert-dialog-title" style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6">Custom Fields</Typography>
                <IconButton onClick={() => closeDialogBox()}>
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {firstEntry && (
                    <TextField
                        size="small"
                        margin="dense"
                        value={numberOfFields?.length}
                        onChange={(e) => {
                            const count = parseInt(e.target.value);
                            setNumberOfFields(Array.from({ length: count }));
                            setFieldValues(Array.from({ length: count }).fill(""));
                        }}
                        type="number"
                        onKeyDown={(e) => blockInvalidChar(e)}
                        fullWidth
                        label="Type Number of Columns"
                    />
                )}

                {!firstEntry && numberOfFields && Array.isArray(numberOfFields) &&
                    numberOfFields.map((val, index) => (
                        <TextField
                            key={index}
                            size="small"
                            margin="dense"
                            type="text"
                            value={fieldValues[index]}
                            onChange={(e) => handleFieldChange(index, e.target.value)}
                            // onKeyDown={(e) => blockInvalidChar(e)}
                            fullWidth
                        />
                    ))}

            </DialogContent>
            <DialogActions>

                <Button color="error" variant="contained" onClick={() => closeDialogBox()}>
                    Cancel
                </Button>

                <Button color="primary" variant="contained" onClick={() => handleAdd()}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TableCompoentModal;
