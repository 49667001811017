const initialState = { isOpen: false };

export const calculatorReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case "OPEN_CALCULATOR":
      return { ...state, isOpen: true };
    case "CLOSE_CALCULATOR":
      return { ...state, isOpen: false };
    default:
      return state;
  }
};
