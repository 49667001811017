import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    secondary: {
      main: '#f50057',
    },
  }
});


export const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(9.3),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    marginBottom: '30px',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      //   marginTop: theme.spacing(6),
      //   marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    width: "100%",
  },
});


export const PENDING_COLOR = "#ffe2e2";
export const APPROVED_COLOR = "#fff";