import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
export default function AlertDialog({
  handleClickOpen,
  open,
  setOpen,
  handleSubmit,
  title,
  submitTitle,
  cancelTitle
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ textAlign: "center", justifyContent: "center" }}
      >
        <div style={{ width: "100%", marginTop: "10px" }}>
          <ErrorOutlineIcon sx={{ fontSize: "150px", color: "#facea8" }} />
        </div>

        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "#3f79b5", fontSize: "20px" }}
        >
          {title ||
            "Do you confirm your decision to finalize and submit the transaction?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions sx={{ justifyContent: "center", gap: "20px" }}>
          <Button
            autoFocus
            onClick={() => handleSubmit()}
            sx={{ backgroundColor: "#3f79b5 !important", color: "white" }}
          >
            {submitTitle || "Submit"}
          </Button>
          <Button
            onClick={handleClose}
            sx={{ backgroundColor: "#f50057 !important", color: "white" }}
          >
            {cancelTitle || "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
