import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  Box,
  ButtonGroup,
  Chip,
  Grid,
  InputAdornment,
  List,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import moment from "moment";
import AmountInfo from "../CommonComps/AmountInfo";
import { voucherTypesUrlV1 } from "../config";
import { userService } from "../userService";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import pdfLogo from "../images/accounting.png";
import ConvertAmountToWords from "../CommonComps/AmountConverter";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const PurchaseVoucherPreview = ({ open, handleClose, title }) => {
  const pdfRef = React.useRef(null);
  const qrValue = 123;

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        sx={{ "& .MuiDialog-paper": { overflow: "visible" } }}
        open={open}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box ref={pdfRef} id="divToPrint">
            <Grid container mb={1}>
              <Grid item md={3}></Grid>
              <Grid item md={6} sx={{ alignSelf: "center" }}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    component={"h2"}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                   NYGGS - HEAD OFFICE
                  </Typography>
                  <Typography
                    component={"p"}
                    sx={{
                      fontSize: "16px",
                      lineHeight: 1.3,
                    }}
                  >
                    Address, <br></br>
                    GSTIN/UIN: 22AAAAA0000A1Z5 <br></br>
                    State Name: ABC, Code: 00 <br></br>
                    CIN: XYZ123456789 <br></br>
                    E-Mail: info@email.com
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: "25px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    component={"h2"}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Purchase Voucher
                  </Typography>
                  <Typography sx={{ fontWeight: "300", color: "gray" }}>
                    12345
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={3} textAlign={"end"}>
                <Box
                  sx={{
                    marginBottom: "30px",
                  }}
                >
                  <div
                    style={{
                      height: "auto",
                      maxWidth: 124,
                      width: "100%",
                      marginLeft: "auto",
                    }}
                  >
                    <QRCode
                      size={356}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={qrValue && JSON.stringify(qrValue)}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Grid container mb={1}>
              <Grid item md={6}>
                <Typography
                  component={"h2"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  Ref.:{" "}
                  <span style={{ fontWeight: "500" }}>
                    XYZ/24-25/76 dt. 11-May-24
                  </span>
                </Typography>
              </Grid>
              <Grid item md={6} textAlign={"end"}>
                <Box>
                  <Typography sx={{ fontWeight: "300", color: "gray" }}>
                    <em>21-Jun-2024</em>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container mb={1}>
              <Grid item md={2}>
                <Typography
                  component={"h2"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  Party's Name :
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  component={"h2"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "300",
                    lineHeight: 1.3,
                  }}
                >
                  <span style={{ fontWeight: "500" }}>AVRTRANSPORT & CONTRACTOR</span> <br></br>
                  Gurgaon
                </Typography>
              </Grid>
              <Grid item md={4}></Grid>
              <Grid item md={2}>
                <Typography
                  component={"h2"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  GSTIN/UIN :
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  component={"h2"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>12ABCXYZ0901D1Z0</span>
                </Typography>
              </Grid>
              <Grid item md={4}></Grid>
            </Grid>
            <Grid container>
              <Grid item md={12}>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderTop: "1px solid rgba(224, 224, 224, 1)",
                            padding: 0,
                          }}
                        >
                          {" "}
                          <b> Particulars </b>{" "}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            borderTop: "1px solid rgba(224, 224, 224, 1)",
                            padding: 0,
                          }}
                        >
                          {" "}
                          <b> Amount </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        height: 350, // Set the desired height here
                        overflowY: "auto", // Enable vertical scrolling if content overflows
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            paddingLeft: 0,
                            verticalAlign: "top",
                          }}
                          className="particular_acc"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              component={"p"}
                              sx={{ fontSize: "14px", width: "70%" }}
                            >
                              ABC Limited <br></br>
                              <em style={{ marginLeft: "20px" }}>
                                ( {"remarks"} )
                              </em>
                            </Typography>
                            <Typography
                              component={"p"}
                              sx={{
                                fontSize: "14px",
                                width: "30%",
                                textAlign: "end",
                              }}
                            >
                              1700
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ verticalAlign: "top", paddingRight: 0 }}
                        >
                          <Typography component={"p"} sx={{ fontSize: "14px" }}>
                              1700
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell sx={{ paddingLeft: 0 }} colSpan={2}>
                          <Typography sx={{ fontWeight: 500, color: "#000" }}>
                            Amount (in words) :
                          </Typography>
                          <Typography
                            sx={{
                              marginLeft: "25px",
                              textTransform: "capitalize",
                            }}
                          >
                            One Thousand Seven Hundred Rupees
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container mt={1}>
              <Grid item md={2}>
                <Typography
                  component={"h2"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  Buyer's PAN :
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  component={"h2"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>XYZABC4070E</span>
                </Typography>
              </Grid>
              <Grid item md={6} textAlign={"end"}>
                <Typography
                  component={"h2"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "300",
                    marginTop: "20px",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>
                    for{" "}
                    NYGGS - HEAD OFFICE
                  </span>
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "300",
                    marginTop: "55px",
                  }}
                >
                  Authorised Signatory
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default PurchaseVoucherPreview;
