import React from "react";
import { Typography, Table, TableBody, TableContainer, TableHead, Paper, Tooltip } from "@mui/material";
import {  EditedtextCode } from "../CommonComps/TextTemplate";
import { pageSizes } from "../Account/util";
import { StyledTableCell, StyledTableRow } from "../utils/index";

import moment from "moment";
import CommonPagination from "../CommonComps/CommonPagination";
import { useNavigate } from "react-router-dom";
import AmountInfo from "../CommonComps/AmountInfo";
import { FcInfo } from "react-icons/fc";
import ColorInfo from "../CommonComps/ColorInfo";
import { DataStatus } from "../CommonComps/DataStatus";

const headers = [
    { id: 1, name: "S.NO", style: { textAlign: "left" } },
    { id: 2, name: "Transaction Date", style: { textAlign: "left" } },
    { id: 3, name: "Status", style: { textAlign: "left" } },
    { id: 5, name: "Particulars", style: { textAlign: "left" } },
    { id: 6, name: "Txn Id", style: { textAlign: "left" } },
    { id: 8, name: "Debit", style: { textAlign: "right", marinLeft: "10px" } },
    { id: 9, name: "Credit", style: { textAlign: "right", marinLeft: "10px" } },
    { id: 10, name: "Closing Balance", style: { textAlign: "right" } },
   
]


function Row({ row, index }) {
    const navigate = useNavigate()

    const { debitAmount, txnId, creditAmount, createdOn, date, partnerAccountName, partnerAccountCode, openingBalance, openingBalanceTypeName, closingBalance, closingBalanceTypeName, remarks, voucherNo, status } = row

    return (
        <React.Fragment>


            <StyledTableRow style={debitAmount ? { background: "#B4EDC0", color: "#fff" } : creditAmount ? { background: "#FADCD9" } : { background: "#997950" }}>
                {/* <StyledTableRow> */}
                <StyledTableCell >
                    <Typography variant="body2"> {index} </Typography>
                </StyledTableCell>

                <StyledTableCell   >
                    <Typography variant="body2">
                        {moment(date).format("DD-MM-YYYY, hh:mm a") || "-"}
                    </Typography>
                </StyledTableCell>

                {/* <StyledTableCell >
                    <Typography variant="body2">{financialYearDTO?.name || "-"}</Typography>
                </StyledTableCell> */}

                <StyledTableCell  >
                    <EditedtextCode
                        text={partnerAccountName}
                        code={partnerAccountCode}
                        textColor="black"
                        codeColor="blue"
                    />
                </StyledTableCell>




                <StyledTableCell align="right" style={{ color: " #307e3a", }}>

                    {
                        +parseInt(Math.abs(debitAmount)) &&
                        <AmountInfo amount={debitAmount} />
                    }
                </StyledTableCell>

                <StyledTableCell align="right" style={{ color: "#ff0000", }}>

                    {
                        +parseInt(Math.abs(creditAmount)) &&
                        <AmountInfo amount={creditAmount} />
                    }
                </StyledTableCell>

                <StyledTableCell align="center" style={{ textAlign: "right" }}>
                    <span>
                        {
                            +parseInt(Math.abs(closingBalance)) &&
                            <AmountInfo amount={closingBalance} />
                        }
                        &nbsp; {closingBalanceTypeName}

                    </span>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ textAlign: "center" }}>
                    <span> {status || "-"}</span>
                </StyledTableCell>

            

            </StyledTableRow>
            <div style={{ height: 10 }}></div>


        </React.Fragment>
    );
}


const RecordsTable = ({ data}) => {




    return (
        <>
            <TableContainer component={Paper} style={{ marginTop: 20, height: data && data.length > 0 &&  650 }}>
                <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                        <StyledTableRow>
                            {
                                headers && Array.isArray(headers) && headers.map(val =>
                                    <StyledTableCell key={val.id} style={val.style}> <span>{val.name}</span> </StyledTableCell>
                                )
                            }
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {data &&
                            data.map((row, index) => {
                                return (
                                    <Row
                                        style={{  }}
                                        row={row}
                                        index={index + 1}
                                    />
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

            {
                data && Array.isArray(data) && data.length > 0 &&
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <ColorInfo />
                   
                </div>
            }
            {
                (!data || data.length <=0) && <DataStatus/>
            }
        </>

    )
}
export default RecordsTable