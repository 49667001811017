import { getEncryptPayload } from "../../Network_Utility";
import { rolePermissionUrl } from "../../config";
import { userService } from "../../userService";
import { fetchUserPermission } from "./PermissionActions";

const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

export function fetchRolePermission(
  setIsLoading,
  errorAlert,
  userId,
  primarySiteId,
  navigate
) {
  return (dispatch) => {
    const payload = { userId };
    const encryptedPayload = getEncryptPayload(payload, true);
    setIsLoading(true);

    userService
      .login(rolePermissionUrl, encryptedPayload)
      .then((res) => {
        if (res?.data?.data) {
          const updatedData = res.data.data;
          localStorage.setItem("rolePermission", JSON.stringify(updatedData));
          dispatch(rolePermissionRequestSuccess(res.data));
          setTimeout(() => {
            dispatch(
              fetchUserPermission(
                setIsLoading,
                errorAlert,
                userId,
                primarySiteId,
                navigate
              )
            );
          }, 500);
        } else {
          dispatch(rolePermissionRequestFailure(res?.data));
          errorAlert(res?.data?.message || "Something went wrong");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        errorAlert("Unable to Fetch Role!!");
        setIsLoading(false);
      });
  };
}

export const rolePermissionRequestSuccess = (data) => {
  return {
    type: SUCCESS,
    data: data
  };
};

export const rolePermissionRequestFailure = (data) => {
  return {
    type: FAILURE,
    data: data
  };
};
