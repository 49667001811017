import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Paper,
  Container,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Badge,
  IconButton,
  Drawer
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom"; // missing import for useNavigate
import GSTReportsIcon from "../images/GSTImage.png"; // assuming transaction image is in assets folder
import { styles } from "../Theme/Theme";
import Header from "../CommonComps/Header";
import AutoDialogBox from "../CommonComps/AutoDialogBox";
import { userConfig } from "../config/UserConfig";
import { transactionsUrl } from "../config";
import { checkPermissionExist } from "../utils";
import TuneIcon from "@mui/icons-material/Tune";
import SideBar from "../CommonComps/SideBar";
import moment from "moment";
import GSTTable from "./GSTTable";
import { GST1Data, GST2Report, GST3BReport } from "./Utils";

const Index = ({ classes }) => {
  const navigate = useNavigate();
  const currentTable =
    window.location.pathname === "/gst/gst-1"
      ? "GSTR1ReportTable"
      : window.location.pathname === "/gst/gst-2"
      ? "GSTR2ReportTable"
      : "GSTR3ReportTable";

  const reduxState = useSelector((val) => val?.rootReducer);
  const storedSite = reduxState?.siteReducer?.site;
  const endPoints = reduxState.permissionReducer?.data;
  const siteList = [
    { id: 909, name: "Select All", alias: "all" },
    { id: 910, name: "Remove All", alias: "remove" },
    ...reduxState?.sitesReducer?.data
  ];

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [headerConfig, setHeaderConfig] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  //==================Pagination====================//
  const [pageNo, setPageNo] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const [totalPages, setTotalPages] = React.useState(1);

  useEffect(() => {
    const dataMap = {
      GSTR1ReportTable: GST1Data,
      GSTR2ReportTable: GST2Report,
      GSTR3ReportTable: GST3BReport
    };

    const selectedData = dataMap[currentTable];
    if (selectedData) {
      setTotalPages(Math.ceil(selectedData.length / pageSize));
    } else {
      setTotalPages(1);
    }
  }, [GST1Data, GST2Report, GST3BReport, currentTable, pageSize]);

  // ===========Alerts===================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: ""
  });

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const handleDelete = (ele) => {
    console.log(ele, currentTable, "vahasb");
    userConfig.hideColumn(window.location.pathname, currentTable, ele.id);
    const getHeaderConfig = userConfig.getHeader(
      window.location.pathname,
      currentTable
    );
    setHeaderConfig(getHeaderConfig);
  };

  const columnHandle = (val) => {
    if (val !== null) {
      if (val?.alias === "all") {
        userConfig.showAllColumn(window.location.pathname, currentTable);
        setHeaderConfig(
          userConfig.getHeader(window.location.pathname, currentTable)
        );
        return;
      }
      if (val?.alias === "remove") {
        userConfig.hideAllColumn(window.location.pathname, currentTable);
        setHeaderConfig(
          userConfig.getHeader(window.location.pathname, currentTable)
        );
        return;
      }
      if (val?.id) {
        userConfig.showColumn(window.location.pathname, currentTable, val?.id);
        setHeaderConfig(
          userConfig.getHeader(window.location.pathname, currentTable)
        );
      }
    }
  };

  useEffect(() => {
    const currentPage = window.location.pathname;
    const getHeaderConfig = userConfig.getHeader(currentPage, currentTable);
    setHeaderConfig(getHeaderConfig);
  }, [currentTable]);

  const handleClickOpen = (event) => {
    setOpen(true);
  };

  const renderAutocomplete = (label, value, width) => (
    <Grid item>
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        size="small"
        margin="dense"
        style={{ minWidth: width, maxWidth: 300 }}
        onChange={() => handleClickOpen(label)}
        value={
          value && value.length > 0
            ? value[0]?.name +
              (value.length > 1 ? " +" + (value.length - 1) : "")
            : ""
        }
        onClick={() => handleClickOpen(label)}
      />
    </Grid>
  );
  const isFilterApplied = fromDate || toDate;

  const handleToDate = (value, type) => {
    if (type === "date") {
      const fromDateCurrent = moment(fromDate);
      const toDateCurrent = moment(value);
      const result = toDateCurrent.diff(fromDateCurrent, "days");
      const alertMessage =
        result < 0 ? "To Date should be after From Date" : "";

      setAlerts({
        visible: result < 0,
        alertMessage,
        alertType: "error"
      });

      if (result >= 0) {
        setToDate(value);
      }
    }
  };

  const handleFromDate = (value, type) => {
    if (type === "date") {
      if (toDate != null) {
        const toDateCurrent = moment(toDate);
        const fromDateCurrent = moment(value);
        const result = toDateCurrent.diff(fromDateCurrent, "days");
        const alertMessage =
          result < 0 ? "From Date should be before To Date" : "";

        setAlerts({
          visible: result < 0,
          alertMessage,
          alertType: "error"
        });

        if (result >= 0) {
          setFromDate(value);
        }
      } else {
        setFromDate(value);
      }
    }
  };

  const filteredValues = [
    {
      typeId: 3,
      label: "From Date",
      value: fromDate,
      style: { width: 200, margin: "0 15px" },
      function: (value) => handleFromDate(value, "date")
    },

    {
      typeId: 3,
      label: "To Date",
      value: toDate,
      style: { width: 200, margin: "0 15px" },
      function: (value) => handleToDate(value, "date")
    }
  ];

  const list = (anchor) => (
    <SideBar
      anchor={anchor}
      toDate={toDate}
      handleToDate={handleToDate}
      fromDate={fromDate}
      handleFromDate={handleFromDate}
      state={state}
      setState={setState}
      toggleDrawer={toggleDrawer}
      filteredValues={filteredValues}
      handleSubmitFilterValue={handleSubmitFilterValue}
      handleResetFilter={handleResetFilter}
    />
  );

  const handleSubmitFilterValue = () => {
    return true;
  };

  const handleResetFilter = (isGetAcc) => {
    const filterValuesData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));
    const currentLocation = window.location.pathname;
    if (filterValuesData) {
      const updatedFilterData = filterValuesData?.filter(
        (ele) => ele?.pathname !== currentLocation
      );
      localStorage.setItem("filter", JSON.stringify(updatedFilterData));
      setFromDate(null);
      setToDate(null);
      setState({ ...state, right: false });
    }
  };
  console.log(headerConfig, "ncvf");

  return (
    <>
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ width: "100%", overflow: "auto" }}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Grid container style={{ margin: "0", alignItems: "center" }}>
            <Header
              gridSize="3"
              source={GSTReportsIcon}
              headerText={
                window.location.pathname === "/gst/gst-1"
                  ? "GSTR-1 Report"
                  : window.location.pathname === "/gst/gst-2"
                  ? "GSTR-2 Report"
                  : "GSTR-3B Report"
              }
            />

            <Grid
              item
              xs={9}
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              {renderAutocomplete(
                "Columns",
                (headerConfig || [])?.filter(
                  (ele) => ele.name !== "S.NO" && ele.isShow !== false
                ) || [],
                "20px"
              )}
              {
                <AutoDialogBox
                  columnHandle={columnHandle}
                  handleDelete={handleDelete}
                  open={open}
                  setOpen={setOpen}
                  setScroll={"paper"}
                  // scroll={scroll}
                  scroll={"paper"}
                  columns={(headerConfig || [])?.filter(
                    (ele) => ele.name !== "S.NO"
                  )}
                  heading={"Columns"}
                  autoCompName={"Columns"}
                />
              }

              <Grid item>
                <Autocomplete
                  options={siteList || []}
                  size="small"
                  margin="dense"
                  style={{
                    width: 200,
                    display: checkPermissionExist(endPoints, transactionsUrl)
                      ? "inline-flex"
                      : "none"
                  }}
                  value={selectedSite || ""}
                  getOptionLabel={(option) =>
                    option ? option.name || option.name : ""
                  }
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, newValue) => {
                    setSelectedSite(newValue);
                    // getAccountsList(newValue);
                    // getAccountGroup(newValue);
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField {...params} label="Site" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item>
                <div
                  style={{
                    display: checkPermissionExist(endPoints, transactionsUrl)
                      ? "inline-flex"
                      : "none"
                  }}
                >
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      {isFilterApplied ? (
                        <Badge color="primary" variant="dot">
                          <IconButton
                            style={{ background: "#c35151", color: "#fff" }}
                            onClick={toggleDrawer(anchor, true)}
                          >
                            <TuneIcon />
                          </IconButton>
                        </Badge>
                      ) : (
                        <IconButton
                          style={{ background: "#c35151", color: "#fff" }}
                          onClick={toggleDrawer(anchor, true)}
                        >
                          <TuneIcon />
                        </IconButton>
                      )}
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <GSTTable
            headerConfig={headerConfig}
            data={
              window.location.pathname === "/gst/gst-1"
                ? GST1Data
                : window.location.pathname === "/gst/gst-2"
                ? GST2Report
                : GST3BReport
            }
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </Container>
      </Paper>
    </>
  );
};

export default withStyles(styles)(Index);
