import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Paper, Container, Grid, Typography, IconButton, Drawer, Stack, Button, Autocomplete, TextField } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { BsFillCloudDownloadFill } from "react-icons/bs";
import TuneIcon from "@mui/icons-material/Tune";
import { userService } from "../userService";
import { IoMdArrowRoundBack } from "react-icons/io";
import { AlertBar } from "../Alert/AlertBar";
import { getEncryptPayload } from "../Network_Utility";
import { importBankReconciliationUrl, singleTransactionGetUrl, transactionLedgerExportUrl, voucherTypesUrl } from "../config";
import moment from "moment";
import LoadingComponent from "../Loader";
import { styles } from "../Theme/Theme";
import { checkPathExist, checkPermissionExist } from "../utils";
import AmountInfo from "../CommonComps/AmountInfo";
import { NumberFormatter } from "../CommonComps/NumberFormatter";
import SideBar from "../CommonComps/SideBar";
import { renderChip } from "../CommonComps/ChipComponent";
import FileUploadBox from "./FileUpload";
import RecordsTable from "./RecordsTable";

// const styles = (theme) => ({
//     paper: {
//         marginTop: theme.spacing(20),
//         marginBottom: theme.spacing(3),
//         padding: theme.spacing(2),

//         [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
//             marginTop: theme.spacing(6),
//             marginBottom: theme.spacing(6),
//             padding: theme.spacing(3),
//             marginLeft: theme.spacing(4),
//             marginRight: theme.spacing(4)
//         },
//         width: "100%"
//     }
// });

const Index = ({ classes }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const reduxState = useSelector(val => val?.rootReducer)
  const storedSite = reduxState?.siteReducer?.site;
  const endPoints = reduxState?.permissionReducer?.data
  const siteList = reduxState?.sitesReducer?.data

  const [site, setSite] = useState("");
  const [records, setRecords] = useState([])
  const [uploadDoc, setUploadDoc] = useState(true)
  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [balance, setBalance] = useState(null);
  const [balanceType, setBalanceType] = useState(null);
  const [accountName, setAccountname] = useState(null);
  const [accountCode, setAccountCode] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [backPath, setbackPath] = useState(null);
  const [voucherList, setVoucherList] = useState();
  const [status, setStatus] = useState(null)

  // ===========Alerts===================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: "",
  });

  //==================Pagination====================//
  const [pageNo, setPageNo] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const [totalPages, setTotalPages] = React.useState(1);
  const [transactionHistory, setTransactionHistory] = useState([]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [showFilteredValues, setShowFilteredValues] = useState(null)



  // Function to close alert bar
  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });



  // Function to show success alert bar
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Data fetched successfully.",
      alertType: "success",
    });
    setLoading(false);
  };

  // Function to show error alert bar
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Something went wrong",
      alertType: "error",
    });
    setLoading(false);
  };

  const importXl = async (doc) => {

    let data = new FormData();
    data.append("file", doc);
    data.append("companyId", localStorage.getItem("companyId"));
    data.append("siteId", localStorage.getItem("siteId"));
    data.append("userId", localStorage.getItem("userId"));
    // data.append("financialYearId", financialYearId?.id)
    // data.append("forceImport", true)


    const res = await userService.postData(importBankReconciliationUrl, data);
    try {
      if (res && res.data && res.data.status === 200 && res.data.data) {
        successAlert(res?.data?.message)
        setUploadDoc(false)
        setRecords(res?.data?.data)
      } else {
        errorAlert(res?.data?.message);
      }
    } catch (error) {
      errorAlert("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const { state } = location ?? {};
    if (state && state.id) {
      const { id, name, code, balance, balanceType, backPath } = state;
      setAccountId(id);
      setAccountname(name);
      setAccountCode(code);
      setBalance(balance);
      setBalanceType(balanceType);
      setbackPath(backPath);
    }

    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ width: "100%", overflow: "auto" }}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item container alignItems="center" xs={3}>
              <IoMdArrowRoundBack
                onClick={() => navigate("/account")}
                style={{ fontSize: 25, cursor: "pointer", color: "#21263c" }}
              />
              <Typography
                variant="h6"
                style={{ display: "inline-block", marginLeft: 20 }}
              >
                Bank reconciliation
              </Typography>
            </Grid>
            {
              !uploadDoc
              &&
              <Grid item container justifyContent="flex-end" xs={9} alignItems="center">
                <div>
                  <Button variant="contained">Reupload file</Button>
                </div>
                <div style={{ margin: "0px 10px" }}>
                  <Autocomplete
                    options={[]}
                    value={null}
                    size="small"
                    style={{ width: "200px" }}
                    getOptionLabel={(option) => `${option?.name}` || ""}
                    getOptionSelected={(option, value) => option?.name === value?.name}
                    onChange={(event, newValue) => {
                    }
                    }
                    variant="outlined"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Section"
                        size="small"
                        variant="outlined"

                      // error={customErrors.debitAccounts}

                      />
                    )}
                  />
                </div>

                <div>
                  <Autocomplete
                    options={[]}
                    value={null}
                    size="small"
                    style={{ width: "200px" }}
                    getOptionLabel={(option) => `${option?.name}` || ""}
                    getOptionSelected={(option, value) => option?.name === value?.name}
                    onChange={(event, newValue) => {
                    }
                    }
                    variant="outlined"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Status"
                        size="small"
                        variant="outlined"

                      // error={customErrors.debitAccounts}

                      />
                    )}
                  />
                </div>
              </Grid>
            }

          </Grid>

          <Grid container justifyContent="space-between">
            <Typography variant="h6">
              Account :{" "}
              <span style={{ color: "rebeccapurple" }}>
                {accountName}{" "}
                <span style={{ color: "black" }}>({accountCode})</span>
              </span>
            </Typography>
            {balance || balanceType ? (
              <Typography variant="h6">
                Balance :
                <span style={{}}>
                  {
                    +parseInt(Math.abs(balance)) >= 0 &&
                    <AmountInfo amount={balance} color="rebeccapurple"
                      fontSize="20px"
                    />
                  }
                  ({balanceType})
                </span>

              </Typography>
            ) : ""
            }
          </Grid>


          <Grid container>
            {
              uploadDoc ?
                <FileUploadBox
                  importXl={importXl}

                />
                : <RecordsTable
                  data={records}
                />
            }
          </Grid>

        </Container>
      </Paper>

      {/* ==============Alerts=============== */}
      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />

      {/* ==========Loader Container======= */}
      {loading && <LoadingComponent />}
    </>
  );
};

export default withStyles(styles)(Index);
