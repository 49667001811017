import React from "react";
import { FormControlLabel, Switch } from "@mui/material";

const MySwitch = ({ checked, onChange, label, ref }) => (
  <FormControlLabel
    control={<Switch checked={checked} onChange={onChange} inputProps={ref} />}
    label={label}
  />
);

export default MySwitch;
