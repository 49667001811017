import React from "react";
import { Box, Chip, Typography } from "@mui/material";

export const renderChip = (label, value, chipColor = "secondary") => {
  return value !== undefined && (label === "Debit" || label === "Credit") ? (
    <Box sx={{display: "flex", gap: "5px", alignItems: "center"}}>
      <Chip
        // label={value !== null ? `${label}: ${value}` : label}
        // color={chipColor}
        sx={{background: label === "Debit" ? "green" : "red", height: "17px", width: "17px",}}
        size="small"
      />
      <Typography sx={{fontWeight: "bold"}}>{label === "Debit" ? "Dr" : "Cr"}</Typography>
    </Box>
  ) : (
    value !== undefined && (
      <Chip
        label={value !== null ? `${label}: ${value}` : label}
        color={chipColor}
        size="small"
      />
    )
  );
};
