import React from 'react';
import { Card, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useDrop } from 'react-dnd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const RightSide = ({ dynamicFields, setDynamicFields, setHandleTableComponent, openEditorFuntion }) => {
    const [, ref] = useDrop({
        accept: 'FIELD',
        drop: (item) => {
            if (item && item.type === "Table") {
                setHandleTableComponent(item);
            } else {
                setDynamicFields([...dynamicFields, item]);
            }
        },
    });

    const handleEditDelete = (index, text) => {
        if (text && text === "Remove") {
            const dt = [...dynamicFields];
            dt.splice(index, 1);
            setDynamicFields(dt);
        }

        if (text && text === "Edit Properties") openEditorFuntion(index)

    };

    return (
        <Grid
            container
            ref={ref}
            component={Card}
            style={{
                padding: '8px',
                minHeight: '310px',
                
            }}
        >
            <Grid item xs={12}>
                <Grid container>
                    {dynamicFields && Array.isArray(dynamicFields) && dynamicFields.length > 0 && dynamicFields.map((field, dyIndex) => {
                        return (
                            <Grid item xs={4} key={field.id}>
                                <TextField
                                    label={field.label}
                                    sx={{ width: "80%" }}
                                    size='small'
                                    margin='dense'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="more"
                                                    id="long-button"
                                                    aria-haspopup="true"
                                                    onClick={() => handleEditDelete(dyIndex, "Edit Properties")}
                                                >
                                                    <EditIcon color='green' sx={{ color: "green" }} />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="more"
                                                    id="long-button"
                                                    aria-haspopup="true"
                                                    onClick={() => handleEditDelete(dyIndex, "Remove")}
                                                >
                                                    <DeleteIcon sx={{ color: "red" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};
export default RightSide