import { userService } from "../../userService";
import { fetchUserPermission, getPermissionState } from "./PermissionActions";
import { fetchRolePermission } from "./rolePermissionAction";

const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

export function AuthenticateUser(payload, navigate, setIsLoading, errorAlert) {

  return (dispatch) => {
    let apiEndpoint = "authenticate";
    userService.login(apiEndpoint, payload)
      .then((res) => {
        if (res && res.data && res.data.token) {
          const { token, userId, companyId, firstName, primarySiteId, username } = res.data;

          sessionStorage.setItem("token", token);
          localStorage.setItem("userId", userId);
          localStorage.setItem("companyId", companyId);
          localStorage.setItem("username", username);
          localStorage.setItem("primarySite", primarySiteId);
          dispatch(loginSuccess(res.data));
          setTimeout(() => {
            dispatch(fetchRolePermission(setIsLoading, errorAlert, userId, primarySiteId, navigate))
            // dispatch(getPermissionState(setIsLoading, errorAlert, userId, primarySiteId, navigate))
          }, 500);
        } else {
          dispatch(loginFailure(res.data));
          errorAlert(res?.data?.message || "Unable to login at this moment.")
          setIsLoading(false)
        }

      })
      .catch(err => {
        errorAlert(err?.response?.status === 401 ? "Invalid Login Credentials" : "Something went wrong.")
        setIsLoading(false)
      })
  };
}

export const loginFailure = (data) => {
  return {
    type: FAILURE,
    data: data,
  };
};

export const loginSuccess = (data) => {
  return {
    type: SUCCESS,
    data: data,
  };
};

