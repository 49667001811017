import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  Box,
  ButtonGroup,
  Chip,
  Grid,
  InputAdornment,
  List,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Divider
} from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
// import JsPDF from "jspdf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import ControlledSwitches from "../BalanceSheet/SwitchButton";
import companyLogo from "../images/companyLogo.png";
import { StyledTableCell, StyledTableRow } from "../utils/index";
import AmountInfo from "./AmountInfo";
import Checkbox from "@mui/material/Checkbox";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const ReportsPDFTemplate = ({ open, handleClose, title, data, CIN }) => {
  const reduxState = useSelector((state) => state?.rootReducer);
  let companyDto = reduxState?.companyReducer?.data || null;
  const pdfRef = React.useRef(null);

  const [state, setState] = React.useState({
    Horizontal: false
  });
  const [silderControl, setSilderControl] = React.useState(false);
  const [tableState, setTableState] = React.useState(false);
  const [StartEndDate, setStartEndDate] = React.useState(
    "(1-Apr-23 to 9-Apr-24)"
  );

  const [reportsData, setReportsData] = React.useState({});
  const [checkedFooter, setCheckedFooter] = React.useState(false);

  React.useEffect(() => {
    var leftTotalAmount = data?.leftAccountGroupFetchResponseList?.reduce(
      (accumulator, currentValue) => {
        return title === "Balance Sheet"
          ? accumulator + currentValue.accountGroupDebitBalance
          : accumulator + currentValue.accountGroupCurrentBalance;
      },
      0
    );

    var rightTotalAmount = data?.rightAccountGroupFetchResponseList?.reduce(
      (accumulator, currentValue) => {
        return title === "Balance Sheet"
          ? accumulator + currentValue.accountGroupDebitBalance
          : accumulator + currentValue.accountGroupCurrentBalance;
      },
      0
    );
    const targetData = {
      leftHeader: [
        { id: 1, name: title === "Balance Sheet" ? "Assets" : "Revenue" },
        { id: 2, name: leftTotalAmount }
      ],
      rightHeader: [
        { id: 1, name: title === "Balance Sheet" ? "Liabilities" : "Expenses" },
        { id: 2, name: rightTotalAmount }
      ],
      reportsDto: data,
      totalAmount: [
        {
          id: 1,
          name: title === "Balance Sheet" ? "Total Assets" : "Total Revenue",
          amount:
            title === "Balance Sheet"
              ? parseInt(leftTotalAmount - rightTotalAmount)
              : parseInt(leftTotalAmount)
        },
        {
          id: 2,
          name:
            title === "Balance Sheet" ? "Total Liabilities" : "Total Expenses",
          amount:
            title === "Balance Sheet"
              ? parseInt(leftTotalAmount - rightTotalAmount)
              : parseInt(rightTotalAmount)
        }
      ]
    };
    setReportsData(targetData);
  }, [data]);

  const printHandle = () => {
    const element = document.getElementById("divToPrint");

    const opt = {
      margin: 0.3,
      filename:
        title === "Balance Sheet"
          ? `${"Balance Sheet"}.pdf`
          : `${"Profit & Loss"}.pdf`,
      image: { type: "jpeg", quality: 2 },
      html2canvas: { scale: 20, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
    };

    // New Promise-based usage:
    html2pdf().set(opt).from(element).save();
  };

  console.log(reportsData, "nbdndj");

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        sx={{ "& .MuiDialog-paper": { overflow: "visible" } }}
        open={open}
        maxWidth={"md"}
        fullWidth
      >
        <Box
          sx={{
            margin: "0px 15px",
            position: "relative",
            display: "grid",
            justifyContent: "center"
          }}
        >
          <DialogTitle
            sx={{ m: 0, padding: "16px 24px 2px 24px", color: "#005eb7" }}
            id="customized-dialog-title"
          >
            {title}
          </DialogTitle>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center"
            }}
          >
            {[
              "Horizontal",
              <ControlledSwitches
                tableState={tableState}
                setTableState={setTableState}
              />,
              "Vertical"
            ].map((text, index) => (
              <Typography key={index} variant="h6" component="h6" fontSize={12}>
                <p>{text}</p>
              </Typography>
            ))}
          </div>
        </Box>

        <DialogContent dividers>
          <Box ref={pdfRef} id="divToPrint" sx={{ padding: "4px 10px" }}>
            <Grid container xs={12}>
              <Grid item xs={4}>
                <img
                  src={companyLogo}
                  alt="company-logo"
                  style={{ height: "4rem" }}
                />
              </Grid>
              <Grid item xs={8} textAlign={"end"}>
                <div
                  style={{
                    // textAlign: "start",
                    display: "inline-block",
                    width: "40%"
                  }}
                >
                  <Typography variant="h6" component="h6">
                    {companyDto?.companyName || "NKC Project Pvt. Ltd."}
                  </Typography>
                  <Typography fontSize={"12px"}>
                    {companyDto.regAddress ||
                      "Plot No. 872, Phase-5, Udyog Vihar, Sector-19, Gurugram, Haryana 234213"}
                  </Typography>
                  <Typography
                    sx={{ textDecoration: "underline", color: "blue" }}
                    fontSize={"11px"}
                  >
                    CIN : {CIN || "No"}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ textAlign: "center", paddingTop: "20px" }}
            >
              <Grid item xs={12}>
                <Typography fontWeight={"bold"}>{title}</Typography>
                <Typography fontSize={"10px"}>{StartEndDate || ""}</Typography>
              </Grid>
            </Grid>
            {!tableState ? (
              <>
                <Grid container sx={{ paddingTop: "10px" }} xs={12}>
                  <Grid item xs={6} sx={{ borderRight: "0.1px solid gray" }}>
                    <ReportsTable
                      data={data && data.leftAccountGroupFetchResponseList}
                      header={reportsData.leftHeader}
                      title={title}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <ReportsTable
                      data={data && data.rightAccountGroupFetchResponseList}
                      header={reportsData.rightHeader}
                      title={title}
                    />
                  </Grid>
                </Grid>
                <Grid container xs={12} sx={{ borderTop: "1px solid gray" }}>
                  {reportsData &&
                    reportsData?.totalAmount?.map((ele, index) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          sx={{
                            justifyContent: "space-between",
                            display: "flex",
                            borderRight:
                              index === 0 ? "0.1px solid gray" : null,
                            padding: "4px 0px"
                          }}
                        >
                          <Typography
                            sx={{
                              paddingLeft: "10px !important",
                              fontSize: "14px",
                              fontWeight: "bold"
                            }}
                          >
                            {ele.name || ""}
                          </Typography>
                          <Typography
                            sx={{
                              paddingRight: "3px !important",
                              fontSize: "14px"
                            }}
                          >
                            <AmountInfo amount={ele.amount} />
                          </Typography>
                        </Grid>
                      );
                    })}
                </Grid>
              </>
            ) : (
              <>
                <Grid container sx={{ paddingTop: "10px" }} xs={12}>
                  <Grid item xs={12}>
                    <ReportsTable
                      data={data && data.leftAccountGroupFetchResponseList}
                      header={reportsData.leftHeader}
                    />
                  </Grid>
                </Grid>
                <Grid container xs={12} sx={{ borderTop: "1px solid gray" }}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      justifyContent: "space-between",
                      display: "flex",
                      padding: "4px 0px"
                    }}
                  >
                    <Typography
                      sx={{
                        paddingLeft: "10px !important",
                        fontSize: "14px",
                        fontWeight: "bold"
                      }}
                    >
                      {title === "Balance Sheet"
                        ? "Total Assets"
                        : "Total Revenue"}
                    </Typography>
                    <Typography
                      sx={{ paddingRight: "3px !important", fontSize: "14px" }}
                    >
                      <AmountInfo amount={7419023.16} />
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            <Divider />
            {checkedFooter && (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "40px 0px 20px 0px"
                  }}
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    {"Receiver's Signature:"}
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {"Authorised Signatory"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "20px 0px"
                  }}
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    {"Prepared By"}
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {"Checked By"}
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {"Verified By"}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {/* <Grid container sx={{ marginTop: "20px" }}>
              <Grid item xs={12} sx={{ textAlign: "end" }}>
                <Typography sx={{ fontSize: "10px" }}>
                  {"Powered by NYGGS"}
                </Typography>
              </Grid>
            </Grid> */}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <FormGroup>
            <FormControlLabel
              sx={{ fontSize: "1px" }}
              control={
                <Checkbox
                  defaultChecked={checkedFooter}
                  onClick={() => setCheckedFooter(!checkedFooter)}
                />
              }
              label={checkedFooter ? "Hide Footer" : "Show Footer"}
            />
          </FormGroup>
          <Button
            color="primary"
            variant="contained"
            onClick={() => printHandle()}
          >
            Print
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default ReportsPDFTemplate;

const ReportsTable = ({ data, header, title }) => {
  console.log(header, "bsdh");
  return (
    <TableContainer>
      <Table aria-label="collapsible table" stickyHeader>
        <TableHead>
          <StyledTableRow sx={{ position: "sticky", zIndex: 10000 }}>
            {header &&
              header.map((ele, index) => (
                <StyledTableCell
                  sx={{ textAlign: ele.id > 1 ? "end" : null }}
                  key={index}
                >
                  {!isNaN(parseFloat(ele?.name)) ? (
                    <AmountInfo amount={ele?.name} />
                  ) : (
                    ele?.name
                  )}
                </StyledTableCell>
              ))}
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.length > 0 &&
            data.map((ele, index) => (
              <Row data={ele} key={index} title={title} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Row = ({ data, title, key }) => {
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell sx={{ paddingLeft: "10px !important" }}>
          {data?.name}
        </StyledTableCell>
        <StyledTableCell sx={{ textAlign: "end" }}>
          <AmountInfo
            amount={
              title === "Balance Sheet"
                ? data?.accountGroupDebitBalance
                : data?.accountGroupCurrentBalance
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};
