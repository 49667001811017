import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import LoadingComponent from "../Loader";
import VendorTemplate from "../VendorTemplate";
import MapData from "../VendorTemplate/mapData";
import ConfigModal from "../VendorTemplate/ConfigModal";
import Voucher from "../Voucher";
import CreateVoucherTemp from "../Voucher/createVoucherTemp";
import EditTemplate from "../Voucher/EditTemplate";
// import ThirdPartyConfig from "../VendorTemplate/ThirdPartyConfig";
import GST_Report from "../GST_Report";
import TDS_Report from "../TDS_Report";
import BankReconciliation from "../Bank Reconciliation";
import BalanceSheet from "../BalanceSheet";
import AccountSiteConfiguration from "../AccountSiteConfiguration";

import Reports from "../Reports";
import BankBook from "../BankBook";
import PayableReports from "../PayableReports";
import ReceivableReports from "../ReceivableReports";
import GSTReports from "../GSTReports";
import { Button } from "@mui/material";
import RolePermission from "../RolePermission/index";

// import VoucherType from "../Voucher/VoucherType";
// import VoucherTransaction from "../Voucher/voucherTransaction";
const PrivateRoute = lazy(() => import("./privateRoute"));
const Login = lazy(() => import("../Login/index"));
const Account = lazy(() => import("../Account"));
const AddAccount = lazy(() => import("../Account/AddAccount"));
const Ledgers = lazy(() => import("../Ledgers"));
const TransactionLocking = lazy(() => import("../TransactionLocking"));
const AddTransaction = lazy(() =>
  import("../TransactionLocking/AddTransaction")
);
const AccountGroup = lazy(() => import("../AccountGroup"));
const ReportGroup = lazy(() => import("../Account_Report_Group"));
const AddAccountGroup = lazy(() => import("../AccountGroup/AddAccountGroup"));
const AddReportGroup = lazy(() =>
  import("../Account_Report_Group/AddReportGroup")
);
const TrialBalance = lazy(() => import("../TrialBalance"));
const AccountType = lazy(() => import("../AccountType"));
const UpdateAccountType = lazy(() =>
  import("../AccountType/UpdateAccountType")
);
const CustomFields = lazy(() => import("../KYC/index"));
const AddKYCField = lazy(() => import("../KYC/AddKYCField"));
const AccountConfig = lazy(() => import("../AccountConfig"));
const SiteList = lazy(() => import("../Sitelist"));
const Dashboard = lazy(() => import("../Dashboard"));
const Journals = lazy(() => import("../Journals"));
const MiniDrawer = lazy(() => import("../Navigation/index"));
const EntityTemplate = lazy(() => import("../EntityTemplate"));
const PaymentRequest = lazy(() =>
  import("../../components/Payment_Request/PaymentRequest")
);
// const PaymentLedger = lazy(() => import('../../Payment_Request/PaymentLedger'))
const RequestTrail = lazy(() =>
  import("../../components/Payment_Request/TransactionTrail")
);
const ProfitLossTemplate = lazy(() => import("../Profit_And_Loss/index"));
// const BalanceSheetTemplate = lazy(() => import('../Balance_sheet/index'))
const TransactionTrail = lazy(() =>
  import("../TransactionLocking/TransactionTimeline")
);
// const AnnualBalanceSheet = lazy(() => import("../AnnualSheets/BalanceSheets/index"));
const DashboardConfiguration = lazy(() =>
  import("../DashboardConfiguration/DashboardConfiguration")
);
const CashflowTemplate = lazy(() => import("../Cashflow/index"));
const errorImage = require("../images/error.jpg");
// const AnnualProfitandloss = lazy(() => import("../AnnualSheets/Profit&Loss/index"));
const ReportsConfiguration = lazy(() => import("../ReportConfiguration/index"));
const Approval = lazy(() => import("../PendingApprovals/PendingApprovals"));
const GroupList = lazy(() => import("../ReportConfiguration/GroupList"));
const Narration = lazy(() => import("../Narrations/index"));
const AddNarration = lazy(() => import("../Narrations/AddNarration"));
const AddConfig = lazy(() => import("../VendorTemplate/ConfigList"));
const Help = lazy(() => import("../Helper/ShortcutKeys"));
const AccountCode = lazy(() => import("../AcccountCode/index"));
const AddAccountCode = lazy(() => import("../AcccountCode/AddAccountCode"));
const AddAccountTransactionType = lazy(() =>
  import("../AcccountTransactionType/AddAccTransactionType")
);
const AccountTransactionType = lazy(() =>
  import("../AcccountTransactionType/index")
);

const CompanyConfiguration = lazy(() =>
  import("../CompanyConfiguration/Index")
);

const CompanyConfigSave = lazy(() => import("../CompanyConfiguration/Save"));

const OverDueAnalysis = lazy(() => import("../OverDueAnalysis/index"));

const PermissionRole = lazy(() => import("../RolePermission/index"));
const AddPermissionRole = lazy(() =>
  import("../RolePermission/AddPermissionRole")
);
const ConfigurationAccountGroup = lazy(() =>
  import("../ConfigurationAccountGroup/index")
);

const AddConfiguration = lazy(() =>
  import("../ConfigurationAccountGroup/AddConfiguration")
);

const AddAccountConfiguration = lazy(() =>
  import("../AccountConfiguration/AddAccountConfig")
);

const AccountCorrection = lazy(() => import("../Account/AccountCorrection"));

const ItemsInformation = lazy(() => import("../Item&HSNcodeMgmt/index"));
const ProjectGroup = lazy(() => import("../ProjectGroup/index"));
const ProjectGroupAdd = lazy(() => import("../ProjectGroup/AddProjectGroup"));
const AddItems = lazy(() => import("../Item&HSNcodeMgmt/AddItems"));
const AddHSNCode = lazy(() => import("../Item&HSNcodeMgmt/AddHSNCode"));
const HSNCodeInformation = lazy(() =>
  import("../Item&HSNcodeMgmt/ShowHSNCodeIndex")
);

const ERROR = () => {
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item lg={12} xs={12} md={12} container justifyContent="center">
        <img
          src={errorImage}
          alt="error_image"
          style={{ width: "100vw", height: "100vh" }}
        />
        <Button
          sx={{
            position: "absolute",
            left: "30px",
            top: "25px",
          }}
          onClick={() => navigate("/")}
          variant="contained"
          color="primary"
        >
          Back to login{" "}
        </Button>
      </Grid>
    </Grid>
  );
};

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingComponent />}>
        <MiniDrawer />

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<ERROR />}></Route>
          <Route
            path="/siteList"
            element={
              <PrivateRoute>
                {" "}
                <SiteList />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                {" "}
                <Dashboard />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/generalLedger"
            element={
              <PrivateRoute>
                {" "}
                <Ledgers />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/trialBalance"
            element={
              <PrivateRoute>
                {" "}
                <TrialBalance />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/preferences"
            element={
              <PrivateRoute>
                {" "}
                <AccountConfig />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoute>
                {" "}
                <Account />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/addAccount"
            element={
              <PrivateRoute>
                {" "}
                <AddAccount />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/accountGroup"
            element={
              <PrivateRoute>
                {" "}
                <AccountGroup />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/reportGroup"
            element={
              <PrivateRoute>
                {" "}
                <ReportGroup />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/addAccountGroup"
            element={
              <PrivateRoute>
                {" "}
                <AddAccountGroup />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/addReportGroup"
            element={
              <PrivateRoute>
                {" "}
                <AddReportGroup />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/accountType"
            element={
              <PrivateRoute>
                {" "}
                <AccountType />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/updateAccountType"
            element={
              <PrivateRoute>
                {" "}
                <UpdateAccountType />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/journals"
            element={
              <PrivateRoute>
                {" "}
                <Journals />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/daybook"
            element={
              <PrivateRoute>
                {" "}
                <TransactionLocking />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/paymentRequest"
            element={
              <PrivateRoute>
                {" "}
                <PaymentRequest />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/pendingApprovals"
            element={
              <PrivateRoute>
                {" "}
                <Approval />{" "}
              </PrivateRoute>
            }
          />
          {/* <Route path="/paymentLedger" element={<PrivateRoute> <PaymentLedger /> </PrivateRoute>} /> */}
          <Route
            path="/addTransaction"
            element={
              <PrivateRoute>
                {" "}
                <AddTransaction />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/kycImplemenation"
            element={
              <PrivateRoute>
                {" "}
                <CustomFields />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/addKYCFields"
            element={
              <PrivateRoute>
                {" "}
                <AddKYCField />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/entityTemplate"
            element={
              <PrivateRoute>
                {" "}
                <EntityTemplate />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/vendorTemplate"
            element={
              <PrivateRoute>
                {" "}
                <VendorTemplate />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/mapData"
            element={
              <PrivateRoute>
                {" "}
                <MapData />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/config"
            element={
              <PrivateRoute>
                {" "}
                <ConfigModal />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/liabilities"
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/fixedAssets"
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/bankBook"
            element={
              <PrivateRoute>
                <BankBook />
              </PrivateRoute>
            }
          />
          <Route
            path="/voucherTemplate"
            element={
              <PrivateRoute>
                <Voucher />
              </PrivateRoute>
            }
          />
          <Route
            path="/createVoucherTemp"
            element={
              <PrivateRoute>
                <CreateVoucherTemp />
              </PrivateRoute>
            }
          />
          {/* <Route path="/annualbalanceSheet" element={<PrivateRoute><AnnualBalanceSheet /></PrivateRoute>} /> */}
          {/* <Route path="/annualprofitandloss" element={<PrivateRoute><AnnualProfitandloss /></PrivateRoute>} /> */}
          <Route
            path="/dashboardConfiguration"
            element={
              <PrivateRoute>
                <DashboardConfiguration />
              </PrivateRoute>
            }
          />
          <Route
            path="/profitLoss"
            element={
              <PrivateRoute>
                {" "}
                <ProfitLossTemplate />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/balanceSheet"
            element={
              <PrivateRoute>
                {" "}
                <BalanceSheet />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/cashflow"
            element={
              <PrivateRoute>
                {" "}
                <CashflowTemplate />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/transactionTrail"
            element={
              <PrivateRoute>
                {" "}
                <TransactionTrail />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/requestTrail"
            element={
              <PrivateRoute>
                {" "}
                <RequestTrail />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/editTemplate"
            element={
              <PrivateRoute>
                {" "}
                <EditTemplate />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/GroupList"
            element={
              <PrivateRoute>
                <GroupList />
              </PrivateRoute>
            }
          />
          <Route
            path="/reportsMaster"
            element={
              <PrivateRoute>
                <ReportsConfiguration />
              </PrivateRoute>
            }
          />
          <Route
            path="/gstReport"
            element={
              <PrivateRoute>
                <GST_Report />
              </PrivateRoute>
            }
          />
          <Route
            path="/tdsReport"
            element={
              <PrivateRoute>
                <TDS_Report />
              </PrivateRoute>
            }
          />
          <Route
            path="/bankReconciliation"
            element={
              <PrivateRoute>
                <BankReconciliation />
              </PrivateRoute>
            }
          />
          <Route
            path="/AccountSiteConfiguration"
            element={
              <PrivateRoute>
                <AccountSiteConfiguration />
              </PrivateRoute>
            }
          />
          <Route
            path="/narration"
            element={
              <PrivateRoute>
                <Narration />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-narration"
            element={
              <PrivateRoute>
                <AddNarration />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/config-apis"
            element={
              <PrivateRoute>
                <AddConfig />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/help"
            element={
              <PrivateRoute>
                <Help />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/account-code"
            element={
              <PrivateRoute>
                <AccountCode />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-account-code"
            element={
              <PrivateRoute>
                <AddAccountCode />
              </PrivateRoute>
            }
          />
          <Route
            path="/account-transaction-type"
            element={
              <PrivateRoute>
                <AccountTransactionType />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-account-transaction-type"
            element={
              <PrivateRoute>
                <AddAccountTransactionType />
              </PrivateRoute>
            }
          />
          <Route
            path="/aging-Summary-Details"
            element={
              <PrivateRoute>
                <PayableReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/company-configuration"
            element={
              <PrivateRoute>
                <CompanyConfiguration />
              </PrivateRoute>
            }
          />
          <Route
            path="/vendor-Balance-Summary"
            element={
              <PrivateRoute>
                <PayableReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/unpaid-Bills-Details"
            element={
              <PrivateRoute>
                <PayableReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/accounts-Payable-Summary"
            element={
              <PrivateRoute>
                <PayableReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/receivable/aging-summary"
            element={
              <PrivateRoute>
                <ReceivableReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/receivable/customer-balance-summary"
            element={
              <PrivateRoute>
                <ReceivableReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/receivable/invoice-list-summary"
            element={
              <PrivateRoute>
                <ReceivableReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/receivable/account-receivable-summary"
            element={
              <PrivateRoute>
                <ReceivableReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/gst/gst-1"
            element={
              <PrivateRoute>
                <GSTReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/gst/gst-2"
            element={
              <PrivateRoute>
                <GSTReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/gst/gst-3B"
            element={
              <PrivateRoute>
                <GSTReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/company-configuration-add"
            element={
              <PrivateRoute>
                <CompanyConfigSave />
              </PrivateRoute>
            }
          />
          <Route
            path="/over-due-analysis"
            element={
              <PrivateRoute>
                <OverDueAnalysis />
              </PrivateRoute>
            }
          />
          <Route
            path="/role-permission-mapping"
            element={
              <PrivateRoute>
                <RolePermission />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-role-permission"
            element={
              <PrivateRoute>
                <AddPermissionRole />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/account-group-configurations"
            element={
              <PrivateRoute>
                <ConfigurationAccountGroup />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/add-account-group-configurations"
            element={
              <PrivateRoute>
                <AddConfiguration />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-account-numbering"
            element={
              <PrivateRoute>
                <AddAccountConfiguration />
              </PrivateRoute>
            }
          />{" "}
          <Route
            path="/account/display-account-correction"
            element={
              <PrivateRoute>
                <AccountCorrection />
              </PrivateRoute>
            }
          />
          <Route
            path="/items-hsncode-management"
            element={
              <PrivateRoute>
                <ItemsInformation />
              </PrivateRoute>
            }
          />
          <Route
            path="/items-hsncode-management/add-item-details"
            element={
              <PrivateRoute>
                <AddItems />
              </PrivateRoute>
            }
          />
          <Route
            path="/items-hsncode-management/add-HSN-code-details"
            element={
              <PrivateRoute>
                <AddHSNCode />
              </PrivateRoute>
            }
          />
          <Route
            path="/items-hsncode-management/HSN-Code-list"
            element={
              <PrivateRoute>
                <HSNCodeInformation />
              </PrivateRoute>
            }
          />
          <Route
            path="/project-group"
            element={
              <PrivateRoute>
                <ProjectGroup />
              </PrivateRoute>
            }
          />
          <Route
            path="/project-group-add"
            element={
              <PrivateRoute>
                <ProjectGroupAdd />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default AppRouter;
