export const addNewSearchableKey = (key) => {
    return {
        type: "ADD_KEY",
        data: key,
    };
};

export const removeKey = (key, index) => {
    return {
        type: "REMOVE_KEY",
        data: key,
        index: index,
    };
};
