import React, { useState, useEffect, useRef } from "react";
import { FiPlus } from "react-icons/fi";
import { checkPathExist, checkPermissionExist } from "../utils";
import { AlertBar } from "../Alert/AlertBar";
import { userService } from "../userService";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import TuneIcon from "@mui/icons-material/Tune";
import { useSelector, useDispatch } from "react-redux";
import { getEncryptPayload } from "../Network_Utility/index";
import {
  TextField,
  Paper,
  Container,
  Grid,
  IconButton,
  Drawer,
  InputAdornment,
  Autocomplete,
  Stack,
  Chip,
  Checkbox,
  FormControlLabel,
  Badge,
} from "@mui/material";
import { FcSearch } from "react-icons/fc";
import { accountRequestSuccess } from "../features/actions/AccountAction";
import {
  accountConfigUpdate,
  accountConfigUrl,
  accountGroupDropdownUrlV2,
  accountGroupGetUrl,
  accountsConfigGet,
  singleAccountUrl,
} from "../config";
import LoadingComponent from "../Loader";
import AccountTable from "./AccountTable";
import Header from "../CommonComps/Header";
import { styles } from "../Theme/Theme";
import SideBar from "../CommonComps/SideBar";
import { Button } from "@material-ui/core";
import { renderChip } from "../CommonComps/ChipComponent";
const accounts = require("../images/accounting.png");

// const kycTypes = [
//     {
//         id: 1, name: "PENDING"
//     },
//     {
//         id: 2, name: "NOT_REQUIRED"
//     },
//     {
//         id: 2, name: "COMPLETED"
//     }]

const Index = ({ classes, path }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxState = useSelector((val) => val?.rootReducer);
  const siteList = reduxState?.sitesReducer?.data;

  const site = reduxState?.siteReducer;
  const accountTypes = reduxState.accountTypeReducer;
  const endPoints = reduxState.permissionReducer?.data;

  const restoredSite = reduxState?.siteReducer?.site;

  // ======================States======================//
  const [accountsList, setAccountsList] = useState([]);
  const [loading, setLoading] = useState(true);

  //==================Pagination====================//
  const [pageNo, setPageNo] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const [totalPages, setTotalPages] = React.useState(1);
  const [selectedSite, setSelectedSite] = useState(null);

  const [accountGroups, setAccountGroups] = useState([]);
  const [showFilteredValues, setShowFilteredValues] = useState(false);

  // ============Filters==============//

  // ===========Alerts================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: "",
  });
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [selectAll, setSelectAll] = useState(false);
  const [accountsFilter, setAccountsFilter] = useState({
    searchField: null,
    accountType: null,
    accountGroup: null,
    state: null,
    kycType: null,
    isEnableForCurrentSite: null,
  });

  const [count, setCount] = useState(0);
  const [isRemove, setIsRemove] = useState(true);
  const [totalLength, setTotalLength] = useState(0);
  const shortcutSearchRef = useRef(null);

  // Function to close alert bar
  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });

  // Function to toggle sidebar
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
    // setSite(null)
  };

  // Function to call on button click event in Sidebar

  // Function to show success alert message
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertType: "success",
      alertMessage: message || "Success",
    });
    setLoading(false);
  };

  // Function  to show error alert message
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertType: "error",
      alertMessage: message || "Something went wrong",
    });
    setLoading(false);
  };

  const handleSubmitFilterValue = () => {
    setShowFilteredValues(true);
    getAccountsList();
    setState({ ...state, right: false });
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    const newData = accountsList?.map((val) => ({
      ...val,
      isSelected: val?.isEnableForCurrentSite ? false : event.target.checked,
    }));
    setAccountsList(newData);
    if (event.target.checked) {
      setIsRemove(false);
    } else {
      setIsRemove(true);
    }
  };

  const handleCheckboxChange = (value, ind) => {
    let data = [...accountsList];
    data[ind].isSelected = value;

    setAccountsList(data);

    // Check if any item in the list has isSelected set to false
    const isAnyNotSelected = data.some((item) => !item.isSelected);
    const allChecked = data.every((item) => item.isSelected);
    if (allChecked) {
      // setIsRemove(true);
      // setSelectAll(true)
    }
    if (!isRemove) {
      setCount((prev) => prev + 1);
    }
    // Check if the number of selected items is equal to the total number of items
    // const isAllSelected = data.filter((item) => item.isSelected).length === totalLength;

    // Update the selectAll state based on the conditions
    if (isAnyNotSelected) {
      setSelectAll(false);
    }
  };

  const handleApproval = async () => {
    if (accountsList?.filter((val) => val?.isSelected)?.length === 0) {
      errorAlert("Please Select atleast One Account to procees");
      return false;
    }

    let payload = {
      isEnableForCurrentSite: true,
      accountIds: accountsList
        ? accountsList.map((ele) => ele.isSelected && ele.id).filter(Boolean)
        : [],
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      setLoading(true);
      const response = await userService.patchData(
        accountConfigUpdate,
        encryptedPayload
      );
      if (response && response.data && response.data.status === 200) {
        successAlert(response?.data?.message);
        getAccountsList();
      } else errorAlert(response?.data?.message);
    } catch (error) {
      errorAlert(error?.message);
    } finally {
      setLoading(false);
    }
  };

  //Function to call getAccountList on the basis of permission
  useEffect(() => {
    const permissionExist = checkPermissionExist(endPoints, accountsConfigGet);

    if (permissionExist) getAccountsList();
    else {
      // errorAlert("Permission denied for account list fetch")
      errorAlert("unauthorized to access account list.");
      dispatch(accountRequestSuccess([], ""));
    }

    // eslint-disable-next-line
  }, [pageNo, pageSize, selectedSite, restoredSite]);


  // /Array of sidebar
  const filteredValues = [
    {
      typeId: 2,
      label: "Account Group",
      options: accountGroups || [],
      value: accountsFilter?.accountGroup,

      style: { width: 200, margin: "0 15px" },
      function: (event, newValue) => {
        setPageNo(1);
        setAccountsFilter({ ...accountsFilter, accountGroup: newValue });
      },
      className: null,
      type: "name",
    },
    {
      typeId: 2,
      label: "Account Type",
      options: accountTypes?.data || [],
      value: accountsFilter?.accountType,
      style: { width: 200, margin: "0 15px" },
      function: (event, newValue) => {
        setPageNo(1);
        setAccountsFilter({ ...accountsFilter, accountType: newValue });
      },
      className: null,
      type: "name",
    },
  ];

  // Function to get sidebar
  const list = (anchor) => (
    <SideBar
      anchor={anchor}
      toggleDrawer={toggleDrawer}
      filteredValues={filteredValues}
      handleSubmitFilterValue={handleSubmitFilterValue}
      handleResetFilter={handleResetFilter}
    />
  );

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getAccountsList();
    }
  };

  const getAccountsList = async (bulkState) => {
    const { searchField, kycType, state, isEnableForCurrentSite } =
      accountsFilter;

    const filteredData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));
    const currentLocation = window.location.pathname;
    const findCurrentFilter = filteredData?.find(
      (ele) => ele.pathname === currentLocation
    );
    const filterValues = findCurrentFilter?.value;

    const accountType =
      filterValues?.find((item) => item.label === "Account Type")?.value ||
      null;
    const accountGroup =
      filterValues?.find((item) => item.label === "Account Group")?.value ||
      null;

    let payload = {
      pageNo: pageNo,
      pageSize: pageSize,
      permissionStateId: bulkState ? null : state?.id,
      searchField: searchField,
      accountTypeId: accountType ? accountType.id : null,
      isEnableForCurrentSite:
        isEnableForCurrentSite?.name == "True"
          ? true
          : isEnableForCurrentSite?.name == "False"
          ? false
          : null,
      accountGroupId: accountGroup ? accountGroup?.id : null,
      kycStatus: kycType ? kycType.name : null,
      site: selectedSite
        ? {
            id: selectedSite?.id,
            name:
              siteList &&
              siteList.find((val) => val.id == selectedSite?.id)?.name,
          }
        : {
            id: localStorage.getItem("siteId"),
            name:
              siteList &&
              siteList.find((val) => val.id == localStorage.getItem("siteId"))
                ?.name,
          },
    };
    let encryptedPayload = getEncryptPayload(payload, true);

    try {
      setLoading(true);
      const res = await userService.postData(
        accountsConfigGet,
        encryptedPayload
      );
      if (res && res.data && res.data.data && res.data.status === 200) {
        successAlert(res?.data?.message);
        const { data, totalLength } = res.data.data;

        if (data && Array.isArray(data) && data.length > 0) {
          const newData = data?.map((val) => ({
            ...val,
            isSelected: selectAll,
          }));
          setTotalLength(totalLength);
          setAccountsList(newData);
          setTotalPages(Math.ceil(totalLength / pageSize));
        } else {
          setAccountsList([]);
          setTotalPages(1);
        }
      } else {
        errorAlert(res?.data?.message);
        setAccountsList([]);
        setTotalPages(1);
      }
    } catch (err) {
      errorAlert(err?.message);
      setAccountsList([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  const getAccountGroup = async () => {
    const pathExist = checkPermissionExist(
      endPoints,
      accountGroupDropdownUrlV2
    );
    if (!pathExist) return;
    let payload = {
      siteId: localStorage.getItem("siteId"),
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      setLoading(true);
      const res = await userService.postData(
        accountGroupDropdownUrlV2,
        encryptedPayload
      );

      if (res && res.data && res.data.data && res.data.status === 200) {
        const { data, totalLength } = res.data;

        if (data && Array.isArray(data) && data.length > 0) {
          setAccountGroups(data);
        } else {
          setAccountGroups([]);
          successAlert("No account group available");
        }
      } else errorAlert(res?.data?.message);
    } catch (error) {
      errorAlert("Unable to fetch data at this moment");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (
      e.altKey &&
      e.shiftKey &&
      (e.key === "s" || e.key === "S") &&
      !e.ctrlKey
    ) {
      shortcutSearchRef.current.focus();
    }

    if (
      e.altKey &&
      e.shiftKey &&
      (e.key === "r" || e.key === "R") &&
      !e.ctrlKey
    ) {
      setState({ ...state, right: true });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    getAccountGroup();
  }, []);

  const handleResetFilter = () => {
    const filterValuesData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));
    const currentLocation = window.location.pathname;
    if (filterValuesData) {
      const updatedFilterData = filterValuesData?.filter(
        (ele) => ele?.pathname !== currentLocation
      );
      localStorage.setItem("filter", JSON.stringify(updatedFilterData));
      setAccountsFilter({
        searchField: null,
        accountType: null,
        accountGroup: null,
        state: null,
        kycType: null,
        platform: null,
      });
      setState({ ...state, right: false });
      getAccountsList();
    }
  };

  useEffect(() => {
    const filteredData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));
    const currentLocation = window.location.pathname;
    if (filteredData) {
      const findCurrentFilter = filteredData.find(
        (ele) => ele.pathname === currentLocation
      );

      if (findCurrentFilter) {
        const filterValues = findCurrentFilter.value;
        const updatedFilter = {
          accountType:
            filterValues.find((item) => item.label === "Account Type")?.value ||
            null,
          accountGroup:
            filterValues.find((item) => item.label === "Account Group")
              ?.value || null,
        };
        setAccountsFilter(updatedFilter);
      }
    }
  }, []);

  const isFilterApplied =
    accountsFilter?.accountGroup || accountsFilter?.accountType;

  return (
    <>
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ width: "100%", overflow: "auto" }}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Grid container style={{ margin: "0", alignItems: "center" }}>
            {path !== "pendingApprovals" && (
              <Header
                gridSize="3"
                source={accounts}
                headerText="Accounts Configuration"
              />
            )}

            <Grid
              item
              xs={9}
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <TextField
                  inputRef={shortcutSearchRef}
                  value={accountsFilter?.searchField}
                  onChange={(e) =>
                    setAccountsFilter({
                      ...accountsFilter,
                      searchField: e.target.value,
                    })
                  }
                  margin="dense"
                  size="small"
                  style={{
                    maxWidth: 200,
                    display: checkPermissionExist(endPoints, singleAccountUrl)
                      ? "inline-flex"
                      : "none",
                  }}
                  label="Search"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => getAccountsList()}
                        >
                          <FcSearch />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
                {/* <MyAutocomplete /> */}
              </Grid>

              <Grid item>
                <Autocomplete
                  options={siteList || []}
                  size="small"
                  margin="dense"
                  style={{
                    width: 200,
                    display: checkPermissionExist(endPoints, accountsConfigGet)
                      ? "inline-flex"
                      : "none",
                  }}
                  value={selectedSite || ""}
                  getOptionLabel={(option) =>
                    option ? option.name || option.name : ""
                  }
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, newValue) => {
                    setSelectedSite(newValue);
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField {...params} label="Site" variant="outlined" />
                  )}
                />
              </Grid>

              <Grid item>
                <Autocomplete
                  options={[
                    { id: 1, name: "True" },
                    { id: 2, name: "False" },
                  ]}
                  size="small"
                  margin="dense"
                  style={{
                    width: 200,
                    display: checkPermissionExist(endPoints, accountsConfigGet)
                      ? "inline-flex"
                      : "none",
                  }}
                  value={accountsFilter?.isEnableForCurrentSite || ""}
                  getOptionLabel={(option) =>
                    option ? option.name || option.name : ""
                  }
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, newValue) => {
                    setAccountsFilter({
                      ...accountsFilter,
                      isEnableForCurrentSite: newValue,
                    });
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enabled for current site"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                style={{
                  display: checkPermissionExist(endPoints, accountsConfigGet)
                    ? "flex"
                    : "none",
                }}
              >
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    {isFilterApplied ? (
                      <Badge color="primary" variant="dot">
                        <IconButton
                          style={{ background: "#c35151", color: "#fff" }}
                          onClick={toggleDrawer(anchor, true)}
                        >
                          <TuneIcon />
                        </IconButton>
                      </Badge>
                    ) : (
                      <IconButton
                        style={{ background: "#c35151", color: "#fff" }}
                        onClick={toggleDrawer(anchor, true)}
                      >
                        <TuneIcon />
                      </IconButton>
                    )}
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* {showFilteredValues && (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              {renderChip("Account Group", accountsFilter?.accountGroup?.name)}
              {renderChip("Account Type", accountsFilter?.accountType?.name)}
              {renderChip("Current State", accountsFilter?.state?.name)}
            </Stack>
          )} */}

          {accountsList?.length > 0 &&
            checkPermissionExist(endPoints, accountConfigUpdate) && (
              <Grid container style={{ margin: "0" }}>
                <Grid item md={4} display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                      />
                    }
                    label="Select All"
                    style={{ marginRight: "7px!important" }}
                  />
                  <span
                    style={{
                      padding: "0px 12px",
                      borderRadius: "8px",
                      background: "#3F79B5",
                      color: "white",
                      fontWeight: 700,
                    }}
                  >
                    {!isRemove
                      ? accountsList.filter((item) => item.isSelected).length
                      : ""}
                  </span>
                </Grid>
                <Grid
                  item
                  md={8}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    margin="dense"
                    onClick={handleApproval}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            )}

          <AccountTable
            data={accountsList}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            pageSize={pageSize}
            setPageSize={setPageSize}
            errorAlert={errorAlert}
            handleCheckboxChange={handleCheckboxChange}
            path={path}
          />
        </Container>
        {loading && <LoadingComponent />}
      </Paper>

      {/*================== Alert bar ====================*/}
      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />
    </>
  );
};
export default withStyles(styles)(Index);
