const initialState = {
  keys: []
};

const MAX_KEYS = 10;

export function searchReducer(state = initialState, action) {
  const { type, data, index } = action;

  switch (type) {
    case "ADD_KEY":
      let updatedKeysAdd = [data, ...state.keys];

      let uniqueHistory = updatedKeysAdd?.filter((ele, i) => {
        return updatedKeysAdd.findIndex(obj => obj.name === ele.name) === i;
      });

      // Ensure only up to MAX_KEYS are stored
      if (uniqueHistory.length > MAX_KEYS) {
        // Trim the array to the maximum allowed keys
        uniqueHistory = uniqueHistory?.slice(0, MAX_KEYS);
      }

      return {
        ...state,
        keys: uniqueHistory
      };

    case "REMOVE_KEY":
      let keyRemoved = false;

      // Use filter to create a new array without the specified key
      const updatedKeysRemove = state.keys.filter((existingKey, i) => {
        if (!keyRemoved && existingKey.name === data.name && i === index) {
          // If the key hasn't been removed yet, remove it and set the flag
          keyRemoved = true;
          return false;
        }
        return true;
      });

      return {
        ...state,
        keys: updatedKeysRemove
      };

    default:
      return state;
  }
}
