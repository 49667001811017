import { Typography, Tooltip } from "@mui/material";
import React from "react";

export const TextToolTip = ({ name }) => {
  return (
    <Tooltip title={<Typography>{name}</Typography>} placement="bottom-start">
      <span style={{ color: "blue", fontSize: 10, cursor: "pointer" }}>
        ...more
      </span>
    </Tooltip>
  );
};

export const EditedtextCode = ({ text, code,textColor,codeColor }) => {

  return (
    <>
      {((text && text.length > 20) || (code && code.length > 8)) ?
        <>
          <span style={{color:textColor}}>
            {text.slice(0, 20)}
          </span>
          <span style={{ color: codeColor, fontSize: 13, fontWeight: 600 }}>({
            code.toString().slice(0, 8)}
            )</span>
        </>
        : text ?
          <>
            <span style={{color:textColor}}>
              {text}
            </span>
            <span style={{ color: codeColor, fontSize: 13, fontWeight: 600 }}>({code})
            </span>
          </>
          : "-"}
      {
        ((text && text.length > 20) || (code && code.length > 8)) &&
        <TextToolTip name={`${text} (${code})`} />
      }
    </>
  )
}

export const Editedtext = ({ text, num, textColor, fWaight }) => {
  return (
    <>
      {text && text.length > num ? (
        <span style={{ color: textColor }}>{text.slice(0, num)}</span>
      ) : text ? (
        <span style={{ color: textColor, fontWeight: fWaight }}>{text}</span>
      ) : (
        "-"
      )}
      {text && text.length > num && <TextToolTip name={text} />}
    </>
  );
};


