import React, { useState } from 'react';
import './uploader.css';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import { AiOutlineFile } from 'react-icons/ai'; // Change to a more generic file icon
import { Grid } from '@mui/material';
// import { importBankReconciliationUrl } from '../config';

function FileUploadBox({importXl}) {
  const [file, setFile] = useState(null);

 

  const handleFileChange = ({ target: { files } }) => {
    if (files[0]) {
      setFile({
        name: files[0].name,
        type: files[0].type,
        url: URL.createObjectURL(files[0]),
      });

      importXl(files[0])
    }
  };

  const resetFile = () => {
    setFile(null);
  };

  return (
    <Grid container  sx={{  height:"500px"}} alignItems="center" justifyContent="center">
      <Grid item xs={4}>
        <form id="form" onClick={() => document.querySelector('.input-field').click()}>
          <input
            type="file"
            className="input-field"
            hidden
            onChange={handleFileChange}
          />

          {file ? (
            <div>
              {file.type.startsWith('image') ? (
                <img src={file.url} width="100px" height="100px" alt={file.name} />
              ) : (
                <AiOutlineFile color="#1475cf" size={60} />
              )}
              <p>{file.name}</p>
            </div>
          ) : (
            <>
              <MdCloudUpload color="#1475cf" size={60} />
              <p>Browse Files to upload</p>
            </>
          )}
        </form>
        {file && (
          <section className="uploaded-row">
            <AiOutlineFile color="#1475cf" style={{ fontSize: 30,}}/>
            <span className="upload-content">
              {file.name} -
              <MdDelete style={{ fontSize: 30, color: 'red', cursor: 'pointer' }} onClick={resetFile} />
            </span>
          </section>
        )}
      </Grid>
    
    </Grid>
  );
}

export default FileUploadBox;
