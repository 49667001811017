import React from "react";
import { accountRegisterUrl, accountTypesAddUrl, addAccountGroupUrl, addReportGroupUrl, addTransactionUrl, customFieldsAddUrl, saveVendorUrl, voucherTypeSaveUrl } from "../config";

const MenuPathsDialog = [
  {
    id: 1,
    name: "Account",
    path: "/addAccount",
    type: "Account",
    api: accountRegisterUrl,


  },
  {
    id: 2,
    name: "Account Group",
    path: "/addAccountGroup",
    type: "Account",
    api: addAccountGroupUrl
  },
  {
    id: 3,
    name: "Account Type",
    path: "/updateAccountType",
    type: "Account",
    api: accountTypesAddUrl
  },
  {
    id: 7,
    name: "KYC",
    path: "/addKYCFields",
    type: "Account",
    api: customFieldsAddUrl
  },
  {
    id: 4,
    name: "Transaction",
    path: "/addTransaction",
    type: "Reports",
    api: addTransactionUrl
  },

  {
    id: 4,
    name: "Account Group Report",
    path: "/addReportGroup",
    type: "Reports",
    api: addReportGroupUrl
  },


  {
    id: 5,
    name: "Voucher",
    path: "/createVoucherTemp",
    type: "Template",
    api: voucherTypeSaveUrl
  },
  {
    id: 6,
    name: "API",
    path: "/mapData",
    type: "Template",
    api: saveVendorUrl
  },
];
export default MenuPathsDialog;
