import * as React from "react";
import Switch from "@mui/material/Switch";

export default function ControlledSwitches({ tableState, setTableState }) {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    // setIsSaving(event.target.checked);
    setChecked(event.target.checked);
    setTableState(!tableState);
  };

  return (
    <Switch
      sx={{
        color: "green"
        // ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked": {
        //   color: "green",
        //   back
        // }
      }}
      checked={checked}
      onChange={handleChange}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}
