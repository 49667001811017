const initialState = {
    data: null,
    response: null,
    message: null,

};

export function companyReducer(state = initialState, action) {
  
    const { type, data } = action;
    

    switch (type) {
        case "COMPANY_DTO_SUCCESS":
            return {
                ...state,
                status: 200,
                data: data,
                message: "Success",
                
            };
        case "COMPANY_DTO_FAILURE": {
            return {
                ...state,
                status: 400,
                data: [],
                message: "Something Went Wrong",

            };
        }

        default:
            return state;
    }
}
