import React from "react";
import { IconButton, Button, Typography, Divider, Dialog, TableContainer, TableHead, TableBody, TextField, Table, DialogContent } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { Add } from "@mui/icons-material";
import { StyledTableCell, StyledTableRow } from "../utils";
import { pageSizes } from "../Account/util";
import CommonPagination from "../CommonComps/CommonPagination";

const ConfigModal = ({ open, closeDialogBox, accounts, handlAdd, handleAccounts,
    pageNo,
    setPageNo,
    totalPages,
    setTotalPages,
    pageSize,
    setPageSize,
    getAccountList,
    debitAccounts


}) => {
    const onPageChange = (event, value) => {
        setPageNo(value)
        setTimeout(() => {
            getAccountList(debitAccounts && debitAccounts.map(val => val.id))
        }, 500);
    }
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="lg"
        // style={{ width: "800px", margin: "auto" }}
        >
            <div id="alert-dialog-title" style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}>
                <Typography variant="h6"> Config </Typography>
                <IconButton onClick={() => closeDialogBox()}>
                    <ClearIcon />
                </IconButton>
            </div>
            <Divider />


            <DialogContent>
                <TableContainer fullWidth>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>S.No</StyledTableCell>
                                <StyledTableCell style={{ minWidth: "150px" }}>Account</StyledTableCell>
                                <StyledTableCell style={{ minWidth: "300px" }}>Action</StyledTableCell>
                                <StyledTableCell style={{ minWidth: "500px" }}>Config Key</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {accounts && accounts.length > 0 ? accounts.map((val, index) =>
                                <StyledTableRow>
                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                    <StyledTableCell>{val.name}</StyledTableCell>
                                    <StyledTableCell>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <TextField
                                                label="Key"
                                                value={val?.value || ""}
                                                style={{ width: "200px" }}
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                onChange={e => handleAccounts(index, e.target.value)}
                                            />
                                            <IconButton onClick={() => handlAdd(index)}>
                                                <Add />
                                            </IconButton>
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {val?.configKeys.length > 0 && val.configKeys.map(val =>
                                            <Button size="small" sx={{ margin: "5px" }} variant="contained" color="secondary">{val}</Button>
                                        )}
                                    </StyledTableCell>

                                </StyledTableRow>

                            ) : <p>No Accounts available</p>
                            }
                        </TableBody>
                    </Table>

                </TableContainer>
                {accounts && Array.isArray(accounts) && accounts.length > 0 && (
                    <CommonPagination
                        pageSize={pageSize}
                        pageSizes={pageSizes}
                        totalPages={totalPages}
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                        setPageSize={setPageSize}
                        onPageChange={onPageChange}
                        style={{ minWidth: 150, margin: "0  20px" }}
                        label="Account Per Page"
                    />
                )}
            </DialogContent>



        </Dialog >
    );
};

export default ConfigModal;
