import React from 'react';
import {Backdrop,CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const LoadingComponent = props => {
  
  const classes = makeStyles((theme) => ({
    backdrop: {
      zIndex: 1,
      color: '#fff'
    },
    loaderComp: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  }))();

  return (
    <Backdrop className={classes.backdrop} open={true} >
      <section className={classes.loaderComp}>
        <div><CircularProgress color="secondary" /></div>
        <div> Loading </div>
      </section>
    </Backdrop>
  )
};

export default LoadingComponent;
