import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import RightSide from './RightSide';
import LeftSide from './LeftSide';
import { Grid } from '@mui/material';
const DragAndDropComponent = ({ dynamicFields, setDynamicFields, setHandleTableComponent, openEditorFuntion }) => {

    return (
        <DndProvider backend={HTML5Backend}>
            <Grid container>
                <Grid item xs={3}>
                    <h2>Available Fields</h2>
                    <LeftSide />
                </Grid>
                <Grid item xs={9}>
                    <h2>Created Fields</h2>
                    <RightSide
                        dynamicFields={dynamicFields}
                        setDynamicFields={setDynamicFields}
                        setHandleTableComponent={setHandleTableComponent}
                        openEditorFuntion={openEditorFuntion}
                    />
                </Grid>
            </Grid>
        </DndProvider>
    );
};

export default DragAndDropComponent;
