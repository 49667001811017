import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  TextField,
  Paper,
  Container,
  Grid,
  Typography,
  Button,
  Box
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { userService } from "../userService";
import { AlertBar } from "../Alert/AlertBar";
import { disableSite } from "../features/actions/siteAction";
import { themecolor } from "../Navigation";
import { companyVendorsUrl, saveVendorUrl, vendorDetailList } from "../config";
import ConfigModal from "./ConfigModal";
import LoadingComponent from "../Loader";
import { styles } from "../Theme/Theme";
import { checkPathExist, checkPermissionExist } from "../utils";
import { useSelector } from "react-redux";
import IntegrationTable from "./IntegrationTable";
import { list, apiList } from "./json";

const accounts = require("../images/templates.png");
// const styles = (theme) => ({
//     paper: {
//         marginTop: theme.spacing(20),
//         marginBottom: theme.spacing(3),
//         padding: theme.spacing(2),
//         [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
//             marginTop: theme.spacing(6),
//             marginBottom: theme.spacing(6),
//             padding: theme.spacing(3),
//             marginLeft: theme.spacing(4),
//             marginRight: theme.spacing(4),
//         },
//         width: "90%"
//     }
// });

const Index = ({ classes }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxState = useSelector((state) => state?.rootReducer);

  let endPoints = reduxState?.permissionReducer?.data || [];
  const [apiName, setApiName] = useState(null);
  const [json, setJson] = useState(null);
  const [api, setApi] = useState(null);
  const [name, setName] = useState(null);
  const [platformList, setPlatformList] = useState([
    { id: 1, name: "Store" },
    { id: 2, name: "Billing" },
    { id: 3, name: "PNM" },
    { id: 4, name: "HRMS" }
  ]);
  const [platform, setPlatform] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accountTemplateName, setAccountTemplateName] = useState(null);
  // const [companyName, setCompanyName] = useState(null)
  const [vendorTemplates, setVendorTemplates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [tempList, setTempList] = useState([]);

  const [pageNo, setPageNo] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const [totalPages, setTotalPages] = React.useState(1);
  const [vendorLists, setVendorLists] = useState([]);

  const closeModal = () => setOpenModal(false);

  // ===========Alerts================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: ""
  });

  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });

  // Function  to show success alert message
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertType: "success",
      alertMessage: message || "Data fetched succesfully"
    });
    setLoading(false);
  };

  // Function  to show error alert message
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertType: "error",
      alertMessage: message || "Something went wrong"
    });
    setLoading(false);
  };

  const getVendorData = async () => {
    const companyId = localStorage.getItem("companyId");
    const payload = {
      platformId: platform?.id || null,
      companyId,
      apiName: null
    };
    setLoading(true);
    try {
      const response = await userService.postData(companyVendorsUrl, payload);
      if (response && response.data && response?.data?.status == 200) {
        const data = response?.data?.data || [];

        const tempList = data.map((item) => {
          const {
            accountTempalteName,
            accountTempalteTypeId,
            apiAndTempleteMap,
            name,
            platformId,
            id,
            apiName,
            accountGroup
          } = item;

          return {
            accountTempalteName,
            accountTempalteTypeId,
            apiAndTempleteMap: JSON.parse(apiAndTempleteMap),
            name: name,
            platformId,
            id,
            apiName,
            accountGroup
          };
        });
        setTempList(tempList);
        successAlert(response?.data?.message);
      } else {
        setTempList([]);
        errorAlert(response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getVendorListData = async () => {
    const companyId = localStorage.getItem("companyId");
    const payload = {
      companyId,
      tokenData: {
        companyId: localStorage.getItem("companyId"),
        moduleId: null,
        organizationId: null,
        userCode: null,
        userId: localStorage.getItem("userId"),
        userName: null,
        userRoleId: null
      }
    };
    setLoading(true);
    try {
      const response = await userService.postData(vendorDetailList, payload);
      if (response && response.data && response?.data?.status == 200) {
        const data = response?.data?.data || [];
        setVendorLists(data);
        successAlert(response?.data?.message);
      } else {
        setVendorLists([]);
        errorAlert(response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === "Insert" && !e.altKey && !e.shiftKey) {
      if (checkPermissionExist(endPoints, saveVendorUrl)) {
        navigate("/mapData");
      } else {
        errorAlert("You don't have permission to add transaction!!");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    getVendorListData();
    dispatch(disableSite(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const permissionExist = checkPermissionExist(endPoints, companyVendorsUrl);
    const pathExist = checkPathExist(endPoints, window.location.pathname);

    // if (permissionExist && pathExist) getVendorData();
    // else errorAlert("Unautharized to access vendor templates.");
  }, [platform]);

  useEffect(() => {
    const pathExist = checkPathExist(endPoints, window.location.pathname);
    if (!pathExist) navigate("/error");
  }, [endPoints]);

  return (
    <>
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ width: "100%", overflow: "auto" }}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Grid container style={{ margin: "0", alignItems: "center" }}>
            <Grid item lg={6} md={6} xs={6} container alignItems="flex-end">
              <img src={accounts} width="50" alt="accounts-icon" />
              <Typography
                variant="h6"
                style={{ display: "inline-block", marginLeft: 20 }}
              >
                Third Party Api Integration
                {/* Vendor Templates */}
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              {/* <Grid item>
                <Autocomplete
                  options={platformList || []}
                  value={platform || null}
                  size="small"
                  style={{ width: "250px", marginRight: 10 }}
                  getOptionLabel={(option) => `${option?.name}` || ""}
                  getOptionSelected={(option, value) =>
                    option?.name === value?.name
                  }
                  onChange={(event, newValue) => {
                    setPlatform(newValue);
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Platform"
                      size="small"
                      // className="text-field"
                      variant="outlined"
                      // error={customErrors.debitAccounts}
                    />
                  )}
                />
              </Grid> */}
              <Button
                color="primary"
                variant="contained"
                style={{
                  background: `${themecolor}`,

                  display: checkPermissionExist(endPoints, saveVendorUrl)
                    ? "inline-flex"
                    : "none"
                }}
                onClick={() => navigate("/mapData")}
              >
                Map Data
              </Button>

              {/* <Button color="primary" variant="contained" style={{ background: `green`, marginLeft: 5 }}
                                onClick={() => {
                                    setOpenModal(true)
                                    setApiName(api)
                                    setJson(name)
                                }}>
                                Template Config
                            </Button> */}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box>
                <IntegrationTable
                  // data={list.data}
                  data={vendorLists}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} marginTop={3}>
            {/* <Grid item lg={12}>
              {tempList &&
                tempList.length > 0 &&
                tempList.map((val, index) => (
                  <div>
                    <Typography variant="body1">
                      {index + 1}.
                      <span
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          marginLeft: 5,
                        }}
                        onClick={() => {
                          navigate("/mapData", { state: val });
                        }}
                      >
                        {val?.accountTempalteName} ({val?.apiName})
                      </span>
                    </Typography>
                    {val?.apiAndTempleteMap &&
                      Object.keys(val?.apiAndTempleteMap).length > 0 && (
                        <Grid item container>
                          {val?.apiAndTempleteMap &&
                            Object.keys(val?.apiAndTempleteMap).length > 0 &&
                            Object.entries(val?.apiAndTempleteMap).map(
                              ([key, value]) => (
                                <Grid
                                  item
                                  key={key}
                                  className="mapped-field"
                                  style={{ margin: "10px" }}
                                >
                                  <Button
                                    disabled
                                    variant="contained"
                                    color="primary"
                                    className="mapped-fields-btn-left mapped-btn"
                                  >
                                    {key}
                                  </Button>
                                  <Button
                                    disabled
                                    variant="contained"
                                    color="primary"
                                    className="mapped-fields-btn-right mapped-btn"
                                  >
                                    {value}
                                  </Button>
                                </Grid>
                              )
                            )}
                        </Grid>
                      )}
                  </div>
                ))}
            </Grid> */}
            {/* <Grid item lg={12} style={{ padding: "10px 0px" }} className="mapped-fields-container">
                            {vendorTemplates && Object.keys(vendorTemplates).length > 0 && (
                                <Grid item container>
                                    {vendorTemplates && Object.keys(vendorTemplates).length > 0 && Object.entries(vendorTemplates).map(([key, value]) => (
                                        <Grid item key={key} className='mapped-field' style={{ margin: "10px" }}>
                                            <Button disabled variant='contained' color='primary' className='mapped-fields-btn-left mapped-btn'>{key}</Button>
                                            <Button disabled variant='contained' color='primary' className='mapped-fields-btn-right mapped-btn'>{value}</Button>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Grid> */}
          </Grid>
        </Container>
      </Paper>

      {/* <ConfigModal
                open={openModal}
                closeModal={closeModal}
                apiName={apiName}
                setApiName={setApiName}
                json={json}
                setJson={setJson}
                setLoading={setLoading}
                successAlert={successAlert}
                errorAlert={errorAlert}
            /> */}

      {/*================== Alert bar ====================*/}
      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />

      {loading && <LoadingComponent />}
    </>
  );
};

export default withStyles(styles)(Index);
