import { withStyles } from "@material-ui/core";
import { TableRow, TableCell } from "@mui/material";
import { themecolor } from "../Navigation";

export const validationFunction = (apiName, json, typeName) => {
  let errors = {};
  const urlPattern =
    /http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+/;

  // Use the test method to check for URLs in the input string
  const validNameOrUrl =
    typeName && typeName === "CONSUMER" ? !urlPattern.test(apiName) : !apiName;

  // if (!apiName || !urlPattern.test(apiName)) errors.apiError = true;
  if (validNameOrUrl) errors.apiError = true;
  if (!json) errors.jsonError = true;
  return errors;
};

export const validationFunctionForConfig = (
  selectedThirdPartyAccType,
  selectedNativeAccType
) => {
  let errors = {};

  if (!selectedThirdPartyAccType) errors.selectedThirdPartyAccType = true;
  if (!selectedNativeAccType) errors.selectedNativeAccType = true;
  return errors;
};

export const headers = [
  { id: 1, name: "S.NO" },
  { id: 2, name: "Platform" },
  { id: 3, name: "Status" },
  { id: 4, name: "Configuration" },
  { id: 5, name: "No. of Api's" },
  { id: 6, name: "Action" },
];

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: `${themecolor} !important`,
    padding: "8px!important",
    color: "white !important",
    fontSize: "14px !important",
    width: "fit-content !important",
  },
  body: {
    fontSize: 13,
    fontWeight: 400,
    padding: "0px 0px!important",
    color: "#555",
    borderBottom: "none",
  },
  p: {
    padding: "0px 10px !important",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
  },
}))(TableRow);

export const ApiManager = [
  { id: 1, name: "Accounts", key: "ACCOUNTS" },
  { id: 2, name: "Transaction", key: "TRANSACTION" },
  { id: 3, name: "Narration", key: "NARRATION" },
  { id: 4, name: "Account Group", key: "ACCOUNTGROUP" },
];
export const ApiNature = [
  { id: 1, name: "Inflow", key: "INFLOW" },
  { id: 2, name: "Outflow", key: "OUTFLOW" },
  { id: 3, name: "Import", key: "IMPORT" },
];
