import React from 'react'
import { Pagination, Grid } from "@mui/material";
import FilterTemplate from './FilterTemplate';

const CommonPagination = ({ pageSizes, pageSize, totalPages, pageNo, setPageNo, setPageSize, label, onPageChange, style }) => {

    const filteredValues = [
        {
            typeId: 2,
            options: pageSizes && label =="Transaction Per Page" ? pageSizes :[15, 20, 50, 100]  || [],
            value: pageSize || "",
            label: label,
            function: (event, newValue) => {
                newValue ? setPageSize(newValue) : setPageSize(15)
                setPageNo(1)
            },
            style: style

        }
    ]
    return (
        <Grid item container justifyContent="flex-end" style={{ marginTop: 20 }}>
            {
                filteredValues && filteredValues.length > 0 &&
                <FilterTemplate filteredValues={filteredValues} />
            }
            <Pagination
                count={totalPages}
                page={pageNo}
                defaultPage={1}
                variant="outlined"
                shape="rounded"
                color="secondary"
                onChange={onPageChange}
            />
        </Grid>
    )
}

export default CommonPagination