import React from "react";
import { Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
const Header = ({ gridSize, source, headerText, arrow, dialog }) => {
  const navigate = useNavigate();
  return (
    <Grid
      item
      xs={gridSize}
      container
      alignItems={dialog ? "center" : source ? "flex-end" : "center"}
    >
      {source && <img src={source} width="50" alt="accounts-icon" />}
      {arrow && (
        <ArrowBackIcon
          sx={{ marginRight: "6px", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
      )}
      <Typography
        variant="h6"
        style={{ display: "inline-block", marginLeft: source ? 20 : 0 }}
      >
        {headerText}
      </Typography>
    </Grid>
  );
};

export default Header;
