const generateHeaders = (headerNames) => {
  return headerNames.map((name, index) => ({
    id: index + 1,
    name
  }));
};

const payableStatus = [
  "Total Pay",
  "Paid",
  "Pending",
  "Due",
  "Over Due",
  "Advance"
];

const detailsFilterStatus = [
  "All",
  "1-30 Days",
  "31-60 Days",
  "60-90 Days",
  "Above 90"
];

const PayableStatusHeader = generateHeaders(payableStatus);
const DetailsFilterStatusHeader = generateHeaders(detailsFilterStatus);

const AgingSummaryStaticData = [
  {
    id: 1,
    vendorName: "Techno Tooling Solutions",
    currentBalance: 87657,
    days30: 87657,
    days60: "-",
    days90: "-",
    over90Days: "-",
    code: "Techno341",
    openingBalance: 50000,
    currentPeriodPurchase: 37657,
    paymentMode: "Bank Transfer",
    creditsApplied: 0,
    outstandingInvoice: 87657,
    closingBalance: 87657,
    lastPaymentDate: "08/01/2024",
    lastPayAmount: 15000,
    billNo: "INV-10234",
    dueDate: "08/31/2024",
    billAmount: 87657,
    balanceDue: 72657
  },
  {
    id: 2,
    vendorName: "Indigrid Technology Pvt. Ltd.",
    currentBalance: 23589,
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: 23589,
    code: "Indigrid340",
    openingBalance: 10000,
    currentPeriodPurchase: 13589,
    paymentMode: "Cheque",
    creditsApplied: 0,
    outstandingInvoice: 23589,
    closingBalance: 23589,
    lastPaymentDate: "07/28/2024",
    lastPayAmount: 5000,
    billNo: "INV-20450",
    dueDate: "08/15/2024",
    billAmount: 23589,
    balanceDue: 18589
  },
  {
    id: 3,
    vendorName: "Indigrid Technology Pvt. Ltd.",
    currentBalance: 18711,
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: 18711,
    code: "Indigrid340",
    openingBalance: 8000,
    currentPeriodPurchase: 10711,
    paymentMode: "Credit Card",
    creditsApplied: 0,
    outstandingInvoice: 18711,
    closingBalance: 18711,
    lastPaymentDate: "08/03/2024",
    lastPayAmount: 7000,
    billNo: "INV-20680",
    dueDate: "08/20/2024",
    billAmount: 18711,
    balanceDue: 11711
  },
  {
    id: 4,
    vendorName: "Indigrid Technology Pvt. Ltd.",
    currentBalance: 1178,
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: 1178,
    code: "Indigrid340",
    openingBalance: 500,
    currentPeriodPurchase: 678,
    paymentMode: "Cash",
    creditsApplied: 0,
    outstandingInvoice: 1178,
    closingBalance: 1178,
    lastPaymentDate: "08/05/2024",
    lastPayAmount: 500,
    billNo: "INV-20890",
    dueDate: "08/25/2024",
    billAmount: 1178,
    balanceDue: 678
  },
  {
    id: 5,
    vendorName: "Indigrid Technology Pvt. Ltd.",
    currentBalance: 356,
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: 356,
    code: "Indigrid340",
    openingBalance: 200,
    currentPeriodPurchase: 156,
    paymentMode: "Credit Card",
    creditsApplied: 0,
    outstandingInvoice: 356,
    closingBalance: 356,
    lastPaymentDate: "08/10/2024",
    lastPayAmount: 100,
    billNo: "INV-21050",
    dueDate: "08/30/2024",
    billAmount: 356,
    balanceDue: 256
  },
  {
    id: 6,
    vendorName: "Indigrid Technology Pvt. Ltd.",
    currentBalance: 4851,
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: 4851,
    code: "Indigrid340",
    openingBalance: 3000,
    currentPeriodPurchase: 1851,
    paymentMode: "Bank Transfer",
    creditsApplied: 0,
    outstandingInvoice: 4851,
    closingBalance: 4851,
    lastPaymentDate: "08/15/2024",
    lastPayAmount: 1500,
    billNo: "INV-21230",
    dueDate: "09/01/2024",
    billAmount: 4851,
    balanceDue: 3351
  },
  {
    id: 7,
    vendorName: "Indigrid Technology Pvt. Ltd.",
    currentBalance: 14850,
    days30: 17082,
    days60: "-",
    days90: 14850,
    over90Days: "-",
    code: "Indigrid340",
    openingBalance: 7000,
    currentPeriodPurchase: 7850,
    paymentMode: "Cheque",
    creditsApplied: 0,
    outstandingInvoice: 14850,
    closingBalance: 14850,
    lastPaymentDate: "08/20/2024",
    lastPayAmount: 5000,
    billNo: "INV-21340",
    dueDate: "09/10/2024",
    billAmount: 14850,
    balanceDue: 9850
  },
  {
    id: 8,
    vendorName: "Indigrid Technology Pvt. Ltd.",
    currentBalance: 64518,
    days30: "-",
    days60: "-",
    days90: 64518,
    over90Days: "-",
    code: "Indigrid340",
    openingBalance: 30000,
    currentPeriodPurchase: 34518,
    paymentMode: "Cash",
    creditsApplied: 0,
    outstandingInvoice: 64518,
    closingBalance: 64518,
    lastPaymentDate: "08/22/2024",
    lastPayAmount: 20000,
    billNo: "INV-21450",
    dueDate: "09/15/2024",
    billAmount: 64518,
    balanceDue: 44518
  },
  {
    id: 9,
    vendorName: "Artek Energy Pvt Ltd",
    currentBalance: 283200,
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "-",
    code: "Artek342",
    openingBalance: 200000,
    currentPeriodPurchase: 83200,
    paymentMode: "Bank Transfer",
    creditsApplied: 0,
    outstandingInvoice: 283200,
    closingBalance: 283200,
    lastPaymentDate: "08/28/2024",
    lastPayAmount: 50000,
    billNo: "INV-21560",
    dueDate: "09/20/2024",
    billAmount: 283200,
    balanceDue: 233200
  },
  {
    id: 10,
    vendorName: "Artek Energy Pvt Ltd",
    currentBalance: 34621,
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "-",
    code: "Artek342",
    openingBalance: 15000,
    currentPeriodPurchase: 19621,
    paymentMode: "Credit Card",
    creditsApplied: 0,
    outstandingInvoice: 34621,
    closingBalance: 34621,
    lastPaymentDate: "08/30/2024",
    lastPayAmount: 10000,
    billNo: "INV-21670",
    dueDate: "09/25/2024",
    billAmount: 34621,
    balanceDue: 24621
  },
  {
    id: 11,
    vendorName: "Ruby Tech",
    currentBalance: 287400,
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "-",
    code: "Ruby343",
    openingBalance: 200000,
    currentPeriodPurchase: 87400,
    paymentMode: "Cheque",
    creditsApplied: 0,
    outstandingInvoice: 287400,
    closingBalance: 287400,
    lastPaymentDate: "08/25/2024",
    lastPayAmount: 75000,
    billNo: "INV-21780",
    dueDate: "09/30/2024",
    billAmount: 287400,
    balanceDue: 212400
  },
  {
    id: 12,
    vendorName: "Sheetal Jain & Associates",
    currentBalance: 1087500,
    days30: 1087500,
    days60: "-",
    days90: "-",
    over90Days: "-",
    code: "Sheetal343",
    openingBalance: 500000,
    currentPeriodPurchase: 587500,
    paymentMode: "Bank Transfer",
    creditsApplied: 0,
    outstandingInvoice: 1087500,
    closingBalance: 1087500,
    lastPaymentDate: "08/25/2024",
    lastPayAmount: 300000,
    billNo: "INV-21890",
    dueDate: "10/15/2024",
    billAmount: 1087500,
    balanceDue: 787500
  }
];

const ReceivableAgingSummaryStaticData = [
  {
    id: 1,
    customerName: "Zinka Logistic",
    terms: 7,
    invoiceDate: "12/06/2024",
    dueDate: "19/06/2024",
    amountOutstanding: "-",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "14,690",
    code: "zinka320",
    openingBalance: "0",
    currentPeriodPurchase: "14,690",
    paymentMode: "Bank Transfer",
    creditsApplied: "0",
    outstandingInvoice: "14,690",
    closingBalance: "0",
    lastPaymentDate: "05/06/2024",
    lastPayAmount: "14,690",
    billNo: "INV1234",
    billDate: "12/06/2024",
    dueDate: "19/06/2024",
    billAmount: "14,690",
    paymentMode: "Bank Transfer",
    balanceDue: "0"
  },
  {
    id: 2,
    customerName: "San Globel Tech",
    terms: 30,
    invoiceDate: "23/12/2023",
    dueDate: "22/01/2024",
    amountOutstanding: "14,690",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "14,690",
    code: "sanglobe001",
    openingBalance: "0",
    currentPeriodPurchase: "0",
    paymentMode: "Cheque",
    creditsApplied: "0",
    outstandingInvoice: "14,690",
    closingBalance: "14,690",
    lastPaymentDate: "22/12/2023",
    lastPayAmount: "14,690",
    billNo: "INV1235",
    billDate: "23/12/2023",
    dueDate: "22/01/2024",
    billAmount: "14,690",
    paymentMode: "Cheque",
    balanceDue: "14,690"
  },
  {
    id: 3,
    customerName: "JR Link Tech",
    terms: 20,
    invoiceDate: "19/04/2024",
    dueDate: "09/05/2024",
    amountOutstanding: "5,15,400",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "5,15,400",
    code: "jr001",
    openingBalance: "50,000",
    currentPeriodPurchase: "5,15,400",
    paymentMode: "Credit Card",
    creditsApplied: "0",
    outstandingInvoice: "5,15,400",
    closingBalance: "50,000",
    lastPaymentDate: "18/04/2024",
    lastPayAmount: "5,15,400",
    billNo: "INV1236",
    billDate: "19/04/2024",
    dueDate: "09/05/2024",
    billAmount: "5,15,400",
    paymentMode: "Credit Card",
    balanceDue: "50,000"
  },
  {
    id: 4,
    customerName: "JR Link Tech",
    terms: 20,
    invoiceDate: "21/11/2023",
    dueDate: "11/12/2023",
    amountOutstanding: "66,200",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "66,200",
    code: "jr002",
    openingBalance: "10,000",
    currentPeriodPurchase: "66,200",
    paymentMode: "Bank Transfer",
    creditsApplied: "0",
    outstandingInvoice: "66,200",
    closingBalance: "10,000",
    lastPaymentDate: "20/11/2023",
    lastPayAmount: "66,200",
    billNo: "INV1237",
    billDate: "21/11/2023",
    dueDate: "11/12/2023",
    billAmount: "66,200",
    paymentMode: "Bank Transfer",
    balanceDue: "10,000"
  },
  {
    id: 5,
    customerName: "HI Track GPS systems",
    terms: 15,
    invoiceDate: "16/02/2024",
    dueDate: "02/03/2024",
    amountOutstanding: "3,63,513",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "3,63,513",
    code: "hit001",
    openingBalance: "20,000",
    currentPeriodPurchase: "3,63,513",
    paymentMode: "Cash",
    creditsApplied: "0",
    outstandingInvoice: "3,63,513",
    closingBalance: "20,000",
    lastPaymentDate: "15/02/2024",
    lastPayAmount: "3,63,513",
    billNo: "INV1238",
    billDate: "16/02/2024",
    dueDate: "02/03/2024",
    billAmount: "3,63,513",
    paymentMode: "Cash",
    balanceDue: "20,000"
  },
  {
    id: 6,
    customerName: "HI Track GPS systems",
    terms: 15,
    invoiceDate: "06/06/2024",
    dueDate: "21/06/2024",
    amountOutstanding: "7,78,800",
    currentAmount: "-",
    days30: "-",
    days60: "7,78,800",
    days90: "-",
    over90Days: "-",
    code: "hit002",
    openingBalance: "30,000",
    currentPeriodPurchase: "7,78,800",
    paymentMode: "Credit Card",
    creditsApplied: "0",
    outstandingInvoice: "7,78,800",
    closingBalance: "30,000",
    lastPaymentDate: "05/06/2024",
    lastPayAmount: "7,78,800",
    billNo: "INV1239",
    billDate: "06/06/2024",
    dueDate: "21/06/2024",
    billAmount: "7,78,800",
    paymentMode: "Credit Card",
    balanceDue: "30,000"
  },
  {
    id: 7,
    customerName: "Nodal Officer, Municipal Corp.Ratlam",
    terms: 30,
    invoiceDate: "07/08/2024",
    dueDate: "06/09/2024",
    amountOutstanding: "55,463",
    currentAmount: "55,463",
    days30: "1,70,822",
    days60: "-",
    days90: "-",
    over90Days: "-",
    code: "municipal001",
    openingBalance: "40,000",
    currentPeriodPurchase: "55,463",
    paymentMode: "Cheque",
    creditsApplied: "10,000",
    outstandingInvoice: "55,463",
    closingBalance: "30,000",
    lastPaymentDate: "06/08/2024",
    lastPayAmount: "55,463",
    billNo: "INV1240",
    billDate: "07/08/2024",
    dueDate: "06/09/2024",
    billAmount: "55,463",
    paymentMode: "Cheque",
    balanceDue: "30,000"
  },
  {
    id: 8,
    customerName: "Nodal Officer, Municipal Corp.Ratlam",
    terms: 30,
    invoiceDate: "01/05/2024",
    dueDate: "31/05/2024",
    amountOutstanding: "78,950",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "78,950",
    over90Days: "-",
    code: "municipal002",
    openingBalance: "50,000",
    currentPeriodPurchase: "78,950",
    paymentMode: "Bank Transfer",
    creditsApplied: "15,000",
    outstandingInvoice: "78,950",
    closingBalance: "35,000",
    lastPaymentDate: "30/04/2024",
    lastPayAmount: "78,950",
    billNo: "INV1241",
    billDate: "01/05/2024",
    dueDate: "31/05/2024",
    billAmount: "78,950",
    paymentMode: "Bank Transfer",
    balanceDue: "35,000"
  },
  {
    id: 9,
    customerName: "Nodal Officer, Municipal Corp.Ratlam",
    terms: 30,
    invoiceDate: "13/02/2024",
    dueDate: "14/03/2024",
    amountOutstanding: "1,10,940",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "1,10,940",
    over90Days: "-",
    code: "municipal003",
    openingBalance: "45,000",
    currentPeriodPurchase: "1,10,940",
    paymentMode: "Cheque",
    creditsApplied: "10,000",
    outstandingInvoice: "1,10,940",
    closingBalance: "35,000",
    lastPaymentDate: "12/02/2024",
    lastPayAmount: "1,10,940",
    billNo: "INV1242",
    billDate: "13/02/2024",
    dueDate: "14/03/2024",
    billAmount: "1,10,940",
    paymentMode: "Cheque",
    balanceDue: "35,000"
  },
  {
    id: 10,
    customerName: "Nodal Officer, Municipal Corp.Ratlam",
    terms: 30,
    invoiceDate: "05/12/2023",
    dueDate: "04/01/2024",
    amountOutstanding: "1,10,940",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "1,10,940",
    code: "municipal004",
    openingBalance: "0",
    currentPeriodPurchase: "1,10,940",
    paymentMode: "Bank Transfer",
    creditsApplied: "0",
    outstandingInvoice: "1,10,940",
    closingBalance: "0",
    lastPaymentDate: "04/12/2023",
    lastPayAmount: "1,10,940",
    billNo: "INV1243",
    billDate: "05/12/2023",
    dueDate: "04/01/2024",
    billAmount: "1,10,940",
    paymentMode: "Bank Transfer",
    balanceDue: "0"
  },
  {
    id: 11,
    customerName: "Manglam Rasayan",
    terms: 60,
    invoiceDate: "19/03/2024",
    dueDate: "18/05/2024",
    amountOutstanding: "2,88,960",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "2,88,960",
    code: "manraajs001",
    openingBalance: "20,000",
    currentPeriodPurchase: "2,88,960",
    paymentMode: "Cheque",
    creditsApplied: "30,000",
    outstandingInvoice: "2,88,960",
    closingBalance: "50,000",
    lastPaymentDate: "18/03/2024",
    lastPayAmount: "2,88,960",
    billNo: "INV1244",
    billDate: "19/03/2024",
    dueDate: "18/05/2024",
    billAmount: "2,88,960",
    paymentMode: "Cheque",
    balanceDue: "50,000"
  },
  {
    id: 12,
    customerName: "Patronusauto Pvt Ltd",
    terms: 30,
    invoiceDate: "27/06/2024",
    dueDate: "27/07/2024",
    amountOutstanding: "1,16,450",
    currentAmount: "-",
    days30: "1,16,450",
    days60: "-",
    days90: "-",
    over90Days: "-",
    code: "patronus001",
    openingBalance: "10,000",
    currentPeriodPurchase: "1,16,450",
    paymentMode: "Bank Transfer",
    creditsApplied: "5,000",
    outstandingInvoice: "1,16,450",
    closingBalance: "15,000",
    lastPaymentDate: "26/06/2024",
    lastPayAmount: "1,16,450",
    billNo: "INV1245",
    billDate: "27/06/2024",
    dueDate: "27/07/2024",
    billAmount: "1,16,450",
    paymentMode: "Bank Transfer",
    balanceDue: "15,000"
  },
  {
    id: 13,
    customerName: "Oprain Tech Solutions",
    terms: 10,
    invoiceDate: "16/05/2024",
    dueDate: "26/05/2024",
    amountOutstanding: "3,72,000",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "3,72,000",
    code: "oprain001",
    openingBalance: "30,000",
    currentPeriodPurchase: "3,72,000",
    paymentMode: "Cheque",
    creditsApplied: "0",
    outstandingInvoice: "3,72,000",
    closingBalance: "30,000",
    lastPaymentDate: "15/05/2024",
    lastPayAmount: "3,72,000",
    billNo: "INV1246",
    billDate: "16/05/2024",
    dueDate: "26/05/2024",
    billAmount: "3,72,000",
    paymentMode: "Cheque",
    balanceDue: "30,000"
  },
  {
    id: 14,
    customerName: "Oprain Tech Solutions",
    terms: 10,
    invoiceDate: "20/03/2024",
    dueDate: "30/03/2024",
    amountOutstanding: "44,178",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "44,178",
    code: "oprain002",
    openingBalance: "10,000",
    currentPeriodPurchase: "44,178",
    paymentMode: "Credit Card",
    creditsApplied: "0",
    outstandingInvoice: "44,178",
    closingBalance: "10,000",
    lastPaymentDate: "19/03/2024",
    lastPayAmount: "44,178",
    billNo: "INV1247",
    billDate: "20/03/2024",
    dueDate: "30/03/2024",
    billAmount: "44,178",
    paymentMode: "Credit Card",
    balanceDue: "10,000"
  },
  {
    id: 15,
    customerName: "Oprain Tech Solutions",
    terms: 10,
    invoiceDate: "20/03/2024",
    dueDate: "30/03/2024",
    amountOutstanding: "24,400",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "24,400",
    code: "oprain003",
    openingBalance: "5,000",
    currentPeriodPurchase: "24,400",
    paymentMode: "Bank Transfer",
    creditsApplied: "0",
    outstandingInvoice: "24,400",
    closingBalance: "5,000",
    lastPaymentDate: "19/03/2024",
    lastPayAmount: "24,400",
    billNo: "INV1248",
    billDate: "20/03/2024",
    dueDate: "30/03/2024",
    billAmount: "24,400",
    paymentMode: "Bank Transfer",
    balanceDue: "5,000"
  },
  {
    id: 16,
    customerName: "Geosafe Technologies Private Limited",
    terms: 30,
    invoiceDate: "31/03/2024",
    dueDate: "30/04/2024",
    amountOutstanding: "65,470",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "65,470",
    code: "geosafe001",
    openingBalance: "15,000",
    currentPeriodPurchase: "65,470",
    paymentMode: "Credit Card",
    creditsApplied: "5,000",
    outstandingInvoice: "65,470",
    closingBalance: "15,000",
    lastPaymentDate: "30/03/2024",
    lastPayAmount: "65,470",
    billNo: "INV1249",
    billDate: "31/03/2024",
    dueDate: "30/04/2024",
    billAmount: "65,470",
    paymentMode: "Credit Card",
    balanceDue: "15,000"
  },
  {
    id: 17,
    customerName: "Geosafe Technologies Private Limited",
    terms: 30,
    invoiceDate: "18/06/2024",
    dueDate: "18/07/2024",
    amountOutstanding: "14,81,500",
    currentAmount: "-",
    days30: "14,81,500",
    days60: "14,81,500",
    days90: "-",
    over90Days: "-",
    code: "geosafe002",
    openingBalance: "25,000",
    currentPeriodPurchase: "14,81,500",
    paymentMode: "Bank Transfer",
    creditsApplied: "10,000",
    outstandingInvoice: "14,81,500",
    closingBalance: "35,000",
    lastPaymentDate: "17/06/2024",
    lastPayAmount: "14,81,500",
    billNo: "INV1250",
    billDate: "18/06/2024",
    dueDate: "18/07/2024",
    billAmount: "14,81,500",
    paymentMode: "Bank Transfer",
    balanceDue: "35,000"
  },
  {
    id: 18,
    customerName: "Geosafe Technologies Private Limited",
    terms: 30,
    invoiceDate: "18/03/2024",
    dueDate: "18/04/2024",
    amountOutstanding: "7,08,570",
    currentAmount: "-",
    days30: "7,08,570",
    days60: "7,08,570",
    days90: "7,08,570",
    over90Days: "-",
    code: "geosafe003",
    openingBalance: "10,000",
    currentPeriodPurchase: "7,08,570",
    paymentMode: "Bank Transfer",
    creditsApplied: "15,000",
    outstandingInvoice: "7,08,570",
    closingBalance: "25,000",
    lastPaymentDate: "17/03/2024",
    lastPayAmount: "7,08,570",
    billNo: "INV1251",
    billDate: "18/03/2024",
    dueDate: "18/04/2024",
    billAmount: "7,08,570",
    paymentMode: "Bank Transfer",
    balanceDue: "25,000"
  },
  {
    id: 19,
    customerName: "Geosafe Technologies Private Limited",
    terms: 30,
    invoiceDate: "10/05/2024",
    dueDate: "09/06/2024",
    amountOutstanding: "6,84,980",
    currentAmount: "-",
    days30: "-",
    days60: "-",
    days90: "-",
    over90Days: "6,84,980",
    code: "geosafe004",
    openingBalance: "15,000",
    currentPeriodPurchase: "6,84,980",
    paymentMode: "Bank Transfer",
    creditsApplied: "10,000",
    outstandingInvoice: "6,84,980",
    closingBalance: "25,000",
    lastPaymentDate: "09/05/2024",
    lastPayAmount: "6,84,980",
    billNo: "INV1252",
    billDate: "10/05/2024",
    dueDate: "09/06/2024",
    billAmount: "6,84,980",
    paymentMode: "Bank Transfer",
    balanceDue: "25,000"
  }
];

export {
  PayableStatusHeader,
  DetailsFilterStatusHeader,
  AgingSummaryStaticData,
  ReceivableAgingSummaryStaticData
};
