import { useEffect, useState } from "react";

export const validationFunction = (
  customFormData,
  nextState,
  updating,
  isInvoiceMandatory,
  isAccountCode,
  isTransactionType,
  referenceNo,
  selectTransactionType,
  selectAccountCode
) => {
  let errors = {};

  if (
    customFormData &&
    Array.isArray(customFormData) &&
    customFormData.length > 0
  ) {
    for (const val of customFormData) {
      const { label, isRequired, value } = val;

      if (isRequired) {
        if (!value) errors[label] = true;
      }
    }
  }

  if (isAccountCode === true) {
    if (!selectAccountCode) {
      errors.accountCode = true;
    }
  }
  if (isTransactionType === true) {
    if (!selectTransactionType) {
      errors.transactionType = true;
    }
  }
  if (isInvoiceMandatory === true) {
    if (!referenceNo) {
      errors.invoiceNumber = true;
    }
  }

  if (!updating) {
    if (!nextState) {
      errors.nextState = true;
    }
  }

  return errors;
};

export const headers = [
  { id: 15, name: "Select All", alias: "all" },
  { id: 16, name: "Remove All", alias: "remove" },
  { id: 0, name: " ", width: "60", textAlign: "left" },
  { id: 1, name: "S.NO", width: "100", textAlign: "left" },
  { id: 2, name: "Transaction Date", width: "200", textAlign: "left" },
  { id: 3, name: "Txn Id", width: "100", textAlign: "left" },
  { id: 4, name: "Debit Account", width: "300", textAlign: "left" },
  { id: 5, name: "Credit Account", width: "300", textAlign: "left" },
  { id: 6, name: "Amount", width: "120", textAlign: "right" },
  { id: 14, name: "Voucher", width: "150", textAlign: "left" },
  { id: 7, name: "Created On", width: "300", textAlign: "left" },
  { id: 8, name: "Created By", width: "300", textAlign: "left" },
  { id: 9, name: "Status", width: "180", textAlign: "left" }
];

export const newHeaders = [
  {
    id: 17,
    name: "Debit Account Closing Balance",
    alias: "sourceAccountClosingBalance"
  },
  {
    id: 18,
    name: "Debit Account Opening Balance",
    alias: "sourceAccountOpeningBalance"
  },
  {
    id: 19,
    name: "Credit Account Closing Balance",
    alias: "targetAccountClosingBalance"
  },
  {
    id: 20,
    name: "Credit Account Opening Balance",
    alias: "targetAccountOpeningBalance"
  },
  { id: 10, name: "CGST", width: "180", textAlign: "right" },
  { id: 11, name: "IGST", width: "180", textAlign: "right" },
  { id: 12, name: "GST", width: "180", textAlign: "right" },
  { id: 13, name: "Total Amount", width: "180", textAlign: "right" }
];

export const updatedHeaders = [
  { id: 1, name: "S.NO" },
  { id: 2, name: "Transaction Date" },
  { id: 3, name: "Txn Id" },
  { id: 4, name: "Debit Account" },
  { id: 5, name: "Credit Account" },
  { id: 6, name: "Amount" },
  { id: 7, name: "Created On" },
  { id: 8, name: "Debit Narration" },
  { id: 9, name: "Credit Narration" },
  { id: 10, name: "Created By" },
  { id: 11, name: "E-Invoice" }
  // { id: 9, name: "Action" }',
  // { id: 10, name: "Total Amount" },
];

export const modalHeaders = [
  { id: 1, name: "S.NO" },
  { id: 2, name: "Transaction Date" },
  { id: 3, name: "Txn Id" },
  { id: 4, name: "Debit Account" },
  { id: 5, name: "Credit Account" },
  { id: 6, name: "Amount" },
  // { id: 11, name: "Voucher" },
  // { id: 7, name: "Created On" },
  // { id: 8, name: "Created By" },
  // { id: 9, name: "Action" },
  { id: 11, name: "Status" },
  { id: 12, name: "Reason" },
  { id: 10, name: "Total Amount" }

  // { id: 12, name: "Action" },
];

export const paymentStatusList = [
  { id: 1, name: "Pending", alias: "PENDING" },
  { id: 2, name: "Partial", alias: "PARTIAL" },
  { id: 3, name: "Completed", alias: "COMPLETED" }
];

export const useLocalStorage = (key) => {
  const [storedValue, setStoredValue] = useState(() =>
    localStorage.getItem(key)
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(localStorage.getItem(key));
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key]);

  return storedValue;
};

export const einvoice = [
  {
    id: 1,
    label: "SlNo",
    alias: false
  },
  {
    id: 2,
    label: "Item Description",
    alias: "PrdDesc"
  },
  {
    id: 3,
    label: "HSN Code",
    alias: "HsnCd"
  },
  {
    id: 4,
    label: "Quantity",
    alias: "Qty"
  },
  {
    id: 5,
    label: "Unit",
    alias: "Unit"
  },
  {
    id: 6,
    label: "Unit Price(Rs)",
    alias: "UnitPrice"
  },
  {
    id: 7,
    label: "Discount(Rs)",
    alias: "Discount"
  },
  {
    id: 8,
    label: "Taxable Amount(Rs)",
    alias: "AssAmt"
  },
  {
    id: 9,
    label: "Tax Rate (GST+Cess | State Cess+Cess Non.Advol)",
    alias: "GstRtCesRt"
    // alias: "GstRt|CesRt|StateCesRt|CesNonAdvlAmt|StateCesNonAdvlAmt",
  },
  {
    id: 10,
    label: "Other Charges(Rs)",
    alias: "OthChrg"
  },
  {
    id: 11,
    label: "Total",
    alias: "TotItemVal"
  }
];

export const einvoice2 = [
  {
    id: 2,
    label: "Tax'ble Amt",
    alias: "AssAmt" // Correct: Assessed Amount (Taxable Amount)
  },
  {
    id: 3,
    label: "IGST Amt",
    alias: "IgstAmt"
  },
  {
    id: 4,
    label: "CGST Amt",
    alias: "CgstAmt" // Correct: CGST Amount
  },
  {
    id: 5,
    label: "SGST Amt",
    alias: "SgstAmt" // Correct: SGST Amount
  },
  {
    id: 6,
    label: "CESS Amt",
    alias: "CesAmt" // Correct: CESS Amount
  },
  {
    id: 7,
    label: "State CESS Amt",
    alias: "StateCesAmt" // Correct: State CESS Amount
  },
  {
    id: 8,
    label: "Discount",
    alias: "Discount" // Correct: Discount
  },
  {
    id: 9,
    label: "Other Charges",
    alias: "OthChrg" // Correct: Other Charges
  },
  {
    id: 10,
    label: "Round off Amt",
    alias: "RoundOffAmt" // Placeholder: Key not present in your JSON, define as needed
  },
  {
    id: 11,
    label: "Total Inv.",
    alias: "TotItemVal" // Correct: Total Invoice Amount
  }
];

export const einvoiceData = [
  {
    id: 1,
    itemDescription: "Item Description",
    HSNCode: "HSN Code",
    quantity: "Quantity",
    unit: "Unit",
    unitPrice: "Unit Price(Rs)",
    discount: "Discount(Rs)",
    taxableAmount: "Taxable Amount(Rs)",
    taxRate: "2345",
    otherCharges: "45643",
    total: "98784778"
  },
  {
    id: 2,
    itemDescription: "Item Description",
    HSNCode: "HSN Code",
    quantity: "Quantity",
    unit: "Unit",
    unitPrice: "Unit Price(Rs)",
    discount: "Discount(Rs)",
    taxableAmount: "Taxable Amount(Rs)",
    taxRate: "2345",
    otherCharges: "45643",
    total: "98784778"
  },
  {
    id: 3,
    itemDescription: "Item Description",
    HSNCode: "HSN Code",
    quantity: "Quantity",
    unit: "Unit",
    unitPrice: "Unit Price(Rs)",
    discount: "Discount(Rs)",
    taxableAmount: "Taxable Amount(Rs)",
    taxRate: "2345",
    otherCharges: "45643",
    total: "98784778"
  },
  {
    id: 4,
    itemDescription: "Item Description",
    HSNCode: "HSN Code",
    quantity: "Quantity",
    unit: "Unit",
    unitPrice: "Unit Price(Rs)",
    discount: "Discount(Rs)",
    taxableAmount: "Taxable Amount(Rs)",
    taxRate: "2345",
    otherCharges: "45643",
    total: "98784778"
  },
  {
    id: 5,
    itemDescription: "Item Description",
    HSNCode: "HSN Code",
    quantity: "Quantity",
    unit: "Unit",
    unitPrice: "Unit Price(Rs)",
    discount: "Discount(Rs)",
    taxableAmount: "Taxable Amount(Rs)",
    taxRate: "2345",
    otherCharges: "45643",
    total: "98784778"
  },
  {
    id: 6,
    itemDescription: "Item Description",
    HSNCode: "HSN Code",
    quantity: "Quantity",
    unit: "Unit",
    unitPrice: "Unit Price(Rs)",
    discount: "Discount(Rs)",
    taxableAmount: "Taxable Amount(Rs)",
    taxRate: "2345",
    otherCharges: "45643",
    total: "98784778"
  },
  {
    id: 7,
    itemDescription: "Item Description",
    HSNCode: "HSN Code",
    quantity: "Quantity",
    unit: "Unit",
    unitPrice: "Unit Price(Rs)",
    discount: "Discount(Rs)",
    taxableAmount: "Taxable Amount(Rs)",
    taxRate: "2345",
    otherCharges: "45643",
    total: "98784778"
  },
  {
    id: 8,
    itemDescription: "Item Description",
    HSNCode: "HSN Code",
    quantity: "Quantity",
    unit: "Unit",
    unitPrice: "Unit Price(Rs)",
    discount: "Discount(Rs)",
    taxableAmount: "Taxable Amount(Rs)",
    taxRate: "2345",
    otherCharges: "45643",
    total: "98784778"
  },
  {
    id: 9,
    itemDescription: "Item Description",
    HSNCode: "HSN Code",
    quantity: "Quantity",
    unit: "Unit",
    unitPrice: "Unit Price(Rs)",
    discount: "Discount(Rs)",
    taxableAmount: "Taxable Amount(Rs)",
    taxRate: "2345",
    otherCharges: "45643",
    total: "98784778"
  },
  {
    id: 10,
    itemDescription: "Item Description",
    HSNCode: "HSN Code",
    quantity: "Quantity",
    unit: "Unit",
    unitPrice: "Unit Price(Rs)",
    discount: "Discount(Rs)",
    taxableAmount: "Taxable Amount(Rs)",
    taxRate: "2345",
    otherCharges: "45643",
    total: "98784778"
  }
];
