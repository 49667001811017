import React from 'react';
import convert from 'number-to-words';

const convertDecimalToWords = (decimalPart) => {

  if (decimalPart < 0 || decimalPart > 99) {
    return
  }

  const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
  const teens = ["", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
  const tens = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

  if (decimalPart === 0) {
    return units[decimalPart];
  } else if (decimalPart < 10) {
    if (decimalPart == "01" || decimalPart == "02" || decimalPart == "03" || decimalPart == "04" || decimalPart == "05" || decimalPart == "06" || decimalPart == "07" || decimalPart == "08" || decimalPart == "09") {
      const stringFormatted = String(Number(decimalPart))
      return units[stringFormatted]
    }
    else return units[decimalPart];
  } else if (decimalPart < 20) {
    return teens[decimalPart - 10];
  } else {
    const digit1 = Math.floor(decimalPart / 10);
    const digit2 = decimalPart % 10;

    return `${tens[digit1]}${digit2 !== 0 ? `-${units[digit2]}` : ''}`;
  }
};

const convertAmountToIndianWords = (amount) => {
  const [integerPart, decimalPart] = String(amount).split('.');


  const crorePart = Math.floor(integerPart / 10000000);
  const lakhPart = Math.floor((integerPart % 10000000) / 100000);
  const thousandPart = Math.floor((integerPart % 100000) / 1000);
  const remainingPart = integerPart % 1000;

  const croreWords = crorePart ? convert.toWords(crorePart) + ' crore' : '';
  const lakhWords = lakhPart ? convert.toWords(lakhPart) + ' lakh' : '';
  const thousandWords = thousandPart ? convert.toWords(thousandPart) + ' thousand' : '';
  const remainingWords = remainingPart ? convert.toWords(remainingPart) : '';

  const integerWords = [croreWords, lakhWords, thousandWords, remainingWords].filter(Boolean).join(' ');

  const decimalWords = (decimalPart != "00" && decimalPart != undefined) ? ` & ${convertDecimalToWords(decimalPart)}` : '';

  return {
    integerWords,
    decimalWords,
  };
};

const ConvertAmountToWords = ({ amount }) => {
  const { integerWords, decimalWords } = convertAmountToIndianWords(amount);

  return (
    <span>
      {integerWords}
      &nbsp;Rupees
      {decimalWords && (
        <>
          {decimalWords}
          &nbsp;Paise
        </>
      )}

    </span>
  );
};

export default ConvertAmountToWords;
