export const openCalculator = () => {
  return {
    type: "OPEN_CALCULATOR",
  };
};

export const closeCalculator = () => {
  return {
    type: "CLOSE_CALCULATOR",
  };
};
