import React, { useState, useEffect, useRef } from "react";
import {
  IconButton,
  Paper,
  Container,
  Grid,
  Typography,
  InputAdornment,
  Autocomplete,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Card,
  Drawer,
  Stack
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../Theme/Theme";
import { checkPathExist, checkPermissionExist, keys } from "../utils";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { themecolor } from "../Navigation";
import { userService } from "../userService";
import { AlertBar } from "../Alert/AlertBar";
import { useNavigate } from "react-router-dom";
import Header from "../CommonComps/Header";
import { FcSearch } from "react-icons/fc";
import { getEncryptPayload, getTemplate } from "../Network_Utility/index";
import LoadingComponent from "../Loader";
import IconTemplate from "../CommonComps/IconTemplate";
import {
  accountTypesAddUrl,
  accountTypesV2Url,
  allPermissionUrl,
  provideRolePermissionUrl,
  updatePermissionUrl
} from "../config";
import PermissionTable from "./PermissionTable";
import SideBar from "../CommonComps/SideBar";
import moment from "moment";
const permissionModuleImage = require("../images/RolePermissions.png");

const TableHeader = [
  { id: 1, name: "S.No." },
  { id: 2, name: "User" },
  { id: 3, name: "Active/Inactive" }
];

const Index = ({ classes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxState = useSelector((val) => val?.rootReducer);
  const endPoints = reduxState.permissionReducer?.data;
  const financialYearMonth = reduxState?.fymReducer?.data || [];
  const shortcutSearchRef = useRef(null);

  const [loading, setLoading] = useState(false);

  // Alerts
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: ""
  });

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [allPermissionRoleList, setAllPermissionRoleList] = useState([]);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [drawerState, setDrawerState] = useState({ right: false });

  // Close AlertBar
  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });

  // Show success alert
  const showAlert = (type, message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Something happened!",
      alertType: type
    });
    setLoading(false);
  };

  // Handle Drawer
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const isFilterApplied = fromDate || toDate || selectedMonth;

  const iconTemplateArray = [
    {
      name: "Add Type",
      isInputIcon: false,
      icon: <FiPlus />,
      function: () => navigate("/add-role-permission"),
      style: {
        background: `${themecolor}`,
        color: "#fff",
        marginLeft: 10,
        display: checkPermissionExist(endPoints, provideRolePermissionUrl)
          ? "inline-flex"
          : "none"
      }
    }
  ];

  const handleDateValidation = (start, end, type) => {
    const startDate = moment(start);
    const endDate = moment(end);
    const result = endDate.diff(startDate, "days");

    if (result < 0) {
      showAlert("error", `${type} Date should be valid!`);
      return false;
    }
    return true;
  };

  const handleToDate = (value) => {
    if (fromDate && !handleDateValidation(fromDate, value, "To")) return;
    setToDate(value);
  };

  const handleFromDate = (value) => {
    if (toDate && !handleDateValidation(value, toDate, "From")) return;
    setFromDate(value);
  };

  // Fetch role permissions
  const getRolePermissionList = async () => {
    setLoading(true);
    try {
      const payload = {
        companyId: parseInt(localStorage.getItem("companyId"), 10),
        applicationId: 1,
        pageNo: pageNo,
        pageSize: pageSize
      };
      const encryptedPayload = getEncryptPayload(payload, true);
      const res = await userService.login(allPermissionUrl, encryptedPayload);
      console.log(res?.data?.data, "vagdhd");
      if (res?.data?.status === 200) {
        setAllPermissionRoleList(res?.data?.data?.data || []);
        setTotalPages(Math.ceil(res?.data?.data?.totalLength / pageSize));
        showAlert(
          "success",
          res?.data?.message || "Data fetched successfully!"
        );
      } else {
        throw new Error(res?.data?.message || "Failed to fetch data.");
      }
    } catch (error) {
      showAlert("error", error.message || "Something went wrong.");
      setAllPermissionRoleList([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRolePermissionList();
  }, [pageNo, pageSize]);

  const handleActivation = async (row) => {
    const updatedStatus = !row?.isActive;
    try {
      setLoading(true);
      const payload = [
        {
          id: row?.userId,
          isActive: updatedStatus,
          loggedInUserId: parseInt(localStorage.getItem("userId"), 10) || null
        }
      ];
      const res = await userService.login(updatePermissionUrl, payload);

      if (res?.data?.status === 200) {
        showAlert(
          "success",
          res?.data?.message || "Status updated successfully."
        );
        setAllPermissionRoleList((prevList) =>
          prevList.map((item) =>
            item.userId === row.userId
              ? { ...item, isActive: updatedStatus }
              : item
          )
        );
      } else {
        throw new Error(res?.data?.message || "Failed to update status.");
      }
    } catch (error) {
      showAlert("error", error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ width: "100%", overflow: "auto" }}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Grid container style={{ margin: "0", alignItems: "center" }}>
            <Header
              gridSize={3}
              source={permissionModuleImage}
              headerText="Role & Permission"
            />
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              xs={9}
            >
              {iconTemplateArray && <IconTemplate data={iconTemplateArray} />}
              {/* <IconButton
                style={{ background: "#c35151", color: "#fff" }}
                onClick={toggleDrawer("right", false)}
              >
                <TuneIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerState.right}
                onClose={toggleDrawer("right", false)}
              >
               
                <SideBar
                  toDate={toDate}
                  fromDate={fromDate}
                  selectedmonth={selectedMonth}
                  handleFromDate={handleFromDate}
                  handleToDate={handleToDate}
                />
              </Drawer> */}
            </Grid>
          </Grid>

          <PermissionTable
            header={TableHeader}
            data={allPermissionRoleList || []}
            updatePermission={updatePermission}
            handleActivation={handleActivation}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalPages={totalPages}
            pageSize={pageSize}
            setPageSize={setPageSize}
            showAlert={showAlert}
            endPoints={endPoints}
          />
        </Container>
      </Paper>

      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />

      {loading && <LoadingComponent />}
    </>
  );
};

export default withStyles(styles)(Index);
