class UserConfig {
  getHeader(pathName, tableName) {
    const headerName =
      localStorage.getItem("columnConfig") &&
      JSON.parse(localStorage.getItem("columnConfig"));

    if (!headerName) {
      return [];
    }

    for (let i = 0; i < headerName.length; i++) {
      const ele = headerName[i];
      if (ele.page === pathName && ele[tableName]) {
        return ele[tableName];
      }
    }

    return [];
  }
  showColumn(pathName, tableName, columnId) {
    const headerName =
      localStorage.getItem("columnConfig") &&
      JSON.parse(localStorage.getItem("columnConfig"));
    if (!headerName) {
      return [];
    }
    this.result = [];
    for (let i = 0; i < headerName?.length; i++) {
      const ele = headerName[i];
      if (ele.page === pathName && ele[tableName]) {
        const filteredData = ele[tableName].map((ele) => {
          const newData = {
            ...ele,
            isShow:
              (ele?.alias !== "all" || ele?.alias !== "remove") &&
              ele.id === columnId
                ? true
                : ele.isShow
          };
          return newData;
        });
        const newObj = {
          ...ele,
          [tableName]: filteredData
        };
        this.result.push(newObj);
      } else {
        this.result.push(ele);
      }
    }
    localStorage.setItem("columnConfig", JSON.stringify(this.result));
  }
  hideColumn(pathName, tableName, columnId) {
    const headerName =
      localStorage.getItem("columnConfig") &&
      JSON.parse(localStorage.getItem("columnConfig"));
    this.result = [];
    for (let i = 0; i < headerName?.length; i++) {
      const ele = headerName[i];
      if (ele.page === pathName && ele[tableName]) {
        const filteredData = ele[tableName].map((ele) => {
          const newData = {
            ...ele,
            isShow: ele.id === columnId ? false : ele.isShow
          };
          return newData;
        });
        const newObj = {
          ...ele,
          [tableName]: filteredData
        };
        this.result.push(newObj);
      } else {
        this.result.push(ele);
      }
    }
    localStorage.setItem("columnConfig", JSON.stringify(this.result));
  }
  showAllColumn(pathName, tableName) {
    const headerName =
      localStorage.getItem("columnConfig") &&
      JSON.parse(localStorage.getItem("columnConfig"));
    this.result = [];
    for (let i = 0; i < headerName?.length; i++) {
      const ele = headerName[i];
      if (ele.page === pathName && ele[tableName]) {
        const filteredData = ele[tableName].map((ele) => {
          const newData = {
            ...ele,
            isShow:
              ele?.alias === "all" || ele?.alias === "remove" ? false : true
          };
          return newData;
        });
        const newObj = {
          ...ele,
          [tableName]: filteredData
        };
        this.result.push(newObj);
      } else {
        this.result.push(ele);
      }
    }
    localStorage.setItem("columnConfig", JSON.stringify(this.result));
  }
  hideAllColumn(pathName, tableName) {
    const headerName =
      localStorage.getItem("columnConfig") &&
      JSON.parse(localStorage.getItem("columnConfig"));
    this.result = [];
    for (let i = 0; i < headerName?.length; i++) {
      const ele = headerName[i];
      if (ele.page === pathName && ele[tableName]) {
        const filteredData = ele[tableName].map((ele) => {
          const newData = {
            ...ele,
            isShow: false
          };
          return newData;
        });
        const newObj = {
          ...ele,
          [tableName]: filteredData
        };
        this.result.push(newObj);
      } else {
        this.result.push(ele);
      }
    }
    localStorage.setItem("columnConfig", JSON.stringify(this.result));
  }
}

export const userConfig = new UserConfig();
