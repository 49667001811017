import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  IconButton,
  Paper,
  Container,
  Grid,
  Stack,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  Switch,
  Button,
  Badge,
  Typography,
  Box,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { FiPlus } from "react-icons/fi";
import { userService } from "../userService";
import { AlertBar } from "../Alert/AlertBar";
import moment from "moment";
import SideBar from "../CommonComps/SideBar";
import Header from "../CommonComps/Header";
import ErrorModal from "../AccountType/ErrorModal";
import LoadingComponent from "../Loader";
import IconTemplate from "../CommonComps/IconTemplate";
import { styles } from "../Theme/Theme";
import { AiFillFileExcel, AiFillFilePdf } from "react-icons/ai";
import TuneIcon from "@mui/icons-material/Tune";
import AutoDialogBox from "../CommonComps/AutoDialogBox";
import PayableImg from "../images/payable.png";
import { userConfig } from "../config/UserConfig";
import { checkPathExist, checkPermissionExist } from "../utils";
import {
  getAllState,
  getPayable,
  getPayableGroup,
  transactionsUrl,
  transactionVoucher,
  voucherTypesUrl,
} from "../config";
import FlexHeaderDivider from "./FlexHeaderDivider";
import TotalPayIcon from "../images/TotalPay.svg";
import PaidIcon from "../images/Paid.svg";
import PendingIcon from "../images/Pending.svg";
import DueIcon from "../images/Due.svg";
import OverDueIcon from "../images/OverDue.svg";
import AdvanceIcon from "../images/Advance.svg";
import { any } from "prop-types";
import { getEncryptPayload } from "../Network_Utility";
import { PayableReportsAction } from "../features/actions/PayableReportsAction";
import PayableTable from "./PayableTable";
import VendorBalanceSummary from "./VendorBalanceSummary";
import { Groups } from "@mui/icons-material";
import {
  AgingSummaryStaticData,
  DetailsFilterStatusHeader,
  PayableStatusHeader,
} from "./Utils";

const dueDaysArray = [
  { id: 1, name: "15 Day", amount: "₹19,08,431", key: "due15AmountPayable" },
  { id: 2, name: "30 Day", amount: "₹18,07,430", key: "due30AmountPayable" },
  { id: 3, name: "45 Day", amount: "₹17,06,429", key: "due45AmountPayable" },
  { id: 4, name: "60 Day", amount: "₹16,05,428", key: "due60AmountPayable" },
  { id: 5, name: "90 Day", amount: "₹16,05,428", key: "due90AmountPayable" },
  {
    id: 6,
    name: "Above 90 Day",
    amount: "₹16,05,428",
    key: "dueAbove90AmountPayable",
  },
];
const overDueDaysArray = [
  {
    id: 1,
    name: "15 Day",
    amount: "₹19,08,431",
    key: "overdue15AmountPayable",
  },
  {
    id: 2,
    name: "30 Day",
    amount: "₹18,07,430",
    key: "overdue30AmountPayable",
  },
  {
    id: 3,
    name: "45 Day",
    amount: "₹17,06,429",
    key: "overdue45AmountPayable",
  },
  {
    id: 4,
    name: "60 Day",
    amount: "₹16,05,428",
    key: "overdue60AmountPayable",
  },
  {
    id: 5,
    name: "90 Day",
    amount: "₹16,05,428",
    key: "overdue90AmountPayable",
  },
  {
    id: 6,
    name: "Above 90 Day",
    amount: "₹16,05,428",
    key: "overdueAbove90AmountPayable",
  },
];

const payableHeader = [
  {
    id: 1,
    name: "Total Amount",
    transaction: 12,
    client: 5,
    amount: "₹3,908,431",
    icon: TotalPayIcon,
    color: "rgba(52, 122, 226, 1)",
  },
  {
    id: 2,
    name: "Paid",
    transaction: 12,
    client: 5,
    amount: "₹4,89,100",
    icon: PaidIcon,
    color: "rgba(28, 195, 35, 1)",
  },
  {
    id: 2,
    name: "Pending",
    transaction: 12,
    client: 5,
    amount: "₹14,19,331",
    icon: PendingIcon,
    color: "rgba(255, 138, 0, 1)",
  },
  {
    id: 3,
    name: "Due",
    transaction: 9,
    client: 5,
    amount: "₹19,08,431",
    icon: DueIcon,
    color: "rgba(255, 0, 0, 1)",
    days15Amt: "₹19,08,431",
    days30Amt: "₹18,07,430",
    days60Amt: "₹17,06,429",
    days90Amt: "₹16,05,428",
  },
  {
    id: 4,
    name: "Over Due",
    transaction: 9,
    client: 5,
    amount: "₹19,08,431",
    icon: OverDueIcon,
    color: "rgba(255, 199, 0, 1)",
    days15Amt: "₹19,08,431",
    days30Amt: "₹18,07,430",
    days60Amt: "₹17,06,429",
    days90Amt: "₹16,05,428",
  },
  {
    id: 5,
    name: "Advance",
    transaction: 12,
    client: 5,
    amount: "₹0",
    icon: AdvanceIcon,
    color: "rgba(33, 66, 243, 1)",
  },
];

const Index = ({ classes, path }) => {
  const recentPath = window?.location?.pathname;
  const currentTable =
    window.location.pathname === "/aging-Summary-Details"
      ? "accountAgingSummaryTable"
      : window.location.pathname === "/vendor-Balance-Summary"
      ? "vendorBalanceSummaryTable"
      : window.location.pathname === "/unpaid-Bills-Details"
      ? "unPaidBillSummaryTable"
      : "vendorBalanceSummaryTable";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let reduxState = useSelector((val) => val?.rootReducer);
  const siteList = reduxState?.sitesReducer?.data;
  let endPoints = reduxState?.permissionReducer?.data;
  let storedSite = reduxState?.siteReducer?.site;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [selectedSite, setSelectedSite] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [payableHeaderData, setPayableHeaderData] = useState([
    {
      id: 1,
      name: "Total Amount",
      transaction: 0,
      client: 0,
      amount: 0,
      icon: TotalPayIcon,
      color: "rgba(52, 122, 226, 1)",
    },
    {
      id: 2,
      name: "Paid",
      transaction: 0,
      client: 0,
      amount: 0,
      icon: PaidIcon,
      color: "rgba(28, 195, 35, 1)",
    },
    {
      id: 2,
      name: "Pending",
      transaction: 0,
      client: 0,
      amount: 0,
      icon: PendingIcon,
      color: "rgba(255, 138, 0, 1)",
    },
    {
      id: 3,
      name: "Due",
      transaction: 0,
      client: 0,
      amount: 0,
      icon: DueIcon,
      color: "rgba(255, 0, 0, 1)",
    },
    {
      id: 6,
      name: "Non Due",
      transaction: 0,
      client: 0,
      amount: 0,
      icon: DueIcon,
      color: "rgba(255, 0, 0, 2)",
    },
    {
      id: 4,
      name: "Over Due",
      transaction: 0,
      client: 0,
      amount: 0,
      icon: OverDueIcon,
      color: "rgba(255, 199, 0, 1)",
    },
    {
      id: 5,
      name: "Advance",
      transaction: 0,
      client: 0,
      amount: 0,
      icon: AdvanceIcon,
      color: "rgba(33, 66, 243, 1)",
    },
  ]);
  const [payableData, setPayableData] = useState(null);
  const [selectedDays, setSelectedDays] = React.useState({
    Due: dueDaysArray && dueDaysArray[0],
    OverDue: overDueDaysArray && overDueDaysArray[0],
  });
  const [group, setGroup] = useState(null);
  const [status, setStatus] = useState(null);
  const [selectedAmountStatus, setSelectedAmountStatus] = useState({
    id: 1,
    name: "All",
  });

  const [payableDetailData, setPayableDetailData] = useState([]);
  const [nextActionList, setNextActionList] = useState([]);
  const [showAllTransaction, setShowAllTransaction] = useState(false);
  const [headerConfig, setHeaderConfig] = useState([]);
  const [transactionLockingList, setTransactionLockingList] = useState([]);
  const [mainTransactionData, setMainTransactionData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [site, setSite] = useState(null);

  const [pageNo, setPageNo] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const [totalPages, setTotalPages] = React.useState(1);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // ===========Alerts===================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: "",
  });

  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });

  // Function to show success alert
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Data fetched succesfully",
      alertType: "success",
    });
    setLoading(false);
  };

  // Function to show error
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Something went wrong",
      alertType: "error",
    });
    setLoading(false);
  };

  const MySwitch = ({ checked, onChange, label }) => (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} />}
      label={label}
    />
  );

  const handleClickOpen = (event) => {
    setOpen(true);
  };

  const handleDelete = (ele) => {
    userConfig.hideColumn(window.location.pathname, currentTable, ele.id);
    const getHeaderConfig = userConfig.getHeader(
      window.location.pathname,
      currentTable
    );
    setHeaderConfig(getHeaderConfig);
  };

  const columnHandle = (val) => {
    if (val !== null) {
      if (val?.alias === "all") {
        userConfig.showAllColumn(window.location.pathname, currentTable);
        setHeaderConfig(
          userConfig.getHeader(window.location.pathname, currentTable)
        );
        return;
      }
      if (val?.alias === "remove") {
        userConfig.hideAllColumn(window.location.pathname, currentTable);
        setHeaderConfig(
          userConfig.getHeader(window.location.pathname, currentTable)
        );
        return;
      }
      if (val?.id) {
        userConfig.showColumn(window.location.pathname, currentTable, val?.id);
        setHeaderConfig(
          userConfig.getHeader(window.location.pathname, currentTable)
        );
      }
    }
  };

  const renderAutocomplete = (label, value, width) => (
    <Grid item>
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        size="small"
        margin="dense"
        style={{ minWidth: width, maxWidth: 300 }}
        onChange={() => handleClickOpen(label)}
        value={
          value && value.length > 0
            ? value[0]?.name +
              (value.length > 1 ? " +" + (value.length - 1) : "")
            : ""
        }
        onClick={() => handleClickOpen(label)}
      />
    </Grid>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <SideBar
      anchor={anchor}
      toDate={toDate}
      handleToDate={handleToDate}
      fromDate={fromDate}
      handleFromDate={handleFromDate}
      toggleDrawer={toggleDrawer}
      filteredValues={filteredValues}
      handleSubmitFilterValue={handleSubmitFilterValue}
      handleResetFilter={handleResetFilter}
    />
  );

  const filteredValues = [
    {
      typeId: 3,
      label: "From Date",
      value: fromDate,
      style: { width: 200, margin: "0 15px" },
      function: (value) => handleFromDate(value, "date"),
    },
    {
      typeId: 3,
      label: "To Date",
      value: toDate,
      style: { width: 200, margin: "0 15px" },
      function: (value) => handleToDate(value, "date"),
    },
    {
      typeId: 2,
      label: "Groups",
      options: [],
      value: group || "",
      style: { width: 200, margin: "0 15px" },
      function: (event, newValue) => {
        setGroup(newValue);
      },
      className: null,
      type: "name",
    },
    {
      typeId: 2,
      label: "Status",
      options: PayableStatusHeader || [],
      value: status || "",
      style: { width: 200, margin: "0 15px" },
      function: (event, newValue) => {
        setStatus(newValue);
      },
      className: null,
      type: "name",
    },
  ];

  // Function to handle  to date
  const handleToDate = (value, type) => {
    if (type === "date") {
      const fromDateCurrent = moment(fromDate);
      const toDateCurrent = moment(value);
      const result = toDateCurrent.diff(fromDateCurrent, "days");
      const alertMessage =
        result < 0 ? "To Date should be after From Date" : "";

      setAlerts({
        visible: result < 0,
        alertMessage,
        alertType: "error",
      });

      if (result >= 0) {
        setToDate(value);
      }
    }
  };

  // Function to handle from date
  const handleFromDate = (value, type) => {
    if (type === "date") {
      if (toDate != null) {
        const toDateCurrent = moment(toDate);
        const fromDateCurrent = moment(value);
        const result = toDateCurrent.diff(fromDateCurrent, "days");
        const alertMessage =
          result < 0 ? "From Date should be before To Date" : "";

        setAlerts({
          visible: result < 0,
          alertMessage,
          alertType: "error",
        });

        if (result >= 0) {
          setFromDate(value);
        }
      } else {
        setFromDate(value);
      }
    }
  };

  const handleSubmitFilterValue = () => {
    setState({ ...state, right: false });
    const permissionExist = checkPermissionExist(endPoints, transactionsUrl);
    if (permissionExist)
      successAlert("Authorized to access transactioTotalPayIconns report.");
    else errorAlert("unauthorized to access transactions report.");
    return true;
  };

  const handleResetFilter = () => {
    const filterValuesData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));

    const currentLocation = window.location.pathname;
    if (filterValuesData) {
      const updatedFilterData = filterValuesData?.filter(
        (ele) => ele?.pathname !== currentLocation
      );
      localStorage.setItem("filter", JSON.stringify(updatedFilterData));
      setFromDate(null);
      setToDate(null);
      setState({ ...state, right: false });
    }
  };

  useEffect(() => {
    const filteredData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));

    const currentLocation = window.location.pathname;
    if (filteredData) {
      const findCurrentFilter = filteredData.find(
        (ele) => ele.pathname === currentLocation
      );

      if (findCurrentFilter) {
        const filterValues = findCurrentFilter.value;
        setFromDate(
          filterValues.find((item) => item.label === "From Date")?.value || null
        );
        setToDate(
          filterValues.find((item) => item.label === "To Date")?.value || null
        );
      }
    }
  }, []);

  const isFilterApplied = fromDate || toDate || group || status;

  useEffect(() => {
    const currentPage = window.location.pathname;
    const getHeaderConfig = userConfig.getHeader(currentPage, currentTable);
    setHeaderConfig(getHeaderConfig);
  }, [currentTable]);

  const getPayableHeaderList = async () => {
    const isPath = checkPathExist(endPoints, window.location.pathname);
    if (!isPath) return;

    const permissionExist = checkPermissionExist(endPoints, getPayableGroup);

    if (!permissionExist) {
      errorAlert("Permission Denied!!");
      return;
    }

    const payload = {
      reportId:9,
      siteId: selectedSite?.id || localStorage.getItem("siteId"),
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      setLoading(true);
      const response = await userService.postData(getPayableGroup, encryptedPayload);
      console.log(response);

      if (response && response.data?.data && response.data?.data[0] && response?.data?.status === 200) {
        setPayableData(response.data?.data[0]);
        const {
          currentPayableAmount,
          // paid amount
          paid15AmountPayable,
          paid30AmountPayable,
          paid45AmountPayable,
          paid60AmountPayable,
          paid90AmountPayable,
          paidAbove90AmountPayable,
          paidAmountPayable,
          // no of paid transaction
          noOfPaid15Transaction,
          noOfPaid30Transaction,
          noOfPaid45Transaction,
          noOfPaid60Transaction,
          noOfPaid90Transaction,
          noOfPaidAbove90Transaction,
          noOfPaidTransaction,
          // no of due transaction
          noOfDue15Transaction,
          noOfDue30Transaction,
          noOfDue45Transaction,
          noOfDue60Transaction,
          noOfDue90Transaction,
          noOfDueAbove90Transaction,
          noOfDueTransaction,
          // no of over due transaction
          noOfOverdue15Transaction,
          noOfOverdue30Transaction,
          noOfOverdue45Transaction,
          noOfOverdue60Transaction,
          noOfOverdue90Transaction,
          noOfOverdueAbove90Transaction,
          noOfOverdueTransaction,
          // Advance
          advanceAmount,
          noOfAdvanceTransaction,
          // Total Amount
          totalAmount,
          noOfTotalTxn,
          // Pending
          pendingAmount,
          noOfPendingTxn,
          // OverDue Paid
          noOfOverduePaid15Transaction,
          noOfOverduePaid30Transaction,
          noOfOverduePaid45Transaction,
          noOfOverduePaid60Transaction,
          noOfOverduePaid90Transaction,
          noOfOverduePaidAbove90Transaction,
          noOfOverduePaidTransaction,
        } = response.data?.data[0];
        const resData = [
          {
            id: 1,
            name: "Total Amount",
            transaction: noOfTotalTxn ?? 0,
            client: 0,
            amount: totalAmount ?? 0,
            icon: TotalPayIcon,
            color: "rgba(52, 122, 226, 1)",
          },
          {
            id: 2,
            name: "Paid",
            transaction:
              noOfPaid15Transaction +
              noOfPaid30Transaction +
              noOfPaid45Transaction +
              noOfPaid60Transaction +
              noOfPaid90Transaction +
              noOfPaidAbove90Transaction +
              noOfPaidTransaction +
              noOfOverduePaid15Transaction +
              noOfOverduePaid30Transaction +
              noOfOverduePaid45Transaction +
              noOfOverduePaid60Transaction +
              noOfOverduePaid90Transaction +
              noOfOverduePaidAbove90Transaction +
              noOfOverduePaidTransaction,
            client: 5,
            amount:
              paid15AmountPayable +
              paid30AmountPayable +
              paid45AmountPayable +
              paid60AmountPayable +
              paid90AmountPayable +
              paidAbove90AmountPayable +
              paidAmountPayable,
            icon: PaidIcon,
            color: "rgba(28, 195, 35, 1)",
          },
          {
            id: 2,
            name: "Pending",
            transaction: noOfPendingTxn ?? 0,
            client: 0,
            amount: pendingAmount ?? 0,
            icon: PendingIcon,
            color: "rgba(255, 138, 0, 1)",
          },
          {
            id: 3,
            name: "Due",
            transaction:
              noOfDue15Transaction +
              noOfDue30Transaction +
              noOfDue45Transaction +
              noOfDue60Transaction +
              noOfDue90Transaction +
              noOfDueAbove90Transaction +
              noOfDueTransaction,
            client: 0,
            amount: 0,
            icon: DueIcon,
            color: "rgba(255, 0, 0, 1)",
          },
          {
            id: 6,
            name: "Non Due",
            transaction: 0,
            client: 0,
            amount: currentPayableAmount,
            icon: DueIcon,
            color: "rgba(255, 0, 0, 2)",
          },
          {
            id: 4,
            name: "Over Due",
            transaction:
              noOfOverdue15Transaction +
              noOfOverdue30Transaction +
              noOfOverdue45Transaction +
              noOfOverdue60Transaction +
              noOfOverdue90Transaction +
              noOfOverdueAbove90Transaction +
              noOfOverdueTransaction,
            client: 0,
            amount: 0,
            icon: OverDueIcon,
            color: "rgba(255, 199, 0, 1)",
          },
          {
            id: 5,
            name: "Advance",
            transaction: noOfAdvanceTransaction,
            client: 0,
            amount: advanceAmount,
            icon: AdvanceIcon,
            color: "rgba(33, 66, 243, 1)",
          },
        ];
        setPayableHeaderData(resData);
        successAlert("Success");
      } else {
        setPayableData(null);
        successAlert(response.data.message);
      }
    } catch (error) {
      console.log(error);
      errorAlert("Something went wrong!");
    } finally {
      // setLoading(false); // Ensure loading state is reset
    }
  };

  const getPayableDetails = async () => {
    const permissionExist = checkPermissionExist(endPoints, getPayable);

    if (!permissionExist) {
      errorAlert("Permission Denied!!");
      return;
    }

    const payload = {
      reportId:9,
      siteId: selectedSite?.id || localStorage.getItem("siteId"),
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      setLoading(true);
      const response = await userService.postData(getPayable, encryptedPayload);
      console.log(response);

      if (
        response &&
        response?.data?.data &&
        response.data &&
        response.data.status === 200
      ) {
        const newData = response.data?.data?.map((ele) => {
          const {
            // paid
            paid15AmountPayable,
            paid30AmountPayable,
            paid45AmountPayable,
            paid60AmountPayable,
            // Due
            due15AmountPayable,
            due30AmountPayable,
            due45AmountPayable,
            due60AmountPayable,
            // Over Due
            overdue15AmountPayable,
            overdue30AmountPayable,
            overdue45AmountPayable,
            overdue60AmountPayable,
          } = ele;
          return {
            ...ele,
            // paid
            ["paid15AmountPayablepaid30AmountPayable"]:
              paid15AmountPayable + paid30AmountPayable,
            ["paid45AmountPayablepaid60AmountPayable"]:
              paid45AmountPayable + paid60AmountPayable,
            // Due
            ["due15AmountPayabledue30AmountPayable"]:
              due15AmountPayable + due30AmountPayable,
            ["due45AmountPayabledue60AmountPayable"]:
              due45AmountPayable + due60AmountPayable,
            // Over Due
            ["overdue15AmountPayableoverdue30AmountPayable"]:
              overdue15AmountPayable + overdue30AmountPayable,
            ["overdue45AmountPayableoverdue60AmountPayable"]:
              overdue45AmountPayable + overdue60AmountPayable,
          };
        });

        setPayableDetailData(newData);

        successAlert("Success");
      } else {
        setPayableDetailData([]);
        successAlert(response.data.message);
      }
    } catch (error) {
      console.log(error);
      setPayableDetailData([]);
      errorAlert("Something went wrong!");
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };

  useEffect(() => {
    getPayableHeaderList();
    getPayableDetails();
  }, [selectedSite, storedSite]);

  return (
    <>
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ width: "100%", overflow: "auto" }}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Grid container style={{ margin: "0", alignItems: "center" }}>
            <Header
              gridSize="3"
              source={PayableImg}
              headerText={
                recentPath === "/aging-Summary-Details"
                  ? "Aging Summary"
                  : recentPath === "/vendor-Balance-Summary"
                  ? "Vendor Balance Summary"
                  : recentPath === "/unpaid-Bills-Details"
                  ? "Unpaid Bills Details Summary"
                  : "Account Payable Summary"
              }
            />
            <Grid
              item
              xs={9}
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              {/* <MySwitch
                checked={showAllTransaction}
                onChange={(e) => {
                  setLoading(true);
                  setShowAllTransaction(e.target.checked);
                  setTimeout(() => {
                    setLoading(false);
                  }, 100);
                }}
                label={
                  !showAllTransaction
                    ? "Expand Transactions"
                    : "Collapse Transactions"
                }
              /> */}
              {renderAutocomplete(
                "Columns",
                (headerConfig || [])?.filter(
                  (ele) =>
                    ele.name !== "S.NO" &&
                    ele.name !== " " &&
                    ele.isShow !== false
                ) || [],
                "250px"
              )}
              {
                <AutoDialogBox
                  columnHandle={columnHandle}
                  handleDelete={handleDelete}
                  open={open}
                  setOpen={setOpen}
                  setScroll={setScroll}
                  // scroll={scroll}
                  scroll={"paper"}
                  columns={(headerConfig || [])?.filter(
                    (ele) => ele.name !== "S.NO" && ele.name !== " "
                  )}
                  heading={"Columns"}
                  autoCompName={"Columns"}
                />
              }
              <Grid item>
                <Autocomplete
                  options={siteList || []}
                  size="small"
                  margin="dense"
                  style={{
                    width: 200,
                    display: checkPermissionExist(endPoints, transactionsUrl)
                      ? "inline-flex"
                      : "none",
                  }}
                  value={selectedSite || ""}
                  getOptionLabel={(option) =>
                    option ? option.name || option.name : ""
                  }
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, newValue) => {
                    setSelectedSite(newValue);
                    // getAccountsList(newValue);
                    // getAccountGroup(newValue);
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField {...params} label="Site" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item>
                <div
                  style={{
                    display: checkPermissionExist(endPoints, transactionsUrl)
                      ? "inline-flex"
                      : "none",
                  }}
                >
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      {isFilterApplied ? (
                        <Badge color="primary" variant="dot">
                          <IconButton
                            style={{ background: "#c35151", color: "#fff" }}
                            onClick={toggleDrawer(anchor, true)}
                          >
                            <TuneIcon />
                          </IconButton>
                        </Badge>
                      ) : (
                        <IconButton
                          style={{ background: "#c35151", color: "#fff" }}
                          onClick={toggleDrawer(anchor, true)}
                        >
                          <TuneIcon />
                        </IconButton>
                      )}
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <FlexHeaderDivider
            resData={payableData}
            payableHeader={payableHeaderData || []}
            selectedDays={selectedDays}
            dueDaysArray={dueDaysArray}
            overDueDaysArray={overDueDaysArray}
            setSelectedDays={setSelectedDays}
          />
          <Grid container sx={{ marginTop: "30px" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: 550 }}>
                {"Details"}
              </Typography>
              {/* {recentPath === "/aging-Summary-Details" && (
                <Box>
                  {" "}
                  <Autocomplete
                    disablePortal
                    options={DetailsFilterStatusHeader || []}
                    getOptionLabel={(option) => option.name}
                    value={selectedAmountStatus}
                    onChange={(_, newValue) =>
                      setSelectedAmountStatus(newValue)
                    }
                    size="small"
                    sx={{
                      width: 150, // full width on small screens
                      background: "#fff",
                      borderRadius: "8px"
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          ".MuiOutlinedInput-root": {
                            borderRadius: "4px"
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid rgba(195, 212, 255, 1)"
                          },
                          ".MuiInputBase-input": {
                            height: "16px"
                          }
                        }}
                      />
                    )}
                  />
                </Box>
              )} */}
            </Grid>
            <Grid item xs={12}>
              {/* {recentPath !== "/vendor-Balance-Summary" ? ( */}
              <PayableTable
                headerConfig={headerConfig}
                data={payableDetailData || []}
                selectedColumns={selectedColumns}
                showAllTransaction={showAllTransaction}
                site={site}
                nextActionList={nextActionList}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
              {/* ) : (
                <VendorBalanceSummary
                  headerConfig={headerConfig}
                  data={transactionLockingList}
                  mainTransactionData={mainTransactionData}
                  selectedColumns={selectedColumns}
                  showAllTransaction={showAllTransaction}
                  site={site}
                  voucherList={vouchers}
                  nextActionList={nextActionList}
                />
              )} */}
            </Grid>
          </Grid>
        </Container>
      </Paper>
      {/* ==============Alerts=============== */}
      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />
      {/* =============Loader=============== */}
      {loading && <LoadingComponent />}
    </>
  );
};

export default withStyles(styles)(Index);
