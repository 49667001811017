import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PayableTable from "./PayableTable";

const trainingDetails = {
  mainContainer: {},
  box: {
    height: "4vh",
    display: "flex",
    alignItems: "center"
  },
  typography: {
    fontWeight: "bold",
    textAlign: "start",
    fontSize: "14px"
  },
  divider: {
    width: "100%",
    marginTop: "8px"
  }
};

const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 2
        }
      }
    }
  }
});

const data1 = [
  {
    id: 1,
    name: "Harsh Kumar",
    totalAmount: 250000
  },
  {
    id: 2,
    name: "Manish Kumar",
    totalAmount: 20000000
  },
  {
    id: 3,
    name: "Veena Nain",
    totalAmount: 3209999
  }
];

const VendorBalanceSummary = ({
  headerConfig,
  data,
  mainTransactionData,
  selectedColumns,
  showAllTransaction,
  site,
  voucherList,
  nextActionList
}) => {
  const [openQuestionId, setOpenQuestionId] = useState(null); // Initialize with null

  const handleCollapseAnswer = (id) => {
    setOpenQuestionId((prevId) => (prevId === id ? null : id)); // Toggle the id
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={trainingDetails.mainContainer}>
        <Grid container spacing={2} sx={{ marginTop: "2px" }}>
          {data1 && data1.length > 0 ? (
            data1.map((ele) => (
              <Grid item xs={12} key={ele.id}>
                <Box
                  sx={{
                    cursor: "pointer",
                    borderRadius: "10px",
                    marginBottom: "8px",
                    overflow: "hidden"
                  }}
                  onClick={() => handleCollapseAnswer(ele.id)}
                >
                  <Box
                    sx={{
                      background: "rgba(201, 223, 255, 1)",
                      color: "white",
                      padding: "14px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <Typography sx={{ color: "black", fontWeight: 540 }}>
                      {ele.name}
                    </Typography>
                    <div style={{ display: "flex" }}>
                      <Typography sx={{ color: "black", fontWeight: 540 }}>
                        Total Amount: {ele.totalAmount}
                      </Typography>
                      {openQuestionId === ele.id ? (
                        <ExpandLessIcon sx={{ color: "black" }} />
                      ) : (
                        <ExpandMoreIcon sx={{ color: "black" }} />
                      )}
                    </div>
                  </Box>
                </Box>
                {openQuestionId === ele.id && ( // Show the PayableTable only if the correct ID is open
                  <Box sx={{ padding: "0px 42px" }}>
                    <PayableTable
                      headerConfig={headerConfig}
                      data={data} // Ensure correct data prop is passed if needed
                      mainTransactionData={mainTransactionData}
                      selectedColumns={selectedColumns}
                      showAllTransaction={showAllTransaction}
                      site={site}
                      voucherList={voucherList}
                      nextActionList={nextActionList}
                    />
                  </Box>
                )}
              </Grid>
            ))
          ) : (
            <Typography>No data available</Typography>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default VendorBalanceSummary;
