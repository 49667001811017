import { legacy_createStore, combineReducers, compose, applyMiddleware } from "redux";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from "redux-thunk";
import rootReducer from "../reducers/index";

const persistConfig = {
  key: 'root',
  storage,
};

const combineReducer = combineReducers({
  rootReducer
});

const persistedReducer = persistReducer(persistConfig, combineReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  return legacy_createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
};

export default configureStore;
