import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Card, Paper, Container, Autocomplete, TextField } from "@mui/material";
import { themecolor } from "../Navigation";
import { userService } from "../userService";
import { withStyles } from "@material-ui/core/styles";
import { AlertBar } from "../Alert/AlertBar";
import { getEncryptPayload } from "../Network_Utility";
import {
  accountCustomFieldsUrl,
  accountGroupDropdownUrl,
  accountGroupDropdownUrlV2,
  accountRegisterUrl,
  accountTypesV1Url,
  companyVendorsUrl,
  entityTemplateUpdate,
  getAllState,
  saveVendorUrl,
  singleVoucherGetUrl,
  transactionVoucher,
} from "../config";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LoadingComponent from "../Loader";
import ConfigModal from "./ConfigModal";
import { ApiManager, ApiNature, validationFunction } from "./util";
import Header from "../CommonComps/Header";
import { getAllAccountTypes } from "../features/actions/AccountTypeAction";
import { checkPermissionExist } from "../utils";
import { useDispatch } from "react-redux";

const accounts = require("../images/templates.png");
const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    width: "90%",
  },
});

const MapData = ({ classes }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [vendorFields, setVendorFields] = useState([]);

  const reduxState = useSelector((val) => val?.rootReducer);
  const state = reduxState?.accountTypeReducer;
  const siteList = reduxState?.sitesReducer?.data;
  const endPoints = reduxState.permissionReducer?.data;
  const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));
  const [vendorDataId, setVendorDataId] = useState(null);
  const [
    vendorDataCompanyApiKeyMappingDTOId,
    setVendorDataCompanyApiKeyMappingDTOIdId,
  ] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [accountGroups, setAccountGroups] = useState([]);
  const [selectedAccountGroup, setSelectedAccountGroup] = useState(null);
  const [customFieldsArray, setCustomFieldsArray] = useState([
    { label: "Name", isMandatory: true, key: "name" },
    // { label: "Code", isMandatory: true, key: "code" },
    // { label: "Opening Balance", isMandatory: true, key: "balance" },
    // {
    //   label: "Min Transaction Amount",
    //   isMandatory: true,
    //   key: "config || minTransactionAmountLimit",
    // },
    // {
    //   label: "Max Transaction Amount",
    //   isMandatory: true,
    //   key: "config || maxTransactionAmountLimit",
    // },
    // { label: "Is KYC Required", isMandatory: true, key: "kycStatus" },
    // { label: "Description", isMandatory: true, key: "description" },
  ]);
  const [fieldMappings, setFieldMappings] = useState({});
  const [selectedCustomButton, setSelectedCustomButton] = useState(null);
  const [customFieldMappings, setCustomFieldMappings] = useState({});
  const [selectedCustomButtonId, setSelectedCustomButtonId] = useState(null);
  const [disableCustomButton, setDisableCustomBtn] = useState(false);
  const [disableVendorButton, setDisableVendorBtn] = useState(true);
  const [expandedVendorFields, setExpandedVendorFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState(null);
  const [accountTypeId, setAcccountTypeId] = useState(null);
  const [platformList, setPlatformList] = useState([
    { id: 1, name: "Store" },
    { id: 2, name: "Billing" },
    { id: 3, name: "PNM" },
    { id: 4, name: "HRMS" },
  ]);
  const [platform, setPlatform] = useState(null);
  const [apiName, setApiName] = useState(null);
  const [json, setJson] = useState(null);
  const [configFor, setConfigFor] = useState(null);
  const [apiType, setApiType] = useState(null);

  const [api, setApi] = useState(null);
  const [voucherLists, setVoucherLists] = useState([]);
  const [voucherList, setVoucherList] = useState(null);
  const [nextActionList, setNextActionList] = useState([]);
  const [permissionData, setPermissionData] = useState(null);
  const [voucherTemplateData, setVoucherTemplateData] = useState([]);
  const [defaultState, setDefaultState] = useState(null);
  // ===========Alerts================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: "",
  });

  const [error, setErrors] = useState({
    apiError: null,
    jsonError: null,
  });

  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });

  // Function  to show success alert message
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertType: "success",
      alertMessage: message || "Data fetched succesfully",
    });
    setLoading(false);
    setTimeout(() => {
      navigate("/vendorTemplate");
    }, 1000);
  };

  // Function  to show error alert message
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertType: "error",
      alertMessage: message || "Something went wrong",
    });
    setLoading(false);
  };

  const closeModal = () => setOpenModal(false);

  // Function to set values of vendor fields into custom field
  const handleFieldClick = (customField, vendorFieldValue) => {
    if (customField) {
      const getFinalValue = customField.id
        ? `customFieldValueList || ${customField.id}`
        : customField.uid
        ? `formDataListForInput || ${customField.uid}`
        : customField.key;
      setSelectedCustomButton(customField.label);
      setSelectedCustomButtonId(getFinalValue);
      setDisableCustomBtn(true);
      setDisableVendorBtn(false);
    } else if (vendorFieldValue) {
      setFieldMappings((prevMappings) => ({
        ...prevMappings,
        [selectedCustomButton]: vendorFieldValue,
      }));
      setCustomFieldMappings((prevMappings) => ({
        ...prevMappings,
        [selectedCustomButtonId]: vendorFieldValue,
      }));
      setSelectedCustomButton(null);
      setSelectedCustomButtonId(null);
      setDisableCustomBtn(false);
      setDisableVendorBtn(true);
    }
  };

  //FUnction to map data using child  value
  const handleChildClick = (parentValue, childValue, type) => {
    const separator = type === "object" ? "||" : "&&";
    const combinedValue = `${parentValue} ${separator} ${childValue}`;

    setFieldMappings((prevMappings) => ({
      ...prevMappings,
      [selectedCustomButton]: combinedValue,
    }));

    setCustomFieldMappings((prevMappings) => ({
      ...prevMappings,
      [selectedCustomButtonId]: combinedValue,
    }));

    setSelectedCustomButton(null);
    setSelectedCustomButtonId(null);
    setDisableCustomBtn(false);
    setDisableVendorBtn(true);
  };

  const isMapped = (customField) => {
    return fieldMappings[customField] !== undefined;
  };

  //Function to save mapped data
  const saveData = async () => {
    if (!platform) {
      errorAlert("Choose platform to proceed");
      return;
    }
    if (!apiType) {
      errorAlert("Select Api Type!");
      return;
    }
    if (
      (!selectedAccountGroup || !selectedAccountGroup || selectedAccountGroup.length == 0) &&
      !voucherList
    ) {
      errorAlert("Select Account group to proceed");
      return;
    }

    const payload = {
      apiManager: configFor.key || null,
      apiNature: apiType.key || null,
      id: vendorDataId || null,
      typeId: accountType
        ? accountType?.id
        : voucherList
        ? voucherList.id
        : null,
      // accountTempalteTypeId: accountType?.id || null,
      plateformId: platform?.id,
      name: json,
      apiName,
      isActive: true,
      companyName: localStorage.getItem("companyName") || "clear-dekho",
      createdBy: localStorage.getItem("userId") || 1400,
      companyId: localStorage.getItem("companyId"),
      companyApiKeyMappingDTO: {
        id: vendorDataCompanyApiKeyMappingDTOId || null,
        createdBy: localStorage.getItem("userId") || 1400,
        defaultStateId: defaultState.stateId,
        isDefaultStateApproved: defaultState.isFinal,
        permissionStateId: permissionData.stateId,
        isAvailable: permissionData.isFinal,
        accountGroup: configFor?.key === "ACCOUNTGROUP" ? selectedAccountGroup?.id : JSON.stringify(
          selectedAccountGroup && selectedAccountGroup.map((val) => val.id)
        ),
        apiAndTempleteMap: JSON.stringify(fieldMappings),
        apiAndTempleteMapForBackEnd: JSON.stringify(customFieldMappings),
      },
    };
    const encryptedPayload = getEncryptPayload(payload, false);
    try {
      setLoading(true);
      const response = await userService.postData(
        saveVendorUrl,
        encryptedPayload
      );
      if (response && response.data && response.data.status === 200) {
        successAlert(response?.data?.message);
      } else {
        errorAlert(response?.data?.message);
      }
    } catch (error) {
      errorAlert(error?.message);
    }
  };

  //Function to convert vendor response into array
  function convertApiResponseToNewArray(apiResponse) {
    function convertObjectToNewArray(obj) {
      const newArray = [];

      for (const key in obj) {
        const childArray = Array.isArray(obj[key])
          ? convertObjectToNewArray(obj[key][0])
          : typeof obj[key] === "object"
          ? convertObjectToNewArray(obj[key])
          : [];

        const dataType = Array.isArray(obj[key])
          ? "array"
          : typeof obj[key] === "object"
          ? "object"
          : "string";

        newArray.push({
          name: key,
          value: key,
          type: dataType, // Add the "type" key here
          ...(childArray.length > 0 && { child: childArray }),
        });
      }

      return newArray;
    }

    return apiResponse && convertObjectToNewArray(apiResponse[0]);
  }
  // Function to get custim fields data
  const getCustomFieldsData = async (id, typeValue) => {
    let payload = {
      entityId: 501,
      entityItemId: id,

      tokenData: {
        companyId: localStorage.getItem("companyId"),
        moduleId: null,
        organizationId: null,
        userCode: null,
        userId: localStorage.getItem("userId"),
        userName: null,
        userRoleId: null,
      },
    };

    const encryptedPayload = getEncryptPayload(payload);

    const res = await userService.postData(
      accountCustomFieldsUrl,
      encryptedPayload
    );
    if (
      res &&
      res.data &&
      res.data.data &&
      Array.isArray(res.data.data) &&
      res.data.status === 200
    ) {
      const customFieldsV2 = [
        { label: "Name", isMandatory: true, key: "name" },
        { label: "Code", isMandatory: true, key: "code" },
        { label: "Opening Balance", isMandatory: true, key: "balance" },
        {
          label: "Min Transaction Amount",
          isMandatory: true,
          key: "config || minTransactionAmountLimit",
        },
        {
          label: "Max Transaction Amount",
          isMandatory: true,
          key: "config || maxTransactionAmountLimit",
        },
        { label: "Is KYC Required", isMandatory: true, key: "kycStatus" },
        { label: "Description", isMandatory: true, key: "description" },
      ];

      if (typeValue?.legalDocumentRequired) {
        customFieldsV2.push({
          label: "GST Number",
          isMandatory: true,
          key: "GstNumber",
        });
        customFieldsV2.push({
          label: "PAN Number",
          isMandatory: true,
          key: "PanNumber",
        });
      }

      // If the response is successful and contains custom field data
      if (res.data.data.length > 0) {
        const customData = res.data.data;
        const customFields = [
          "Name",
          "Code",
          "Opening Balance",
          "Current Balance",
          "Min Transaction Amount",
          "Max Transaction Amount",
          "Is KYC Required",
          "Description",
        ];
        // const customFieldsV2 = [
        //   { label: "Name", isMandatory: true, key: "name" },
        //   { label: "Code", isMandatory: true, key: "code" },
        //   { label: "Opening Balance", isMandatory: true, key: "balance" },
        //   {
        //     label: "Min Transaction Amount",
        //     isMandatory: true,
        //     key: "config || minTransactionAmountLimit",
        //   },
        //   {
        //     label: "Max Transaction Amount",
        //     isMandatory: true,
        //     key: "config || maxTransactionAmountLimit",
        //   },
        //   { label: "Is KYC Required", isMandatory: true, key: "kycStatus" },
        //   { label: "Description", isMandatory: true, key: "description" },
        // ];
        for (let i = 0; i < customData.length; i++) {
          customFields.push(customData[i].label);
          customFieldsV2.push(customData[i]);
        }

        setCustomFieldsArray(customFieldsV2);
      } else {
        setCustomFieldsArray(customFieldsV2);
        // errorAlert("No custom fields present for selected account type");
      }
      setLoading(false); // Set the loading state to false
    } else {
      setLoading(false);
      // errorAlert("Unable to get custom field data"); // Display an error alert
    }
  };

  //Function to remove last added mapped key
  const undoData = () => {
    const keys = Object.keys(fieldMappings);
    const customKeys = Object.keys(customFieldMappings);

    if (keys.length > 0) {
      const lastKey = keys[keys.length - 1];
      const newData = { ...fieldMappings };
      delete newData[lastKey];
      setFieldMappings(newData);
    }

    if (customKeys.length > 0) {
      const lastKey = customKeys[customKeys.length - 1];
      const newData = { ...customFieldMappings };
      delete newData[lastKey];
      setCustomFieldMappings(newData);
    }
    setDisableCustomBtn(false);
  };

  //Function to remove all mapped data
  const resetData = () => {
    setFieldMappings({});
    setSelectedCustomButton(null);
    setCustomFieldMappings({});
    setSelectedCustomButtonId(null);
    setDisableCustomBtn(false);
    setDisableVendorBtn(true);
  };

  // Function to toggle expanded state for a vendor field
  const toggleExpanded = (vendorFieldValue) => {
    setExpandedVendorFields((prevExpanded) => ({
      ...prevExpanded,
      [vendorFieldValue]: !prevExpanded[vendorFieldValue],
    }));
  };

  // Function to set account type based on existing template
  useEffect(() => {
    if (accountTypeId && state?.data.length > 0) {
      const selectedAccountType = state?.data.find(
        (val) => val.id === Number(accountTypeId)
      );
      setAccountType(selectedAccountType);
    }
    // eslint-disable-next-line
  }, [accountTypeId]);

  function isJSONValid(jsonString) {
    if (!jsonString) {
      return false; // If the string is empty or null, it's not valid JSON.
    }

    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }

  // Function to close modal
  const handleClose = () => {
    closeModal();

    setErrors({
      apiError: false,
      jsonError: false,
    });
  };

  //Function to save data for configuration of compnay template
  const handleSubmit = async () => {
    const error = validationFunction(apiName, json, apiType?.key);

    setErrors(error);
    if (Object.keys(error).length <= 0) {
      const vendorArrays = json && JSON.parse(json);
      const newArray = convertApiResponseToNewArray(vendorArrays?.data);
      if (newArray) {
        setVendorFields(newArray);
        handleClose();
      } else {
        setVendorFields([]);
        handleClose();
      }
    }
  };

  //   const jsonObject = fieldMappings && JSON.parse(fieldMappings);
  // // Iterate over key-value pairs
  // Object.keys(jsonObject).forEach(function(key) {
  //   const value = jsonObject[key];
  //   console.log(`Key: ${key}, Value: ${value}`);
  // });

  useEffect(() => {
    if (
      location &&
      location.state &&
      state?.data &&
      accountGroups?.length > 0
    ) {
      const {
        id,
        companyApiKeyMappingDTO,
        typeId,
        plateformId,
        apiManager,
        apiNature,
        name,
        apiName,
      } = location.state;

      setPlatform(platformList.find((val) => val.id == plateformId));
      setConfigFor(ApiManager.find((val) => val.key == apiManager));
      setApiType(ApiNature.find((val) => val.key == apiNature));
      if (apiManager === "ACCOUNTS") {
        setAccountType(state?.data.find((val) => val.id == typeId));
        getCustomFieldsData(typeId);
        const arrayGroup = accountGroups?.filter(
          (group) =>
            companyApiKeyMappingDTO &&
            JSON.parse(companyApiKeyMappingDTO?.accountGroup)?.includes(
              group.id
            )
        );
        setSelectedAccountGroup(arrayGroup);
      }
      if (apiManager === "TRANSACTION") {
        setVoucherList(voucherLists?.find((val) => val.id == typeId));
        getTemplateDataById(typeId);
        // getCustomFieldsData(typeId);
      }

      companyApiKeyMappingDTO &&
        setFieldMappings(
          JSON.parse(companyApiKeyMappingDTO?.apiAndTempleteMap)
        );
      companyApiKeyMappingDTO &&
        setCustomFieldMappings(
          JSON.parse(companyApiKeyMappingDTO?.apiAndTempleteMapForBackEnd)
        );
      companyApiKeyMappingDTO &&
        setDefaultState(
          nextActionList?.find(
            (val) => val.stateId == companyApiKeyMappingDTO.defaultStateId
          )
        );
      companyApiKeyMappingDTO &&
        setPermissionData(
          nextActionList?.find(
            (val) => val.stateId == companyApiKeyMappingDTO.permissionStateId
          )
        );
      setVendorDataId(id);
      companyApiKeyMappingDTO &&
        setVendorDataCompanyApiKeyMappingDTOIdId(companyApiKeyMappingDTO?.id);
      setApiName(apiName);
      setJson(name);

      const vendorArrays = JSON.parse(name);
      const newArray = convertApiResponseToNewArray(vendorArrays?.data);
      setVendorFields(newArray);
    }
  }, [location, state, accountGroups]);

  const getAccountGroup = async () => {
    // Prepare the payload object with tokenData
    const permissionExist = checkPermissionExist(
      endPoints,
      accountGroupDropdownUrlV2
    );
    if (!permissionExist) return;

    let payload = {
      siteId: siteList && siteList.find((val) => val.isHeadOffice)?.id,
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      // Send a POST request to the API endpoint with the payload
      const res = await userService.postData(
        accountGroupDropdownUrlV2,
        encryptedPayload
      );

      if (res && res.data && res.data.data && res.data.status === 200) {
        // If the response is successful, extract the data
        const { data } = res.data;

        // Update the accountGroups state with the retrieved data
        setAccountGroups(data);

        // Set enableAsync state to true
      } else {
        // If the response is not successful or the data is missing, reset the accountGroups state and set enableAsync to true
        setAccountGroups([]);
      }
    } catch (error) {
      // Handle any errors that occur during the API request
      console.log("error", error);
    }
  };

  // Function to get account  types list
  const getAccountTypes = async () => {
    const permissionExist = checkPermissionExist(endPoints, accountTypesV1Url);
    if (permissionExist) {
      // setLoading(true);
      let payload = {};
      let encryptedPayload = getEncryptPayload(payload, true);
      dispatch(getAllAccountTypes(encryptedPayload));
    }
    // dispatch(getAllAccountTypes({}))
    // else { errorAlert("Permission Denied for account type") }
  };

  // Function to get Voucher types list
  const getVoucherTypes = async () => {
    const permissionExist = checkPermissionExist(endPoints, transactionVoucher);
    if (!permissionExist) return;
    let payload = {
      siteId: siteList && siteList.find((val) => val.isHeadOffice)?.id,
      companyId: localStorage.getItem("companyId"),
    };
    try {
      // Send a POST request to the API endpoint with the payload
      const res = await userService.postData(transactionVoucher, payload);

      if (res && res.data && res.data.data && res.data.status === 200) {
        // If the response is successful, extract the data
        const { data } = res.data;
        setVoucherLists(data);
      } else {
        // If the response is not successful or the data is missing, reset the accountGroups state and set enableAsync to true
        setVoucherLists([]);
      }
    } catch (error) {
      // Handle any errors that occur during the API request
      console.log("error", error);
    }
  };

  const getPermissionCombinationList = async (url) => {
    const payload = {
      permissionId:
        endPoints && endPoints.find((val) => val.apiEndpoint === `/${url}`)?.id,
      permissionStateId: null,
      isActive: true,
      roleId: null
      // rolePermission && rolePermission.length > 0
      // ? rolePermission.map((ele) => ele?.roleId)
      // : [],
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      const res = await userService.getPermissionCombination(
        getAllState,
        encryptedPayload
      );
      if (res && res.data && res.data.status) {
        let data = res?.data?.data.map((val) => ({
          ...val,
          stateId: val?.stateId?.id,
          name: val?.stateId?.name,
        }));
        setNextActionList(data);
      }
    } catch (error) {}
  };

  const getTemplateDataById = async (id) => {
    const companyId = localStorage.getItem("companyId");
    let apiEndpoint = `${singleVoucherGetUrl}?companyId=${companyId}&templateTypeId=${id}`;

    try {
      setLoading(true);
      const response = await userService.getData(apiEndpoint);

      if (response && response.data && response.data.status === 200) {
        const customData = response?.data?.data?.templateData;
        const customFields = [
          "Name",
          "Code",
          "Opening Balance",
          "Current Balance",
          "Min Transaction Amount",
          "Max Transaction Amount",
          "Is KYC Required",
          "Description",
        ];
        const customFieldsV2 = [
          { label: "Name", isMandatory: true, key: "name" },
          // { label: "Code", isMandatory: true, key: "code" },
          // { label: "Opening Balance", isMandatory: true, key: "balance" },
          // {
          //   label: "Min Transaction Amount",
          //   isMandatory: true,
          //   key: "config || minTransactionAmountLimit",
          // },
          // {
          //   label: "Max Transaction Amount",
          //   isMandatory: true,
          //   key: "config || maxTransactionAmountLimit",
          // },
          // { label: "Is KYC Required", isMandatory: true, key: "kycStatus" },
          // { label: "Description", isMandatory: true, key: "description" },
        ];
        for (let i = 0; i < customData.length; i++) {
          customFields.push(customData[i].label);
          customFieldsV2.push(customData[i]);
        }
        if (customData.length > 0) {
          setCustomFieldsArray(customFieldsV2);
        } else {
          setCustomFieldsArray([
            { label: "Name", isMandatory: true, key: "name" },
          ]);
        }

        setVoucherTemplateData(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        setVoucherTemplateData([]);
        setCustomFieldsArray([
          { label: "Name", isMandatory: true, key: "name" },
        ]);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getVoucherTypes();
    getAccountTypes();
    getAccountGroup();
    getPermissionCombinationList(accountRegisterUrl);
  }, []);

  return (
    <Paper
      className={classes.paper}
      elevation={0}
      style={{ width: "100%", overflow: "auto", marginTop: "75px" }}
    >
      <Container style={{ maxWidth: "100%" }}>
        {/* ===============Account Group and type=============== */}
        <Grid container alignItems="center" justifyContent="space-between">
          <Header headerText="Template Config" gridSize={6} source={accounts} />

          <Grid item>
            <Button
              color="primary"
              variant="contained"
              style={{ background: `green`, marginLeft: 5 }}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Template Config
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ margin: "40px 0px" }}>
          <Grid item xs={3}>
            <Autocomplete
              disabled={Boolean(location?.state?.id)}
              options={platformList || []}
              value={platform || null}
              size="small"
              // style={{ width: "100%" }}
              getOptionLabel={(option) => `${option?.name}` || ""}
              getOptionSelected={(option, value) =>
                option?.name === value?.name
              }
              onChange={(event, newValue) => {
                setPlatform(newValue);
              }}
              fullWidth
              variant="outlined"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Platform"
                  size="small"
                  variant="outlined"
                  // error={customErrors.debitAccounts}
                />
              )}
            />
          </Grid>

          <Grid item xs={3}>
            <Autocomplete
              disabled={Boolean(location?.state?.id)}
              options={ApiManager || []}
              value={configFor || null}
              size="small"
              margin="dense"
              getOptionLabel={(option) =>
                option ? option.name || option.name : ""
              }
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={(event, newValue) => {
                setConfigFor(newValue);
                if (newValue?.key === "TRANSACTION") setAccountType(null);
                if (newValue?.key === "ACCOUNTS") setVoucherList(null);
                if (newValue?.key === "ACCOUNTGROUP") {
                  setSelectedAccountGroup(null);
                  setCustomFieldsArray([
                    { label: "Name", isMandatory: true, key: "name" },
                    {
                      label: "Description",
                      isMandatory: true,
                      key: "description",
                    },
                    {
                      label: "Parent Group Id",
                      isMandatory: true,
                      key: "parentGroupId",
                    },
                    { label: "Type Id", isMandatory: true, key: "typeId" },
                    {
                      label: "Plateform Id",
                      isMandatory: true,
                      key: "plateformId",
                    },
                  ]);
                }else{
                  setSelectedAccountGroup(null)
                }
                if (newValue?.key === "NARRATION") {
                  setAccountType(null);
                  setVoucherList(null);
                }
              }}
              fullWidth
              variant="outlined"
              renderInput={(params) => (
                <TextField {...params} label="Config For" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disabled={Boolean(location?.state?.id)}
              options={ApiNature || []}
              value={apiType || null}
              size="small"
              margin="dense"
              getOptionLabel={(option) =>
                option ? option.name || option.name : ""
              }
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={(event, newValue) => {
                setApiType(newValue);
              }}
              fullWidth
              variant="outlined"
              renderInput={(params) => (
                <TextField {...params} label="API Type" variant="outlined" />
              )}
            />
          </Grid>
          {configFor &&
            configFor.key !== "ACCOUNTGROUP" &&
            configFor.key !== "TRANSACTION" &&
            configFor.key !== "NARRATION" && (
              <Grid item xs={3}>
                <Autocomplete
                  disabled={Boolean(location?.state?.id)}
                  options={state?.data || []}
                  value={accountType || null}
                  size="small"
                  margin="dense"
                  getOptionLabel={(option) =>
                    option ? option.name || option.name : ""
                  }
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, newValue) => {
                    // Call the getCustomFieldsData function with the ID of the selected account type
                    // Set the selected account type
                    if (newValue) {
                      setAccountType(newValue);
                      getCustomFieldsData(newValue.id, newValue);
                    } else {
                      setAccountType(null);
                    }
                  }}
                  fullWidth
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Account Type"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}
          {configFor && configFor.key === "TRANSACTION" && (
            <Grid item xs={3}>
              <Autocomplete
                disabled={Boolean(location?.state?.id)}
                options={voucherLists || []}
                value={voucherList || null}
                size="small"
                margin="dense"
                getOptionLabel={(option) =>
                  option ? option.customFormType || option.customFormType : ""
                }
                getOptionSelected={(option, value) =>
                  option.customFormType === value.customFormType
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setVoucherList(newValue);
                  } else {
                    setVoucherList(null);
                  }
                  newValue && getTemplateDataById(newValue.id);
                }}
                fullWidth
                variant="outlined"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Voucher Type"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
          {configFor && (
            <Grid item xs={3}>
              <Autocomplete
                disabled={false}
                options={nextActionList || []}
                value={defaultState || null}
                size="small"
                margin="dense"
                getOptionLabel={(option) =>
                  option ? option.name || option.name : ""
                }
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                onChange={(event, newValue) => {
                  setDefaultState(newValue);
                }}
                fullWidth
                variant="outlined"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Default Status"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
          {configFor && (
            <Grid item xs={3}>
              <Autocomplete
                disabled={false}
                options={nextActionList || []}
                value={permissionData || null}
                size="small"
                margin="dense"
                getOptionLabel={(option) =>
                  option ? option.name || option.name : ""
                }
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                onChange={(event, newValue) => {
                  setPermissionData(newValue);
                }}
                fullWidth
                variant="outlined"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Current Status"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
          {configFor &&
            configFor.key !== "TRANSACTION" &&
            configFor.key !== "NARRATION" && (
              <Grid item xs={3}>
                <Autocomplete
                  multiple={configFor?.key === "ACCOUNTGROUP" ? false : true}
                  filterSelectedOptions
                  options={accountGroups || []}
                  value={
                    selectedAccountGroup ||
                    (configFor?.key === "ACCOUNTGROUP" ? null : [])
                  }
                  limitTags={1}
                  size="small"
                  margin="dense"
                  getOptionLabel={(option) =>
                    option ? option.name || option.name : ""
                  }
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, newValue) => {
                    setSelectedAccountGroup(newValue);
                  }}
                  fullWidth
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField {...params} label="Groups" variant="outlined" />
                  )}
                />
              </Grid>
            )}

          <Grid item xs={3}>
            {location?.state?.id ? (
              <Button
                disabled={
                  (!accountType && !voucherList && !apiType) ||
                  Object.keys(fieldMappings)?.length === 0
                }
                color="primary"
                variant="contained"
                style={{
                  marginRight: 5,
                  background: `${themecolor}`,
                  color: "#fff",
                }}
                onClick={() => saveData()}
              >
                Update
              </Button>
            ) : (
              <Button
                disabled={
                  (!accountType && !voucherList && !apiType) ||
                  Object.keys(fieldMappings)?.length === 0
                }
                color="primary"
                variant="contained"
                style={{
                  marginRight: 5,
                  background: `${themecolor}`,
                  color: "#fff",
                }}
                onClick={() => saveData()}
              >
                Save
              </Button>
            )}

            <Button
              // disabled={!accountType}
              color="primary"
              variant="contained"
              style={{ marginRight: 5, background: `grey`, color: "#fff" }}
              onClick={() => undoData()}
            >
              Undo
            </Button>

            <Button
              color="secondary"
              variant="contained"
              style={{ marginRight: 5, background: "green" }}
              onClick={resetData}
            >
              Reset
            </Button>
            <Button
              color="secondary"
              variant="contained"
              style={{ marginRight: 5 }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        {(accountType || voucherList || configFor?.key === "ACCOUNTGROUP") && (
          <Grid container spacing={2}>
            <Grid
              item
              container
              alignItems="flex-start"
              style={{ padding: "40px 20px" }}
              component={Card}
            >
              <Grid item xs={6}>
                <h2>Custom Fields</h2>
                {customFieldsArray &&
                  customFieldsArray.length > 0 &&
                  customFieldsArray.map((customField) => (
                    <Button
                      disabled={
                        disableCustomButton || isMapped(customField.label)
                      }
                      variant={
                        isMapped(customField.label) ? "outlined" : "contained"
                      }
                      color={
                        isMapped(customField.label) ? "secondary" : "primary"
                      }
                      style={{ margin: 10 }}
                      onClick={() => handleFieldClick(customField, null)}
                      key={customField.label}
                    >
                      {customField.label} {customField.isMandatory ? "*" : ""}
                    </Button>
                  ))}
              </Grid>
              <Grid item xs={6}>
                <h2>Vendor Fields</h2>

                {vendorFields &&
                  vendorFields.map((vendorField, index) => (
                    <React.Fragment key={vendorField.value}>
                      <Button
                        disabled={disableVendorButton}
                        style={{
                          margin: 10,
                          backgroundColor: Object.values(
                            fieldMappings
                          ).includes(vendorField.value)
                            ? "#b77c7c"
                            : "",
                          color: Object.values(fieldMappings).includes(
                            vendorField.value
                          )
                            ? "white"
                            : "",
                        }}
                        variant={
                          Object.values(fieldMappings).includes(
                            vendorField.value
                          )
                            ? "outlined"
                            : "contained"
                        }
                        color={
                          Object.values(fieldMappings).includes(
                            vendorField.value
                          )
                            ? "primary"
                            : "secondary"
                        }
                        onClick={() => {
                          if (!vendorField.child) {
                            handleFieldClick(null, vendorField.value);
                          } else {
                            toggleExpanded(vendorField.value); // Toggle expanded state for the vendor field
                          }
                        }}
                      >
                        <span
                          onClick={() => {
                            !vendorField?.child &&
                              handleFieldClick(null, vendorField.value);
                          }}
                        >
                          {vendorField?.value}
                        </span>
                        {vendorField?.child && (
                          <ArrowDropDownIcon
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                              transform: expandedVendorFields[vendorField.value]
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            }}
                          />
                        )}
                      </Button>
                      {vendorField?.child &&
                        vendorField?.child.length > 0 &&
                        expandedVendorFields[vendorField.value] && (
                          <div>
                            {vendorField.child.map((child, childIndex) => (
                              <Button
                                size="small"
                                style={{
                                  margin: 10,
                                  backgroundColor: Object.values(
                                    fieldMappings
                                  ).includes(child.value)
                                    ? "#b77c7c"
                                    : "green",
                                  color: Object.values(fieldMappings).includes(
                                    child.value
                                  )
                                    ? "white"
                                    : "",
                                }}
                                variant={
                                  Object.values(fieldMappings).includes(
                                    child.value
                                  )
                                    ? "outlined"
                                    : "contained"
                                }
                                color={
                                  Object.values(fieldMappings).includes(
                                    child.value
                                  )
                                    ? "primary"
                                    : "secondary"
                                }
                                onClick={() => {
                                  handleChildClick(
                                    vendorField.value,
                                    child.value,
                                    vendorField.type
                                  );
                                  toggleExpanded(vendorField.value);
                                }}
                                key={childIndex}
                              >
                                {child.value}
                              </Button>
                            ))}
                          </div>
                        )}
                    </React.Fragment>
                  ))}
              </Grid>
            </Grid>
            {fieldMappings && Object.keys(fieldMappings).length > 0 && (
              <Grid
                item
                justifyContent="flex-start"
                container
                className="mapped-fields-container"
              >
                {Object.entries(fieldMappings).map(([key, value]) => (
                  <div key={key} className="mapped-field">
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      className="mapped-fields-btn-left mapped-btn"
                    >
                      {key}
                    </Button>
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      className="mapped-fields-btn-right mapped-btn"
                    >
                      {value}
                    </Button>
                  </div>
                ))}
              </Grid>
            )}
          </Grid>
        )}
      </Container>

      {/* ==============Alerts=============== */}
      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />

      {/* ===============Loader ================ */}
      {loading && <LoadingComponent />}

      <ConfigModal
        apiType={apiType}
        open={openModal}
        closeModal={closeModal}
        apiName={apiName}
        setApiName={setApiName}
        json={json}
        setJson={setJson}
        setLoading={setLoading}
        successAlert={successAlert}
        errorAlert={errorAlert}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        error={error}
        setErrors={setErrors}
      />
    </Paper>
  );
};

export default withStyles(styles)(MapData);
