// Define initial state
const initialState = {

    loading: false,
    error: null,
    message: null,
    data: [],
    visible: false
};


const accountTypeReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'START_ACCOUNT_TYPE_REQUEST':
            return {
                ...state,
                data: null,
                loading: true,

            };

        case 'ACCOUNT_TYPE_REQUEST_SUCCESS':
            return {
                ...state,
                loading: false,
                error: false,
                data: action?.payload?.data,
                message: action?.payload?.message
            };
        case 'ACCOUNT_TYPE_REQUEST_FAILURE':
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload,
                visible: true,
                data: []
            };


        default:
            return state;
    }
};

export default accountTypeReducer;
