import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import DragAndDropComponent from "./DynamicFields";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PurchaseVoucherPreview from "../VoucherTemplates/PurchaseVoucherPreview";
import JournalVoucherPreview from "../VoucherTemplates/JournalVoucherPreview";
import AddIcon from "@mui/icons-material/Add";
import { StyledTableCell, StyledTableRow } from "../utils";
import DeleteIcon from "@mui/icons-material/Delete";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest
  })
}));

// DetailsViewConfigurations
function DetailsViewConfigurations({
  templateViewList,
  selectTemplateView,
  setSelectTemplateView,
  customErrors,
  setCustomErrors
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [viewVoucher, setViewVoucher] = React.useState("");

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleClose = () => setViewVoucher("");

  return (
    <>
      {viewVoucher === "PURCHASE" ? (
        <PurchaseVoucherPreview
          open={viewVoucher === "PURCHASE"}
          handleClose={handleClose}
          title={"Purchase Voucher Preview"}
        />
      ) : viewVoucher === "JOURNAL" ? (
        <JournalVoucherPreview
          open={viewVoucher === "JOURNAL"}
          handleClose={handleClose}
          title={"Journal Voucher Preview"}
        />
      ) : (
        ""
      )}
      <Card sx={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "4px 10px",
            backgroundColor: customErrors?.selectTemplateView
              ? "rgb(255 240 240)"
              : "#f0f7ff",
            alignItems: "center"
          }}
          onClick={handleExpandClick}
        >
          <Typography variant="body2" color="text.secondary" fontWeight="bold">
            Details View Configuration*
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </div>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <div style={{ alignItems: "center", display: "flex", gap: "20px" }}>
              <Autocomplete
                options={templateViewList || []}
                value={selectTemplateView}
                size="small"
                fullWidth
                getOptionLabel={(option) => `${option?.name}` || ""}
                onChange={(event, newValue) => {
                  setSelectTemplateView(newValue);
                }}
                style={{ width: "300px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Voucher Template View"
                    size="small"
                    required
                    error={customErrors.selectTemplateView}
                    onBlur={() => {
                      selectTemplateView
                        ? setCustomErrors({
                            ...customErrors,
                            selectTemplateView: false
                          })
                        : setCustomErrors({
                            ...customErrors,
                            selectTemplateView: true
                          });
                    }}
                    onInput={(e) =>
                      (e.target.value = e.target.value.toString().slice(0, 20))
                    }
                  />
                )}
              />
              {selectTemplateView && (
                <IconButton
                  onClick={() => setViewVoucher(selectTemplateView?.alias)}
                >
                  <VisibilityIcon />
                </IconButton>
              )}
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}

// DueDataConfigurations
function DueDataConfigurations({
  overDueBoolean,
  setOverDueBoolean,
  accountCategory,
  setAccountCategory,
  overDueDays,
  setOverDueDays,
  customErrors,
  setCustomErrors,
  checkboxError,
  setCheckboxError,
  radioError,
  setRadioError,
  taggedInvoice
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setOverDueBoolean(isChecked);
    setCheckboxError(!isChecked);
    if (!isChecked) {
      setAccountCategory(false);
      setOverDueDays(null);
    }
  };

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setAccountCategory(value);
    setRadioError(value === "");
  };

  const handleOverDueDaysChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setOverDueDays(value);
    }
  };

  const handleOverDueDaysBlur = () => {
    if (overDueDays) {
      setCustomErrors({ ...customErrors, overDueDays: false });
    } else {
      setCustomErrors({ ...customErrors, overDueDays: true });
    }
  };

  React.useEffect(() => {
    setCheckboxError(!overDueBoolean);
    setRadioError(accountCategory === "");
  }, [overDueBoolean, accountCategory]);

  return (
    <Card sx={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "4px 10px",
          backgroundColor: "#f0f7ff",
          alignItems: "center"
        }}
        onClick={handleExpandClick}
      >
        <Typography variant="body2" color="text.secondary" fontWeight="bold">
          Due Days Configurations*
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                className="checkbox_input"
                checked={overDueBoolean}
                onChange={handleCheckboxChange}
                disabled={
                  taggedInvoice === "none" ||
                  taggedInvoice === false ||
                  taggedInvoice === null
                    ? false
                    : true
                }
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography variant="body2" color="text.secondary">
                Over Due
              </Typography>
              {checkboxError ||
                (taggedInvoice === true && (
                  <Typography
                    color="error"
                    variant="body2"
                    style={{ marginLeft: 8 }}
                  >
                    *This field is required
                  </Typography>
                ))}
            </div>
            <div>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleRadioChange}
                value={accountCategory}
              >
                <FormControlLabel
                  value="debit"
                  control={<Radio />}
                  label="Debit"
                  disabled={
                    taggedInvoice === "none" ||
                    taggedInvoice === false ||
                    taggedInvoice === null
                      ? false
                      : true
                  }
                />
                <FormControlLabel
                  value="credit"
                  control={<Radio />}
                  label="Credit"
                  disabled={
                    taggedInvoice === "none" ||
                    taggedInvoice === false ||
                    taggedInvoice === null
                      ? false
                      : true
                  }
                />{" "}
              </RadioGroup>
              {radioError && (
                <Typography color="error" variant="body2">
                  *Please select an account category
                </Typography>
              )}
            </div>
          </div>
          {overDueBoolean ? (
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                margin: "5px 0px"
              }}
            >
              <TextField
                id="outlined-basic"
                label="Over Due Days"
                variant="outlined"
                size={"small"}
                value={overDueDays}
                onChange={handleOverDueDaysChange}
                error={customErrors.overDueDays}
                onBlur={handleOverDueDaysBlur}
                helperText={
                  customErrors.overDueDays ? "* Over Due Days is required" : ""
                }
              />
            </div>
          ) : null}
        </CardContent>
      </Collapse>
    </Card>
  );
}

// Tagged Functions
function TaggedFunction({
  taggedInvoice,
  setTaggedInvoice,
  customErrors,
  setCustomErrors,
  overDueBoolean
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "4px 10px",
          backgroundColor: "#f0f7ff",
          alignItems: "center"
        }}
        onClick={handleExpandClick}
      >
        <Typography variant="body2" color="text.secondary" fontWeight="bold">
          Tagged Invoice*
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => setTaggedInvoice(e.target.value)}
                value={taggedInvoice}
              >
                <FormControlLabel
                  value="debit"
                  control={<Radio />}
                  label="Debit"
                  disabled={overDueBoolean === true ? true : false}
                />
                <FormControlLabel
                  value="credit"
                  control={<Radio />}
                  label="Credit"
                  disabled={overDueBoolean === true ? true : false}
                />{" "}
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="none"
                  disabled={overDueBoolean === true ? true : false}
                />
              </RadioGroup>
            </div>
          </div>
        </CardContent>
      </Collapse>
    </Card>
  );
}

// Mandatory check
function MandatoryCheck({
  invoiceOrNoteNumber,
  setInvoiceorNotNumber,
  accountCode,
  setAccountCode,
  transactionType,
  setTransactionType,
  eInvoiceGeneration,
  setEInvoiceGeneration,
  eInvoiceGenerationImmediate,
  setEInvoiceGenerationImmediate,
  customErrors,
  setCustomErrors
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
 
  return (
    <Card sx={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "4px 10px",
          backgroundColor: "#f0f7ff",
          alignItems: "center"
        }}
        onClick={handleExpandClick}
      >
        <Typography variant="body2" color="text.secondary" fontWeight="bold">
          Mandatory Check's*
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={invoiceOrNoteNumber}
                  onChange={(e) => setInvoiceorNotNumber(e.target.checked)}
                />
              }
              label="Invoice/Note Number"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountCode}
                  onChange={(e) => setAccountCode(e.target.checked)}
                />
              }
              label="Account Code"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={transactionType}
                  onChange={(e) => setTransactionType(e.target.checked)}
                />
              }
              label="Transaction Type"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={eInvoiceGeneration}
                  onChange={(e) => setEInvoiceGeneration(e.target.checked)}
                />
              }
              label="E-Invoice Generation"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={eInvoiceGenerationImmediate}
                  onChange={(e) =>
                    setEInvoiceGenerationImmediate(e.target.checked)
                  }
                />
              }
              label="E-Invoice Generation Immediate"
            />
          </FormGroup>
        </CardContent>
      </Collapse>
    </Card>
  );
}

function ConfigurationCheckFields({
  openModal,
  setOpenModal,
  dynamicFields,
  setDynamicFields,
  setHandleTableComponent,
  openEditorFuntion
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "4px 10px",
          backgroundColor: "#f0f7ff",
          alignItems: "center"
        }}
        onClick={handleExpandClick}
      >
        <Typography variant="body2" color="text.secondary" fontWeight="bold">
          Configure Voucher Fields*
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* <Grid item xs={12}> */}
          <DragAndDropComponent
            openModal={openModal}
            setOpenModal={setOpenModal}
            dynamicFields={dynamicFields}
            setDynamicFields={setDynamicFields}
            setHandleTableComponent={setHandleTableComponent}
            openEditorFuntion={openEditorFuntion}
          />
          {/* </Grid> */}
        </CardContent>
      </Collapse>
    </Card>
  );
}

// Child Account Configuration
function ChildAccountConfiguration({
  targetAccountTypes,
  accountTypes,
  sourceAccountType,
  setSourceAccountType,
  targetAccountType,
  setTargetAccountType,
  sourceAccountTypeChildLists,
  sourceAccountTypeChildList,
  targetAccountTypeChildLists,
  targetAccountTypeChildList,
  setSourceAccountTypeChildLists,
  setSourceAccountTypeChildList,
  setTargetAccountTypeChildLists,
  setTargetAccountTypeChildList,
  childConfigError,
  setChildConfigError,
  addSourceAccountChildHandle,
  sourceAccountChildTableList,
  deleteSourceAccountChildHandle,
  addTargetAccountChildHandle,
  deleteTargetAccountChildHandle,
  targetAccountChildTableList,
  setTargetAccountChildTableList
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "4px 10px",
          backgroundColor: "#f0f7ff",
          alignItems: "center"
        }}
        onClick={handleExpandClick}
      >
        <Typography variant="body2" color="text.secondary" fontWeight="bold">
          Account Type Configuration
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2}>
            <Grid items md={6}>
              <Box
                sx={{
                  background: "aliceblue",
                  padding: 2,
                  height: "100%",
                  borderRadius: 1,
                  marginTop: 1
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: 2, fontSize: "14px" }}
                >
                  Debit Account Type
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    <Autocomplete
                      options={accountTypes || []}
                      value={sourceAccountType}
                      size="small"
                      style={{ width: "300px" }}
                      getOptionLabel={(option) => `${option?.name}` || ""}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      onChange={(event, newValue) => {
                        setSourceAccountTypeChildList(null);
                        if (newValue) {
                          setSourceAccountType(newValue);
                          setSourceAccountTypeChildLists(
                            newValue?.childAccountTypeDtoList
                          );
                        } else {
                          setSourceAccountType(null);
                          setSourceAccountTypeChildLists([]);
                          setSourceAccountTypeChildList(null);
                        }
                      }}
                      variant="outlined"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Account Type"
                          size="small"
                          variant="outlined"
                          error={childConfigError?.sourceAccountType}
                          helperText={childConfigError?.sourceAccountType}
                          onBlur={() => {
                            sourceAccountType
                              ? setChildConfigError({
                                  ...childConfigError,
                                  sourceAccountType: false
                                })
                              : setChildConfigError({
                                  ...childConfigError,
                                  sourceAccountType: true
                                });
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    <Autocomplete
                      options={sourceAccountTypeChildLists || []}
                      value={sourceAccountTypeChildList}
                      size="small"
                      style={{ width: "300px" }}
                      getOptionLabel={(option) =>
                        `${option?.childAccountNamePrifixSubString || ""} - ${
                          option?.childAccountNameSufixSubString || ""
                        }`
                      }
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      onChange={(event, newValue) => {
                        setSourceAccountTypeChildList(newValue);
                      }}
                      variant="outlined"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Child Account Type"
                          size="small"
                          variant="outlined"
                          error={childConfigError?.sourceAccountTypeChildList}
                          helperText={
                            childConfigError?.sourceAccountTypeChildList
                          }
                          onBlur={() => {
                            sourceAccountTypeChildList
                              ? setChildConfigError({
                                  ...childConfigError,
                                  sourceAccountTypeChildList: false
                                })
                              : setChildConfigError({
                                  ...childConfigError,
                                  sourceAccountTypeChildList: true
                                });
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={12} xs={12}>
                    <IconButton
                      onClick={addSourceAccountChildHandle}
                      sx={{ background: "green", color: "#fff" }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Grid>

                  {/* <IconButton
                          onClick={arrayUpdate}
                          sx={{ background: "green", color: "#fff" }}
                        >
                          <UpdateIcon />
                        </IconButton> */}
                </Grid>
                <Grid container mt={2}>
                  <Grid item md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <StyledTableRow>
                            {[
                              { id: 1, name: "Account Type" },
                              { id: 2, name: "Account Name" },
                              { id: 3, name: "Action" }
                            ].map((val) => (
                              <StyledTableCell key={val.id}>
                                {val.name}
                              </StyledTableCell>
                            ))}
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {sourceAccountChildTableList?.map((val, index) => (
                            <StyledTableRow
                              key={val.id}
                              // style={{
                              //   pointerEvents: val?.isDisabled ? "none" : "all",
                              // }}
                            >
                              <StyledTableCell>
                                {val.type?.name || "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {val?.child?.childAccountNamePrifixSubString ||
                                  ""}
                                {" - "}{" "}
                                {val?.child?.childAccountNameSufixSubString ||
                                  ""}
                              </StyledTableCell>
                              <StyledTableCell>
                                <IconButton
                                  onClick={() =>
                                    deleteSourceAccountChildHandle(val)
                                  }
                                >
                                  <DeleteIcon sx={{ color: "#f44336" }} />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid items md={6}>
              <Box
                sx={{
                  background: "aliceblue",
                  padding: 2,
                  height: "100%",
                  borderRadius: 1,
                  marginTop: 1
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: 2, fontSize: "14px" }}
                >
                  Credit Account Type
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    <Autocomplete
                      options={targetAccountTypes || []}
                      value={targetAccountType}
                      size="small"
                      style={{ width: "300px" }}
                      getOptionLabel={(option) => `${option?.name}` || ""}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      onChange={(event, newValue) => {
                        setTargetAccountTypeChildList(null);
                        if (newValue) {
                          setTargetAccountType(newValue);
                          setTargetAccountTypeChildLists(
                            newValue?.childAccountTypeDtoList
                          );
                        } else {
                          setTargetAccountType(null);
                          setTargetAccountTypeChildLists([]);
                          setTargetAccountTypeChildList(null);
                        }
                      }}
                      variant="outlined"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Account Type"
                          size="small"
                          variant="outlined"
                          error={childConfigError?.targetAccountType}
                          helperText={childConfigError?.targetAccountType}
                          onBlur={() => {
                            targetAccountType
                              ? setChildConfigError({
                                  ...childConfigError,
                                  targetAccountType: false
                                })
                              : setChildConfigError({
                                  ...childConfigError,
                                  targetAccountType: true
                                });
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    <Autocomplete
                      options={targetAccountTypeChildLists || []}
                      value={targetAccountTypeChildList}
                      size="small"
                      style={{ width: "300px" }}
                      getOptionLabel={(option) =>
                        `${option?.childAccountNamePrifixSubString || ""} - ${
                          option?.childAccountNameSufixSubString || ""
                        }`
                      }
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      onChange={(event, newValue) => {
                        setTargetAccountTypeChildList(newValue);
                      }}
                      variant="outlined"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Child Account Type"
                          size="small"
                          variant="outlined"
                          error={childConfigError?.targetAccountTypeChildList}
                          helperText={
                            childConfigError?.targetAccountTypeChildList
                          }
                          onBlur={() => {
                            targetAccountTypeChildList
                              ? setChildConfigError({
                                  ...childConfigError,
                                  targetAccountTypeChildList: false
                                })
                              : setChildConfigError({
                                  ...childConfigError,
                                  targetAccountTypeChildList: true
                                });
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={12} xs={12}>
                    <IconButton
                      onClick={addTargetAccountChildHandle}
                      sx={{ background: "green", color: "#fff" }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  <Grid item md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <StyledTableRow>
                            {[
                              { id: 1, name: "Account Type" },
                              { id: 2, name: "Account Name" },
                              { id: 3, name: "Action" }
                            ].map((val) => (
                              <StyledTableCell key={val.id}>
                                {val.name}
                              </StyledTableCell>
                            ))}
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {targetAccountChildTableList?.map((val, index) => (
                            <StyledTableRow key={val.id}>
                              <StyledTableCell>
                                {val.type?.name || "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {val?.child?.childAccountNamePrifixSubString ||
                                  ""}
                                {" - "}{" "}
                                {val?.child?.childAccountNameSufixSubString ||
                                  ""}
                              </StyledTableCell>
                              <StyledTableCell>
                                <IconButton
                                  onClick={() =>
                                    deleteTargetAccountChildHandle(val)
                                  }
                                >
                                  <DeleteIcon sx={{ color: "#f44336" }} />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}

function EInvoiceDocTypes({
  invDocType,
  setInvDocType,
  customErrors,
  setCustomErrors
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  console.log(invDocType, "vahsbsh");

  return (
    <Card sx={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "4px 10px",
          backgroundColor: "#f0f7ff",
          alignItems: "center"
        }}
        onClick={handleExpandClick}
      >
        <Typography variant="body2" color="text.secondary" fontWeight="bold">
          E-Invoice Document Type*
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => setInvDocType(e.target.value)}
                value={invDocType}
              >
                <FormControlLabel
                  value="DBN"
                  control={<Radio />}
                  label="Debit Note"
                />
                <FormControlLabel
                  value="CRN"
                  control={<Radio />}
                  label="Credit Note"
                />{" "}
                <FormControlLabel
                  value="INV"
                  control={<Radio />}
                  label="Invoice"
                />
              </RadioGroup>
            </div>
          </div>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export {
  DetailsViewConfigurations,
  DueDataConfigurations,
  TaggedFunction,
  MandatoryCheck,
  ConfigurationCheckFields,
  ChildAccountConfiguration,
  EInvoiceDocTypes
};
