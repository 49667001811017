import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  alpha,
  styled
} from "@material-ui/core";
import { checkPermissionExist, searchableMenus } from "../utils";
import { useNavigate } from "react-router-dom";
import { SiSoundcharts } from "react-icons/si";
import { BiCustomize } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Modal } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PostAddIcon from "@mui/icons-material/PostAdd";
import moment from "moment";
import { Close, Delete, Remove } from "@mui/icons-material";
import {
  addNewSearchableKey,
  removeKey
} from "../features/actions/SearchableAction";
import { useDispatch } from "react-redux";
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";

function SearchHistoryModal({ data, open, setOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  return (
    <Box>
      <Grid container>
        <Grid item style={{ display: "inline-flex" }}>
          <div>
            {open && (
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                sx={{ cursor: "pointer" }}
                BackdropProps={{ style: { backgroundColor: "transparent" } }} // Set BackdropProps to an object with a transparent background
              >
                <Card
                  style={{
                    minWidth: "250px",
                    position: "absolute",
                    top: "71px",
                    right: "35%",
                    boxShadow: "rgb(188, 188, 188) 5px 5px 5px 0px",
                    overflow: "overlay",
                    borderRadius: "5px",
                    padding: "10px 5px"
                  }}
                >
                  <Typography style={{ marginLeft: 10 }}>
                    Recent Search
                  </Typography>
                  <Divider />
                  <ul style={{ listStyle: "none" }}>
                    {data && data.length > 0 ? (
                      data.map((val, index) => (
                        <li
                          style={{
                            margin: "10px 5px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "5px"
                          }}
                          className="history-list"
                          key={index}                          
                        >
                          <Typography color="secondary" variant="small1" onClick={()=>navigate(val.path)}>
                            {val?.name}
                          </Typography>
                          <Close
                            sx={{ fontSize: 15, cursor: "pointer" }}
                            onClick={() => {
                              dispatch(removeKey(val, index));
                            }}
                          />
                        </li>
                      ))
                    ) : (
                      <p style={{ margin: "10px 0px", textAlign: "center" }}>
                        No Recent Search
                      </p>
                    )}
                  </ul>
                </Card>
              </Modal>
            )}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SearchHistoryModal;
