import * as React from "react";
import { useNavigate } from "react-router-dom";
import { DataStatus } from "../CommonComps/DataStatus";
import { StyledTableCell, StyledTableRow, checkPermissionExist } from "../utils/index";
import { Editedtext, EditedtextCode } from "../CommonComps/TextTemplate";
import { IconButton, Table, TableBody, TableContainer, TableHead, Typography, Paper } from "@mui/material";
import moment from "moment";
import HistoryIcon from "@mui/icons-material/History";
import { useSelector } from "react-redux";
import { singleAccountUrl, singleTransactionGetUrl } from "../config";
import AmountInfo from "../CommonComps/AmountInfo";
const rightAlign = { textAlign: "right" };
const headers=[{id:1,name:"S.No"}, {id:2,name:"Name"},{id:3,name:"Amount"},{id:4,name:"Action"}]

function Row({ row, index }) {
  const endPoints = useSelector(val => val?.rootReducer?.permissionReducer?.data)
  const { name, code, openingBalance, openingBalanceTypeName, balance, balanceTypeName, createdOn, accountTypeName } = row
  const navigate = useNavigate();

  const handleEditCategory = (row) =>
    navigate("/addAccount", { state: row.id });

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell style={{ paddingLeft: 10 }}>
          <Typography variant="body2" style={{ paddingLeft: 10 }}>
            {index}{" "}
          </Typography>
        </StyledTableCell>

        <StyledTableCell sx={{
          cursor:
            checkPermissionExist(endPoints, `${singleAccountUrl}/{account-id}`) && "pointer"
        }}
          onClick={() => {
            checkPermissionExist(endPoints, `${singleAccountUrl}/{account-id}`) && handleEditCategory(row)
          }}
        >
          <EditedtextCode
            text={name}
            code={code}
            textColor="blue"
            codeColor="black"
          />
        </StyledTableCell>

        <StyledTableCell>
          <Editedtext text={accountTypeName} num="30" />{" "}
        </StyledTableCell>

        <StyledTableCell style={{ textAlign: "right" }}>

          <span
            style={{ color: openingBalanceTypeName === "Cr" ? "red" : "green" }}
          >
            {
              +parseInt(Math.abs(openingBalance))  ? 
              <AmountInfo amount={openingBalance} color={openingBalanceTypeName === "Cr" ? "red" : "green"} />
              :"0"
            }
            &nbsp; {openingBalanceTypeName}

          </span>
        </StyledTableCell>

        <StyledTableCell style={{ textAlign: "right" }}>
          <span style={{ color: balanceTypeName === "Cr" ? "red" : "green" }}>
            {
              +parseInt(Math.abs(balance)) &&
              <AmountInfo amount={balance} color={balanceTypeName === "Cr" ? "red" : "green"} />
            }
            &nbsp; {balanceTypeName}
          </span>
        </StyledTableCell>

        <StyledTableCell style={rightAlign}>
          {moment(createdOn).format("DD-MM-YYYY, hh:mm a")}
        </StyledTableCell>

        {/* <StyledTableCell style={rightAlign}>
          <IconButton>
            {kycStatusName === "COMPLETED" ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : kycStatusName === "PENDING" ? (
              <PendingIcon style={{ color: "#ff8d00" }} />
            ) : (
              <CancelIcon style={{ color: "red" }} />
            )}
          </IconButton>
          
        </StyledTableCell> */}

        <StyledTableCell style={{ textAlign: "right" }}>
          <IconButton
            style={{
              visibility: checkPermissionExist(endPoints, singleTransactionGetUrl) ? "visible" : "hidden"
            }}
            name={name}
            onClick={() => {
              checkPermissionExist(endPoints, singleTransactionGetUrl)
                &&
                navigate("/generalLedger", { state: row })
            }}
          >
            <HistoryIcon style={{ color: "green", visibility: checkPermissionExist(endPoints, singleTransactionGetUrl) ? "visible" : "hidden" }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function GstTable({
  gstData,
 
}) {
  

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ marginTop: 20, maxHeight: "calc(100vh - 300px)" }}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <StyledTableRow>
              {headers &&
                Array.isArray(headers) &&
                headers.length > 0 &&
                headers.map((val) => (
                  <StyledTableCell style={val.style} key={val.id}>
                    {val.name}
                  </StyledTableCell>
                ))}
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {gstData &&
              gstData.map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index + 1}
                 
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {gstData && gstData.length < 1 && <DataStatus />}

     
    </>
  );
}
