import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  Collapse,
  Box,
  Switch,
  TableRow,
  TableCell
} from "@mui/material";
import { DataStatus } from "../CommonComps/DataStatus";
import CommonPagination from "../CommonComps/CommonPagination";
import { headers, pageSizes } from "../Account/util";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  checkPermissionExist,
  StyledTableCell,
  StyledTableRow
} from "../utils/index";
import { provideRolePermissionUrl, updatePermissionUrl } from "../config";
import { alpha } from "@material-ui/core";
import { themecolor } from "../Navigation";

function Row({
  row,
  index,
  updatePermission,
  setUpdatePermission,
  handleActivation,
  showAlert,
  endPoints
}) {
  const [open, setOpen] = useState(false);

  const handleOpenOff = (row) => {
    if (Array.isArray(row?.siteList) && row.siteList.length > 0) {
      setOpen(!open);
    } else {
      showAlert("error", "Site-wise permission list is not available.");
    }
  };

  return (
    <>
      <StyledTableRow sx={{ height: "40px" }}>
        <StyledTableCell sx={{ fontSize: "0.875rem" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleOpenOff(row)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              {index + 1}
            </Typography>
          </div>
        </StyledTableCell>
        <StyledTableCell>{row?.userName || "-"}</StyledTableCell>
        <StyledTableCell>
          {checkPermissionExist(endPoints, updatePermissionUrl) ? (
            <Switch
              checked={!!row?.isActive}
              onChange={() => handleActivation(row)}
              inputProps={{ "aria-label": "controlled" }}
            />
          ) : (
            "-"
          )}
        </StyledTableCell>
      </StyledTableRow>
      {row?.siteList?.length > 0 && (
        <StyledTableRow sx={{ display: !open ? "none" : null }}>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={3}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 2,
                  backgroundColor: (theme) =>
                    alpha(theme.palette[themecolor]?.main || themecolor, 0.1)
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell
                        sx={{
                          width: "120px"
                        }}
                      >
                        S.No.
                      </TableCell> */}
                      <TableCell
                        sx={{
                          width: "425px"
                        }}
                      >
                        Site Name
                      </TableCell>
                      <TableCell>Role</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.siteList.map((site, i) => (
                      <TableRow key={site.id}>
                        {/* <TableCell>{i + 1}</TableCell> */}
                        <TableCell>{site.siteName || "-"}</TableCell>
                        <TableCell>
                          {site.roleList?.length
                            ? site.roleList
                                .map((role) => role.roleName)
                                .join(", ")
                            : "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      )}
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleActivation: PropTypes.func.isRequired
};

export default function PermissionTable({
  header = headers,
  data = [],
  pageNo,
  setPageNo,
  totalPages = 0,
  setPageSize,
  pageSize,
  updatePermission,
  setUpdatePermission,
  handleActivation,
  showAlert,
  endPoints
}) {
  const navigate = useNavigate();

  const onPageChange = (event, value) => setPageNo(value);

  return (
    <>
      <TableContainer
        component={Paper}
        style={{
          marginTop: 20,
          height: data.length > 0 ? "calc(100vh - 305px)" : "auto"
        }}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <StyledTableRow>
              {header.map((col) => (
                <StyledTableCell
                  key={col.id}
                  sx={{
                    width:
                      col.id === 1 ? "70px" : col.id === 3 ? "150px" : "auto",
                    textAlign: col.id === 1 ? "center" : "left"
                  }}
                >
                  {col.name}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data?.map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  updatePermission={updatePermission}
                  setUpdatePermission={setUpdatePermission}
                  handleActivation={handleActivation}
                  showAlert={showAlert}
                  endPoints={endPoints}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length === 0 && <DataStatus />}
      {data.length > 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <CommonPagination
            pageSize={pageSize}
            pageSizes={pageSizes}
            totalPages={totalPages}
            pageNo={pageNo}
            setPageNo={setPageNo}
            setPageSize={setPageSize}
            onPageChange={onPageChange}
            style={{ minWidth: 150, margin: "0 20px" }}
            label="R&P Per Page"
          />
        </div>
      )}
    </>
  );
}

PermissionTable.propTypes = {
  header: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  pageNo: PropTypes.number.isRequired,
  setPageNo: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleActivation: PropTypes.func.isRequired
};
