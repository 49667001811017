import React from 'react';
import { Grid } from '@mui/material';
import { useDrag } from 'react-dnd';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ListIcon from '@mui/icons-material/List';
import { GiChecklist } from 'react-icons/gi';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { themecolor } from '../Navigation';
import { v4 as uuidv4 } from 'uuid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const fieldTypes = [
    {
        name: 'TextField',
        icon: <TextFieldsIcon style={{ color: `${themecolor}` }} />,
        type: 'TextField',
        defaultValue: 'Text',
        label: "TextField"
    },

    {
        name: 'Textarea',
        type: 'Textarea',
        defaultValue: 'Text',
        icon: <TextFieldsIcon style={{ color: `${themecolor}` }} />,
        label: "Textarea"
    },
    {
        name: 'Checkbox',
        type: 'Checkbox',
        defaultValue: false,
        icon: <CheckBoxIcon style={{ color: `${themecolor}` }} />,
        label: "Checkbox"
    },
    {
        name: 'SingleSelect',
        type: 'SingleSelect',
        defaultValue: '',
        options: ['Option 1', 'Option 2', 'Option 3'],
        icon: <GiChecklist style={{ color: `${themecolor}` }} />,
        label: "Single-Select"

    },
    {
        name: 'MultiSelect',
        type: 'MultiSelect',
        defaultValue: [],
        options: ['Option 1', 'Option 2', 'Option 3'],
        icon: <ListIcon style={{ color: `${themecolor}` }} />,
        label: "Multi-select"
    },
    {
        name: 'File',
        type: 'File',
        icon: <AttachmentIcon style={{ color: `${themecolor}` }} />,
        label: "File"
    },
    {
        name: 'Date',
        type: 'Date',
        icon: <CalendarMonthIcon style={{ color: `${themecolor}` }} />,
        label: "Date"
    },
    {
        name: 'Table',
        type: 'Table',
        icon: <BackupTableIcon style={{ color: `${themecolor}` }} />,
        label: "Table"
    },
];

const LeftSide = () => {
    return (
        <>
            {fieldTypes.map((fieldType) => (
                <Field key={fieldType.type} fieldType={fieldType} />
            ))}
        </>
    );
};

const Field = ({ fieldType }) => {

    // Function to generate a UUID
    const generateUUID = () => {
        return uuidv4();
    };

    const [, ref] = useDrag({
        type: 'FIELD',
        item: {
            name: fieldType?.name,
            type: fieldType?.type,
            defaultValue: fieldType?.defaultValue,
            label: fieldType?.label,
            uid: generateUUID(),
            options: fieldType?.options || [],
            value: null,
            isRequired:false,
        },
    });

    return (
        <Grid container flexDirection="row">
            <Grid item
                container
                xs={6}
                ref={ref}
                style={{
                    padding: '8px',
                    border: '1px solid blue',
                    marginBottom: '4px',
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: 'pointer',
                }}
            >
                {fieldType.name}

                {fieldType?.icon}

            </Grid>
        </Grid>
    );
};

export default LeftSide