import React from "react";
import { IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  closeCalculator,
  openCalculator,
} from "../features/actions/calculatorAction";
import Calculator from "../CommonComps/Calculator";
const img = require("../images/calculator.png");

export var themecolor;

const Footer = () => {
  const reduxState = useSelector((state) => state?.rootReducer);
  let companyDto = reduxState?.companyReducer?.data || null;
  themecolor = companyDto?.colourTheme[0]?.colourCode || "#3f79b5";
  const dispatch = useDispatch();

  const useStyled = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: themecolor ? themecolor : "rgb(63, 121, 181) !important",
    bottom: 0,
    width: "100%",
    height: "25px",
    position: "fixed",
    color: "white",
    zIndex: "2000",
  };

  const calculatorOpenHandle = () => {
    dispatch(openCalculator());
  };

  return (
    <>
      <Calculator />
      <div
        style={{
          position: "fixed",
          right: "-14px",
          bottom: "17px",
          zIndex: 999,
        }}
      >
        <IconButton onClick={calculatorOpenHandle}>
          <img src={img} alt="calc" style={{ height: "40px", width: "40px" }} />
        </IconButton>
      </div>
      <div style={useStyled}>
        <Typography
          variant="h6"
          component="h2"
          sx={{ fontWeight: "lighter", fontSize: "11px !important" }}
        >
          CopyRight @NYGGS Accounts 2025.
        </Typography>
      </div>
    </>
  );
};

export default Footer;
