import { amountLength } from "../utils";

export const validationFunction = (
  code,
  accountGroup,
  accountType,
  accountName,
  openingBalance,
  maxTransactionAmountLimit,
  minTransactionAmountLimit,
  customFields,
  legalDocRequired,
  gstNo,
  panNo,
  updating,
  nextState,
  parentSite
) => {
  const gstPattern = /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1})$/;
  const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const errors = {};

  if (!code) errors.code = true;
  if (!accountName) errors.accountName = true;
  if (!accountType) errors.accountType = true;
  if (accountGroup.length < 1) errors.accountGroup = true;

  if (maxTransactionAmountLimit?.length > amountLength)
    errors.maxTransactionAmountLimit = true;
  if (minTransactionAmountLimit?.length > amountLength)
    errors.minTransactionAmountLimit = true;
  if (openingBalance?.length > amountLength)
    errors.openingBalance = "Max 15 characters allowed";

  if (!parentSite) errors.parentSite = true;

  if (legalDocRequired) {
    if (gstNo && !gstPattern.test(gstNo))
      errors.gstNo = "Provide valid GST Number or remove it";
    if (!panNo || !panPattern.test(panNo))
      errors.panNo = "Provide valid PAN Number";
  }

  if (customFields?.length > 0) {
    customFields.forEach((val) => {
      const {
        label,
        isMandatory,
        defaultValue,
        minLength,
        maxLength,
        validationRegex
      } = val;

      if (isMandatory) {
        if (!defaultValue) {
          errors[label] = "Required";
        } else if (minLength && defaultValue.length < minLength) {
          errors[label] = `Provide min ${minLength} characters`;
        } else if (maxLength && defaultValue.length > maxLength) {
          errors[label] = `Provide max ${maxLength} characters`;
        } else if (validationRegex && !defaultValue.match(validationRegex)) {
          errors[label] = `Value not matching with regex ${validationRegex}`;
        }
      }
    });
  }

  // if (updating && !nextState) {
  //     errors.nextState = true
  // }

  return errors;
};

export const validationFunctionForKYC = (data) => {
  const errors = {};

  if (data && Array.isArray(data) && data.length > 0) {
    for (const val of data) {
      const {
        label,
        isMandatory,
        defaultValue,
        minLength,
        maxLength,
        validationRegex
      } = val;

      if (isMandatory) {
        if (!defaultValue) {
          errors[label] = "Required";
        } else if (minLength && defaultValue.length < minLength) {
          errors[label] = `Provide min ${minLength} characters`;
        } else if (maxLength && defaultValue.length > maxLength) {
          errors[label] = `Provide max ${maxLength} characters`;
        } else if (validationRegex && !defaultValue.match(validationRegex)) {
          errors[label] = `Value not matching with regex ${validationRegex}`;
        }
      }
    }
  }

  return errors;
};

export const headers = [
  { id: 1, name: "S.No.", style: { textAlign: "left" } },
  { id: 2, name: "Name", style: { textAlign: "left" } },
  { id: 3, name: "Account Number", style: { textAlign: "left" } },
  { id: 4, name: "Code", style: { textAlign: "left" } },
  { id: 5, name: "Type", style: { textAlign: "left" } },
  { id: 6, name: "Status" },
  { id: 7, name: "Opening Balance", style: { textAlign: "right" } },
  { id: 8, name: "Closing Balance", style: { textAlign: "right" } },
  { id: 9, name: "Due Amount", style: { textAlign: "right" } },
  { id: 10, name: "Over Due Amount", style: { textAlign: "right" } },
  { id: 11, name: "Created On", style: { textAlign: "right" } },
  // { id: 12, name: "KYC Status", style: { textAlign: "left" } },
  { id: 12, name: "Action", style: { textAlign: "right" } }
];

// export const pageSizes = [15, 20, 50, 100, 500];
export const pageSizes = [15, 20, 50, 100];

export const successCorrectionHeader = [
  { id: 1, name: "S.No.", style: { textAlign: "left" } },
  { id: 2, name: "Name", style: { textAlign: "center" } },
  { id: 3, name: "Account Code", style: { textAlign: "center" } },
  { id: 4, name: "Pan No.", style: { textAlign: "center" } },
  { id: 5, name: "Reason", style: { textAlign: "center" } },
  { id: 6, name: "Match Accounts", style: { textAlign: "center" } },
  { id: 7, name: "Edit", style: { textAlign: "center" } }
];

export const partialSuccessCorrectionHeader = [
  { id: 1, name: "S.No.", style: { textAlign: "left" } },
  { id: 2, name: "Name", style: { textAlign: "center" } },
  { id: 3, name: "Account Code", style: { textAlign: "center" } },
  { id: 4, name: "Pan No.", style: { textAlign: "center" } },
  { id: 5, name: "Reason", style: { textAlign: "center" } },
  { id: 6, name: "Edit", style: { textAlign: "center" } }
];

export const unsuccessCorrectionHeader = [
  { id: 1, name: "S.No.", alias: "", style: { textAlign: "left" } },
  { id: 2, name: "Name", alias: "name", style: { textAlign: "center" } },
  {
    id: 3,
    name: "Account Code",
    alias: "code",
    style: { textAlign: "center" }
  },
  {
    id: 4,
    name: "Pan No.",
    alias: "panCard",
    style: { textAlign: "center" }
  },
  { id: 5, name: "Reason", alias: "reason", style: { textAlign: "center" } }
];
