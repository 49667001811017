import React, { useEffect, useRef } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Card,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  alpha,
  styled
} from "@material-ui/core";
import { checkIsHeadOffice, checkPermissionExist } from "../utils";
import { useNavigate } from "react-router-dom";
import { SiSoundcharts } from "react-icons/si";
import { BiCustomize } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Modal } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PostAddIcon from "@mui/icons-material/PostAdd";
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";

function SearchHistoryContainer({ MenuPathsDialog, open, setOpen }) {

  const navigate = useNavigate();

  const reduxState = useSelector((state) => state?.rootReducer);
  let endPoints = reduxState?.permissionReducer?.data || [];
  const siteList = reduxState?.sitesReducer?.data


  const filteredValue = MenuPathsDialog.reduce((obj, ele) => {
    if (!obj[ele.type]) {
      obj[ele.type] = [];
    }
    obj[ele.type].push(ele);
    return obj;
  }, {});

  const [headingPops, setHeadingPops] = React.useState(
    Object.keys(filteredValue)
  );

  const handleOpenUserMenu = (event) => {
    setOpen(!open);
  };

  const handleCloseUserMenu = () => {
    setOpen(false);
  };

  const handlePathLocation = (data) => {
    if (data) {
      navigate(data.path);
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const determineDisplay = (val) => {
    if (val.name === "Account" || val.name === "Account Group") {
      return checkIsHeadOffice(siteList, localStorage.getItem("siteId")) && checkPermissionExist(endPoints, val?.api)
        ? "inline-flex"
        : "none";
    } else {
      return checkPermissionExist(endPoints, val?.api) ? "inline-flex" : "none";
    }
  };


  return (
    <Box >
      <Grid container>
        <Grid item style={{ display: "inline-flex" }}>

          <div >
            <Tooltip title="Quick Create">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {!open ? (
                  <AddCircleIcon
                    sx={{
                      color: "#fff",
                      width: "30px",
                      height: "30px",
                      margin: "3px",

                    }}
                  />
                ) : (
                  <CancelIcon
                    sx={{
                      color: "#fff",
                      width: "30px",
                      height: "30px",
                      marginTop: "3px",

                    }}
                  />
                )}
              </IconButton>
            </Tooltip>
            {/* <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              // anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={handleCloseUserMenu}
            >
              {MenuPathsDialog.map((ele, index) => (
                <MenuItem key={ele} onClick={handleCloseUserMenu}>
                  <Typography
                    textAlign="center"
                    style={{ display: "flex", position: "relative" }}
                    onClick={(e) => handlePathLocation(ele)}
                  >
                    {ele.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu> */}
            {open && (
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                sx={{ cursor: "pointer" }}
                BackdropProps={{ style: { backgroundColor: 'transparent' } }} // Set BackdropProps to an object with a transparent background
              >
                <Card

                  style={{
                    // width: "900px",
                    position: "absolute",
                    top: "60px",
                    right: "8%",
                    boxShadow: "rgb(188 188 188) 5px 5px 5px 0px",
                    overflow: "overlay",
                    borderRadius: "5px"
                  }}
                >
                  {headingPops && headingPops.length > 0 && (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      {headingPops.map((heading, index) => (
                        <div key={index} style={{ margin: "30px 0px 30px 13px", width: "220px" }}>
                          <div style={{ display: "flex", alignItems: "center" }}>

                            {
                              heading === "Account" ?
                                <SiSoundcharts size={20} style={{ color: "#5f5f5f" }} />
                                : heading === "Reports" ?
                                  <PostAddIcon style={{ color: "#5f5f5f" }} /> :
                                  heading === "Template" ?
                                    <BiCustomize size={20} style={{ color: "#5f5f5f" }} /> : ""

                            }
                            <Typography variant="body1" style={{ marginLeft: "10px" }}>

                              {heading}

                            </Typography>
                          </div>
                          <div>
                            {MenuPathsDialog &&
                              MenuPathsDialog.length > 0 &&
                              MenuPathsDialog.map((val, innerIndex) =>
                                heading === val.type ? (
                                  <Box key={innerIndex} style={{ margin: "10px 0px", }}

                                  >
                                    <Typography
                                      variant="small1"

                                      onClick={(e) => handlePathLocation(val)}
                                      style={{
                                        cursor: "pointer",
                                        color: "#666666",

                                        display: determineDisplay(val)
                                      }}
                                      onMouseEnter={(e) => {
                                        e.target.style.color = "green";
                                      }}
                                      onMouseLeave={(e) => {
                                        e.target.style.color = "#666666";
                                      }}
                                    >
                                      {"+ "}
                                      {val.name}
                                    </Typography>
                                  </Box>
                                ) : null
                              )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Card>
              </Modal>
            )}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SearchHistoryContainer;
