const initialState = {
  data: null,
  response: null,
  message: null,
};

export function permissionReducer(state = initialState, action) {
  const { type, data } = action;

  switch (type) {
    case "PERMISSION_FETCH_SUCCESS":
      return {
        ...state,
        status: 200,
        data: data,
        message: "Success",
      };
    case "PERMISSION_FETCH_FAILURE": {
      return {
        ...state,
        status: 400,
        data: [],
        message: "Something Went Wrong",
      };
    }

    default:
      return state;
  }
}
