import React, { useEffect } from "react";
import AppRouter from "./components/Routes/AppRouter";
import { withStyles } from "@material-ui/core/styles";
// import LoadingComponent from "./components/Loader";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },

  paper: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),

    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    width: "100%",
  },
});

function App({ classes }) {
  return (
    <>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppRouter />
        </div>
      </div>
      {/* <LoadingComponent/> */}
    </>
  );
}

export default withStyles(styles)(App);
