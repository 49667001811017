import React from "react";
import { Grid, Button, Typography, Divider, DialogContent, Dialog, DialogActions, DialogTitle } from "@material-ui/core";



const ErrorModal = ({ open, closeDialogBox, errors, continueProcess }) => {

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth maxWidth="sm">

            <DialogTitle id="alert-dialog-title" style={{ display: "flex", color: "white", background: "#21263c", justifyContent: "space-between" }}>
                <Typography variant="h5">Errors </Typography>
              
            </DialogTitle>

            <DialogContent>
                <Grid container alignItems="center">
                    {errors && errors.length > 0 && errors.map((val, index) =>
                        val && <Grid key={index} item xs={12} style={{ margin: "10px 0" }}>
                            <span> {index + 1}. {val}</span>
                        </Grid>
                    )}
                </Grid>
                <Divider style={{ marginTop: "2%" }} />
            </DialogContent>

            <DialogActions>
               {
                continueProcess &&  <Button color="primary" variant="contained" onClick={() => continueProcess()} autoFocus>
                Continue
            </Button>
               }
                <Button color="secondary" variant="contained" onClick={() => closeDialogBox()} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorModal



