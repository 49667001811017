import * as React from "react";
import { headers } from "./util";
import { pageSizes } from "../Account/util";
import { useNavigate } from "react-router-dom";
import { DataStatus } from "../CommonComps/DataStatus";
import { Editedtext } from "../CommonComps/TextTemplate";
import { StyledTableCell, StyledTableRow } from "./util";
import { IconButton, Table, TableBody, TableContainer, TableHead, Typography, Paper } from "@mui/material";
import CommonPagination from "../CommonComps/CommonPagination";
import EditIcon from "@mui/icons-material/Edit";
import { checkPermissionExist } from "../utils";
import { accountTypesUpdateUrl } from "../config";
import { useSelector } from "react-redux";

function Row({ row, index }) {
  const endPoints = useSelector(val => val?.rootReducer?.permissionReducer?.data)

  const navigate = useNavigate();

  const { plateFormName, isActive, noOfApis } = row


  const handleEditCategory = (row) => {
    navigate("/config-apis", {
      state: row
    });
  };


  return (
    <React.Fragment>
      <StyledTableRow>

        <StyledTableCell style={{ paddingLeft: 10 }}>
          <Typography variant="body2" style={{ paddingLeft: 10 }}>
            {index}
          </Typography>
        </StyledTableCell>

        <StyledTableCell>

          <Typography>
          {plateFormName || "-"}
          </Typography>

        </StyledTableCell>
        <StyledTableCell>
        <Typography style={{ color: isActive ? "green" : "red" }}>
            {isActive ? "Active" : "Inactive"}
          </Typography>
          </StyledTableCell>
        <StyledTableCell>
          <Typography>
            Configuration
          </Typography>

        </StyledTableCell>
        <StyledTableCell>
          <Typography>
          {noOfApis || 0}
          </Typography>
        </StyledTableCell>
        

        <StyledTableCell>
          <IconButton onClick={() => handleEditCategory(row)}
          // style={{
          //   visibility :checkPermissionExist(endPoints,accountTypesUpdateUrl) ? "visible" :"hidden"
          // }}
          >
            <EditIcon style={{ color: "green", }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>


    </React.Fragment>
  );
}

export default function IntegrationTable({ data, pageNo, setPageNo, totalPages, setPageSize, pageSize }) {


  // const onPageChange = (event, value) => setPageNo(value);

  return (
    <>
      <TableContainer component={Paper} style={{ marginTop: 20,height: data && data.length > 0 && "calc(-208px + 100vh)" }}>
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <StyledTableRow>
              {headers && Array.isArray(headers) && headers.map(val =>
                <StyledTableCell key={val.id}>{val.name}</StyledTableCell>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.map((row, index) => {
                return (
                  <Row key={row.id} row={row} index={(pageNo - 1) * pageSize + index + 1}

                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {data && data.length < 1 && <DataStatus />}
      {/* {
        data && Array.isArray(data) && data.length > 0 &&
        <CommonPagination
          pageSize={pageSize}
          pageSizes={pageSizes}
          totalPages={totalPages}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setPageSize={setPageSize}
          onPageChange={onPageChange}
          style={{ minWidth: 180, margin: "0  20px" }}
          label="API Third Party Integration Per Page"
        />
      } */}
    </>
  );
}
