
import React from 'react'
import { Alert, IconButton, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
export const AlertBar = ({ visible, closeAlertBar, alertMessage, alertType }) => {
    return (
        <Snackbar
            open={visible}
            autoHideDuration={3000}
            onClose={closeAlertBar}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
            <Alert variant="filled" severity={alertType}

                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={closeAlertBar}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {alertMessage}
            </Alert>
        </Snackbar>
    )
}


