import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  IconButton,
  Paper,
  Container,
  Grid,
  Typography,
  InputAdornment,
  Autocomplete,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Card,
  Drawer,
  Stack,
  Badge,
  Tooltip
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { userService } from "../userService";
import BalanceSheetTable2 from "./BalanceSheetTable";
import { AlertBar } from "../Alert/AlertBar";
import LoadingComponent from "../Loader";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AccessibleIcon from "@mui/icons-material/Accessible";
import DateFnsUtils from "@date-io/date-fns";
import jsPDF from "jspdf";
import { AiFillFileExcel, AiFillFilePdf } from "react-icons/ai";
import TuneIcon from "@mui/icons-material/Tune";
import "jspdf-autotable";
import { styles } from "../Theme/Theme";
import config, {
  horizontalVerticalEndpoint,
  plUrl,
  singleAccountUrl
} from "../config";
import { checkPathExist, checkPermissionExist } from "../utils";
import AmountInfo from "../CommonComps/AmountInfo";
import { useNavigate } from "react-router-dom";
import BalanceSheetSiteWiseTable2 from "./BalanceSheetSiteWiseTable";
import { getEncryptPayload } from "../Network_Utility";
import SideBar from "../CommonComps/SideBar";
import { renderChip } from "../CommonComps/ChipComponent";
import AutoDialogBox from "../CommonComps/AutoDialogBox";
import ReportsPDFTemplate from "../CommonComps/ReportsPDFTemplate";
import { userConfig } from "../config/UserConfig";
// import PLTable from "./PLTable";
const transaction = require("../images/Profit loss.png");

const Index = ({ classes }) => {
  const navigate = useNavigate();
  const reduxState = useSelector((val) => val?.rootReducer);
  const storedSite = reduxState?.siteReducer?.site;
  const endPoints = reduxState.permissionReducer?.data;
  const companyDto = reduxState.companyReducer?.data;
  const siteList = [
    { id: 909, name: "Select All", alias: "all" },
    { id: 910, name: "Remove All", alias: "remove" },
    ...reduxState?.sitesReducer?.data
  ];
  const [loading, setLoading] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [balanceSheetData, setBalanceSheetData] = useState({});
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [netAmountArray, setNetAmountArray] = useState([]);
  const [site, setSite] = useState(null);

  const [showFilteredValues, setShowFilteredValues] = useState(false);
  const [getSiteWiseData, setGetSiteWiseData] = useState(false);
  const [getApprovedBal, setGetApprovedBal] = useState(false);

  const [selectedSites, setSelectedSites] = useState(siteList && []);

  // const [yearOptions, setYearOptions] = useState([
  //   { name: "Custom", id: 0 },
  //   { name: "Quarterly", id: 1 },
  //   { name: "Half-Yearly", id: 2 },
  //   { name: "Yearly", id: 3 },
  // ]);

  // ===========Alerts===================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: ""
  });
  const [selectedmonth, setselectedmonth] = useState(null);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [vertHoriOpen, setVertHoriOpen] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const monthsNameArray = [
    { id: 10, name: "January", type: "january" },
    { id: 11, name: "February", type: "february" },
    { id: 12, name: "March", type: "march" },
    { id: 1, name: "April", type: "april" },
    { id: 2, name: "May", type: "may" },
    { id: 3, name: "June", type: "june" },
    { id: 4, name: "July", type: "july" },
    { id: 5, name: "August", type: "august" },
    { id: 6, name: "September", type: "september" },
    { id: 7, name: "October", type: "october" },
    { id: 8, name: "November", type: "november" },
    { id: 9, name: "December", type: "december" }
  ];
  //==================Pagination====================//
  const [pageNo, setPageNo] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const [totalPages, setTotalPages] = React.useState(1);

  //=================Select Year==================
  const [selectedYear, setSelectedYear] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [openReportsPDFTemplate, setOpenReportsPDFTemplate] =
    React.useState(false);

  const [isBalanceWiseData, setIsBalanceWiseData] = useState(true);

  let financialYearMonth = reduxState?.fymReducer?.data || [];

  const [showOpeningBalance, setShowOpeningBalance] = useState(false);
  const [siteName, setSiteName] = useState([]);

  // =======================Date====================

  // Function to close alert bar
  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });

  // Function to show success alert
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Data fetched succesfully",
      alertType: "success"
    });
    setLoading(false);
  };

  // Function to show error
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Something went wrong",
      alertType: "error"
    });
    setLoading(false);
  };

  const handleSubmitFilterValue = () => {
    const siteId = localStorage.getItem("siteId");
    setPageNo(1);
    setState({ ...state, right: false });
    setShowFilteredValues(true);

    if (getSiteWiseData) {
      getBalanceSheetData(true);
    } else {
      getBalanceSheetData(false);
    }
  };

  // console.log(storedSite, "bshsbd");
  // Function to handle  to date
  const handleToDate = (value) => {
    const fromDateCurrent = moment(fromDate);
    const toDateCurrent = moment(value);
    const result = toDateCurrent.diff(fromDateCurrent, "days");
    const alertMessage = result < 0 ? "To Date should be after From Date" : "";

    setAlerts({
      visible: result < 0,
      alertMessage,
      alertType: "error"
    });

    if (result >= 0) {
      setToDate(value);
    }
  };

  // Function to handle from date
  const handleFromDate = (value) => {
    if (toDate != null) {
      const toDateCurrent = moment(toDate);
      const fromDateCurrent = moment(value);
      const result = toDateCurrent.diff(fromDateCurrent, "days");
      const alertMessage =
        result < 0 ? "From Date should be before To Date" : "";

      setAlerts({
        visible: result < 0,
        alertMessage,
        alertType: "error"
      });

      if (result >= 0) {
        setFromDate(value);
      }
    } else {
      setFromDate(value);
    }
  };

  // Function to get transaction list
  const getBalanceSheetData = async (allSiteData) => {
    const pathExist = checkPathExist(endPoints, window.location.pathname);
    if (!pathExist) return;

    const filteredData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));
    const currentLocation = window.location.pathname;
    const findCurrentFilter = filteredData?.find(
      (ele) => ele.pathname === currentLocation
    );
    const filterValues = findCurrentFilter?.value;

    const fromDate =
      filterValues?.find((item) => item.label === "From Date")?.value || null;

    const toDate =
      filterValues?.find((item) => item.label === "To Date")?.value || null;

    const selectedmonth =
      filterValues?.find((item) => item.label === "Month")?.value || null;

    try {
      setLoading(true);
      let payload = {
        reportId: 2,
        siteId: allSiteData ? null : localStorage.getItem("siteId"),
        fromDate: fromDate,
        toDate: toDate,
        monthId: selectedmonth?.monthValue
      };
      const encryptedPayload = getEncryptPayload(payload, true);
      let apiEndpoint = config.baseUrl + config.getReports;
      const res = await userService.postData(
        apiEndpoint,
        encryptedPayload,
        null,
        true
      );

      // const res = await userService.getData(`${plUrl}${siteId}`);
      if (res && res.data && res.data.data && res.data.status === 200) {
        const { data } = res.data;

        if (data && Object.keys(data).length > 0) {
          // getApprovedBal ? setNetAmount(data?.netApprovedBalance) : setNetAmount(data?.netBalance);

          // setTotalPages(Math.ceil(totalLength / pageSize));
          successAlert(res?.data?.message);

          setBalanceSheetData(data);
        } else {
          setBalanceSheetData([]);
          setTotalPages(1);
          successAlert("No records available");
        }
      } else {
        errorAlert(res?.data?.message);
        setBalanceSheetData([]);
      }
    } catch (error) {
      errorAlert("Unable to fetch data at this moment");
      setBalanceSheetData([]);
    } finally {
      allSiteData ? setGetSiteWiseData(true) : setGetSiteWiseData(false);
    }
  };

  useEffect(() => {
    !getSiteWiseData && getBalanceSheetData();
    // eslint-disable-next-line
  }, [storedSite]);

  useEffect(() => {
    const pathExist = checkPathExist(endPoints, window.location.pathname);
    if (!pathExist) navigate("/error");
  }, [endPoints]);

  useEffect(() => {
    if (balanceSheetData && Object.keys(balanceSheetData).length > 0) {
      getApprovedBal
        ? setNetAmount(balanceSheetData?.netApprovedBalance)
        : setNetAmount(balanceSheetData?.netBalance);
    }
  }, [getApprovedBal, balanceSheetData]);

  useEffect(() => {
    if (
      balanceSheetData &&
      Object.keys(balanceSheetData).length > 0 &&
      getSiteWiseData
    ) {
      const inputObject = getApprovedBal
        ? balanceSheetData?.siteApprovedNetBalanceMap
        : balanceSheetData?.siteNetBalanceMap;

      if (inputObject && typeof inputObject === "object") {
        const outputArray = Object.entries(inputObject).map(
          ([siteId, balance]) => ({
            siteId,
            balance
          })
        );

        setNetAmountArray(outputArray);
      } else {
        console.error("Invalid inputObject. It should be an object.");
      }
    } else {
      console.error(
        "Invalid or missing data: balanceSheetData or getSiteWiseData"
      );
    }
  }, [getApprovedBal, balanceSheetData, getSiteWiseData]);

  const MySwitch = ({ checked, onChange, label }) => (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} />}
      label={label}
    />
  );

  const renderAutocomplete = (
    options,
    label,
    value,
    onChange,
    multiple = false,
    limitTags = 1,
    width
  ) => (
    <Grid item>
      {/* <Autocomplete
        options={options || []}
        size="small"
        margin="dense"
        style={{ minWidth: width, maxWidth: 450 }}
        value={value[0]}
        getOptionLabel={(option) => (option ? option.name || option.name : "")}
        getOptionSelected={(option, value) => option.name === value.name}
        // onChange={(event, newValue) => onChange(newValue)}
        multiple={false}
        filterSelectedOptions
        limitTags={limitTags}
        variant="outlined"
        // PopperComponent={CustomPaper}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            onClick={handleClickOpen}
          />
        )}
      /> */}
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        size="small"
        margin="dense"
        style={{ minWidth: width, maxWidth: 250 }}
        onChange={() => handleClickOpen(label)}
        value={
          value && value.length > 0
            ? value[0]?.name +
              (value.length > 1 ? " +" + (value.length - 1) : "")
            : ""
        }
        onClick={() => handleClickOpen(label)}
      />
    </Grid>
  );

  const navigateToLedger = async (rowData) => {
    const { accountId, accountCode, accountGroupCurrentBalance, name } =
      rowData;

    navigate("/generalLedger", {
      state: {
        id: accountId,
        name: name,
        code: accountCode,
        balance: accountGroupCurrentBalance,
        balanceType: accountGroupCurrentBalance < 0 ? "DEBIT" : "CREDIT",
        backPath: "balanceSheet"
      }
    });
  };

  const list = (anchor) => (
    <SideBar
      anchor={anchor}
      state={state}
      setState={setState}
      toDate={toDate}
      fromDate={fromDate}
      selectedmonth={selectedmonth}
      toggleDrawer={toggleDrawer}
      filteredValues={filteredValues}
      handleSubmitFilterValue={handleSubmitFilterValue}
      handleResetFilter={handleResetFilter}
    />
  );

  const filteredValues = [
    {
      typeId: 3,
      label: "From Date",
      value: fromDate,
      style: { width: 200, margin: "0 15px" },
      function: (value) => handleFromDate(value),
      isDisabled: selectedmonth?.id ? true : false
    },
    {
      typeId: 3,
      label: "To Date",
      value: toDate,
      style: { width: 200, margin: "0 15px" },
      function: (value) => handleToDate(value),
      isDisabled: selectedmonth?.id ? true : false
    },

    {
      typeId: 2,
      label: "Month",
      options: financialYearMonth || [],
      value: selectedmonth,
      style: { width: 200, margin: "0 15px" },
      function: (event, newValue) => {
        setselectedmonth(newValue);
        if (newValue) {
          setToDate(null);
          setFromDate(null);
        }
      },
      className: null,
      type: "name"
    }
  ];

  const handleCloseReportsPDFTemplate = () => {
    setOpenReportsPDFTemplate(false);
  };

  const handleClickOpen = (event) => {
    setOpen(true);
  };
  const handleKeyDown = (e) => {
    if (
      e.altKey &&
      (e.key === "w" || e.key === "W") &&
      !e.ctrlKey &&
      !e.shiftKey
    ) {
      getBalanceSheetData(!getSiteWiseData);

      if (!getSiteWiseData) {
        const filteredSites =
          siteList &&
          siteList.filter((val) => val.alias != "all" && val.alias != "remove");
        setSelectedSites(filteredSites);
      }
    }

    if (
      e.altKey &&
      e.shiftKey &&
      (e.key === "r" || e.key === "R") &&
      !e.ctrlKey
    ) {
      setState({ ...state, right: true });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const siteNameHandleDelete = (ele) => {
    userConfig.hideColumn(window.location.pathname, "siteName", ele.id);
    const getHeaderConfig = userConfig.getHeader(
      window.location.pathname,
      "siteName"
    );
    setSiteName(getHeaderConfig);
  };
  const siteNameColumnHandle = (val) => {
    if (val !== null) {
      if (val?.alias === "all") {
        userConfig.showAllColumn(window.location.pathname, "siteName");
        setSiteName(userConfig.getHeader(window.location.pathname, "siteName"));
        return;
      }
      if (val?.alias === "remove") {
        userConfig.hideAllColumn(window.location.pathname, "siteName");
        setSiteName(userConfig.getHeader(window.location.pathname, "siteName"));
        return;
      }
      if (val?.id) {
        userConfig.showColumn(window.location.pathname, "siteName", val?.id);
        setSiteName(userConfig.getHeader(window.location.pathname, "siteName"));
      }
    }
  };
  useEffect(() => {
    const currentPage = window.location.pathname;
    const getSiteName = userConfig.getHeader(currentPage, "siteName");
    setSiteName(getSiteName);
  }, []);

  const filteredSite = siteName?.filter((ele) => ele.isShow);

  const handleResetFilter = () => {
    const filterValuesData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));
    const currentLocation = window.location.pathname;
    if (filterValuesData) {
      const updatedFilterData = filterValuesData?.filter(
        (ele) => ele?.pathname !== currentLocation
      );
      localStorage.setItem("filter", JSON.stringify(updatedFilterData));
      setFromDate(null);
      setToDate(null);
      setselectedmonth(null);
      setState({ ...state, right: false });

      getBalanceSheetData();
    }
  };

  useEffect(() => {
    const filteredData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));
    const currentLocation = window.location.pathname;
    if (filteredData) {
      const findCurrentFilter = filteredData.find(
        (ele) => ele.pathname === currentLocation
      );

      if (findCurrentFilter) {
        const filterValues = findCurrentFilter.value;
        setFromDate(
          filterValues.find((item) => item.label === "From Date")?.value || null
        );
        setToDate(
          filterValues.find((item) => item.label === "To Date")?.value || null
        );
        setselectedmonth(
          filterValues.find((item) => item.label === "Month")?.value || null
        );
      }
    } else {
      setFromDate(null);
      setToDate(null);
      setselectedmonth(null);
      setState({ ...state, right: false });
    }
  }, [storedSite]);

  const isFilterApplied = fromDate || toDate || selectedmonth;
  console.log(balanceSheetData, "bshbndj");

  const downloadPdf = async () => {
    try {
      setLoading(true);

      const filteredData = JSON.parse(localStorage.getItem("filter") || "[]");
      const financialYear = JSON.parse(
        localStorage.getItem("financialYear") || "{}"
      );

      const currentLocation = window.location.pathname;
      const findCurrentFilter = filteredData.find(
        (ele) => ele.pathname === currentLocation
      );

      const filterValues = findCurrentFilter?.value || [];
      const financialYearId = financialYear?.id || null;
      const fromDate =
        filterValues.find((item) => item.label === "From Date")?.value || null;
      const toDate =
        filterValues.find((item) => item.label === "To Date")?.value || null;

      let payload = {
        reportId: 2,
        reportTypeId: vertHoriOpen ? 1 : 0,
        siteId: localStorage.getItem("siteId"),
        fromDate,
        toDate,
        financialYearId,
        balanceOff: isBalanceWiseData || false,
        siteName: (storedSite && storedSite?.name) || null,
        monthId: selectedmonth?.monthValue || null,
        companyId: localStorage.getItem("companyId"),
        urlLink:
          companyDto?.headerLogo ||
          "https://nyggs-bucket.s3.ap-south-1.amazonaws.com/1695796119595_NKC%20side%20menu.png",
        isOpeningBalanceOff: showOpeningBalance || false,
        isProvisionalBalanceOff: getApprovedBal || false
      };

      const encryptedPayload = getEncryptPayload(payload, true);

      const res = await userService.getReports(
        horizontalVerticalEndpoint,
        encryptedPayload,
        "Balance Sheet"
      );

      if (res?.data?.data) {
        successAlert(res.data.message || "Success");
      } else {
        throw new Error(res?.data?.message || "Failed to download");
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
      errorAlert(error.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {openReportsPDFTemplate && (
        <ReportsPDFTemplate
          open={openReportsPDFTemplate}
          handleClose={handleCloseReportsPDFTemplate}
          title={"Balance Sheet"}
          CIN={"U80903UP2020OPC132055"}
          data={balanceSheetData}
          totalAmount={129023330}
        />
      )}
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ width: "100%", overflow: "auto" }}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Grid
            container
            style={{ margin: "20px 0", alignItems: "center" }}
            justifyContent="space-between"
          >
            <Grid item container alignItems="center" xs={3}>
              <img src={transaction} width="50" alt="icon" />
              <Typography
                variant="h6"
                style={{ display: "inline-block", marginLeft: 20 }}
              >
                Balance Sheet
              </Typography>
            </Grid>

            <Grid
              item
              xs={9}
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item>
                <MySwitch
                  checked={vertHoriOpen}
                  onChange={(e) => {
                    // setLoading(true);
                    setVertHoriOpen(e.target.checked);
                    // setShowOpeningBalance(e.target.checked);
                    // setTimeout(() => {
                    //   setLoading(false);
                    // }, 1000);
                  }}
                  label={vertHoriOpen ? "Vertical" : "Horizontal"}
                />

                <MySwitch
                  checked={isBalanceWiseData}
                  onChange={(e) => {
                    getBalanceSheetData(getSiteWiseData);
                    setIsBalanceWiseData(e.target.checked);
                  }}
                  label={!isBalanceWiseData ? "Balance On" : "Balance Off"}
                />
              </Grid>

              <MySwitch
                checked={getSiteWiseData}
                onChange={(e) => {
                  getBalanceSheetData(e.target.checked);

                  if (e.target.checked) {
                    const filteredSites =
                      siteList &&
                      siteList.filter(
                        (val) => val.alias != "all" && val.alias != "remove"
                      );
                    setSelectedSites(filteredSites);
                  }
                }}
                label={!getSiteWiseData ? "Site Wise On" : "Site Wise Off"}
              />

              <MySwitch
                checked={getApprovedBal}
                onChange={(e) => {
                  setLoading(true);
                  setGetApprovedBal(e.target.checked);
                  setTimeout(() => {
                    setLoading(false);
                  }, 1000);
                }}
                label={
                  !getApprovedBal ? "Provisional Balance" : "Approved Balance"
                }
              />
              <MySwitch
                checked={showOpeningBalance}
                onChange={(e) => {
                  setLoading(true);
                  setShowOpeningBalance(e.target.checked);
                  setTimeout(() => {
                    setLoading(false);
                  }, 1000);
                }}
                label={
                  !showOpeningBalance
                    ? "Show Opening Balance"
                    : "Hide Opening Balance"
                }
              />

              {/* <AiFillFilePdf color="#FF0000" size={40} /> */}
              {getSiteWiseData &&
                renderAutocomplete(
                  (siteName && siteName) || [],
                  "Site",
                  siteName?.filter((ele) => ele.isShow),
                  // (newValue) => setSelectedSites(newValue),
                  (newValue) => {
                    if (
                      newValue &&
                      newValue.find((val) => val.alias == "all")
                    ) {
                      const filteredSites =
                        siteName &&
                        siteName.length > 0 &&
                        siteName.filter(
                          (val) => val.alias !== "all" && val.alias !== "remove"
                        );
                      setSelectedSites(filteredSites);
                    } else if (
                      newValue &&
                      newValue.find((val) => val.alias == "remove")
                    ) {
                      setSelectedSites([]);
                    } else setSelectedSites(newValue);
                  },
                  true,
                  1,
                  "220px"
                )}
              {
                <AutoDialogBox
                  columnHandle={siteNameColumnHandle}
                  handleDelete={siteNameHandleDelete}
                  open={open}
                  setOpen={setOpen}
                  setScroll={setScroll}
                  // scroll={scroll}
                  scroll={"paper"}
                  columns={siteName || []}
                  selectedColumns={selectedSites}
                  setSelectedColumns={setSelectedSites}
                  heading={"Site"}
                  autoCompName={"Site"}
                />
              }
              {!getSiteWiseData && (
                <Tooltip title={"Export Balance Sheet Report"}>
                  <IconButton>
                    <AiFillFilePdf
                      color="#FF0000"
                      size={40}
                      style={{
                        cursor: "pointer",
                        display: checkPermissionExist(
                          endPoints,
                          horizontalVerticalEndpoint
                        )
                          ? "inline-flex"
                          : "none"
                      }}
                      onClick={downloadPdf}
                    />
                  </IconButton>
                </Tooltip>
              )}

              <div>
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    {isFilterApplied ? (
                      <Badge color="primary" variant="dot">
                        <IconButton
                          style={{ background: "#c35151", color: "#fff" }}
                          onClick={toggleDrawer(anchor, true)}
                        >
                          <TuneIcon />
                        </IconButton>
                      </Badge>
                    ) : (
                      <IconButton
                        style={{ background: "#c35151", color: "#fff" }}
                        onClick={toggleDrawer(anchor, true)}
                      >
                        <TuneIcon />
                      </IconButton>
                    )}
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </div>
            </Grid>
            {/* {showFilteredValues && (
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-end"
                sx={{ width: "100%" }}
              >
                {fromDate &&
                  renderChip(
                    "From",
                    fromDate && moment(fromDate).format("MMMM Do YYYY")
                  )}
                {toDate &&
                  renderChip(
                    "To",
                    toDate && moment(toDate).format("MMMM Do YYYY")
                  )}
                {selectedmonth &&
                  renderChip("Month", selectedmonth && selectedmonth?.name)}
              </Stack>
            )} */}
          </Grid>
          {!getSiteWiseData ? (
            <Card
              elevation={3}
              style={{ border: "1px solid black", padding: "5px" }}
            >
              <BalanceSheetTable2
                data={balanceSheetData}
                setData={setBalanceSheetData}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                pageSize={pageSize}
                setPageSize={setPageSize}
                // handleRevert={handleRevert}
                site={site}
                totalRevenue={totalRevenue}
                totalExpenses={totalExpenses}
                netAmount={netAmount}
                selectedYear={selectedYear}
                setLoading={setLoading}
                successAlert={successAlert}
                errorAlert={errorAlert}
                getApprovedBal={getApprovedBal}
                navigateToLedger={navigateToLedger}
                fromDate={fromDate}
                toDate={toDate}
                monthId={selectedmonth?.monthValue}
                showOpeningBalance={showOpeningBalance}
                isBalanceWiseData={isBalanceWiseData}
                vertHoriOpen={vertHoriOpen}
                getBalanceSheetData={getBalanceSheetData}
                endPoints={endPoints}
              />
            </Card>
          ) : (
            <div
              id="cashflow_table_container"
              style={{
                display: "flex",
                flexDirection: "row",
                overflow: "auto",
                flexWrap: "nowrap"
              }}
            >
              {filteredSite &&
                filteredSite?.length > 0 &&
                filteredSite?.map((val) => (
                  <Card
                    id={`scroll_id_${val.id}`}
                    elevation={3}
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      minWidth: filteredSite?.length > 1 ? "50%" : "100%",
                      margin: filteredSite?.length > 1 ? "0px 10px" : "0px",
                      maxHeight: "65vh",
                      overflow: "auto" // Ensures scroll if content exceeds maxHeight
                    }}
                  >
                    <div
                      style={{
                        color: "blue",
                        margin: "10px 0px",
                        // textAlign: "center",
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <h3>
                        <u
                          style={{
                            textDecoration: "none",
                            color: "rgb(188, 83, 253)"
                          }}
                        >
                          {val?.name}
                        </u>
                      </h3>
                      {/* <AiFillFilePdf
                        color="#FF0000"
                        size={40}
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenReportsPDFTemplate(true)}
                      /> */}
                    </div>
                    <BalanceSheetSiteWiseTable2
                      data={balanceSheetData}
                      setData={setBalanceSheetData}
                      pageNo={pageNo}
                      setPageNo={setPageNo}
                      totalPages={totalPages}
                      setTotalPages={setTotalPages}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      // handleRevert={handleRevert}
                      site={site}
                      totalRevenue={totalRevenue}
                      totalExpenses={totalExpenses}
                      netAmount={netAmount}
                      selectedYear={selectedYear}
                      setLoading={setLoading}
                      successAlert={successAlert}
                      errorAlert={errorAlert}
                      getApprovedBal={getApprovedBal}
                      siteId={val?.id}
                      siteList={filteredSite}
                      netAmountArray={netAmountArray}
                      fromDate={fromDate}
                      toDate={toDate}
                      monthId={selectedmonth?.monthValue}
                      showOpeningBalance={showOpeningBalance}
                      navigateToLedger={navigateToLedger}
                      isBalanceWiseData={isBalanceWiseData}
                      vertHoriOpen={vertHoriOpen}
                      getBalanceSheetData={getBalanceSheetData}
                    />
                  </Card>
                ))}
            </div>
          )}

          {/* <Grid container justifyContent="space-between">

            <Grid item xs={(leftSiteData?.length > 0 && rightSiteData?.length > 0) ? 5.5 : 12}>

              {
                leftSiteData?.length > 0 &&
                <>
                  <span>   Left Side Group</span>
                  <PLTable
                    data={leftSiteData}
                    setData={setLeftSideData}
                    pageNo={pageNo}
                    handleClick={handleClick}
                    pageSize={pageSize}
                    setAlerts={setAlerts}
                    alerts={alerts}
                    successAlert={successAlert}
                    errorAlert={errorAlert}
                    setLoading={setLoading}
                    endPoints={endPoints}
                    siteId={selectedSite?.id}
                  />
                </>
              }
            </Grid>


            <Grid item xs={(leftSiteData?.length > 0 && rightSiteData?.length > 0) ? 5.5 : 12}>
            
              {
                rightSiteData?.length > 0 &&
               <>
                <span>  Right Side Group</span>
                <PLTable
                  data={rightSiteData}
                  setData={setRightSideData}
                  pageNo={pageNo}
                  handleClick={handleClick}
                  pageSize={pageSize}
                  setAlerts={setAlerts}
                  alerts={alerts}
                  successAlert={successAlert}
                  errorAlert={errorAlert}
                  setLoading={setLoading}
                  endPoints={endPoints}
                  siteId={selectedSite?.id}
                />
               </>
              }

            </Grid>
          </Grid> */}
        </Container>
      </Paper>

      {/* ==============Alerts=============== */}
      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />

      {/* ==============Loader============= */}
      {loading && <LoadingComponent />}
    </>
  );
};
export default withStyles(styles)(Index);
