import React, { useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Collapse,
  Paper,
  IconButton,
  Box
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from "@mui/icons-material";
import { StyledTableCell, StyledTableRow } from "../utils";
import { Editedtext } from "../CommonComps/TextTemplate";
import { DataStatus } from "../CommonComps/DataStatus";
import CommonPagination from "../CommonComps/CommonPagination";
import AmountInfo from "../CommonComps/AmountInfo";

const isNumeric = (value) => !isNaN(value) && isFinite(value);

const formatDate = (dateString) => {
  const date = moment(dateString);
  return date.isValid() ? date.format("DD-MM-YYYY hh:mm A") : "-";
};

// Updated hierarchical index formatting
const formatIndex = (parentIdx, childIdx = null) => {
  return childIdx === null
    ? `${parentIdx + 1}` // Parent numbering: 1, 2, 3, ...
    : `${parentIdx + 1}.${childIdx + 1}`; // Child numbering: 1.1, 1.2, 1.3, ...
};

const Row = ({ row, header, onToggle, expandedRows, parentIdx }) => {
  const isExpanded = expandedRows.includes(row.id);
  const hasChildData = row.childList && row.childList.length > 0;

  return (
    <>
      {/* Parent Row */}
      <StyledTableRow
        sx={{ backgroundColor: "#9dceff !important", height: "auto" }}
      >
        {header.map(
          (item, index) =>
            item.isShow && (
              <StyledTableCell
                key={item.id || index}
                size="small"
                sx={{
                  textAlign: isNumeric(row[item.alias]) ? "end" : "start",
                  paddingLeft: 0,
                  height: "auto",
                  width: item.name === "S.NO" ? 94 : 200
                }}
              >
                {item.name === "S.NO" ? (
                  <>
                    {/* Parent row numbering */}
                    {formatIndex(parentIdx)}
                    {hasChildData && (
                      <IconButton onClick={() => onToggle(row.id)} size="small">
                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    )}
                  </>
                ) : item.alias === "invoiceDate" ? (
                  formatDate(row[item.alias])
                ) : isNumeric(row[item.alias]) ? (
                  <AmountInfo amount={parseFloat(row[item.alias])} />
                ) : (
                  <Editedtext
                    text={row[item.alias]}
                    textColor={item.alias === "invoiceNo" ? "blue" : "inherit"}
                  />
                )}
              </StyledTableCell>
            )
        )}
      </StyledTableRow>

      {/* Child Rows (in collapsible part) */}
      {hasChildData && (
        <TableRow>
          <TableCell colSpan={8} sx={{ padding: 0 }}>
            <Collapse in={isExpanded}>
              <Table>
                {row.childList.map((child, childIndex) => (
                  <StyledTableRow
                    key={`child-${row.id}-${childIndex}`}
                    sx={{
                      backgroundColor: "#dae9f9 !important",
                      height: "auto"
                    }}
                  >
                    {header.map(
                      (item) =>
                        item.isShow && (
                          <StyledTableCell
                            key={item.id || item.alias}
                            size="small"
                            sx={{
                              textAlign: isNumeric(child[item.alias])
                                ? "end"
                                : "start",
                              // height: "auto",
                              width: item.name === "S.NO" ? 94 : 200,
                              padding:
                                item.name === "S.NO"
                                  ? "0px 20px !important"
                                  : "auto"
                            }}
                          >
                            {item.name === "S.NO" ? (
                              formatIndex(parentIdx, childIndex)
                            ) : item.alias === "invoiceDate" ? (
                              formatDate(child[item.alias])
                            ) : isNumeric(child[item.alias]) ? (
                              <AmountInfo
                                amount={parseFloat(child[item.alias])}
                              />
                            ) : (
                              <Editedtext text={child[item.alias]} />
                            )}
                          </StyledTableCell>
                        )
                    )}
                  </StyledTableRow>
                ))}
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default function GSTTable({
  headerConfig,
  data = [],
  pageNo,
  setPageNo,
  totalPages,
  setPageSize,
  pageSize
}) {
  const [expandedRows, setExpandedRows] = useState([]);

  const onToggle = (rowId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(rowId)
        ? prevExpandedRows.filter((id) => id !== rowId)
        : [...prevExpandedRows, rowId]
    );
  };

  const onPageChange = (event, value) => setPageNo(value);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
          height: "56vh",
          overflowX: "auto"
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <StyledTableRow>
              {headerConfig.map(
                ({ name, id, alias, isShow }, columnIndex) =>
                  isShow && (
                    <StyledTableCell
                      key={id || columnIndex}
                      sx={{
                        minWidth: name === "S.NO" ? 94 : 200,
                        textAlign: data.some((row) => isNumeric(row[alias]))
                          ? "right"
                          : "start",
                        padding: name === "S.NO" ? "0px 20px" : "auto",
                        height: "auto"
                      }}
                    >
                      {name}
                    </StyledTableCell>
                  )
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  header={headerConfig}
                  onToggle={onToggle}
                  expandedRows={expandedRows}
                  parentIdx={index} // Passing parent index for hierarchical numbering
                />
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={headerConfig.length + 1}>
                  <DataStatus />
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data && Array.isArray(data) && data.length > 0 && (
        <CommonPagination
          pageSize={pageSize}
          totalPages={totalPages}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setPageSize={setPageSize}
          onPageChange={onPageChange}
          sx={{ minWidth: 200, margin: "0 20px" }}
          label="GST's Report"
        />
      )}
    </>
  );
}
