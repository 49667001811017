import React, { useEffect, useState } from "react";
import { IconButton, FormControlLabel, Checkbox, Grid, Button, Typography, Divider, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { FcPlus } from "react-icons/fc";

const Editor = ({ open, closeDialogBox, dynamicFields, setDynamicFields, editedIndex, selectedField, setSelectedField, errorAlert }) => {

    const [option, setOption] = useState(null)

    const handleAdd = () => {
        if (selectedField && (selectedField.type === "MultiSelect" || selectedField.type === "SingleSelect" || selectedField.type === "Table")) {
            if (selectedField?.options.length == 0) errorAlert("Provide options to proceed.")
            else updatedFieldValues()
        }
        else updatedFieldValues()

    };

    const updatedFieldValues = () => {
        setDynamicFields((prevFields) => {
            const updatedFields = prevFields.map((field, index) => {
                if (index === editedIndex) {
                    return selectedField;
                }
                return field;
            });
            return updatedFields;
        });
        closeDialogBox()
    }

    const handleAddOption = () => {
        setSelectedField((prevSelectedField) => ({
            ...prevSelectedField,
            options: [...prevSelectedField?.options, option],
        }));
    };

    const handleRemoveOption = (indexToRemove) => {
        setSelectedField((prevSelectedField) => ({
            ...prevSelectedField,
            options: prevSelectedField.options.filter(
                (option, index) => index !== indexToRemove
            ),
        }));
    };


    useEffect(() => {
        if ((dynamicFields && Array.isArray(dynamicFields) && dynamicFields.length > 0) || editedIndex) {
            setSelectedField(dynamicFields[editedIndex])
        }
        // eslint-disable-next-line
    }, [dynamicFields, editedIndex])
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            style={{ width: "500px", margin: "auto" }}
        >
            <DialogTitle id="alert-dialog-title" style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6">Edit Fields </Typography>
                <IconButton onClick={() => closeDialogBox()}>
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {selectedField && Object.keys(selectedField).length > 0 && (
                    <>
                        <TextField
                            size="small"
                            margin="dense"
                            value={selectedField?.label || ""}
                            label="Edit Label"
                            onChange={(e) => {
                                setSelectedField({ ...selectedField, label: e.target.value })
                            }}
                            // type={selectedField?.label || ""}

                            fullWidth
                        />
                        <TextField
                            size="small"
                            margin="dense"
                            value={selectedField?.type || ""}
                            label="Type"
                            disabled
                            // type={selectedField?.label || ""}

                            fullWidth
                        />
                    </>

                )}
                {
                    selectedField && Object.keys(selectedField).length > 0 && (selectedField.type !== "Table" &&
                        selectedField.type !== "Checkbox"

                    ) &&
                    <FormControlLabel control={<Checkbox checked={selectedField?.isRequired}
                        onChange={e => {
                            setSelectedField({ ...selectedField, isRequired: e.target.checked })

                        }}

                    />} label="Is Required" />
                }

                {
                    selectedField && Object.keys(selectedField).length > 0 && (selectedField.type === "MultiSelect" || selectedField.type === "SingleSelect" || selectedField.type === "Table") &&
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "10px 0px" }}>
                        <TextField
                            size="small"
                            margin="dense"
                            value={option}
                            label="Add Options"

                            sx={{ width: 300 }}
                            onChange={(e) => {
                                setOption(e.target.value)
                            }}
                        />
                        <IconButton onClick={handleAddOption}>
                            <FcPlus />
                        </IconButton>
                    </div>
                }

                <Grid container spacing={2}>{

                    selectedField && Object.keys(selectedField).length > 0 && selectedField.options && selectedField.options.length > 0 && selectedField.options.map((val, index) =>

                        <Grid item>
                            <Button className="styled-button" >{val}
                                <ClearIcon sx={{ fontSize: 20 }} onClick={() => handleRemoveOption(index)} />
                            </Button>
                        </Grid>


                    )

                }
                </Grid>

                <Divider style={{ marginTop: "5%" }} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={() => handleAdd()}>
                    Update
                </Button>
                <Button color="secondary" variant="contained" onClick={() => closeDialogBox()}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Editor;
