import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Container, Grid } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { AlertBar } from "../Alert/AlertBar";

import Header from "../CommonComps/Header";
import LoadingComponent from "../Loader";
import { styles } from "../Theme/Theme";
import { tdsReportUrl } from "../config";
import { getEncryptPayload } from "../Network_Utility";
import { userService } from "../userService";
import TdsTable from "./TdsTable";

const transaction = require("../images/Daybook.png");

const TDS_Report = ({ classes }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let reduxState = useSelector((val) => val?.rootReducer);
  let storedSite = reduxState?.siteReducer?.site;
  let companyDetail = reduxState?.loginReducer?.data;
  let accounts = reduxState?.accountReducer;
  let accountTypes = reduxState?.accountTypeReducer?.data;
  let endPoints = reduxState?.permissionReducer?.data;
  const siteList = reduxState?.sitesReducer?.data;
  // ================States=======================//
  const [loading, setLoading] = useState(false);
  const [tdsData, setTdsData] = useState([])


  //==================error====================//
  const [errors, setErrors] = useState([]);

  // ===========Alerts===================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: "",
  });

  // Function to show success alert
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Data fetched succesfully",
      alertType: "success",
    });
    setLoading(false);
  };

  // Function to show error
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Something went wrong",
      alertType: "error",
    });
    setLoading(false);
  };

  //Function to get TDS data
  const getTdsData = async () => {
    const payload = {};
    const encryptedPayload = getEncryptPayload(payload, true);

    setLoading(true);

    try {
      const res = await userService.postData(tdsReportUrl, encryptedPayload);

      if (res?.data?.status === 200 && res?.data?.data) {
        setTdsData(res.data.data);

        if (res.data.data.length > 0) {
          successAlert(res.data.message);
        } else {
          successAlert("No GST data available");
        }
      } else {
        errorAlert(res?.data?.message);
        setTdsData([]);
      }
    } catch (error) {
      errorAlert(error?.message);
      setTdsData([]);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getTdsData()
  }, [])

  // Function to close alert bar
  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });
  return (
    <>
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ width: "100%", overflow: "auto" }}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Grid container style={{ margin: "0", alignItems: "center" }}>
            <Header gridSize="3" source={transaction} headerText="TDS Report" />
          </Grid>
          <TdsTable
       {...{
        tdsData

       }}
      />
        </Container>
      </Paper>

      {/* ==============Alerts=============== */}
      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />

      
      {/* ==============Loader============= */}
      {loading && <LoadingComponent />}
    </>
  );
};
export default withStyles(styles)(TDS_Report);
