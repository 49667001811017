import React from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";

const IconTemplate = ({ data }) => {
  return (
    <>
      {data.map((val) => (
        <Tooltip
          key={val.name}
          title={<Typography>{val.name}</Typography>}
          placement="bottom-start"
        >
          {val.isInputIcon ? (
            <IconButton style={val.style} component="label" name={val.name}>
              {val.icon}
              <input
              id="fileInput"
                type="file"
                accept=".xlsx, .xls"
                onChange={(event) => val.function(event)}
                hidden
              />
            </IconButton>
          ) : (
            <IconButton
              variant="contained"
              onClick={(e) => val.function(e)}
              style={val.style}
            >
              {val.icon}
            </IconButton>
          )}
        </Tooltip>
      ))}
    </>
  );
};

export default IconTemplate;
