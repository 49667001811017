const initialState = {
  data: null,
  response: null,
  message: null,
  isLoggedIn: null
};

export function loginReducer(state = initialState, action) {
  const { type, data } = action;

  switch (type) {
    case "SUCCESS":
      return {
        ...state,
        status: 200,
        data: data,
        message: "Successfully logged in",
        isLoggedIn: true
      };
    case "FAILURE": {
      return {
        ...state,
        status: 400,
        data: data,
        message: "Wrong id and password",
        isLoggedIn: false
      };
    }

    default:
      return state;
  }
}
