import React from 'react'
import ConvertAmountToWords from './AmountConverter'
import { Tooltip, Typography } from '@mui/material'
import { NumberFormatter } from './NumberFormatter'

const AmountInfo = ({ amount, color,fontWeight,fontSize }) => {
    return (
        <Tooltip title={
            <Typography>
                {
                    +parseInt(Math.abs(amount)) > 0 && <ConvertAmountToWords amount={Math.abs(amount.toFixed(2))} />
                }
            </Typography>
        } placement="bottom-start">
            <span style={{ color: color, fontSize:fontSize || 14, cursor: "pointer" ,fontWeight}}>
                <NumberFormatter number={Math.abs(amount).toFixed(2)} />
            </span>
        </Tooltip>
    )
}

export default AmountInfo