import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import { Editedtext } from "../CommonComps/TextTemplate";
import { DataStatus } from "../CommonComps/DataStatus";
import {
  StyledTableCell,
  StyledTableRow,
  checkModuleExist,
  checkPermissionExist,
} from "../utils/index";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  addTransactionUrl,
  singleVoucherGetUrl,
  voucherTypeSaveUrl,
} from "../config";
import { AiOutlineTransaction } from "react-icons/ai";

const headers = [
  { id: 1, name: "S.No", style: { minWidth: "50px" } },
  { id: 2, name: "Name", style: { minWidth: "200px" } },
  { id: 3, name: "Credit Groups", style: { minWidth: "400px" } },
  { id: 4, name: "Debit Groups", style: { minWidth: "500px" } },
  { id: 4, name: "Action", style: { minWidth: "100px" } },
];

function Row({ row, index, setOpen, setGroups, endPoints }) {
  const navigate = useNavigate();

  const handleClick = (row) => {
    navigate("/addTransaction", {
      state: {
        id: row.id,
        path: "voucherTemplate",
      },
    });
  };
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell style={{ paddingLeft: 10 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography variant="body2" style={{ paddingLeft: 10 }}>
              {index}{" "}
            </Typography>
            {checkPermissionExist(endPoints, voucherTypeSaveUrl) &&
              checkModuleExist(endPoints, "Copy Voucher") && (
                <IconButton
                  onClick={() =>
                    navigate("/createVoucherTemp", {
                      state: { id: row?.id, name: row?.customFormType },
                    })
                  }
                >
                  <ContentCopyIcon />
                </IconButton>
              )}
          </Box>
        </StyledTableCell>

        <StyledTableCell
          style={{
            cursor:
              checkPermissionExist(endPoints, singleVoucherGetUrl) && "pointer",
            color: "blue",
          }}
          onClick={() => {
            const permissionExist = checkPermissionExist(
              endPoints,
              singleVoucherGetUrl
            );
            if (permissionExist)
              navigate("/editTemplate", {
                state: { id: row?.id, name: row?.customFormType },
              });
          }}
        >
          <Editedtext text={row?.customFormType} />
        </StyledTableCell>

        <StyledTableCell>
          {row?.creditGroup && Array.isArray(row.creditGroup) && (
            <>
              {row.creditGroup.slice(0, 2).map((ele, index) => (
                <Button
                  key={index}
                  className="account_group_btn"
                  sx={{
                    marginLeft: 1,
                    marginY: "5px",
                    background: "#a11919",
                    color: "#fff !important",
                  }}
                  size="small"
                  // variant="contained"
                  disabled
                >
                  {ele?.name}
                </Button>
              ))}
              {row.creditGroup.length > 2 && (
                <span
                  style={{ color: "blue", cursor: "pointer", fontSize: 12 }}
                  onClick={() => {
                    setOpen(true);

                    setGroups([
                      {
                        list: row.creditGroup,
                        type: "Credit",
                      },
                    ]);
                  }}
                >
                  +more ({row.creditGroup.length - 2}{" "}
                  {row.creditGroup.length - 2 === 1 ? " group" : " groups"})
                </span>
              )}
            </>
          )}
        </StyledTableCell>

        <StyledTableCell>
          {row?.debitGroup && Array.isArray(row.debitGroup) && (
            <>
              {row.debitGroup.slice(0, 2).map((ele, index) => (
                <Button
                  key={index}
                  disabled
                  className="account_group_btn"
                  sx={{
                    marginLeft: 1,
                    marginY: "5px",
                    background: "green",
                    color: "#fff !important",
                  }}
                  size="small"
                  // variant="contained"
                >
                  {ele?.name}
                </Button>
              ))}
              {row.debitGroup.length > 2 && (
                <span
                  style={{ color: "blue", cursor: "pointer", fontSize: 12 }}
                  onClick={() => {
                    setOpen(true);
                    // setGroups(row.debitGroup)
                    setGroups([
                      {
                        list: row.debitGroup,
                        type: "Debit",
                      },
                    ]);
                  }}
                >
                  +more ({row.debitGroup.length - 2}
                  {row.debitGroup.length - 2 === 1 ? " group" : " groups"})
                </span>
              )}
            </>
          )}
        </StyledTableCell>

        <StyledTableCell>
          <Tooltip
            key={row?.id}
            title={<Typography>Create Transaction</Typography>}
            placement="bottom-start"
          >
            <IconButton
              onClick={() => handleClick(row)}
              style={{
                display: checkPermissionExist(endPoints, addTransactionUrl)
                  ? "inline-flex"
                  : "none",
              }}
            >
              <AiOutlineTransaction color="green" />
            </IconButton>
          </Tooltip>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function TemplateTable({ data, setOpen, setGroups, endPoints }) {
  return (
    <>
      <TableContainer
        component={Paper}
        style={{
          marginTop: 20,
          height: data && data.length > 0 && "calc(100vh - 192px)",
        }}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <StyledTableRow>
              {headers &&
                Array.isArray(headers) &&
                headers.length > 0 &&
                headers.map((val) => (
                  <StyledTableCell style={val.style} key={val.id}>
                    {val.name}
                  </StyledTableCell>
                ))}
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {data &&
              data.map((row, index) => (
                <Row
                  key={row?.id}
                  row={row}
                  index={index + 1}
                  setOpen={setOpen}
                  setGroups={setGroups}
                  endPoints={endPoints}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data && data.length < 1 && <DataStatus />}
    </>
  );
}
