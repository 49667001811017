const initialState = {
    site: null,
};

export function siteReducer(state = initialState, action) {
    const { type, data } = action;

    switch (type) {
        case "SET_SITE":
            return {
                ...state,
                site: data,

            };
        default:
            return state;
    }
}

const initialStateDisable = {
    disable: false
}

export function siteDisableReducer(state = initialStateDisable, action) {
    // DISABLE_SITE
    const { type, data } = action;
    switch (type) {
        case "DISABLE_SITE":
            return {
                ...state,
                disable: data,
            };
        default:
            return state;
    }
}
