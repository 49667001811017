import React, { useState, useEffect } from "react";
import * as ReactDOMServer from "react-dom/server";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  AppBar,
  Box,
  Tooltip,
  Avatar,
  Modal,
  Backdrop,
  Fade,
  Card,
  Grid,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import { Button } from "@mui/material";
import { setNewSite } from "../features/actions/siteAction";
import {
  checkIsHeadOffice,
  checkPermissionExist,
  navigationMenus,
  navRoutes,
} from "../utils";
import clsx from "clsx";
import Swal from "sweetalert2";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterTemplate from "../CommonComps/FilterTemplate";
import { Autocomplete, Badge, InputAdornment, TextField } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SideBar from "../CommonComps/SideBar";
import { accountTypeRequestSuccess } from "../features/actions/AccountTypeAction";
import { accountRequestSuccess } from "../features/actions/AccountAction";
import { permissionRequestSuccess } from "../features/actions/PermissionActions";
import { siteRequestSucess } from "../features/actions/SitesAction";
import { fyFetchSuccess } from "../features/actions/FinancialYearsAction";
import { loginSuccess } from "../features/actions/LoginActions";
import { History } from "@mui/icons-material";
import MenuPathsDialog from "../CommonComps/MenuPathsDialog";
import SearchHistoryContainer from "../CommonComps/SearchHistoryContainer";
import { addNewSearchableKey } from "../features/actions/SearchableAction";
import SearchHistoryModal from "../CommonComps/SearchedKeysModal";
import { userService } from "../userService";
import { getNotification } from "../config";
import Footer from "./Footer";
import HelpIcon from "@mui/icons-material/Help";

require("./style.css");

const drawerWidth = 255;

export var themecolor;
export const cellColor = "#9b9b9b";

const styles = {
  searchContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    height: "35px", // Increased height for better touch interaction
    borderRadius: "25px",
    overflow: "hidden",
    // padding: "0 15px", // Added padding for better spacing
    width: "15%",
  },
  historyIcon: {
    borderRadius: "50%",
    color: "rgb(63, 121, 181)",
    width: "32px",
    height: "29px",
    cursor: "pointer",
    fontSize: "24px",
    position: "absolute",
    zIndex: 1000,
    margin: "0px 5px",
  },
  autocomplete: {
    borderRadius: "30px",
    flex: 1,
    border: "none",
    overflow: "hidden",
    // paddingLeft: "30px",
  },
  inputField: {
    height: "100%",
    // paddingLeft: "30px"
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: "all 0.8s ease",
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen
    // })
  },
  drawerClose: {
    transition: "all 0.8s ease",
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen
    // }),
    overflowX: "hidden",
    width: 100,
    background: "rgb(63, 121, 181)",
    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(10) + 5
    // }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 450,
    maxWidth: 600,
    height: 185,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    fontFamily: "Poppins",
    letterSpacing: 2,
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state?.rootReducer);

  let sitesList = reduxState?.sitesReducer?.data || [];
  let financialYears = reduxState?.fyReducer?.data || [];
  let disableSite = reduxState?.siteDisableReducer?.disable || false;
  let companyDto = reduxState?.companyReducer?.data || null;
  let endPoints = reduxState?.permissionReducer?.data || [];
  let searchedKeys = reduxState?.searchReducer?.keys || [];
  const siteId = localStorage.getItem("siteId");

  themecolor = companyDto?.colourTheme[0]?.colourCode || "#3f79b5";

  function MySVG() {
    return (
      <svg
        width="1920"
        height="94"
        viewBox="0 0 1920 94"
        fill={themecolor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1920 94V0L0 0V4.02857L601.74 4.02857C619.272 4.02857 636.166 10.6074 649.08 22.464L706.92 75.5646C719.834 87.4212 736.728 94 754.26 94L1920 94Z"
          fill={themecolor}
        />
      </svg>
    );
  }

  const navBar = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(<MySVG />)
  );

  const selectedSite =
    sitesList &&
    sitesList.find(
      (val) => val.id === Number(localStorage.getItem("siteId")) || ""
    );
  const userDetails = localStorage.getItem("username");
  const [searchableVal, setSearchableVal] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [openMenuModal, setOpenMenuModal] = React.useState(false);
  const [site, setSite] = useState(null);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(null);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  // const [isChecked, setIsChecked] = useState(false);
  const [openSearchedModal, setOpenSearchModal] = useState(false);

  // const handleChange = (checked) => {
  //   setIsChecked(checked);
  //   localStorage.setItem("encryptData", checked);
  // };

  const handleMenuClick = (menuId) => {
    if (expandedMenu === menuId) {
      setExpandedMenu(null);
    } else {
      setExpandedMenu(menuId);
    }
  };
  const handleDrawerOpen = () => !open && setOpen(true);

  const handleDrawerClose = () => open && setOpen(false);
  // const [searchInfo, setSearchInfo] = useState("");

  // const switchModule = () => {
  //   const token = localStorage.getItem("token")
  //   setTimeout(() => {
  //     window.location.href = `https://sso.nyggs.com/modulesSelection/?token=${token}`
  //   }, 200);
  // }

  const handleChangeSite = (val) => {
    if (val) {
      setSite(val);
      localStorage.setItem("siteId", val?.id);
      dispatch(setNewSite(val));
    }
  };

  // useEffect function to set site
  useEffect(() => {
    setSite(selectedSite);
  }, [selectedSite]);

  const filteredValues = [
    // {
    //   typeId: 1,
    //   value: searchInfo || "",
    //   style: {
    //     borderRadius: 10,
    //     background: "#e4e9fd",
    //     marginLeft: 20,
    //     width: 180
    //   },
    //   function: (e) => {
    //     setSearchInfo(e.target.value);
    //   }
    // },
    {
      typeId: 2,
      // label: "Financial Year",
      options: financialYears || [],
      value: selectedFinancialYear || financialYears[0],
      style: {
        borderRadius: 20,
        background: "#e4e9fd",
        marginLeft: 20,
        width: 180,
        backgroundColor: "white",
        height: "35px",
        overflow: "hidden",
      },
      function: (event, newValue) => {
        if (newValue) {
          setSelectedFinancialYear(newValue);
          localStorage.setItem("financialYear", JSON.stringify(newValue));
        } else {
          setSelectedFinancialYear(financialYears[0]);
          localStorage.setItem(
            "financialYear",
            JSON.stringify(financialYears[0])
          );
        }
      },
      className: null,
      disable: disableSite,
      type: "name",
    },
    {
      typeId: 2,
      // label: "Site",
      options: sitesList && sitesList.length > 0 ? sitesList : [],
      value: site,
      style: {
        borderRadius: 20,
        background: "#e4e9fd",
        marginLeft: 20,
        width: 200,
        backgroundColor: "white",
        height: "35px",
        overflow: "hidden",
      },

      function: (event, newValue) => {
        newValue
          ? handleChangeSite(newValue)
          : handleChangeSite(
              sitesList &&
                sitesList.find(
                  (val) =>
                    Number(val.id) ===
                    Number(localStorage.getItem("primarySite") || "")
                )
            );
        localStorage.removeItem("filter");
        // window.location.reload();
      },
      className: null,
      disable: disableSite,
      type: "name",
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <SideBar
      notification={true}
      anchor={anchor}
      toggleDrawer={toggleDrawer}
      filteredValues={null}
      handleSubmitFilterValue={false}
      notificationData={notificationData}
      setState={setState}
      state={state}
    />
  );

  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: themecolor,
      cancelButtonColor: "#f50057",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/");
        clearStorageAndResetState();
      }
    });
  };

  const clearStorageAndResetState = () => {
    localStorage.clear();
    sessionStorage.clear();
    setOpen(false);
    setExpandedMenu(false);

    const resetActions = [
      accountTypeRequestSuccess(null, ""),
      fyFetchSuccess(null),
      accountRequestSuccess(null, ""),
      permissionRequestSuccess(null),
      siteRequestSucess(null),
      setNewSite(null),
      loginSuccess(null),
    ];

    resetActions.forEach((action) => dispatch(action));
  };

  // Check if the current route is the error page, if yes, don't render MiniDrawer
  // if (navigate && navigate().pathname === '/error') {
  //   return null;
  // }

  const getOptions = () => {
    const standardMenus = [
      {
        id: 1,
        name: "Dashboard",
        subName: "Dashboard",
        path: "/dashboard",
        subPath: "/dashboard",
      },

      {
        id: 2,
        name: "Chart Of Accounts",
        subName: "Accounts",
        apiEndpoint: "/account/v1/get",
        path: "/account",
        subPath: "/addAccount",
      },

      {
        id: 17,
        name: "Voucher Template",
        path: "/voucherTemplate",
        subName: "Template",
      },

      {
        id: 17,
        name: "Voucher Entry",
        path: "/createVoucherTemp",
        subName: "Template",
      },

      {
        id: 18,
        name: "Third Party Api Integration",
        path: "/vendorTemplate",
        // name: "Vendor Template",
        // path: "/vendorTemplate",
        subName: "Temp Config",
      },
      {
        id: 18,
        name: "Vendor Entry",
        path: "/mapData",
        subName: "Temp Config",
      },

      {
        id: 20,

        name: "Reports Configurations",
        subName: "Configurations",
        path: "/reportsMaster",
        subPath: "/reportsMaster",
      },
    ];

    const reportsMenus = [
      {
        id: 3,
        name: "Daybook",
        path: "/daybook",
        apiEndpoint: "/account-transaction/v2/get",
      },

      {
        id: 4,
        name: "Trial Balance",
        subName: "Trial Bal",
        apiEndpoint: "/account-transaction/v1/account/group/tree/list",
        path: "/trialBalance",
      },
      {
        id: 5,
        name: "Bank Book",
        path: "/bankBook",
        subName: "Bank Book",
      },
      {
        id: 6,
        name: "Fixed Assets",
        subName: "Fix Asset",
        path: "/fixedAssets",
      },
      {
        id: 7,
        name: "Liabilities",
        subName: "Liabilities",
        path: "/liabilities",
      },
      {
        id: 8,
        name: "P&L Statement",
        subName: "P&L Statement",
        path: "/profitLoss",
      },
      {
        id: 9,
        name: "Balance Sheet",
        subName: "Bal Sheet",
        path: "/balanceSheet",
      },
      // {
      //   id: 10,
      //   name: "Annual Balance Sheet",
      //   subName: "Anl Bal Sheet",
      //   path: "/annualbalanceSheet",
      //   icon: <FaBalanceScale size={20} style={{ color: "#fff" }} />
      // },
      // {
      //   id: 11,
      //   name: "Annual Profit & Loss",
      //   subName: "P & L Sheet",
      //   path: "/annualprofitandloss",
      //   icon: <FaBalanceScale size={20} style={{ color: "#fff" }} />
      // },

      {
        id: 12,
        name: "Cash Flow",
        subName: "Cash flow",
        path: "/cashflow",
      },
    ];

    const accConfigMenus = [
      {
        id: 13,
        name: "Account Type",
        subName: "Acc Type",
        path: "/accountType",
        subPath: "/addAccountType",
      },
      {
        id: 14,
        name: "Account Group",
        subName: "Acc Group",
        path: "/accountGroup",
        subPath: "/addAccountGroup",
      },

      {
        id: 24,
        name: "Report Group",
        subName: "Acc Report Group",
        path: "/reportGroup",
        subPath: "/addReportGroup",
      },
      {
        id: 15,
        name: "KYC Implementation",
        subName: "KYC Config",
        path: "/kycImplemenation",
        subPath: null,
      },

      {
        id: 16,
        path: "/preferences",
        subPath: null,
        name: "Preferences",
        subName: "Preference",
      },
      {
        id: 17,
        name: "Narration Configuration",
        subName: "Narration",
        path: "/narration",
        subPath: null,
      },
      {
        id: 18,
        name: "Third Party Api Integration",
        subName: "API",
        // name: "Vendor Template",
        // subName: "Vendor",
        path: "/vendorTemplate",
        // subMenus: [
        //   {
        //     id: "6.1",
        //     name: "Template Config",
        //     path: "/vendorTemplate",
        //     subName: "Temp Config",
        //     icon: <AiFillSetting size={20} style={{ color: "#fff" }} />,
        //   },
        //   // More vendor template items...
        // ],
      },
      {
        id: 19,
        name: "Account Code",
        subName: "Acc Code",
        path: "/account-code",
        subPath: null,
      },
      {
        id: 20,
        name: "Account Transaction Type",
        subName: "Transaction Type",
        path: "/account-transaction-type",
        subPath: null,
      },
      {
        id: 21,
        name: "Company Configuration",
        subName: "Company Config",
        path: "/company-configuration",
        subPath: null,
      },
      {
        id: 22,
        name: "Account Group Configuration",
        subName: "Acc Group Config",
        path: "/account-group-configurations",
        subPath: null,
      },
      {
        id: 23,
        name: "Project Group",
        subName: "Project Group",
        path: "/project-group",
        subPath: null,
      },
    ];

    let filteredReports = [];
    let filteredAccConfigs = [];
    let filteredStandardMenus = [];
    const filteredEndpoints =
      endPoints && endPoints.filter((val) => !val.apiEndpoint);

    for (let i = 0; i < filteredEndpoints.length; i++) {
      if (filteredEndpoints[i].name == "Reports")
        filteredReports =
          endPoints &&
          endPoints.filter((val) =>
            reportsMenus.some((ele) => ele?.name === val?.name)
          );

      if (filteredEndpoints[i].name == "Master Management") {
        // filteredAccConfigs =
        const filteredMenu =
          endPoints &&
          endPoints.filter((val) =>
            accConfigMenus.some((ele) => val?.name === ele?.name)
          );
        filteredAccConfigs = filteredMenu;
      } else
        filteredStandardMenus =
          endPoints &&
          endPoints.filter((val) =>
            standardMenus.some((ele) => ele?.name === val?.name)
          );
    }

    const autoComplatesMenus = [
      ...filteredStandardMenus,
      ...filteredAccConfigs,
      ...filteredReports,
    ];

    if (
      autoComplatesMenus &&
      Array.isArray(autoComplatesMenus) &&
      autoComplatesMenus.length > 0
    ) {
      return autoComplatesMenus;
    }
  };
  const foundMenuItem =
    navRoutes.find((val) => val?.path === window.location.pathname) ||
    navRoutes
      .map(
        (val) =>
          val.subMenus &&
          Array.isArray(val.subMenus) &&
          val.subMenus.length > 0 &&
          val.subMenus.find((ele) => ele?.path === window.location.pathname)
      )
      .find(Boolean);

  const handleNotification = async (anchor) => {
    try {
      const payload = {
        pageNumber: 1,
        pageSize: 10,
        userId: localStorage.getItem("userId"),
      };
      const res = await userService.postData(getNotification, payload);

      if (res?.data?.status === 200) {
        setNotificationData(res?.data?.data);
        // setState({ ...state, [anchor]: true });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // checkPermissionExist(endPoints, getNotification) && handleNotification();
    getOptions();
  }, []);

  const handleHistoryClick = () => {
    setOpenSearchModal(true);
  };

  const handleAutocompleteChange = (event, newValue) => {
    // debugger
    if (newValue) {
      window.location.href = newValue.path;
      dispatch(addNewSearchableKey(newValue));
    }
  };

  const currentLocation = window.location.pathname;

  function getTimeMessage() {
    var currentTime = new Date();
    var currentHour = currentTime.getHours();

    if (currentHour < 12) {
      return "Good Morning";
    } else if (currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }

  var timeMessage = getTimeMessage();

  const searchHistorySetHandle = (data) => {
    for (let i = 0; i < data?.length; i++) {
      const ele = data[i];
      if (currentLocation === ele.path) {
        dispatch(addNewSearchableKey(ele));
      }
    }
  };

  useEffect(() => {
    searchHistorySetHandle(getOptions());
  }, [foundMenuItem]);

  return (
    foundMenuItem && (
      <>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            style={{
              minHeight: "50px",
              backgroundImage: `url("data:image/svg+xml,${navBar}"), linear-gradient(45deg, #fff, #fff)`,
              backgroundSize: "cover",
            }}
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="hamburger icon"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon style={{ color: "black" }} />
              </IconButton>

              {/* logo */}
              <Typography variant="h6" noWrap>
                <img
                  src={companyDto?.headerLogo || ""}
                  alt="company-logo"
                  style={{ height: "3rem" }}
                />
              </Typography>
              <div className="logo-right-container">
                <Tooltip title={"Help"}>
                  <IconButton onClick={() => window.open("/help", "_blank")}>
                    <HelpIcon sx={{ fontSize: 30, color: "#fff" }} />
                  </IconButton>
                </Tooltip>
                <div style={styles.searchContainer}>
                  {/* <History
                    sx={styles.historyIcon}
                    onClick={handleHistoryClick}
                  /> */}

                  <Autocomplete
                    size="small"
                    style={styles.autocomplete}
                    options={getOptions() || []}
                    value={searchableVal}
                    disabledItemsFocusable
                    margin="dense"
                    getOptionLabel={(option) =>
                      option ? option.name || option.name : ""
                    }
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    onChange={handleAutocompleteChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={styles.inputField}
                        sx={{ "& input": { paddingLeft: "35px !important" } }}
                        placeholder="Search..."
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <History
                                sx={styles.historyIcon}
                                onClick={handleHistoryClick}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <SearchHistoryModal
                  open={openSearchedModal}
                  setOpen={setOpenSearchModal}
                  data={searchedKeys}
                />

                {/* <FormControlLabel


                  control={
                    <Checkbox
                      icon={<FaLockOpen size="30px"  color="white"/>} // Custom icon for unchecked state
                      checkedIcon={<FaLock size="30px" color="white" />} // Custom icon for checked state
                      checked={isChecked}
                      onChange={e => handleChange(e.target.checked)} />}
                /> */}

                {filteredValues && filteredValues.length > 0 && (
                  <FilterTemplate filteredValues={filteredValues} />
                )}
                {/* {

                  <IconButton onClick={() => setOpenActionModal(true)}>
                    <Add sx={{ color: "#fff",display:"none" }} />
                  </IconButton>
                }

                {
                  openActionModal && localStorage.getItem("siteId") == 12 &&
                  <div className="action_modal" style={{}}>

                    <Card style={{ padding: 5 }}>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="body1">Menus</Typography>
                        <Close sx={{ cursor: "pointer" }} onClick={() => setOpenActionModal(false)} />
                      </div>
                      <Divider />
                      {addEventButton && addEventButton.length > 0 && addEventButton.map((val, index) =>
                        <Box sx={{
                          margin: "10px 0px",

                          // display: checkPermissionExist(endPoints, val?.api) ? "inline-flex" : "none"
                        }}>
                          <a href={val.path} style={{ color: "blue" }}>{index + 1}. {val.name}</a>
                        </Box>
                      )}
                    </Card>
                  </div>
                } */}

                {/* <IconButton style={{ marginLeft: 10 }}>
                  <Badge badgeContent={4} color="error">
                    <NotificationsIcon sx={{ color: "white" }} />
                  </Badge>
                </IconButton> */}

                {/* <MenuPathsDialog /> */}
                <SearchHistoryContainer
                  MenuPathsDialog={MenuPathsDialog}
                  open={openMenuModal}
                  setOpen={setOpenMenuModal}
                />
                {checkPermissionExist(endPoints, getNotification) &&
                  ["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <IconButton
                        style={{ marginLeft: -5 }}
                        onClick={() => {
                          // handleNotification(anchor, true);
                          setState({ ...state, [anchor]: true });
                        }}
                        // onClick={()=>navigate("/pendingApprovals")}
                      >
                        <Badge badgeContent={0} color="error">
                          <NotificationsIcon sx={{ color: "white" }} />
                        </Badge>
                      </IconButton>
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}

                {/* <Button variant="contained" color="primary"
                style={{ margin: "0px 10px" }}
                onClick={() => switchModule()}>Switch Module
                </Button> */}
                <Box sx={{ flexGrow: 0, marginLeft: 20 }}>
                  <Tooltip
                    title={userDetails ? userDetails : ""}
                    style={{ padding: 0 }}
                  >
                    <IconButton sx={{ p: 0 }} onClick={() => logout()}>
                      <Avatar></Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
                {/* <div>
                  <Checkbox
                    icon={<AiFillUnlock />} // Custom icon for unchecked state
                    checkedIcon={<AiFillLock />} // Custom icon for checked state
                    checked={isChecked}
                    onChange={(e)=>{
                      
                      handleChange(e.target.checked)
                    }}
                  />
                  <span>{isChecked  ? "checked" :"false"}</span>

                </div> */}
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
            open={open}
            style={{
              transition: "width 495ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            }}
            // #3f79b5
          >
            <div className={classes.toolbar}>
              <Typography
                component={"h3"}
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#3f79b5",
                }}
              >
                {timeMessage}
              </Typography>
              <IconButton onClick={handleDrawerClose} aria-label="close-button">
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon style={{ color: "black" }} />
                ) : (
                  <ChevronLeftIcon
                    style={{ color: open ? "black" : "transparent" }}
                  />
                )}
              </IconButton>
            </div>
            <Divider />

            <List
              // onMouseEnter={handleDrawerOpen}
              onMouseLeave={handleDrawerClose}
              component="nav"
              className="navbar-component"
              style={{
                background: themecolor ? themecolor : "#3f79b5",
                overflow: "auto",
                paddingBottom: "30px",
              }}
            >
              {navigationMenus &&
                navigationMenus.length > 0 &&
                navigationMenus.map((menu) => (
                  <div key={menu.id}>
                    <Link
                      to={menu?.path}
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display:
                          endPoints &&
                          endPoints.find((val) => val?.name == menu.name)
                            ? "block"
                            : "none",
                      }}
                    >
                      <div
                        onClick={() => {
                          menu.subMenus && handleMenuClick(menu.id);
                          menu.path && setOpen(false);
                        }}
                        style={{
                          borderBottom: "1px solid #fff",
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <ListItem
                          style={{
                            color: "#fff",
                            padding: "8px 21px",
                            // width: !open ? "fit-content" : "initial",
                            margin: !open ? "auto" : "initial",
                            flexDirection: open ? "row" : "column",
                            background:
                              currentLocation === menu?.path && "#000",
                            display:
                              !checkIsHeadOffice(sitesList, siteId) &&
                              [
                                "Account Numbering",
                                "Account Group Configuration",
                                "Company Configuration",
                                "Settings",
                              ].includes(menu?.name)
                                ? "none"
                                : "",
                          }}
                          button
                        >
                          <ListItemIcon
                            style={{
                              minWidth: "inherit",
                              padding: "10px",
                            }}
                          >
                            {menu?.icon}{" "}
                          </ListItemIcon>
                          {open && (
                            <p style={{ fontSize: "12px" }}>{menu.name}</p>
                          )}
                          {menu.subMenus && open ? <ArrowDropDownIcon /> : null}

                          {!open && (
                            <span
                              className="spanned-menu-text"
                              style={{ fontSize: "10px", fontWeight: "500" }}
                            >
                              {menu?.subName}
                            </span>
                          )}
                        </ListItem>
                      </div>
                    </Link>
                    {menu?.subMenus && expandedMenu === menu.id && (
                      <>
                        {menu.subMenus.map((subMenu) => (
                          <Link
                            to={subMenu?.path}
                            key={subMenu.id}
                            style={{
                              // background: themecolor && themecolor,
                              textDecoration: "none",
                              color: "inherit",
                              display:
                                endPoints &&
                                endPoints.find(
                                  (val) => val?.name == subMenu.name
                                )
                                  ? "block"
                                  : "none",
                            }}
                            // onClick={() =>
                            //   localStorage.setItem(
                            //     "title",
                            //     JSON.stringify({
                            //       id: subMenu.id,
                            //       name: subMenu.name
                            //     })
                            //   )
                            // }
                          >
                            <div
                              style={{
                                borderBottom: "1px solid #fff",
                                position: "relative",
                                textAlign: "center",
                              }}
                            >
                              <ListItem
                                style={{
                                  color: "#fff",
                                  padding: "8px 21px",
                                  background:
                                    currentLocation === subMenu?.path
                                      ? "#000"
                                      : "rgb(20 72 126)",
                                  flexDirection: open ? "row" : "column",
                                  justifyContent: !open && "center",
                                  alignItems: open && "center",
                                  display:
                                    !checkIsHeadOffice(sitesList, siteId) &&
                                    [
                                      "Account Numbering",
                                      "Account Group Configuration",
                                      "Company Configuration",
                                      "Role & Permission",
                                    ].includes(subMenu?.name)
                                      ? "none"
                                      : "",
                                }}
                                button
                              >
                                <ListItemIcon
                                  style={{
                                    padding: "10px",
                                    // display: "inline-block",
                                    textAlign: "center",
                                    minWidth: "fit-content",
                                    // height: "50px",
                                    // width: "50px"
                                  }}
                                >
                                  {subMenu?.icon}{" "}
                                </ListItemIcon>
                                {open && (
                                  <p style={{ fontSize: "12px" }}>
                                    {subMenu.name}
                                  </p>
                                )}
                                {!open && (
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "500",
                                      color: "#fff",
                                    }}
                                  >
                                    {subMenu?.subName}
                                  </span>
                                )}
                              </ListItem>
                            </div>
                          </Link>
                        ))}
                      </>
                    )}
                  </div>
                ))}
            </List>

            <Divider />
          </Drawer>
          <Footer />
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={modelOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={modelOpen}>
            <Card className={classes.modalPaper} variant="elevation">
              <Grid container style={{ paddingLeft: 15, color: "#fff" }}>
                <h3 style={{ padding: "10px 0px" }}>Logout Confirmation</h3>
              </Grid>
              <Grid container justifyContent="center" style={{ padding: 20 }}>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", margin: "10px 0" }}
                >
                  <Typography style={{ fontSize: "1.3rem" }}>
                    Do you want to logout???
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-evenly"
                  style={{ margin: "10px 0" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      localStorage.clear();
                      navigate("/");
                    }}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setModelOpen(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Fade>
        </Modal>
      </>
    )
  );
}
