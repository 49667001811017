import React, { useState } from "react";
import { Grid, DialogActions, Card } from "@mui/material";
import { Typography, Button, IconButton, DialogContent, Dialog, DialogTitle, TextField } from "@mui/material";
import { userService } from "../userService";
import { validationFunction } from "./util";
import { getEncryptPayload } from "../Network_Utility";
import { saveVendorUrl } from "../config";
import ClearIcon from "@mui/icons-material/Clear";

const ConfigModal = ({apiType, open, closeModal, apiName, setApiName, json, setJson, setLoading, successAlert, errorAlert,handleSubmit,handleClose,error,setErrors }) => {

    const DialogTitleContent = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">Config</Typography>
            <IconButton onClick={handleClose}>
                <ClearIcon />
            </IconButton>
        </div>
    );


    return (
        <Card>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
                style={{ padding: "20px" }}
            >
                <DialogTitle id="alert-dialog-title">{DialogTitleContent}</DialogTitle>
                <DialogContent style={{ padding: 10 }}>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>

                        <Grid item xs={12}>
                            <TextField
                                label="Name"
                                variant="outlined"
                                value={apiName}
                                onChange={e => setApiName(e.target.value)}
                                size="small"
                                fullWidth
                                error={error?.apiError}
                                onBlur={() => apiName && setErrors({ ...error, apiError: false })}
                            />
                        </Grid>

                        <Grid item xs={12}>

                            <TextField
                                label="JSON"
                                variant="outlined"
                                multiline
                                minRows={5}
                                maxRows={20}
                                fullWidth
                                value={json}
                                onChange={e => setJson(e.target.value)}
                                size="small"
                                error={error?.jsonError}
                                onBlur={() => json && setErrors({ ...error, jsonError: false })}
                            // margin="dense"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>


                <DialogActions>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>
        </Card>
    );
};

export default ConfigModal;
