import { financialYearsUrl, financialYearMonthUrl } from "../../config";
import { userService } from "../../userService";
import { navigationMenus } from "../../utils";

const FY_FETCH_SUCCESS = "FY_FETCH_SUCCESS";
const FY_FETCH_FAILURE = "FY_FETCH_FAILURE";

const filterMenusByPermissions = (menus, permissions) =>
  menus.filter((menuItem) =>
    permissions.some((permission) => menuItem?.name === permission?.name)
  );

function navigateWithDelay(path, navigate) {
  setTimeout(() => {
    navigate(path);
  }, 100);
}

export function fetchFinancialYears(
  setIsLoading,
  errorAlert,
  navigate,
  permissionList
) {
  return (dispatch) => {
    setIsLoading(true);
    const payload = {
      countryId: 1 || localStorage.getItem("companyId"),
      isCurrent: 1,
      isActive: 1
    };
  
    userService
      .postData(financialYearsUrl, payload)
      .then((response) => {
        if (response?.data?.status === 200) {
          setIsLoading(false);
          const { data } = response.data;
          dispatch(fyFetchSuccess(data));
          localStorage.setItem("financialYears", JSON.stringify(data));
          localStorage.setItem("financialYear", JSON.stringify(data[0]));

          const psList = permissionList.filter(
            (val) => val.apiEndpoint == "" || !val.apiEndpoint
          );

          const filteredMenus = filterMenusByPermissions(
            navigationMenus,
            psList
          );

          if (filteredMenus.length > 0) {
            const [firstMenuItem] = filteredMenus;

            if (
              !firstMenuItem.subMenus ||
              firstMenuItem.subMenus.length === 0
            ) {
              navigateWithDelay(firstMenuItem.path, navigate);
            } else {
              const filteredSubMenus = filterMenusByPermissions(
                firstMenuItem.subMenus,
                psList
              );
              if (filteredSubMenus.length > 0) {
                navigateWithDelay(filteredSubMenus[0].path, navigate);
              }
            }
          } else errorAlert("Provide Left side menus to proceed.");
        } else {
          dispatch(fyFetchFailure(response.data));
          errorAlert(response?.data?.message || "Something went wrong.");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        errorAlert("Something went wrong");
        setIsLoading(false);
      });
  };
}

export function fetchFinancialYearMonth(
  setIsLoading,
  errorAlert,
) {
  return (dispatch) => {
    setIsLoading(true);  
    userService
      .getData(financialYearMonthUrl)
      .then((response) => {
        if (response?.data?.status === 200) {
          setIsLoading(false);
          const { data } = response.data;
          dispatch(fymFetchSuccess(data));          
        } else {
          dispatch(fymFetchFailure(response.data));
          errorAlert(response?.data?.message || "Something went wrong.");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        errorAlert("Something went wrong");
        setIsLoading(false);
      });
  };
}

export const fyFetchFailure = (data) => {
  return {
    type: FY_FETCH_FAILURE,
    data: data
  };
};
export const fyFetchSuccess = (data) => {
  return {
    type: FY_FETCH_SUCCESS,
    data: data
  };
};

export const fymFetchFailure = (data) => {
  return {
    type: "FYM_FETCH_FAILURE",
    data: data
  };
};
export const fymFetchSuccess = (data) => {
  return {
    type: "FYM_FETCH_SUCCESS",
    data: data
  };
};
